import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import css from '../../booking-form.module.scss';
import Button from '../../../Button';
import { useForm } from 'react-hook-form';
import InputField from '../../../InputField';
import { IconDarkClock, IconEmail, IconUser } from '../../../../../assets/icons';
import Select from '../../../Select';
import DIAL_NUMBERS from '../../../../../json/dial-numbers.json';
import { BOAT_PRICES, BOAT_OPTIONS } from '../../../../../utils/constants';
import currencyFormatterHelper from '../../../../../helpers/currency-formater';
import InputNumberField from '../../../InputNumberField';
import DatePicker from '../../../DatePicker';

const FormTypeThree = ({ className, handleForm, startPassengersPrice = 110.0 }) => {
  // total price
  const [passengersPrice, setPassengersPrice] = useState(startPassengersPrice);
  const [totalPrice, setTotalPrice] = useState(1 * passengersPrice);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {
      passengers: 1,
    },
  });

  const onFormSubmit = async (values) => {
    handleForm({
      ...values,
      departureDate: values.departureDate.toISOString(),
      totalPrice,
      passengersPrice,
    });
  };

  // UPDATE PASSENGERS START PRICE
  useEffect(() => {
    if (watch('boatOption')) {
      setPassengersPrice(BOAT_PRICES[watch('boatOption')] + startPassengersPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('boatOption'), startPassengersPrice]);

  // UPDATE TOTAL PRICE
  useEffect(() => {
    if (watch('passengers')) {
      setTotalPrice(parseInt(watch('passengers'), 10) * passengersPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('passengers'), passengersPrice]);

  return (
    <div>
      <div className={css.totalPriceContainer}>TOTAL: {currencyFormatterHelper(totalPrice)}</div>
      <form className={classNames(css.formWrapper, className)} onSubmit={handleSubmit(onFormSubmit)}>
        <InputField
          errors={errors}
          icon={<IconUser />}
          name="name"
          register={register}
          placeholder="Your name and surname"
        />
        <InputField
          errors={errors}
          icon={<IconEmail />}
          name="email"
          type="email"
          register={register}
          placeholder="Email address"
        />
        <Select errors={errors} name="countryCode" register={register} options={DIAL_NUMBERS} setValue={setValue} />
        <InputField
          errors={errors}
          type="number"
          name="mobile"
          register={register}
          placeholder="Phone number"
          rules={{
            required: true,
            validate: (value) => /^.{4,12}$/.test(value) || 'Mobile phone is too long or too short',
          }}
        />
        <Select
          errors={errors}
          icon={<IconDarkClock />}
          name="boatOption"
          register={register}
          options={BOAT_OPTIONS}
          setValue={setValue}
          placeholder="Boat option"
        />
        <DatePicker
          control={control}
          errors={errors}
          name="departureDate"
          type="text"
          register={register}
          placeholder="Departure time"
        />
        <div className={css.passengers}>
          <p className={css.passengersTitle}>PASSENGERS</p>
          <div className={css.singleRow}>
            <InputNumberField
              errors={errors}
              name="passengers"
              type="number"
              register={register}
              className={css.numberField}
              min={1}
            />
            <p className={css.passengersPrice}>
              x {currencyFormatterHelper(passengersPrice)} ={' '}
              {currencyFormatterHelper(parseInt(watch('passengers'), 10) * passengersPrice)}
            </p>
          </div>
        </div>
        <Button type="submit" text="Book now" />
      </form>
    </div>
  );
};

export default FormTypeThree;
