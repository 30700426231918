import { S3_BASE_URL, BANNERS, IMPORTANT_BRING } from '..';
import {
  PRIVATE_TOURS_BLUE_CAVE,
  PRIVATE_TOURS_SUNSET,
  PRIVATE_TOURS_BOL_SOLTA,
  PRIVATE_TOURS_HVAR_PAKLENI,
  PRIVATE_TOURS_BOL_HVAR_PAKLENI,
  PRIVATE_TOURS_BLUE_LAGOON_3_ISLANDS,
  PRIVATE_TOURS_BLUE_LAGOON_4_ISLANDS,
  PRIVATE_TOURS_BLUE_LAGOON_5_ISLANDS,
} from '../galleries';

export const BANNER_IMAGES = BANNERS.private_tours;

const IMAGE = 'https://via.placeholder.com/600/53449e';

export const GALLERY = (number) => Array(number).fill(IMAGE);

export const PRIVATE_TOURS_CARDS = {
  hvar_and_bol: S3_BASE_URL + '/private-tours-cards/bol-1-min.jpg',
  sunset: S3_BASE_URL + '/private-tours-cards/wine-min.jpg',
  hvar_pakleni: S3_BASE_URL + '/private-tours-cards/hvar-min.jpg',
  blue_cave_five_islands: S3_BASE_URL + '/private-tours-cards/stiniva-min.jpg',
  blue_lagoon_three_islands: S3_BASE_URL + '/private-tours-cards/blue-lagoon-1-min.jpg',
  blue_lagoon_five_islands: S3_BASE_URL + '/private-tours-cards/blue-lagoon-2-min.jpg',
  blue_lagoon_four_islands: S3_BASE_URL + '/private-tours-cards/trogir-min.jpg',
  bol_solta: S3_BASE_URL + '/private-tours-cards/bol-2-min.jpg',
};

export const SUNSET = {
  overview: S3_BASE_URL + '/private-tours/sunset-tour/karta.jpg',
  important_bring: IMPORTANT_BRING,
  sunset_1: S3_BASE_URL + '/private-tours/sunset-tour/sunset-1.jpg',
  sunset_2: S3_BASE_URL + '/private-tours/sunset-tour/sunset-2.jpg',
  sunset_3: S3_BASE_URL + '/private-tours/sunset-tour/sunset-3.jpg',
  gallery: PRIVATE_TOURS_SUNSET,
};

export const BOL_HVAR_PAKLENI = {
  overview: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/karta.jpg',
  important_bring: IMPORTANT_BRING,
  pakleni_1: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/pakleni-1.jpg',
  pakleni_2: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/pakleni-2.jpg',
  pakleni_3: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/pakleni-3.jpg',
  hvar_1: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/hvar-1.jpg',
  hvar_2: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/hvar-2.jpg',
  hvar_3: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/hvar-3.jpg',
  bol_1: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/bol-1.jpg',
  bol_2: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/bol-2.jpg',
  bol_3: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/bol-3.jpg',
  gallery: PRIVATE_TOURS_BOL_HVAR_PAKLENI,
};

export const HVAR_PAKLENI = {
  overview: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/karta.jpg',
  important_bring: IMPORTANT_BRING,
  pakleni_1: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/pakleni-1.jpg',
  pakleni_2: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/pakleni-2.jpg',
  pakleni_3: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/pakleni-3.JPG',
  hvar_1: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/hvar-1.jpg',
  hvar_2: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/hvar-2.jpg',
  hvar_3: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/hvar-3.jpg',
  gallery: PRIVATE_TOURS_HVAR_PAKLENI,
};

export const BOL_SOLTA = {
  overview: S3_BASE_URL + '/private-tours/bol-and-solta-island/karta.jpg',
  important_bring: IMPORTANT_BRING,
  milna_1: S3_BASE_URL + '/private-tours/bol-and-solta-island/milna-1.jpg',
  milna_2: S3_BASE_URL + '/private-tours/bol-and-solta-island/milna-2.jpg',
  stomorska_1: S3_BASE_URL + '/private-tours/bol-and-solta-island/stomorska-1.jpg',
  stomorska_2: S3_BASE_URL + '/private-tours/bol-and-solta-island/stomorska-2.jpg',
  tunel_1: S3_BASE_URL + '/private-tours/bol-and-solta-island/tunnel-1.jpg',
  tunel_2: S3_BASE_URL + '/private-tours/bol-and-solta-island/tunnel-2.jpg',
  zlatni_rat: S3_BASE_URL + '/private-tours/bol-and-solta-island/zlatni-rat.jpg',
  gallery: PRIVATE_TOURS_BOL_SOLTA,
};

export const BLUE_CAVE_FIVE_ISLANDS = {
  overview: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/karta.jpg',
  important_bring: IMPORTANT_BRING,
  blue_cave_1: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/blue-cave-1.jpg',
  blue_cave_2: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/blue-cave-2.jpg',
  blue_cave_3: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/blue-cave-3.jpg',
  blue_lagoon_1: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/blue-lagoon-1.jpg',
  blue_lagoon_2: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/blue-lagoon-2.jpg',
  blue_lagoon_3: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/blue-lagoon-3.jpg',
  pakleni_1: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/pakleni-1.jpg',
  pakleni_2: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/pakleni-2.jpg',
  pakleni_3: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/pakleni-3.jpg',
  stiniva_1: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/stiniva-1.jpg',
  stiniva_2: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/stiniva-2.jpg',
  stiniva_3: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/stiniva-3.jpg',
  hvar_1: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/hvar-10.jpg',
  hvar_2: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/hvar-5.jpg',
  hvar_3: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/hvar-3.jpg',
  gallery: PRIVATE_TOURS_BLUE_CAVE,
};

export const BLUE_LAGOON_THREE_ISLANDS = {
  overview: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/karta.jpg',
  important_bring: IMPORTANT_BRING,
  blue_lagoon_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/blue-lagoon-1.jpg',
  blue_lagoon_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/blue-lagoon-2.webp',
  blue_lagoon_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/blue-lagoon-3.jpg',
  ciovo_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/ciovo-1.jpg',
  ciovo_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/ciovo-2.jpg',
  ciovo_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/ciovo-3.jpg',
  maslinica_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/maslinica-1.jpg',
  maslinica_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/maslinica-2.jpg',
  maslinica_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/maslinica-3.jpg',
  shipwreck_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/shipwreck-1.jpg',
  shipwreck_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/shipwreck-2.jpg',
  shipwreck_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/shipwreck-3.jpg',
  trogir_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/trogir-1.jpg',
  trogir_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/trogir-2.jpg',
  gallery: PRIVATE_TOURS_BLUE_LAGOON_3_ISLANDS,
};

export const BLUE_LAGOON_FOUR_ISLANDS = {
  overview: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/karta.jpg',
  important_bring: IMPORTANT_BRING,
  blue_lagoon_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/blue-lagoon-1.jpg',
  blue_lagoon_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/blue-lagoon-2.webp',
  blue_lagoon_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/blue-lagoon-3.jpg',
  maslinica_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/maslinica-1.jpg',
  maslinica_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/maslinica-2.jpg',
  maslinica_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/maslinica-3.jpg',
  shipwreck_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/shipwreck-1.jpg',
  shipwreck_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/shipwreck-2.jpg',
  shipwreck_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/shipwreck-3.jpg',
  ciovo_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/ciovo-1.jpg',
  ciovo_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/ciovo-2.jpg',
  ciovo_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/ciovo-3.jpg',
  trogir_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/trogir-1.jpg',
  trogir_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/trogir-2.jpg',
  gallery: PRIVATE_TOURS_BLUE_LAGOON_4_ISLANDS,
};

export const BLUE_LAGOON_FIVE_ISLANDS = {
  overview: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/karta.jpg',
  important_bring: IMPORTANT_BRING,
  blue_lagoon_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/blue-lagoon-1.jpg',
  blue_lagoon_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/blue-lagoon-2.webp',
  blue_lagoon_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/blue-lagoon-3.jpg',
  hvar_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/hvar-1.jpg',
  hvar_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/hvar-2.jpg',
  hvar_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/hvar-3.jpg',
  pakleni_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/pakleni-1.jpg',
  pakleni_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/pakleni-2.jpg',
  pakleni_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/pakleni-3.jpg',
  shipwreck_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/shipwreck-1.jpg',
  shipwreck_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/shipwreck-2.jpg',
  shipwreck_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/shipwreck-3.jpg',
  trogir_1: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/trogir-1.jpg',
  trogir_2: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/trogir-2.jpg',
  trogir_3: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/trogir-3.jpg',
  gallery: PRIVATE_TOURS_BLUE_LAGOON_5_ISLANDS,
};
