import React from 'react';
import css from './group-tours.module.scss';
import GroupToursCard from '../../../components/universal/GroupToursCard';
import { GROUP_TOURS } from '../../../data/group-tours';
import { useNavigate } from 'react-router-dom';
import { BANNERS } from '../../../utils/images';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const GroupTours = () => {
  const navigate = useNavigate();

  return (
    <MotionDiv className={css.groupToursPageLayout}>
      <div className={css.bannerWrapper}>
        <Img
          src={BANNERS.group_tours.general.src}
          alt="excursion dalmatia most popular tours"
          className={css.bannerImg}
        />
        <div className={css.filter} />
        <h1>MOST POPULAR TOURS IN SPLIT</h1>
      </div>

      <div className={css.cardsContainer}>
        {GROUP_TOURS.map((groupTour, index) => (
          <GroupToursCard
            link={`/group-tours${groupTour.slug}`}
            key={`group-tour-card-${index}`}
            name={groupTour.name}
            photo={groupTour.photo}
            description={groupTour.description}
            views={groupTour.views}
            duration={groupTour.duration}
            price={groupTour.price}
            onButtonClick={() => navigate(`/group-tours${groupTour.slug}`)}
            priceText={groupTour.slug === '/five-islands-tour-and-blue-cave' ? 'Price starting from:' : 'Price:'}
          />
        ))}
      </div>
    </MotionDiv>
  );
};

export default GroupTours;
