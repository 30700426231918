import { S3_BASE_URL } from '..';

// DECLARE HERE IMAGES
const IMG_WEATHER = S3_BASE_URL + '/blogs/weather/weather.webp';
const IMG_TOP_10_THINGS_TO_DO = S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/split-night.jpg';
const IMG_APARTMENRS = S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/tonci.webp';
const IMG_PLANNING =
  S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/dubrovnik-old-town.webp';
const IMG_ACCOMMODATION =
  S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/acommodation-in-split.webp';
const IMG_BEST_ROMANTIC = S3_BASE_URL + '/blogs/best-romantic-restaurants-in-split/romance.webp';
const IMG_NIGHTLIFE = S3_BASE_URL + '/blogs/nightlife-in-split-the-best-places-for-fun-night-out/split.jpg';
const IMG_TOP_BEACHES = S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/top-10-beaches.webp';
const IMG_CHEAP_EATS = S3_BASE_URL + '/blogs/cheap-eats-in-split-best-fast-foods-sandwich-bars/cheap-food-split.webp';
const IMG_VEGE = S3_BASE_URL + '/blogs/the-best-vegetarian-restaurants-in-split/vege-food.webp';

export const BLOGS_BANNERS = {
  weather: IMG_WEATHER,
  top_10_things_to_do_in_split: IMG_TOP_10_THINGS_TO_DO,
  top_10_croatia_beaches: IMG_TOP_BEACHES,
  the_best_vegetarian_restaurants: IMG_VEGE,
  planning_a_vacation: IMG_PLANNING,
  nightlife_in_split: IMG_NIGHTLIFE,
  cheap_eats_in_split: IMG_CHEAP_EATS,
  best_romantic_restaurants: IMG_BEST_ROMANTIC,
  best_places_for_accommodation: IMG_ACCOMMODATION,
  apartments_in_split: IMG_APARTMENRS,
};

export const WEATHER_IN_CROATIA = {
  winter: {
    src: S3_BASE_URL + '/blogs/weather/winter.webp',
    alt: 'Winter months in Croatia',
  },
  summer: {
    src: S3_BASE_URL + '/blogs/weather/summer.webp',
    alt: 'Summer months in Croatia',
  },
};

export const PLANNING_VACATION = {
  location: {
    src: S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/croatia-location.webp',
    alt: 'Croatia location',
  },
  food: {
    src: S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/croatian-food.webp',
    alt: 'Croatian food',
  },
  dubrovnik_architecture: {
    src: S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/dubrovnik-architecture.webp',
    alt: 'Dubrovnik architecture',
  },
  dubrovnik_old_town: {
    src: S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/dubrovnik-old-town.webp',
    alt: 'Dubrovnik old town',
  },
  dubrovnik: {
    src: S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/dubrovnik.webp',
    alt: 'Dubrovnik',
  },
  yachts: {
    src: S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/yachts-and-boats.webp',
    alt: 'Yachts and boats',
  },
  zlatni_rat: {
    src: S3_BASE_URL + '/blogs/planning-a-vacation-in-croatia-heres-what-you-need-to-know/zlatni-rat.webp',
    alt: 'Zlatni rat',
  },
};

export const APARTMENTS_SPLIT = {
  view: {
    src: S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/view.webp',
    alt: 'Split View',
  },
  apartment1: {
    src: S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/teo.webp',
    alt: 'Apartment Split Explore',
  },
  apartment2: {
    src: S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/tonci.webp',
    alt: 'Bepo Luxury Apartments',
  },
  apartment3: {
    src: S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/dedic.webp',
    alt: 'Split ViewApartment City and Sea',
  },
  apartment4: {
    src: S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/visnja.webp',
    alt: 'Studio Vanilla',
  },
  apartment5: {
    src: S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/marija.webp',
    alt: 'Apartment Dalmatian',
  },
  apartment6: {
    src: S3_BASE_URL + '/blogs/apartments-in-split-with-direct-contact-to-the-owners/marin.webp',
    alt: 'Apartment Marin',
  },
};

export const THINGS_TO_DO = {
  view: {
    src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/mosor.webp',
    alt: 'Split View',
  },
  splitBeaches: [
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/split-night.jpg',
      alt: 'Split beaches 1',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/split.jpg',
      alt: 'Split beaches 2',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/otok.jpg',
      alt: 'Split beaches 3',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/top-view.webp',
      alt: 'Split beaches 4',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/bacvice-2.webp',
      alt: 'Split beaches 5',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/bacvice.webp',
      alt: 'Split beaches 6',
    },
  ],
  shopping: [
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/trogir.jpg',
      alt: 'Shopping in Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/salona.webp',
      alt: 'Shopping in Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/mall.webp',
      alt: 'Shopping in Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/omis.webp',
      alt: 'Shopping in Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/city.webp',
      alt: 'Shopping in Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/night.webp',
      alt: 'Shopping in Split',
    },
  ],
  adriatic: [
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/pakleni.webp',
      alt: 'Adriatic sea and Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/hvar.jpg',
      alt: 'Adriatic sea and Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/plitvice.webp',
      alt: 'Adriatic sea and Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/bol.jpg',
      alt: 'Adriatic sea and Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/krka.webp',
      alt: 'Adriatic sea and Split',
    },
    {
      src: S3_BASE_URL + '/blogs/top-10-things-to-do-in-split/shipwreck.webp',
      alt: 'Adriatic sea and Split',
    },
  ],
};

export const ACCOMMODATION = {
  hotels: [
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/atrium.webp',
      alt: 'Accommodation in hotels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/hotel-park.webp',
      alt: 'Accommodation in hotels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/hotel-lav.webp',
      alt: 'Accommodation in hotels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/medena.webp',
      alt: 'Accommodation in hotels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/radisson-blu.webp',
      alt: 'Accommodation in hotels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/hotel-president.webp',
      alt: 'Accommodation in hotels',
    },
  ],
  villas: [
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/villa-split.webp',
      alt: 'Accommodation villas',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/villa-tomislav.webp',
      alt: 'Accommodation villas',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/villa-red-land.webp',
      alt: 'Accommodation villas',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/villa-bila.webp',
      alt: 'Accommodation villas',
    },
  ],
  hostels: [
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/hostel-goli-i-bosi.webp',
      alt: 'Accommodation hostels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/hostel-snooze-i-booze.webp',
      alt: 'Accommodation hostels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/hostel-split-back-packer.webp',
      alt: 'Accommodation hostels',
    },
    {
      src: S3_BASE_URL + '/blogs/best-places-for-accommodation-in-around-split/hostel-split.webp',
      alt: 'Accommodation hostels',
    },
  ],
};

export const ROMANTIC_RESTAURANTS = {
  split: {
    src: S3_BASE_URL + '/blogs/best-romantic-restaurants-in-split/split-riva.webp',
    alt: 'Split',
  },
  grill: [
    {
      src: S3_BASE_URL + '/blogs/best-romantic-restaurants-in-split/vidilica.webp',
      alt: 'Caper Grill',
    },
    {
      src: S3_BASE_URL + '/blogs/best-romantic-restaurants-in-split/caper-grill.webp',
      alt: 'Caper Grill',
    },
    {
      src: S3_BASE_URL + '/blogs/best-romantic-restaurants-in-split/dvor.webp',
      alt: 'Caper Grill',
    },
  ],
};

export const NIGHTLIFE = {
  vanilla: {
    src: S3_BASE_URL + '/blogs/nightlife-in-split-the-best-places-for-fun-night-out/vanilla.webp',
    alt: 'Vanilla',
  },
  central: {
    src: S3_BASE_URL + '/blogs/nightlife-in-split-the-best-places-for-fun-night-out/central.webp',
    alt: 'Central',
  },
  fabrique: {
    src: S3_BASE_URL + '/blogs/nightlife-in-split-the-best-places-for-fun-night-out/fabrique1.webp',
    alt: 'Fabrique',
  },
  bacvice: {
    src: S3_BASE_URL + '/blogs/nightlife-in-split-the-best-places-for-fun-night-out/bacvice-clubs.webp',
    alt: 'Bacvice beach',
  },
  ultra: {
    src: S3_BASE_URL + '/blogs/nightlife-in-split-the-best-places-for-fun-night-out/ultra.webp',
    alt: 'Ultra festival',
  },
};

export const BEACHES = {
  zlatni_rat: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/bol.jpg',
    alt: 'Zlatni rat',
  },
  stiniva: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/blue-cave.jpg',
    alt: 'Stiniva',
  },
  lovrecina: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/lovrecina.webp',
    alt: 'Lovrečina Bay',
  },
  pakleni: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/pakleni.jpg',
    alt: 'Pakleni islands',
  },
  makarska: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/makarska.webp',
    alt: 'Makarska',
  },
  zrce: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/zrce.webp',
    alt: 'Zrće',
  },
  bacvice: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/bacvice.webp',
    alt: 'Bačvice',
  },
  sakarun: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/blue-lagoon.webp',
    alt: 'Sakarun',
  },
  omis: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/omis.webp',
    alt: 'Omiš',
  },
  banje: {
    src: S3_BASE_URL + '/blogs/top-10-croatia-beaches-find-your-slice-of-paradise/banje-beach.webp',
    alt: 'Banje',
  },
};

export const CHEAP_EATS = {
  food: [
    {
      src: S3_BASE_URL + '/blogs/cheap-eats-in-split-best-fast-foods-sandwich-bars/paulina.webp',
      alt: 'Paulina',
    },
    {
      src: S3_BASE_URL + '/blogs/cheap-eats-in-split-best-fast-foods-sandwich-bars/kebab-house.webp',
      alt: 'Kebab house',
    },
  ],
};

export const VEGE = {
  food: [
    {
      src: S3_BASE_URL + '/blogs/the-best-vegetarian-restaurants-in-split/marta.jpeg',
      alt: 'Vege',
    },
    {
      src: S3_BASE_URL + '/blogs/the-best-vegetarian-restaurants-in-split/makrovega.webp',
      alt: 'Vege',
    },
    {
      src: S3_BASE_URL + '/blogs/the-best-vegetarian-restaurants-in-split/up-cafe.webp',
      alt: 'Vege',
    },
    {
      src: S3_BASE_URL + '/blogs/the-best-vegetarian-restaurants-in-split/vege.webp',
      alt: 'Vege',
    },
  ],
};
