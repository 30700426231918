import React, { useState } from 'react';
import css from './rent-a-boat.module.scss';
import PrivateToursForm from '../../../components/universal/PrivateToursForm';
import { IconDropdown } from '../../../assets/icons';
import { BANNERS } from '../../../utils/images';
import classNames from 'classnames';
import TabsContent from './TabsContent';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const TABS_CONTENT = [
  <h3>
    SMALLER SPEEDBOATS
    <br />
    <span>FOR UP TO 8 PEOPLE</span>
  </h3>,
  <h3>
    MIDSIZE SPEEDBOATS
    <br />
    <span>FOR UP TO 12 PEOPLE</span>
  </h3>,
];

const PrivateTours = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
    setIsOpened(false);
  };

  return (
    <MotionDiv className={css.boatTransfersMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.rent_a_boat.src} alt={BANNERS.rent_a_boat.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>RENT A BOAT</h1>
      </div>

      {/* CONTENT CONTAINER */}
      <div className={css.contentContainer}>
        <div className={css.contentContainerSection1}>
          <div className={css.privateTourContent}>
            <div className={css.privateTourContentBox}>
              <h2>Rent a Boat in Split & be your own skipper</h2>
              <p style={{ marginBottom: '16px' }}>
                If you're looking for a fun and adventurous way to explore the Adriatic islands, you can rent a boat in
                Split or Trogir. With our boat rental services, you can be your own skipper and enjoy in complete
                privacy while cruising around the stunning islands with your family and friends.
              </p>
              <p style={{ marginBottom: '16px' }}>
                To rent a boat without a skipper, you must have a valid certified skipper license. You DO NOT need a
                license issued in Croatia. Any valid skipper license issued in your country is sufficient for you to
                rent a speedboat.
              </p>
              <p style={{ marginBottom: '16px' }}>
                These speedboats are the perfect option for smaller groups (4 - 6 people), couples, or anyone looking
                for a budget-friendly and fast-paced adventure.
              </p>
              <p>
                When it comes to boat rental in Split, our speedboats are best suited for exploring the smaller towns
                and hidden beaches of mid-Adriatic islands, such as Brač, Hvar, Šolta, and Blue Lagoon.
              </p>
            </div>
          </div>
          <div className={css.bookPrivateTour}>
            <h2>RENT A BOAT</h2>
            <PrivateToursForm />
          </div>
        </div>

        {/* TABS */}
        <div className={css.contentContainerSection2}>
          <div className={css.tabsWrapper}>
            <div
              className={classNames(css.activeTabName, { [css.isOpened]: isOpened })}
              onClick={() => setIsOpened(!isOpened)}
            >
              <div>{TABS_CONTENT[activeTab]}</div>
              <IconDropdown />
            </div>
            {isOpened ? (
              <div className={css.dropdownWrapper}>
                <div className={css.dropdownItem} onClick={() => changeTab(0)}>
                  <h3>SMALLER SPEEDBOATS</h3>
                  <h4>FOR UP TO 8 PEOPLE</h4>
                </div>
                <div className={css.dropdownItem} onClick={() => changeTab(1)}>
                  <h3>MIDSIZE SPEEDBOATS</h3>
                  <h4>FOR UP TO 12 PEOPLE</h4>
                </div>
              </div>
            ) : null}
          </div>

          {/* TABS CONTENT */}
          <TabsContent tabIndex={activeTab} />
        </div>
      </div>

      <div className={css.endTextContainer}>
        <h2>Rent a boat in Split: Terms and Conditions</h2>
        <p>
          The list of boats that we rent without the skipper you can find above. Besides the skipper license, you need
          to bring your passport or personal ID card. Also, you need to be at least 21 years old. You can rent a boat
          for one day or several days.
        </p>
        <p>
          You can pick it up from 8:00AM and return it until 7:00 PM, on your last day of rent. Whether the last day is
          the first day or seventh day of weekly rent.
        </p>
      </div>
      <div className={css.endTextContainer}>
        <h2>Pick up point</h2>
        <p>
          At our boat rental service in Split, our pick-up point is usually at the Split Waterfront, near the promenade.
          However, we understand that some customers may prefer a different pick-up location. That's why we offer
          customizable pick-up locations to suit your needs.
        </p>
        <p>
          If you want the boat to be delivered to a specific location, we can arrange this for you. Please note that we
          may charge an extra fee for this special service, depending on the circumstances. Feel free to contact us with
          any questions if you want to rent a boat in Split or Trogir.
        </p>
        <p>
          Don't let transportation logistics hold you back from enjoying the beautiful Adriatic islands. Contact us to
          arrange a boat rental pick-up location that works for you. We're here to make your experience as seamless and
          enjoyable as possible.
        </p>
      </div>
      <div
        className={css.endTextContainer}
        style={{
          padding: '20px',
          background: '#F5F5F5',
        }}
      >
        <h2>How to Rent a Boat in Split</h2>
        <p>
          To rent a boat, you will need to pay a deposit at least one day in advance. The deposit is 30% of the total
          price, and the remaining 70% will be paid when you pick up the boat. You can book the boat up to one month in
          advance, and we recommend booking early to secure the boat you want.
        </p>
        <p>
          When our skipper delivers the boat at the agreed time and location, they will go over the basic rules with the
          person who will be sailing the boat. It is essential that the person who will rent a boat and steer the boat
          has a VALID SKIPPER LICENSE.
        </p>
        <p>
          If you have additional questions or want to rent a boat in Split, please contact us via email, WhatsApp, or
          Viber at +385 95 826 7981. We're here to make your boat rental experience as enjoyable and straightforward as
          possible.
        </p>
      </div>
    </MotionDiv>
  );
};

export default PrivateTours;
