import React from 'react';
import css from './cookie-policy.module.scss';
import { BANNERS } from '../../../utils/images';
import { Link } from 'react-router-dom';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const CookiePolicy = () => {
  return (
    <MotionDiv className={css.cookiePolicyPageLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.cookie_policy.src} alt="excursion dalmatia cookie policy" className={css.bannerImg} />
        <div className={css.filter} />
        <h1>COOKIE POLICY</h1>
      </div>

      <div className={css.contentContainer}>
        <h2>About Our Cookies</h2>
        <p>
          A cookie is a string of information that <span>excursion-dalmatia.com</span> stores on your computer, which
          your browser provides to <span>excursion-dalmatia.com</span> each time you return to the site. We use cookies
          to help us identify and track visitors, their use of our website, and their website access preferences.
        </p>
        <h2>Google Analytics Cookies</h2>
        <p>
          <span>excursion-dalmatia.com</span> uses Google Analytics to analyze the use of this website. Google Analytics
          collects demographic and interest data, and generates statistical and other information about website use, by
          means of cookies. These cookies are kept on your computer. We share this information with Google, which will
          store and use this information on its servers. Google's privacy policy is available{' '}
          <Link to={'https://policies.google.com/privacy'} target="_blank">
            here
          </Link>
          .
        </p>
        <p>
          <span>excursion-dalmatia.com</span> uses information from Google Analytics to track the number of visits to
          the website, browsers and operating systems. No personally identifiable information is obtained from these
          cookies. We use the information that Google Analytics generates relating to our website to create reports
          about the use of the site. These reports allow us to update the site and improve your usage of it.
        </p>
        <p>
          If you do not wish your data to be collected via Google Analytics, you can{' '}
          <Link to={'https://tools.google.com/dlpage/gaoptout/'} target="_blank">
            install its opt-out browser extension or add-on…
          </Link>
        </p>
        <h2>Refusing Cookies</h2>
        <p>
          You can set your browser to accept or refuse all cookies automatically, or notify you when a cookie is being
          requested. Taking this action should not cause a problem with the continued use of our site. Consult the Help
          section of your browser for guidance on how to refuse all cookies or to notify you when a cookie is requested.
        </p>
        <p>
          If you choose to refuse <span>excursion-dalmatia.com</span>'s cookies, you may not be able to fully experience
          the interactive features of the site.
        </p>
      </div>
    </MotionDiv>
  );
};

export default CookiePolicy;
