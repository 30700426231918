import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { BLUE_CAVE_FIVE_ISLANDS } from '../../../../../utils/images/private-tours';
import {
  IconBlueCalendar,
  IconBlueClock,
  IconBlueFlag,
  IconBlueLocation,
  IconDropdown,
} from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={BLUE_CAVE_FIVE_ISLANDS.important_bring} alt="Important to bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and crew.</p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const TABS = ['OVERVIEW', 'IMPORTANT', 'GALLERY'];

const FirstPart = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
    setIsOpened(false);
  };

  const tabContent = {
    0: <Img src={BLUE_CAVE_FIVE_ISLANDS.overview} alt="overview" className={classNames(css.overviewImg, css.fadeIn)} />,
    1: <ImportantTab />,
    2: <Gallery images={BLUE_CAVE_FIVE_ISLANDS.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div>
          <IconBlueFlag />
          <p>Tour type: daily</p>
        </div>
        <div>
          <IconBlueCalendar />
          <p>Availability: From 1th April</p>
        </div>
        <div>
          <IconBlueClock />
          <p>Duration: 10 hours</p>
        </div>
        <div>
          <IconBlueLocation />
          <p>
            Location: Trg Franje Tudmana <br /> 21 000, Split, Hrvatska
          </p>
        </div>
      </div>

      <div className={css.textBox} style={{ marginBottom: '32px' }}>
        <h2>Private Blue Cave & 5 islands tour</h2>
        <p>
          Unlock a new world of adventure and join us on an unforgettable journey to explore the stunning Blue Cave & 5
          islands of Croatia!
        </p>
        <p>
          On this full-day tour, you will get to experience the magnificence of some of Europe's most beautiful and
          hidden gems. From chrystal clear waters to hidden caves, each moment of this tour will give you a unique
          chance to explore the extraordinary beauty of the Adriatic Sea.
        </p>
        <p>
          Swim and snorkel in the clearest waters while admiring the 50 shades of blue, uncover stories of the ancient
          architecture and explore Croatia you've never seen before.
        </p>
        <p>
          Our experienced and passionate tour guides will give you an insight into the culture, history and beauty of
          the islands and caves. Let us take you on a journey to discover the stunning beauty of Croatia's Blue Cave & 5
          islands.
        </p>
      </div>

      <div className={css.textBox} style={{ marginBottom: '32px' }}>
        <h2>Private Blue Cave & 5 islands tour: Blue Cave</h2>
        <p>Discover the unique Croatian natural phenomenon.</p>
        <p>Unlock the million shades of blue hidden inside the Blue Cave of Biševo, located near Vis island.</p>
        <p>
          As you descend into this gorgeous oasis, you'll be mesmerized by the grandeur of nature's creation. The cave's
          naturally formed walls, illuminated by the sun's rays, will take your breath away.
        </p>
        <p>This is a trip that everyone should experience at least once in a lifetime.</p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_CAVE_FIVE_ISLANDS.blue_cave_1} alt="Blue cave 1" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.blue_cave_2} alt="Blue cave 2" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.blue_cave_3} alt="Blue cave 3" />
        </div>
      </div>

      <div className={css.textBox} style={{ marginBottom: '32px' }}>
        <h2>Private Blue Cave & 5 islands tour: Stiniva</h2>
        <p>Take a journey with us to the beautiful and secluded Stiniva beach.</p>
        <p>
          Easily accessible by boat and boasting chrystal clear waters perfect for swimming and snorkeling. Stiniva Cove
          was once a cave, but the roof of the cave collapsed.
        </p>
        <p>
          With tall cliffs surrounding the beach and a stunning view, you don't want to miss out on this unique
          experience. Immerse yourself in the breathtaking beauty of Stiniva Cove and make unforgettable memories.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_CAVE_FIVE_ISLANDS.stiniva_1} alt="Stiniva 1" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.stiniva_2} alt="Stiniva 2" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.stiniva_3} alt="Stiniva 3" />
        </div>
      </div>

      <div className={css.textBox} style={{ marginBottom: '32px' }}>
        <h2>Private Blue Cave & 5 islands tour: Budikovac island</h2>
        <p>
          With its chrystal clear turquoise lagoon, white sand beaches and lush greenery, this island paradise offers
          visitors the perfect opportunity to relax, unwind and enjoy spectacular views of the Adriatic Sea.
        </p>
        <p>
          Swim, snorkel or simply bask in the sun while admiring the array of local marine life. Take a stroll along the
          beach and soak up the peaceful atmosphere with friends and family.
        </p>
        <p>Let the natural beauty of the Budikovac Island be the backdrop to your next unforgettable vacation.</p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_CAVE_FIVE_ISLANDS.blue_lagoon_1} alt="Budikovac 1" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.blue_lagoon_2} alt="Budikovac 2" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.blue_lagoon_3} alt="Budikovac 3" />
        </div>
      </div>

      <div className={css.textBox} style={{ marginBottom: '16px' }}>
        <h2>Private Blue Cave & 5 islands tour: Pakleni islands</h2>
        <p>
          This string of islands situated in front of Hvar offers unforgettable experiences. These islands are protected
          monuments of nature.
        </p>
      </div>

      <div className={css.textBox} style={{ marginBottom: '32px' }}>
        <h2>Palmižana bay</h2>
        <p>
          Uncover the hidden beauty of Palmižana Bay - a paradise nestled in the lush pine tree forest. Take a dip in
          the chrystal clear turquoise waters and soak up the sun's rays.
        </p>
        <p>
          Enjoy in the relaxed atmosphere of the beach bars or sample the original Mediterranean cuisine. Visit a
          beautiful botanical garden with exotic plants from all over the world.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_CAVE_FIVE_ISLANDS.pakleni_1} alt="Pakleni 1" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.pakleni_2} alt="Pakleni 2" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.pakleni_3} alt="Pakleni 3" />
        </div>
      </div>

      <div className={css.textBox} style={{ marginBottom: '32px' }}>
        <h2>Private Blue Cave & 5 islands tour: Hvar</h2>
        <p>
          Discover the stunning beauty of Hvar. From historic sites and ruins to hidden beaches and secluded Coves, this
          picturesque island is really something special.
        </p>
        <p>
          Explore the narrow, cobblestone streets and stone houses of the town center or bask in the sunshine. Inviting
          restaurants, lively bars, and unique cultural attractions, Hvar is the ideal destination for everyone.
        </p>
        <p>Abundant nightlife is something Hvar is famous for and the reason why many people keep returning here.</p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_CAVE_FIVE_ISLANDS.hvar_1} alt="Hvar 1" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.hvar_2} alt="Hvar 2" />
          <Img src={BLUE_CAVE_FIVE_ISLANDS.hvar_3} alt="Hvar 3" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 24px' }} />

      <div className={css.tabsContainer}>
        <div className={css.tabsWrapper}>
          <div
            className={classNames(css.activeTabName, { [css.isOpened]: isOpened })}
            onClick={() => setIsOpened(!isOpened)}
          >
            {TABS[activeTab]}
            <IconDropdown />
          </div>
          {isOpened ? (
            <div className={css.dropdownWrapper}>
              {TABS.map((tab, index) => (
                <div key={`tab-${index}`} className={css.dropdownItem} onClick={() => changeTab(index)}>
                  {tab}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {tabContent[activeTab]}

      <div className={css.devider} style={{ margin: '24px 0px' }} />
    </div>
  );
};

export default FirstPart;
