import React from 'react';
import css from './home.module.scss';
import './whatsapp.scss';
import Banner from '../../../components/desktop/Banner';
import TextCarousel from '../../../components/universal/TextCarousel';
import PrivateTours from '../../../components/desktop/PrivateTours';
import OurBoats from '../../../components/desktop/OurBoats';
import SpeedboatTransfers from '../../../components/desktop/SpeedboatTransfers';
import MostPopularGroupTours from '../../../components/desktop/MostPopularGroupTours';
import RentBoat from '../../../components/universal/RentBoat';
import Splitter from '../../../components/universal/Splitter';
import Tripadvisor from '../../../components/desktop/Tripadvisor';
import TopPrivateTours from '../../../components/desktop/TopPrivateTours';
import TopBlogs from '../../../components/desktop/TopBlogs';
import MotionDiv from '../../../components/universal/MotionDiv';
// wapp widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { WHATSAPP_MOBILE } from '../../../utils/constants';

const Home = () => {
  return (
    <MotionDiv className={css.homePageLayout}>
      <Banner />

      <TextCarousel />

      <PrivateTours />

      <TopPrivateTours />

      <OurBoats />

      <SpeedboatTransfers />

      <MostPopularGroupTours />

      <RentBoat />

      <Splitter />

      <Tripadvisor />

      <TopBlogs />

      <div className="whatsAppWidget">
        <WhatsAppWidget
          sendButtonText="Start Chat"
          phoneNumber={WHATSAPP_MOBILE}
          companyName="Maria"
          replyTimeText="Typically replies in minutes"
          message={`Hi there! \n\nIf you need some info about tours or anything else, I'm here to help!`}
        />
      </div>
    </MotionDiv>
  );
};

export default Home;
