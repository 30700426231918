import React from 'react';
import css from './tripadvisor.module.scss';
import { TRIPADVISOR } from '../../../utils/images';
import Img from '../../universal/Img';
import { SOCIAL_MEDIA_LINKS } from '../../../utils/constants';

const Tripadvisor = () => {
  return (
    <div className={css.tripadvisorWrapper}>
      <Img src={TRIPADVISOR.tripadvisor_2022.src} alt="tripadvisor award 2022" className={css.mainPhoto} />

      <div className={css.tripadvisorInfo}>
        <h2>TOGETHER, WE WILL BECOME BETTER!</h2>
        <p>
          If you liked our tours from Split, please leave your honest review on TripAdvisor. We need your feedback to
          improve our service and provide you with the best time of your life. Only with you, we can become better!
          Thank you.
        </p>
      </div>

      <div className={css.tripadvisorReviews}>
        <h2>LEAVE YOUR REVIEW</h2>
        <a href={SOCIAL_MEDIA_LINKS.tripadvisor} target="_blank" rel="noreferrer">
          Click here to check out for our TripAdvisor reviews!
        </a>
        <div className={css.images}>
          <Img src={TRIPADVISOR.tripadvisor_01.src} alt="tripadvisor 1" />
          <Img src={TRIPADVISOR.tripadvisor_02.src} alt="tripadvisor 2" />
          <Img src={TRIPADVISOR.tripadvisor_03.src} alt="tripadvisor 3" />
          <Img src={TRIPADVISOR.tripadvisor_04.src} alt="tripadvisor 4" />
        </div>
      </div>
    </div>
  );
};

export default Tripadvisor;
