import { BLOGS_BANNERS } from '../utils/images/blogs';

export const TOP_BLOGS = [
  // {
  //   name: 'WEATHER IN CROATIA',
  //   photo: BLOGS_BANNERS.weather,
  //   slug: 'weather-in-croatia',
  // },
  {
    name: 'APARTMENTS IN SPLIT WITH DIRECT CONTACT TO THE OWNERS',
    photo: BLOGS_BANNERS.apartments_in_split,
    slug: 'apartments-in-split-with-direct-contact-to-the-owners',
  },
  {
    name: "PLANNING A VACATION IN CROATIA? HERE'S WHAT YOU NEED TO KNOW",
    photo: BLOGS_BANNERS.planning_a_vacation,
    slug: 'planning-a-vacation-in-croatia-heres-what-you-need-to-know',
  },
  {
    name: 'TOP 10 THINGS TO DO IN SPLIT',
    photo: BLOGS_BANNERS.top_10_things_to_do_in_split,
    slug: 'top-10-things-to-do-in-split',
  },
  {
    name: 'BEST PLACES FOR ACCOMMODATION IN & AROUND SPLIT',
    photo: BLOGS_BANNERS.best_places_for_accommodation,
    slug: 'best-places-for-accommodation-in-around-split',
  },
  {
    name: 'BEST ROMANTIC RESTAURANTS IN SPLIT',
    photo: BLOGS_BANNERS.best_romantic_restaurants,
    slug: 'best-romantic-restaurants-in-split',
  },
  {
    name: 'NIGHTLIFE IN SPLIT: THE BEST PLACES FOR FUN NIGHT OUT',
    photo: BLOGS_BANNERS.nightlife_in_split,
    slug: 'nightlife-in-split-the-best-places-for-fun-night-out',
  },
  {
    name: 'TOP 10 CROATIA BEACHES - FIND YOUR SLICE OF PARADISE',
    photo: BLOGS_BANNERS.top_10_croatia_beaches,
    slug: 'top-10-croatia-beaches-find-your-slice-of-paradise',
  },
  {
    name: 'CHEAP EATS IN SPLIT: BEST FAST FOODS & SANDWICH BARS',
    photo: BLOGS_BANNERS.cheap_eats_in_split,
    slug: 'cheap-eats-in-split-best-fast-foods-sandwich-bars',
  },
  {
    name: 'THE BEST VEGETARIAN RESTAURANTS IN SPLIT',
    photo: BLOGS_BANNERS.the_best_vegetarian_restaurants,
    slug: 'the-best-vegetarian-restaurants',
  },
];
