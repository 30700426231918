import classNames from 'classnames';
import React, { useState } from 'react';
import css from './img.module.scss';
import { DEFAULT_IMAGE } from '../../../utils/images';

const Img = ({ src, alt, className, onClick, rest }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  return (
    <img
      className={classNames(css.img, { [css.photoVisible]: !isLoading }, className)}
      src={isError ? DEFAULT_IMAGE : src || DEFAULT_IMAGE}
      alt={alt}
      onClick={onClick}
      aria-hidden="true"
      onError={() => setIsError(true)}
      onLoad={() => setIsLoading(false)}
      {...rest}
      loading="lazy"
    />
  );
};

export default Img;
