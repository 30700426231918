import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { GROUP_TOUR_3_ISLANDS_SHIPWRECK } from '../../../../../utils/images/group-tours';
import {
  IconBlueCalendar,
  IconBlueClock,
  IconBlueFlag,
  IconBlueLocation,
  IconDropdown,
} from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img
        src={GROUP_TOUR_3_ISLANDS_SHIPWRECK.important_bring}
        alt="important bring"
        className={css.importantContainerImg}
      />
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, fuel and skipper, safety gear, snorkeling equipment, lunch, unlimited drinks(white Wine,juice and
        water) are included in the price of the tour.
      </p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const TABS = ['OVERVIEW', 'IMPORTANT', 'GALLERY'];

const FirstPart = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
    setIsOpened(false);
  };

  const tabContent = {
    0: (
      <Img
        src={GROUP_TOUR_3_ISLANDS_SHIPWRECK.overview}
        alt="overview"
        className={classNames(css.overviewImg, css.fadeIn)}
      />
    ),
    1: <ImportantTab />,
    2: <Gallery images={GROUP_TOUR_3_ISLANDS_SHIPWRECK.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div>
          <IconBlueFlag />
          <p>Tour type: daily</p>
        </div>
        <div>
          <IconBlueCalendar />
          <p>Availability: From 1th April</p>
        </div>
        <div>
          <IconBlueClock />
          <p>Duration: 8.5 hours</p>
        </div>
        <div>
          <IconBlueLocation />
          <p>
            Location: Trg Franje Tudmana <br /> 21 000, Split, Hrvatska
          </p>
        </div>
      </div>

      <div className={css.textBox}>
        <h2 style={{ fontSize: '26px' }}>Three islands tour with a big boat</h2>
        <p>
          If you're looking for a thrilling full day adventure that allows you to explore the stunning beauty of the
          Adriatic Sea, the Three Islands Tour is the perfect choice.
        </p>
        <p>
          This full day tour takes you on a journey to some of the most picturesque spots in the region, including the
          Shipwreck at Nečujam bay, town Maslinica on Šolta island, and Blue Lagoon, all on a big boat. With lunch and
          drinks included in the price, it's an affordable way to experience the magic of these three islands.
        </p>
        <p>
          During the tour, you'll have plenty of opportunities to swim and snorkel in the crystal-clear waters of the
          Adriatic Sea.
        </p>
        <p>
          Three Islands Tour is one of the most popular trips, considering how much you pay and what you get for a full
          day of fun.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Why choose Three Islands Tour</h2>
        <p>
          Are you looking for a fun and budget-friendly boat tour? Our Three Islands Tour is the perfect choice for
          families with kids, as well as groups of two or solo travelers. There is something for everyone on this trip.
        </p>
        <p>On top of that, you'll have unlimited free drinks onboard, including water, juice and wine.</p>
        <p>
          Our big boat also offers more shade and space than speedboats, making it perfect for those who want to relax
          and enjoy the scenery.
        </p>
        <p>
          Whether you're traveling with children, prefer a slower pace, or if you like to jump from the top deck of a
          big boat. The Three Islands Tour is the best value boat tour near Split. Book your spot now and get ready for
          an unforgettable experience.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Shipwreck at Nečujam bay</h2>
        <p>
          Nečujam Bay, located on the Island of Šolta, is our first stop on the Three Islands Tour. Here, we anchor near
          an ancient shipwreck that provides the perfect opportunity for swimming, snorkeling, jumping off the boat, or
          simply soaking up the sun.
        </p>
        <p>
          With our free snorkeling equipment, you can explore the shipwreck and the secrets of Šolta Island hidden
          beneath the sea and uncover the island's lost time and history. It's a fascinating way to start your adventure
          on the Three Islands Tour.
        </p>
        <p>
          After our stop at Nečujam Bay, we usually organize lunch for our guests. Depending on the number of people
          onboard, lunch is either provided at a restaurant in Maslinica or on the boat during the cruise to our second
          destination.
        </p>
        <p>
          World's most famous Croatian writer, Marko Marulić spent his last years here and dedicated verses to Nečujam
          bay. Also, this bay was frequently visited by Roman emperor Diocletian.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_3_ISLANDS_SHIPWRECK.shipwreck.map((img, index) => (
            <Img key={`shipwreck-${index}`} src={img} alt={`shipwreck-${index}`} className={css.imgsContainerImg} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Maslinica on island Šolta</h2>
        <p>
          Our second destination on the Three Islands Tour is the charming town of Maslinica, located in a bay on the
          western side of Šolta Island.
        </p>
        <p>
          Maslinica, which in Croatian means little olive, used to be a fisherman's village, but it slowly transformed
          into a tourist and nautical center of the island, welcoming an increasing number of guests and orchards lovers
          each year.
        </p>
        <p>
          During your free time in Maslinica, take a stroll around the town's charming old stone houses or visit the
          medieval castle Martinis Marchi, dating back to 1603. You can also relax in one of the town's many cafes and
          restaurants or simply swim or walk along its lovely pebble beaches.
        </p>
        <p>
          For the best view of Maslinica, climb the small hill above castle Martinis Marchi. From there, you can see the
          entire town, including the beaches located on the western side.
        </p>
        <p>
          Don't forget to stop by the small shops in the town center to purchase souvenirs or buy lavender and olive
          oil, synonymous with the town and the island.
        </p>
        <p>
          In 2012, as a confirmation of its quality and beauty, Maslinica received the national award as the 3rd best
          tourist destination for up to 1,000 inhabitants on the Adriatic, and in 2017 the national award for the most
          successful authentic coastal destination.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_3_ISLANDS_SHIPWRECK.maslinica.map((img, index) => (
            <Img key={`maslinica-${index}`} src={img} alt={`maslinica-${index}`} className={css.imgsContainerImg} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon at Drvenik Island</h2>
        <p>Before heading back to Split, our last stop on the Three Islands Tour is also the best one, Blue Lagoon.</p>
        <p>
          This secluded lagoon is nestled between two small islands, Krknjaši and Drvenik, and offers untouched nature,
          crystal-clear turquoise sea, and a shallow sandy seabed. Perfect place for swimming and especially snorkeling.
        </p>
        <p>
          The sun's rays reflect from the white sandy bottom, creating a mesmerizing game of colors and shades of blue
          and green. It's an unforgettable sight that you'll remember for a long time.
        </p>
        <p>
          Please note that while our snorkeling equipment is free, it is limited in numbers, and the boats can
          accommodate between 60 to 120 people. You may have to wait a bit for your turn, but you can always bring your
          own equipment with you.
        </p>
        <p>
          Since the lagoon is quite shallow, the boats can't dock at the pier, and we usually anchor in the middle of
          the lagoon. However, many of our guests prefer jumping from the boat, especially from its sundeck.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_3_ISLANDS_SHIPWRECK.blue_lagoon.map((img, index) => (
            <Img key={`blue_lagoon-${index}`} src={img} alt={`blue_lagoon-${index}`} className={css.imgsContainerImg} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Three Islands Tour - The Boat</h2>
        <p>
          Discover the breathtaking beauty of Šolta Island with big boats, perfect for exploring top attractions like
          the shipwreck at Necujam Bay, Maslinica and the stunning Blue Lagoon.
        </p>
        <p>
          Our boats have two decks, providing ample space for relaxation and adventure. The first deck is shaded for
          your comfort, while the second deck features a sundeck.
        </p>
        <p>
          Our boats measure 15-20 meters (50-66 feet) in length and 4-5 meters (13-16 feet) in width, and are equipped
          with powerful diesel engines, water tank, GPS, VHF, fridge, full safety equipment, and a toilet. Experience
          comfort and safety on your next island adventure with us.
        </p>
      </div>

      <div className={css.devider} style={{ margin: '0px 0px 32px' }} />

      <div className={css.tabsContainer}>
        <div className={css.tabsWrapper}>
          <div
            className={classNames(css.activeTabName, { [css.isOpened]: isOpened })}
            onClick={() => setIsOpened(!isOpened)}
          >
            {TABS[activeTab]}
            <IconDropdown />
          </div>
          {isOpened ? (
            <div className={css.dropdownWrapper}>
              {TABS.map((tab, index) => (
                <div key={`tab-${index}`} className={css.dropdownItem} onClick={() => changeTab(index)}>
                  {tab}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {tabContent[activeTab]}

      <div className={css.devider} style={{ margin: '24px 0px' }} />
    </div>
  );
};

export default FirstPart;
