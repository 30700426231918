import React from 'react';
import css from './cheap-eats.module.scss';
import Img from '../../../components/universal/Img';
import { CHEAP_EATS } from '../../../utils/images/blogs';

const CheapEats = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>Cheap eats in Split: Best fast foods & sandwich bars</h2>
        <p>
          Embarking on a budget-friendly adventure in Split? Yo've come to the right place! We've got you covered with
          the ultimate guide to the city's best fast food and sandwich bars, where you can indulge in scrumptious,
          satisfying meals without breaking the bank. These hotspots are not just affordable, but they've also earned a
          place on our list of favorites.
        </p>
      </div>
      <div className={css.box}>
        <h3>The Best cheap eats in Split</h3>
        <p>
          Craving a quick, delectable bite that won't empty your wallet? Check out our top picks for the most loved and
          budget-friendly fast food and sandwich joints in Split:
        </p>
        <p>
          <span>Fast Food Medeni</span> is conveniently situated at various locations throughout the town. The closest
          outlet is near the picturesque west coast of Split promenade, a leisurely stroll from the bustling town
          center.
        </p>
        <p>
          Here you can indulge in classic comfort food like juicy hamburgers, cheeseburgers, and crispy golden fries.
          Treat your palate to scrumptious mixed meat platters, delectable pizzas, and a selection of delightful veggie
          dishes.
        </p>
        <p>
          Don't miss out ćepavi - succulent grilled minced meat served with a generous helping of onions and ajvar. This
          traditional delicacy is crafted from a tantalizing blend of roasted red bell peppers, smoky eggplant, fragrant
          garlic, and tangy vinegar.
        </p>
        <p>
          <span>Slastice Kod Mate</span> is the bakery that has captured the hearts and taste buds of locals! Operating
          24/7, this hotspot is not only beloved for its delectable treats, but it's also the perfect destination for
          post-clubbing cravings.
        </p>
        <p>
          This bakery pride themselves on crafting the finest donuts in town. Watch in awe as our skilled bakers
          generously fill each donut with mouth-watering chocolate or scrumptious marmalade right before your eyes. But
          that's not all - our bakery offers a diverse selection of freshly baked goods, including savory pizza and
          hearty meat pies.
        </p>
        <p>
          <span>Kebab House</span> is the ultimate destination for affordable and appetizing delights. Just 5 minutes
          away from the Riva, this culinary gem offers the most delectable kebabs in town, priced at a mere 5€. Elevate
          your taste buds with an enticing selection of zesty dressings to accompany your meal.
        </p>
        <p>They also offer other tasty fast food, like juicy chicken burgers, hamburgers, or traditional ćevapi.</p>
        <p>
          <span>Kantun Paulina</span> is a renowned place for takeaway ćevapi in the old town. They also offer an
          alluring selection of mouthwatering treats, including juicy hamburgers, succulent chicken burgers, sizzling
          sausages, and tender steak.
        </p>
        <p>
          With a carefully curated menu boasting only a few items, Kantun Paulina ensures that each dish is nothing
          short of perfection, guaranteed to delight your taste buds and leave you craving more.
        </p>
        <p>
          <span>Rizzo Sandwiches</span> is the oldest and the best sandwich bar in Split. When ordinary cheap eats just
          won't cut it, Rizzo serves up the ultimate sandwich experience you've been craving. Inspired by the renowned
          American fast-food franchise Subway, their menu offers a range of customizable sandwich sizes, ensuring every
          bite is tailored to your taste buds.
        </p>
        <p>
          Indulge in an array of premium dairy and meat delicacies, along with a bountiful selection of fresh vegetables
          and tantalizing toppings. To elevate your sandwich satisfaction, Rizzo only uses homemade bread buns and the
          freshest ingredients, guaranteeing a mouthwatering meal that's always worth the visit.
        </p>
        <div className={css.gallery}>
          {CHEAP_EATS.food.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheapEats;
