import React from 'react';
import css from './description.module.scss';
import { GROUP_TOUR_DELUXE_FIVE_ISLANDS } from '../../../../../utils/images/group-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Img from '../../../../../components/universal/Img';

const FirstPart = () => {
  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div>
          <IconBlueFlag />
          <p>Tour type: daily</p>
        </div>
        <div>
          <IconBlueCalendar />
          <p>Availability: From 1th April</p>
        </div>
        <div>
          <IconBlueClock />
          <p>Duration: 10.5 hours</p>
        </div>
        <div>
          <IconBlueLocation />
          <p>
            Location: Trg Franje Tudmana <br /> 21 000, Split, Hrvatska
          </p>
        </div>
      </div>

      <div className={css.textBox}>
        <h2 style={{ fontSize: '26px' }}>Standard Five Islands Tour: Explore the islands with bigger boat</h2>
        <p>
          Experience our most popular tour, the Standard Five Islands or Standard Blue Cave Tour, which offers a
          comfortable and safe journey on a speedboat. Enjoy more space and protection from inclement weather as you
          explore the stunning mid-Adriatic islands, including the breathtaking Blue Cave.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>More protection and comfort</h2>
        <p>
          Standard Five Islands Tour offers speedboat excursions in Enzo 35 or Mercan 34 boats. These boats are designed
          for transfers and excursions, providing ample space and comfort for up to 12 to 14 passengers in the
          leather-coated front-end cockpit.
        </p>
        <p>
          The boats also feature a large sun deck for sunbathing and a sturdy roof which is equipped with transparent
          roll-down side covers to protect against sun, wind, and rain.
        </p>
        <p>
          At 11 meters in length, the Enzo 35 and Mercan 34 boats are safe even in rough seas, while still being small
          enough to visit hidden coves and beaches on the islands. The boats also have a stern platform with ladders on
          the sun deck.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_DELUXE_FIVE_ISLANDS.speedboat.map((img, index) => (
            <Img key={`rib_boar-${index}`} src={img} alt={`rib_boar-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.devider} style={{ margin: '0px 0px 32px' }} />
    </div>
  );
};

export default FirstPart;
