import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { GROUP_TOUR_3_ISLANDS_HALF_DAY } from '../../../../../utils/images/group-tours';
import {
  IconBlueCalendar,
  IconBlueClock,
  IconBlueFlag,
  IconBlueLocation,
  IconDropdown,
} from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img
        src={GROUP_TOUR_3_ISLANDS_HALF_DAY.important_bring.src}
        alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.important_bring.alt}
      />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, fuel and skipper, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const TABS = ['OVERVIEW', 'IMPORTANT', 'GALLERY'];

const FirstPart = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
    setIsOpened(false);
  };

  const tabContent = {
    0: (
      <Img
        src={GROUP_TOUR_3_ISLANDS_HALF_DAY.overview}
        alt="overview"
        className={classNames(css.overviewImg, css.fadeIn)}
      />
    ),
    1: <ImportantTab />,
    2: <Gallery images={GROUP_TOUR_3_ISLANDS_HALF_DAY.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div>
          <IconBlueFlag />
          <p>Tour type: daily</p>
        </div>
        <div>
          <IconBlueCalendar />
          <p>Availability: From 1th April</p>
        </div>
        <div>
          <IconBlueClock />
          <p>Duration: 4.5 hours</p>
        </div>
        <div>
          <IconBlueLocation />
          <p>
            Location: Trg Franje Tudmana <br /> 21 000, Split, Hrvatska
          </p>
        </div>
      </div>

      <div className={css.textBox}>
        <h2 style={{ fontSize: '26px' }}>Half Day Blue Lagoon Tour from Split with speedboat</h2>
        <p>
          Looking for an unforgettable half-day adventure? Look no further than our highly popular Blue Lagoon Tour from
          Split! With departures in the morning and afternoon, this tour is the ultimate way to experience the best
          swimming and snorkeling spots on the mid-Adriatic islands, all within easy reach of Split.
        </p>
        <p>
          Our tour also takes you to the stunning World Heritage-listed old town of Trogir for some breathtaking
          sightseeing. During the high season, we even stop by the famous Laganini Beach Bar, where you can relax and
          take a dip in the refreshing waters.
        </p>
        <p>
          And in the low season, we have a special treat in store for you - a visit to the charming town of Maslinica on
          Šolta island. Take in the sights, explore the town, and soak up the local culture.
        </p>
        <p>
          If you're itching to take a swim, there's a beautiful beach with a cozy café bar just a quick five-minute
          stroll from the center of Maslinica.
        </p>
        <Img src={GROUP_TOUR_3_ISLANDS_HALF_DAY.trogir_1.src} alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.trogir_1.alt} />
      </div>

      <div className={css.textBox}>
        <h2>Why choose Blue Lagoon Tour from Split?</h2>
        <p>
          Looking for an adventure but don't have a full day to spare? Our half day Blue Lagoon tour from Split is the
          perfect solution! Lasting around 5 hours, it's the ideal way to explore the stunning mid-Adriatic islands when
          time is of the essence.
        </p>
        <p>
          We'll whisk you away to our first destination in just 20 to 30 minutes, and you'll have plenty of time to
          fully enjoy each stop on our tour. Whether you're into swimming, snorkeling, or sightseeing, we've got
          something for everyone!
        </p>
        <p>
          With morning and afternoon departures available, you can choose the time that works best for your schedule.
          And the best part? We provide water and snorkeling gear.
        </p>
        <Img src={GROUP_TOUR_3_ISLANDS_HALF_DAY.trogir_2.src} alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.trogir_2.alt} />
      </div>

      <div className={css.textBox}>
        <h2>Trogir - UNESCO heritage site</h2>
        <p>
          On our Blue Lagoon Tour from Split we visit Trogir, a charming UNESCO World Heritage town dating back to 380
          BC, boasts a rich history with splendid Romanesque, Renaissance, and Baroque fortresses, churches, and palaces
          conveniently clustered together on a small island for easy exploration.
        </p>
        <p>This town has the best preserved Romanesque-Gothic complex in all of Central Europe.</p>
        <p>Its wide seaside promenade is lined with bars, cafes, and yachts in the summer.</p>
        <p>
          Trogir is also known as Little Venice, thanks to its Venetian influence and a labyrinth of narrow cobbled
          streets, wide squares, bridges, and channels that surround this historical gem.
        </p>
        <p>
          The town's most famous attraction is the Venetian Cathedral of St. Lawrence, whose bell tower offers stunning
          views from the top. Cathedral's main west portal was created by master Radovan, and is the most significant
          work of the Romanesque-Gothic style in Croatia.
        </p>
        <Img src={GROUP_TOUR_3_ISLANDS_HALF_DAY.trogir_3.src} alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.trogir_3.alt} />
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon at Drvenik Veli</h2>
        <p>Stunning Blue Lagoon is the highlight of our Blue Lagoon tour from Split.</p>
        <p>
          Located between Krknjaši and Drvenik islands, this picturesque location, with its shallow crystal-clear
          turquoise water and sandy sea bed, is a perfect spot for swimming, snorkeling, sunbathing and underwater
          photography.
        </p>
        <p>Waters in the lagoon are calm so waves won't disturb your underwater activities.</p>
        <p>
          Upon arrival, we will dock at the pier on Drvenik island, where you can choose to explore the island on foot,
          relax in a restaurant, or even take a dip in the Blue Lagoon. You can also take masks from the boat and go
          snorkeling to explore the colorful underwater world.
        </p>
        <p>
          If you're looking for a longer adventure, join our full day Three Islands Tour instead of the half day Blue
          Lagoon Tour from Split. Explore more of the stunning Croatian coastline and islands, while still experiencing
          the beauty of the Blue Lagoon.
        </p>
        <div className={css.imgsContainer}>
          <Img
            src={GROUP_TOUR_3_ISLANDS_HALF_DAY.drvenik_veli_1.src}
            alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.drvenik_veli_1.alt}
          />
          <Img
            src={GROUP_TOUR_3_ISLANDS_HALF_DAY.drvenik_veli_2.src}
            alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.drvenik_veli_2.alt}
          />
          <Img
            src={GROUP_TOUR_3_ISLANDS_HALF_DAY.drvenik_veli_3.src}
            alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.drvenik_veli_3.alt}
          />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Duga Beach Resort - Laganini Beach Bar</h2>
        <p>
          Escape to the tranquil shores of Čiovo island and unwind at Laganini Beach Bar. Nestled on a beautiful stretch
          of rocky and pebble beach, this beach club offers a peaceful haven away from the crowds and loud music. Enjoy
          the sun and crystal-clear sea, as you relax on chairs, beds, and sofas provided for your comfort.
        </p>
        <p>
          What sets Laganini Beach Bar apart is its excellent service, great selection of cocktails, delicious food, and
          beautiful views. You can indulge in mouth-watering dishes while taking in the breathtaking scenery. With a
          laid-back atmosphere and reasonable prices, Laganini Beach Bar is a must-visit spot.
        </p>
        <p>
          Located just next to the sea, this beautiful beach club perfectly complements the stunning surroundings of the
          whole Blue Lagoon Tour from Split. So, if you're looking for a place to unwind and soak up the Mediterranean
          sun, Laganini Beach Bar is the perfect destination.
        </p>
        <div className={css.imgsContainer}>
          <Img
            src={GROUP_TOUR_3_ISLANDS_HALF_DAY.laganini_bar_1.src}
            alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.laganini_bar_1.alt}
          />
          <Img
            src={GROUP_TOUR_3_ISLANDS_HALF_DAY.laganini_bar_2.src}
            alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.laganini_bar_2.alt}
          />
          <Img
            src={GROUP_TOUR_3_ISLANDS_HALF_DAY.laganini_bar_3.src}
            alt={GROUP_TOUR_3_ISLANDS_HALF_DAY.laganini_bar_3.alt}
          />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '0px 0px 32px' }} />

      <div className={css.tabsContainer}>
        <div className={css.tabsWrapper}>
          <div
            className={classNames(css.activeTabName, { [css.isOpened]: isOpened })}
            onClick={() => setIsOpened(!isOpened)}
          >
            {TABS[activeTab]}
            <IconDropdown />
          </div>
          {isOpened ? (
            <div className={css.dropdownWrapper}>
              {TABS.map((tab, index) => (
                <div key={`tab-${index}`} className={css.dropdownItem} onClick={() => changeTab(index)}>
                  {tab}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {tabContent[activeTab]}

      <div className={css.devider} style={{ margin: '24px 0px' }} />
    </div>
  );
};

export default FirstPart;
