import React from 'react';
import css from './private-tours.module.scss';
import TripadvisorReviews from '../../universal/TripadvisorReviews';
import PrivateToursForm from '../../universal/PrivateToursForm';
import { BACKGROUND } from '../../../utils/images';
import Img from '../../universal/Img';

const PrivateTours = () => {
  return (
    <div className={css.privateTourWrapper}>
      <Img src={BACKGROUND.form.src} alt="private tours background" className={css.privateTourImg} />
      <div className={css.privateTourWrapperContent}>
        <div className={css.privateTourWrapperContentColumn}>
          <h2 className={css.privateTours_h2_1}>BOOK A PRIVATE TOUR</h2>
          <PrivateToursForm />
        </div>
        <div className={css.privateTourWrapperContentColumn}>
          <h2 className={css.privateTours_h2_2}>OUR TRIPADVISOR REVIEWS</h2>
          <TripadvisorReviews />
        </div>
      </div>
    </div>
  );
};

export default PrivateTours;
