import React, { useState } from 'react';
import css from './gallery.module.scss';
import classNames from 'classnames';
import Modal from 'react-modal';
import { IconExit, IconNext, IconPrev } from '../../../assets/icons';
import Img from '../Img';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const Gallery = ({ className, images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openImg = (img, index) => {
    setActiveIndex(index);
    setIsOpen(true);
  };

  const setNextPhoto = () => {
    setActiveIndex((activeIndex) => (activeIndex + 1) % images.length);
  };

  const setPrevPhoto = () => {
    if (activeIndex === 0) setActiveIndex(images.length - 1);
    else setActiveIndex((activeIndex) => (activeIndex - 1) % images.length);
  };

  return (
    <>
      <div className={classNames(className, css.galleryContainer)}>
        {images.map((image, index) => (
          <Img key={`gallery-img-${index}`} src={image} alt="gallery" onClick={() => openImg(image, index)} />
        ))}
      </div>
      <Modal isOpen={isOpen} style={customStyles} className={css.modal}>
        <IconExit onClick={() => setIsOpen(false)} className={css.iconExit} />
        <div className={css.iconNext} onClick={setNextPhoto}>
          <IconNext color="#000" />
        </div>

        <div className={css.modalContainer}>
          <Img src={images[activeIndex]} alt="selected" className={css.modalImg} />
        </div>
        <div className={css.iconPrev} onClick={setPrevPhoto}>
          <IconPrev color="#000" />
        </div>
      </Modal>
    </>
  );
};

export default Gallery;
