export const S3_BASE_URL = 'https://excursion-dalmatia-bucket.s3.eu-central-1.amazonaws.com';

// LOGO
const ImgFooterSponsors = S3_BASE_URL + '/logo/footer-sponsors.png';
const ImgFooterMobile1 = S3_BASE_URL + '/logo/footer-mobile-1.png';
const ImgFooterMobile2 = S3_BASE_URL + '/logo/footer-mobile-2.png';
const ImgLogoPaypal = S3_BASE_URL + '/logo/logo-paypal.png';
const ImgLogoRevolut = S3_BASE_URL + '/logo/logo-revolut.png';
const ImgLogoWise = S3_BASE_URL + '/logo/logo-wise.png';
const ImgLogo = S3_BASE_URL + '/logo/logo.png';
const ImgLogoInstagram = S3_BASE_URL + '/logo/logo-instagram-desktop.png';
const ImgLogoFacebook = S3_BASE_URL + '/logo/logo-facebook-desktop.png';
const ImgLogoTripadvisor = S3_BASE_URL + '/logo/logo-tripadvisor-desktop.png';
const ImgLogoStripeFooter = S3_BASE_URL + '/logo/stripe-footer-mobile.png';
const ImgTripadvisor01 = S3_BASE_URL + '/logo/tripadvisor-1.png';
const ImgTripadvisor02 = S3_BASE_URL + '/logo/tripadvisor-2.png';
const ImgTripadvisor03 = S3_BASE_URL + '/logo/tripadvisor-3.png';
const ImgTripadvisor04 = S3_BASE_URL + '/logo/tripadvisor-4.png';
const ImgTripadvisor2022 = S3_BASE_URL + '/logo/tripadvisor-2022.png';

export const IMPORTANT_BRING = S3_BASE_URL + '/important-bring.png';

export const DEFAULT_IMAGE = S3_BASE_URL + '/default-image.jpg';

export const BANNERS = {
  about_us: {
    src: S3_BASE_URL + '/main-images/main-image-general.jpg',
    alt: 'About Us',
  },
  group_tours: {
    general: {
      src: S3_BASE_URL + '/main-images/main-image-general.jpg',
      alt: 'Group Tours',
    },
    blue_cave: {
      src: S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/main-image.jpg',
      alt: 'Blue cave five islands',
    },
    budget: {
      src: S3_BASE_URL + '/group-tours/budget-five-islands-tour/main-image.webp',
      alt: 'Budget tour',
    },
    standard: {
      src: S3_BASE_URL + '/group-tours/standard-five-islands-tour/main-image.webp',
      alt: 'Standard tour',
    },
    deluxe: {
      src: S3_BASE_URL + '/group-tours/deluxe-five-islands-tour/main-image.JPG',
      alt: 'Deluxe tour',
    },
    hvar_bol_pakleni: {
      src: S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/main-image.jpg',
      alt: 'Hvar, Bol and Pakleni islands',
    },
    three_islands: {
      src: S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/main-image.jpg',
      alt: 'Hvar, Bol and Pakleni islands',
    },
    three_islands_half_day: {
      src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/main-image.jpg',
      alt: 'Hvar, Bol and Pakleni islands',
    },
  },
  private_tours: {
    general: {
      src: S3_BASE_URL + '/main-images/main-image-general.jpg',
      alt: 'Private Tours',
    },
    blue_lagoon_three_islands: {
      src: S3_BASE_URL + '/private-tours/blue-lagoon-and-3-islands/main-photo.jpg',
      alt: 'Blue lagoon and 3 islands',
    },
    blue_lagoon_four_islands: {
      src: S3_BASE_URL + '/private-tours/blue-lagoon-and-4-islands/main-image.webp',
      alt: 'Blue lagoon and 4 islands',
    },
    blue_lagoon_five_islands: {
      src: S3_BASE_URL + '/private-tours/blue-lagoon-and-5-islands/main-image.webp',
      alt: 'Blue lagoon and 5 islands',
    },
    split_sunset: {
      src: S3_BASE_URL + '/private-tours/sunset-tour/main-image.jpg',
      alt: 'Split Sunset private tour',
    },
    hvar_pakleni_islands: {
      src: S3_BASE_URL + '/private-tours/hvar-and-pakleni-islands/main-image.jpg',
      alt: 'Hvar and Pakleni Islands',
    },
    blue_cave_five_islands: {
      src: S3_BASE_URL + '/private-tours/blue-cave-and-5-islands/main-image.webp',
      alt: 'Blue Cave and five islands tour',
    },
    bol_solta_island: {
      src: S3_BASE_URL + '/private-tours/bol-and-solta-island/main-image.webp',
      alt: 'Bol and Solta island',
    },
    bol_hvar_pakleni_islands: {
      src: S3_BASE_URL + '/private-tours/bol-hvar-and-pakleni-islands/main-image.jpg',
      alt: 'Bol, Hvar and Pakleni Islands',
    },
  },
  transfers: {
    src: S3_BASE_URL + '/main-images/main-image-general.jpg',
    alt: 'Transfers',
  },
  boat_transfers: {
    src: S3_BASE_URL + '/main-images/main-image-general.jpg',
    alt: 'Boat Transfers',
  },
  rent_a_boat: {
    src: S3_BASE_URL + '/main-images/main-image-general.jpg',
    alt: 'Rent A Boat',
  },
  our_boats: {
    src: S3_BASE_URL + '/main-images/main-image-general.jpg',
    alt: 'Our Boats',
  },
  cookie_policy: {
    src: S3_BASE_URL + '/main-images/cookie-policy/main-image.webp',
    alt: 'Cookie policy',
  },
  privacy_policy: {
    src: S3_BASE_URL + '/main-images/privacy-policy/main-image.jpg',
    alt: 'Privacy policy',
  },
  faq: {
    src: S3_BASE_URL + '/main-images/faq/main-image.jpg',
    alt: 'FAQ',
  },
  contact: {
    src: S3_BASE_URL + '/main-images/contact/main-image.webp',
    alt: 'Contact',
  },
  contact_mobile: {
    src: S3_BASE_URL + '/main-images/contact/contact-page-mobile.png',
    alt: 'Contact',
  },
  blogs: {
    blog_page: {
      src: S3_BASE_URL + '/main-images/blog/main-image.webp',
      alt: 'Excursion Dalmatia blogs',
    },
    weather: {
      src: DEFAULT_IMAGE,
      alt: 'Weather in Croatia',
    },
    apartments: {
      src: DEFAULT_IMAGE,
      alt: 'Apartments in Split with direct contact to the owners',
    },
    planing_vacation: {
      src: DEFAULT_IMAGE,
      alt: "Planning a vacation in Croatia? Here's what you need to know",
    },
    things_to_do: {
      src: DEFAULT_IMAGE,
      alt: 'Top 10 things to do in Split',
    },
    accommodation: {
      src: DEFAULT_IMAGE,
      alt: 'Best places for accommodation in & around Split',
    },
    romantic_restaurants: {
      src: DEFAULT_IMAGE,
      alt: 'Best romantic restaurants in Split',
    },
    nightlife: {
      src: DEFAULT_IMAGE,
      alt: 'Nightlife in Split: the best places for fun night out',
    },
    beaches: {
      src: DEFAULT_IMAGE,
      alt: 'Top 10 croatia beaches - find your slice of paradise',
    },
    cheap_eats: {
      src: DEFAULT_IMAGE,
      alt: 'Cheap eats in split: best fast foods & sandwich bars',
    },
    vegetarian: {
      src: DEFAULT_IMAGE,
      alt: 'The best vegetarian restaurants in split',
    },
    best_restaurants: {
      src: DEFAULT_IMAGE,
      alt: 'Best restaurants in split: find the one for you',
    },
  },
};

export const BACKGROUND = {
  form: {
    src: S3_BASE_URL + '/bg-form.jpg',
    alt: 'Form background',
  },
};

export const FOOTER = {
  sponsors_desktop: {
    src: ImgFooterSponsors,
    alt: 'Footer brands',
  },
  sponsors_mobile_1: {
    src: ImgFooterMobile1,
    alt: 'Footer brands 1',
  },
  sponsors_mobile_2: {
    src: ImgFooterMobile2,
    alt: 'Footer brands 2',
  },
  stripe_mobile: {
    src: ImgLogoStripeFooter,
    alt: 'Stripe logo',
  },
};

export const LOGO = {
  logo: {
    src: ImgLogo,
    alt: 'Logo',
  },
  logo_paypal: {
    src: ImgLogoPaypal,
    alt: 'PayPal Logo',
  },
  logo_Revolut: {
    src: ImgLogoRevolut,
    alt: 'Revolut Logo',
  },
  logo_wise: {
    src: ImgLogoWise,
    alt: 'Wise Logo',
  },
  instagram_desktop: {
    src: ImgLogoInstagram,
    alt: 'Instagram',
  },
  facebook_desktop: {
    src: ImgLogoFacebook,
    alt: 'Facebook',
  },
  tripadvisor_desktop: {
    src: ImgLogoTripadvisor,
    alt: 'Tripadvisor',
  },
};

export const TRIPADVISOR = {
  tripadvisor_01: {
    src: ImgTripadvisor01,
    alt: 'Tripadvisor 1',
  },
  tripadvisor_02: {
    src: ImgTripadvisor02,
    alt: 'Tripadvisor 2',
  },
  tripadvisor_03: {
    src: ImgTripadvisor03,
    alt: 'Tripadvisor 3',
  },
  tripadvisor_04: {
    src: ImgTripadvisor04,
    alt: 'Tripadvisor 4',
  },
  tripadvisor_2022: {
    src: ImgTripadvisor2022,
    alt: 'Tripadvisor 2022 award',
  },
};

export const LANDING_PAGE = {
  banner_01: {
    src: S3_BASE_URL + '/main-images/main-image-landing-page-1.webp',
    alt: 'Landing page - Excursion Dalmatia',
  },
  banner_02: {
    src: S3_BASE_URL + '/main-images/main-image-landing-page-2.webp',
    alt: 'Landing page - Excursion Dalmatia',
  },
  banner_03: {
    src: S3_BASE_URL + '/main-images/main-image-landing-page-3.webp',
    alt: 'Landing page - Excursion Dalmatia',
  },
  banner_04: {
    src: S3_BASE_URL + '/main-images/main-image-landing-page-4.webp',
    alt: 'Landing page - Excursion Dalmatia',
  },
  banner_05: {
    src: S3_BASE_URL + '/main-images/main-image-landing-page-5.webp',
    alt: 'Landing page - Excursion Dalmatia',
  },
  our_boats: {
    src: S3_BASE_URL + '/landing/speedboat.jpg',
    alt: 'Speedboat',
  },
  rent_a_boat: [
    S3_BASE_URL + '/landing/boat-1.webp',
    S3_BASE_URL + '/landing/boat-2.webp',
    S3_BASE_URL + '/landing/boat-3.webp',
    S3_BASE_URL + '/landing/boat-4.webp',
    S3_BASE_URL + '/landing/boat-5.webp',
    S3_BASE_URL + '/landing/boat-6.webp',
  ],
  speedboat_transfers: {
    speedboart_01: {
      src: S3_BASE_URL + '/landing/speedboat-transfer-1.jpg',
      alt: 'Speedboat 1',
    },
    speedboart_02: {
      src: S3_BASE_URL + '/landing/speedboat-transfer-2.jpg',
      alt: 'Speedboat 2',
    },
    speedboart_03: {
      src: S3_BASE_URL + '/landing/speedboat-transfer-3.jpg',
      alt: 'Speedboat 3',
    },
  },
};

export const TRANSFERS = {
  img_1: {
    src: S3_BASE_URL + '/transfers/transfers-boat-01.jpg',
    alt: 'Speedboat',
  },
  img_2: {
    src: S3_BASE_URL + '/transfers/transfers-passengers.jpg',
    alt: 'Passengers',
  },
};

export const ABOUT_US = {
  banner: {
    src: S3_BASE_URL + '/main-images/main-image-general.jpg',
    alt: 'Banner',
  },
  about_us_1: {
    src: S3_BASE_URL + '/about-us/about-us-1.jpg',
    alt: 'About us',
  },
};

export const OUR_BOATS = {
  tab_1: {
    src: S3_BASE_URL + '/our-boats/private.webp',
    alt: 'Private speedboat tours',
  },
  tab_2: {
    src: S3_BASE_URL + '/our-boats/luxury.webp',
    alt: 'Luxury yacht tours',
  },
  tab_3: {
    src: S3_BASE_URL + '/our-boats/big.webp',
    alt: 'Big boat tours',
  },
};

export const RENT_A_BOAT = {
  tab_1: {
    boat_1: {
      src: S3_BASE_URL + '/rent-a-boat/smaller-1.webp',
      alt: 'MANO MARINE 18.50',
    },
    boat_2: {
      src: S3_BASE_URL + '/rent-a-boat/smaller-2.webp',
      alt: 'BARRACUDA 545',
    },
    boat_3: {
      src: S3_BASE_URL + '/rent-a-boat/smaller-3.webp',
      alt: 'PRINCE 570 OPEN',
    },
    boat_4: {
      src: S3_BASE_URL + '/rent-a-boat/smaller-4.webp',
      alt: 'PRINCE 625 OPEN',
    },
  },
  tab_2: {
    boat_1: {
      src: S3_BASE_URL + '/rent-a-boat/mid-1.webp',
      alt: 'ATLANTIC 670 OPEN',
    },
    boat_2: {
      src: S3_BASE_URL + '/rent-a-boat/mid-2.webp',
      alt: 'NUOVA JOLLY PRINCE 23',
    },
    boat_3: {
      src: S3_BASE_URL + '/rent-a-boat/mid-3.webp',
      alt: 'JEANNEAU CAP CAMARAT 755 OPEN',
    },
    boat_4: {
      src: S3_BASE_URL + '/rent-a-boat/mid-4.webp',
      alt: 'MARSEA SP 170',
    },
  },
};

export const BOAT_TRANSFERS = {
  slider_1: {
    tab_1: {
      img_1: S3_BASE_URL + '/boat-transfers/smaller-1.webp',
      img_2: S3_BASE_URL + '/boat-transfers/smaller-2.webp',
      img_3: S3_BASE_URL + '/boat-transfers/smaller-3.webp',
    },
    tab_2: {
      img_1: S3_BASE_URL + '/boat-transfers/mid-1.webp',
      img_2: S3_BASE_URL + '/boat-transfers/mid-2.webp',
      img_3: S3_BASE_URL + '/boat-transfers/mid-3.webp',
    },
    tab_3: {
      img_1: S3_BASE_URL + '/boat-transfers/deluxe-1.webp',
      img_2: S3_BASE_URL + '/boat-transfers/deluxe-2.webp',
      img_3: S3_BASE_URL + '/boat-transfers/deluxe-3.webp',
    },
  },
  slider_2: {
    tab_1: {
      img_1: S3_BASE_URL + '/boat-transfers/luxury-1.webp',
      img_2: S3_BASE_URL + '/boat-transfers/luxury-2.webp',
      img_3: S3_BASE_URL + '/boat-transfers/luxury-3.webp',
    },
    tab_2: {
      img_1: S3_BASE_URL + '/boat-transfers/big-1.webp',
      img_2: S3_BASE_URL + '/boat-transfers/big-2.webp',
      img_3: S3_BASE_URL + '/boat-transfers/big-3.webp',
    },
  },
};
