import css from '../assets/style/faq.module.scss';

export const FAQ_DATA = [
  // 1
  {
    title: 'Where can I purchase my ticket?',
    data: (
      <p className={css.text}>
        Contact us via Whatsapp or Viber for detailed instructions on how to buy tickets and find us in the city center,
        at our selling point on the Riva seafront promenade.
      </p>
    ),
  },
  // 2
  {
    title: 'Do you offer a discount for children?',
    data: (
      <p className={css.text}>
        Yes, we offer discounts for children for most of our excursions. The discount depends on the age of the child
        and on the type of service being offered.
      </p>
    ),
  },
  // 3
  {
    title: 'Where is the meeting point for your tours?',
    data: (
      <p className={css.text}>
        Meeting point for most our tours (except private ones) is at the Split waterfront, next to the fountain
        (Bajamontuša). Important: You are advised to plan your journey in advance and make sure you arrive at the
        meeting point 30 minutes before the scheduled departure.
      </p>
    ),
  },
  // 4
  {
    title: 'Are we able to tailor make our own private tour?',
    data: (
      <p className={css.text}>
        Feel free to contact us and we can personalize an excursion to the destination of your choice. Depending on the
        size of your group, private tour can be even cheaper with the possibility of changing route plan and schedule.
      </p>
    ),
  },
  // 5
  {
    title: 'What is the cancellation policy?',
    data: (
      <div className={css.block}>
        <p className={css.text} style={{ marginBottom: '16px' }}>
          Client has the right to cancel the tour. If client cancels reservation / service purchased within 24 hours
          before the start of the tour, he / she is not entitled to a refund of the money paid for the tour.
        </p>
        <p className={css.text} style={{ marginBottom: '16px' }}>
          If client does not appear on the departure day of the tour (which had previously paid entirely), and did not
          announce the arrival (within 24 hours before departure), he / she is not entitled to a refund paid for the
          tour.
        </p>
        <p className={css.text} style={{ marginBottom: '16px' }}>
          Situations where:
        </p>
        <p className={css.text} style={{ marginBottom: '16px' }}>
          - group (5 people or more) purchased any of our services and cancelled it in one of the following periods
        </p>
        <p className={css.text} style={{ marginBottom: '16px' }}>
          - client books a daytrip/ private tour / rents a boat with skipper cancelled by himself in a following
          periods:
        </p>
        <ul className={css.list} style={{ marginBottom: '16px' }}>
          <li>up to 30 days before departure Excursion-Dalmatia retains 10% of the paid amount, and at least 100 kn</li>
          <li>29-15 days before departure Excursion-Dalmatia retains 25% of the paid amount</li>
          <li>14-3 days before departure Excursion-Dalmatia retains 40% of the paid amount</li>
          <li>2 -0 days before departure Excursion-Dalmatia retains 100% of the paid amount</li>
        </ul>
        <p className={css.text} style={{ marginBottom: '24px' }}>
          Our tours are based on reservations and therefore notification of your absence must be as soon as possible in
          order to allow other guests to join the tour.
        </p>
        <h4 className={css.title_h4} style={{ marginBottom: '8px' }}>
          TOUR CANCELLATION OR CHANGE OF PROGRAMME BY Excursion-Dalmatia
        </h4>
        <p className={css.text} style={{ marginBottom: '24px' }}>
          Our tours are based on reservations and therefore notification of your absence must be as soon as possible in
          order to allow other guests to join the tour.
        </p>
        <h4 className={css.title_h4} style={{ marginBottom: '8px' }}>
          Not showing up for my tour
        </h4>
        <p className={css.text} style={{ marginBottom: '24px' }}>
          What happens if I do not show up for my tour? In general, we always try to keep in touch with our customers by
          exchanging full contact details: e-mail address, phone numbers, accommodation address and other contacts. If
          you are running late or are unable to show up, it would be appreciated if you informed us you are unable to
          reach the meeting point on time, as soon as possible. This way, it might still be possible for you to join the
          group and not miss your tour. Unfortunately, if you do not arrive to tour meeting point in reasonable time,
          you will lose your payment (the same as missing your flight, etc.)
        </p>
        <h4 className={css.title_h4} style={{ marginBottom: '8px' }}>
          Refund
        </h4>
        <p className={css.text}>
          All cancellations should be made at least one day before the departure. If the reason you are unable to attend
          your booked tour is an emergency unfortunate event, health condition or an injury, we will gladly refund the
          whole paid amount, provided that you are able to prove this by enclosing a legitimate medical documentation
          from the Croatian hospital you have been treated in.
        </p>
      </div>
    ),
  },
  // 6
  {
    title: 'Are there any age or health restrictions?',
    data: (
      <p className={css.text}>
        There are no restrictions in terms of age or health on our tours with big boats and land tours. However, when it
        comes to our speedboat tours, we don't take pregnant women and children 3 years old or younger on the
        powerboats. Also, individuals suffering from injuries such as back and neck issues are not advised to go on
        speedboat tours as the sea can get rough in an instant.
      </p>
    ),
  },
  // 7
  {
    title: "Does the tour still go if it's raining?",
    data: (
      <p className={css.text}>
        If the weather is deemed to be unsuitable then the tour will be cancelled in which case all guests will receive
        a 100% refund or the option of rescheduling.
      </p>
    ),
  },
  // 8
  {
    title: 'Tour guides experience',
    data: (
      <p className={css.text}>
        All our tour guides are carefully selected, trained and all certified by Croatia Tourism Administration.
      </p>
    ),
  },
  // 9
  {
    title: 'What do you offer in the form of safety on the tours?',
    data: (
      <p className={css.text}>
        Safety equipment is provided for all of our guests such as a lightweight life jacket. In the event of accident
        or injury, insurance is included in the price of the tour. Your luggage on board is insured as well.
      </p>
    ),
  },
  // 10
  {
    title: 'We are staying outside of town, how can we get to our meeting point in time?',
    data: (
      <p className={css.text}>
        Give us your address and we will arrange you a pick-up if possible or provide you with detailed directions.
      </p>
    ),
  },
  // 11
  {
    title: 'What should I wear or bring with me on the tour?',
    data: (
      <p className={css.text}>
        You have special recommendations in the info section for each and every tour on our site. However, general
        recommendations are associated with time of year and weather conditions. Sportswear, swimsuit and comfortable
        walking shoes are a good choice to wear during spring and summer. Wind jacket, camera, hat, sunscreen, a towel
        and sunglasses are advised as well.
      </p>
    ),
  },
];
