import React, { useRef, useState } from 'react';
import css from './boat-transfers.module.scss';
import PrivateToursForm from '../../../components/universal/PrivateToursForm';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider-style.scss';
import { BANNERS, BOAT_TRANSFERS } from '../../../utils/images';
import TripadvisorReviews from '../../../components/universal/TripadvisorReviews';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const PrivateTours = () => {
  const [activeTab1, setActiveTab1] = useState(0);
  const [activeTab2, setActiveTab2] = useState(0);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  const settingsSlider1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: true,
    afterChange: (index) => setActiveTab1(index),
  };
  const settingsSlider2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: true,
    afterChange: (index) => setActiveTab2(index),
  };

  const changeTab1 = (index) => {
    setActiveTab1(index);
    sliderRef1.current.slickGoTo(index);
  };

  const changeTab2 = (index) => {
    setActiveTab2(index);
    sliderRef2.current.slickGoTo(index);
  };

  return (
    <MotionDiv className={css.boatTransfersPageLayout}>
      <div className={css.bannerWrapper}>
        <Img
          src={BANNERS.boat_transfers.src}
          alt="List of excursion dalmatia private tours"
          className={css.bannerImg}
        />
        <div className={css.filter} />
        <h1>BOAT TRANSFERS</h1>
      </div>

      {/* CONTENT CONTAINER */}
      <div className={css.contentContainer}>
        <div className={css.contentLeft}>
          <div className={css.contentLeftBox}>
            <h3>Split Boat Transfers: Choose your boat</h3>
            <p>
              At Split Boat Transfers, we pride ourselves on providing fast and high-quality services. Whether you opt
              for our affordable speedboat or our luxurious yacht, we guarantee a comfortable and relaxing experience.
            </p>
            <p>
              Our speedy boats not only offer style but also save you valuable time. We offer pickup services for our
              guests from Hvar and Split Airport, conveniently located in Kaštela.
            </p>
            <p>
              Moreover, we provide pickup services from your hotel marina or accommodation at a pre-scheduled point.
              Simply inform us, and we will take care of the rest.
            </p>
          </div>

          <div className={css.contentLeftBox}>
            <h3>Transfers from Split</h3>
            <p>
              Our company is based in Split, the largest town in Dalmatia and very popular tourist destination. We offer
              boat transfers from Split to various destinations, including the airport and popular mid-Adriatic islands
              such as Hvar, Brač, and Šolta.
            </p>
            <p>
              Our services extend to all destinations on the Croatian side of the Adriatic, and we also provide Split
              boat transfers to Dubrovnik or Pula. Our team is committed to providing reliable and efficient transfer
              services to ensure that your journey is comfortable and stress-free.
            </p>
          </div>

          <div className={css.contentLeftBox}>
            <h3>Transfers from Hvar</h3>
            <p>
              If you're looking for a speedboat transfer from Hvar to Split, Dubrovnik, Zadar, or even Split Airport,
              our company can help. We have offices in Hvar, which is the most popular destination in the mid-Adriatic
              region. Hvar refers to both the island and the town. Contact us today for all your speedboat transfer
              needs.
            </p>
          </div>

          <div className={css.contentLeftBox}>
            <h3>Transfers from Split Airport</h3>
            <p>
              If you're traveling to Split for a summer vacation, you'll likely arrive at Split Airport, located in the
              town of Kaštela, 25 kilometers away from Split. During the peak season, the airport is bustling with
              tourists from all over the world who come to enjoy the mid-Adriatic islands.
            </p>
            <p>
              If you need a direct transfer from Split Airport to your hotel or one of the islands, we can help. Contact
              us today via email, WhatsApp, or Viber to arrange your transfer.
            </p>
          </div>
        </div>

        <div className={css.contentRight}>
          <div className={css.form}>
            <h3>BOOK A TRANSFER</h3>
            <PrivateToursForm firstRowColumn />
          </div>

          <TripadvisorReviews className={css.tripadvisor} />
        </div>
      </div>
      {/* TABS */}
      <div className={css.tabSection1}>
        <h3>TRANSFERS WITH:</h3>
        <div className={css.tabsContainer}>
          <div
            className={classNames(css.tab, {
              [css.activeTab]: activeTab1 === 0,
            })}
            onClick={() => changeTab1(0)}
          >
            SMALLER SPEEDBOATS
          </div>
          <div
            className={classNames(css.tab, {
              [css.activeTab]: activeTab1 === 1,
            })}
            onClick={() => changeTab1(1)}
          >
            MIDSIZE SPEEDBOATS
          </div>
          <div
            className={classNames(css.tab, {
              [css.activeTab]: activeTab1 === 2,
            })}
            onClick={() => changeTab1(2)}
          >
            DELUXE SIZE SPEEDBOATS
          </div>
        </div>
        <Slider {...settingsSlider1} ref={sliderRef1} className={classNames('boatTransferSlider1', css.sliderWrapper)}>
          <div className={css.sliderCard}>
            <div className={css.sliderCardContent}>
              <p>
                If you're looking for a small and comfortable speedboat for a group of up to 6 or 7 passengers,
                Barracuda 545, Prince 625, and Saver 650 are great options.
              </p>
              <p>
                Despite being only 5-6 meters long, they offer adequate cover and comfort, making them ideal for Split
                boat transfers to nearby islands or to the airport.
              </p>
            </div>
            <div className={css.cardImages}>
              <Img src={BOAT_TRANSFERS.slider_1.tab_1.img_1} alt="boat Saver" />
              <Img src={BOAT_TRANSFERS.slider_1.tab_1.img_2} alt="boat Baracuda" />
              <Img src={BOAT_TRANSFERS.slider_1.tab_1.img_3} alt="boat Mano marine" />
            </div>
          </div>
          <div className={css.sliderCard}>
            <div className={css.sliderCardContent}>
              <p>
                Mid-sized boats are ideal for private tours and boat transfers in Split. With enough space and
                protection, they can accommodate 10 to 12 passengers comfortably.
              </p>
              <p>
                While they may be more expensive than smaller boats, mid-sized boats offer added value, especially if
                you're traveling to a destination that's farther away from your pickup point.
              </p>
            </div>
            <div className={css.cardImages}>
              <Img src={BOAT_TRANSFERS.slider_1.tab_2.img_1} alt="boat Marlin" />
              <Img src={BOAT_TRANSFERS.slider_1.tab_2.img_2} alt="boat White boat" />
              <Img src={BOAT_TRANSFERS.slider_1.tab_2.img_3} alt="boat Marsea" />
            </div>
          </div>
          <div className={css.sliderCard}>
            <div className={css.sliderCardContent}>
              <p>
                Deluxe speedboats offer plenty of space, protection, and comfort for all passengers. With their larger
                size, they can accommodate up to 12 passengers with plenty of room to spare, making them ideal for
                bigger groups and those who prefer larger boats.
              </p>
              <p>
                However, it's important to note that bigger boats come with a larger engine, which means they consume
                more fuel and are more expensive than smaller boats.
              </p>
            </div>
            <div className={css.cardImages}>
              <Img src={BOAT_TRANSFERS.slider_1.tab_3.img_1} alt="speedboat" />
              <Img src={BOAT_TRANSFERS.slider_1.tab_3.img_2} alt="speedboat 2" />
              <Img src={BOAT_TRANSFERS.slider_1.tab_3.img_3} alt="boat Colnago" />
            </div>
          </div>
        </Slider>
      </div>

      <div className={css.tabSection2}>
        <div className={css.tabsContainer}>
          <div
            className={classNames(css.tab, {
              [css.activeTab]: activeTab2 === 0,
            })}
            onClick={() => changeTab2(0)}
          >
            LUXURY YACHTS
          </div>
          <div
            className={classNames(css.tab, {
              [css.activeTab]: activeTab2 === 1,
            })}
            onClick={() => changeTab2(1)}
          >
            BIG BOATS FOR BIG GROUPS
          </div>
        </div>
        <Slider {...settingsSlider2} ref={sliderRef2} className={classNames('boatTransferSlider2', css.sliderWrapper)}>
          <div className={css.sliderCard}>
            <div className={css.sliderCardContent}>
              <p>
                If you're looking for the ultimate luxury experience, we have a few spacious yacht options for you to
                choose from. Designed to accommodate a maximum of 11 passengers, these yachts offer plenty of comfort
                for you to relax and even take a nap during your trip.
              </p>
            </div>
            <div className={css.cardImages}>
              <Img src={BOAT_TRANSFERS.slider_2.tab_1.img_1} alt="boat Jeanneau Prestige" />
              <Img src={BOAT_TRANSFERS.slider_2.tab_1.img_2} alt="luxury yacht" />
              <Img src={BOAT_TRANSFERS.slider_2.tab_1.img_3} alt="boat Cranci Endurance" />
            </div>
          </div>
          <div className={css.sliderCard}>
            <div className={css.sliderCardContent}>
              <p>
                If you need to arrange a transfer for a larger group of more than 12 people and want everyone to be on
                one vessel, we have several big boats that can accommodate groups ranging from 60 to 150 passengers.
                While they may not be as fast as speedboats, their size more than makes up for it.
              </p>
            </div>
            <div className={css.cardImages}>
              <Img src={BOAT_TRANSFERS.slider_2.tab_2.img_1} alt="boat Blaga" />
              <Img src={BOAT_TRANSFERS.slider_2.tab_2.img_2} alt="boat Humac" />
              <Img src={BOAT_TRANSFERS.slider_2.tab_2.img_3} alt="big boat" />
            </div>
          </div>
        </Slider>
      </div>

      <div className={css.grayWrapper}>
        <h3>Split Transfers - How to book?</h3>
        <p>
          Reserve a transfer up to one month in advance. To confirm your booking, we require 30% deposit with the
          remaining 70% to be paid directly to your skipper upon pick up.
        </p>
        <h4>No extra fees</h4>
        <p>
          We pride ourselves on our transparent pricing policy, which means there are no hidden fees or extra charges to
          worry about. To get started, simply provide us with your travel details and we'll check availability for your
          desired date and time.
        </p>
        <p>
          Once we've confirmed availability, we'll send you an email or text message with all the details you need to
          know about our private Split transfers. If you need a quicker response, feel free to reach out to us on
          WhatsApp or Viber at +385 95 826 79 81.
        </p>
      </div>
    </MotionDiv>
  );
};

export default PrivateTours;
