import React from 'react';
import css from './about-us.module.scss';
import { ABOUT_US, BANNERS } from '../../../utils/images';
import ABOUT_US_TEXT from '../../../json/pages/about-us.json';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const AboutUs = () => {
  return (
    <MotionDiv className={css.aboutUsMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.about_us.src} alt="about us - excursion dalmatia" className={css.bannerImg} />
        <div className={css.filter} />
        <h1>{ABOUT_US_TEXT.title}</h1>
      </div>

      <div className={css.contentContainer}>
        <h2>{ABOUT_US_TEXT.h2}</h2>
        <h3>{ABOUT_US_TEXT.h3}</h3>
        <div className={css.text}>
          {ABOUT_US_TEXT.text.map((text, index) => (
            <p key={`text-${index}`}>{text}</p>
          ))}
        </div>
        <Img src={ABOUT_US.about_us_1.src} alt="excursion dalmatia office" className={css.image} />
      </div>
    </MotionDiv>
  );
};

export default AboutUs;
