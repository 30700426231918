import React from 'react';
import css from './rent-a-boat.module.scss';
import { CONTENT } from '../../../data/rent-a-boat';
import Img from '../../../components/universal/Img';

const TabsContent = ({ tabIndex }) => {
  return (
    <div className={css.tabContainer}>
      {CONTENT[tabIndex].map((content, index) => (
        <div className={css.card} key={`card-${index}`}>
          <Img src={content.photo} alt="rent a boat" />
          <div className={css.contentWrapper}>
            <h4>{content.section1.name}</h4>
            <ul className={css.list}>
              {content.section1.bullets.map((bullet, index) => (
                <li key={`bullte-${index}`}>{bullet}</li>
              ))}
            </ul>
            <div className={css.divider} />
            <h4>{content.section2.name}</h4>
            {content.section2.text.map((text, index) => (
              <p key={`text-${index}`}>{text}</p>
            ))}
            <div className={css.divider} />
            <p style={{ color: '#6B7280' }}>{content.footer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TabsContent;
