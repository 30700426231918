import emailjs from '@emailjs/browser';
import { EMAILJS_PUBLIC_KEY, EMAILJS_TEMPLATE_ID, EMAILJS_SERVICE_ID, IS_LOCALHOST } from '../config/env';
import _ from 'lodash';
import { lowerCase, spaceCase } from 'case-anything';
import { wait } from '.';

const NOTIFICATION_SUCCESS_MESSAGE = 'Email was successfully sent.';
const NOTIFICATION_ERROR_MESSAGE = 'Email was not successfully sent.';

const randomEmailId = () => `message-${Date.now().toString(36)}`;

const sendEmailJs = async (data) => {
  const { name, email, countryCode, mobile, type, ...restData } = data;
  const otherInfo = _.reduce(
    restData,
    (result, value, key) => {
      result += `${_.capitalize(lowerCase(spaceCase(key)))}:\n${value}\n\n`;
      return result;
    },
    ''
  );

  const params = { otherInfo, name, email, countryCode, mobile, type, messageId: randomEmailId() };

  if (IS_LOCALHOST) {
    await wait(500);
    return { success: true, status: 200, message: NOTIFICATION_SUCCESS_MESSAGE };
  } else {
    try {
      const { status } = await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, params, EMAILJS_PUBLIC_KEY);
      if (status === 200) return { success: true, status, message: NOTIFICATION_SUCCESS_MESSAGE };
      else return { success: false, status: 500, message: NOTIFICATION_ERROR_MESSAGE };
    } catch (error) {
      return { success: false, status: 500, message: NOTIFICATION_ERROR_MESSAGE };
    }
  }
};

export default sendEmailJs;
