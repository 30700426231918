import React from 'react';
import css from './tour-itinerary.module.scss';
import classNames from 'classnames';
import Img from '../Img';

const GroupTourItinerary = ({ textItinerary }) => {
  return (
    <div className={css.itinerary}>
      <div className={css.box1}>
        <h3>{textItinerary.part_1.h}</h3>
        {textItinerary.part_1.list.map((text, index) => (
          <p key={`p1-${index}`}>{text}</p>
        ))}
      </div>
      {textItinerary.part_2 && (
        <div className={css.box2}>
          <h3>{textItinerary.part_2.h}</h3>
          {textItinerary.part_2.upper_text?.length ? (
            textItinerary.part_2.upper_text.map((text, index) => (
              <p
                key={`upper-text-${index}`}
                style={{ marginBottom: index + 1 !== textItinerary.part_2.upper_text.length ? '16px' : null }}
              >
                {text}
              </p>
            ))
          ) : (
            <></>
          )}
          <ul>
            {textItinerary.part_2.list.map((text, index) => (
              <li key={`p2-${index}`}>{text}</li>
            ))}
          </ul>
          {textItinerary.part_2.lower_text?.length ? (
            textItinerary.part_2.lower_text.map((text, index) => <p key={`lower-text-${index}`}>{text}</p>)
          ) : (
            <></>
          )}
        </div>
      )}
      {textItinerary.photo && (
        <Img src={textItinerary.photo} alt="itinerary" className={classNames(css.photoItinerary, css.fadeIn)} />
      )}
    </div>
  );
};

export default GroupTourItinerary;
