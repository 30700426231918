import React from 'react';
import classNames from 'classnames';
import css from './date-picker.module.scss';
import { IconBlackCalendar } from '../../../assets/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';

const DatePickerComponent = ({ control, className, name, rules = { required: true }, placeholder, errors }) => {
  return (
    <div className={classNames(className, css.inputFieldWrapper)}>
      <IconBlackCalendar />
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <DatePicker
            placeholderText={placeholder}
            onChange={(date) => {
              field.onChange(date);
            }}
            selected={field.value}
            minDate={new Date()}
            dateFormat={'MM/dd/yyyy'}
          />
        )}
      />
      {errors[name] && (
        <p className={css.error}>
          {errors[name].type === 'required' ? 'This field is required.' : errors[name].message}
        </p>
      )}
    </div>
  );
};

export default DatePickerComponent;
