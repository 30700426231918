import React from 'react';
import css from './description.module.scss';
import { GROUP_TOUR_DELUXE_FIVE_ISLANDS } from '../../../../../utils/images/group-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Img from '../../../../../components/universal/Img';

const Description = () => {
  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10.5 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Deluxe Five Islands Tour: Explore the islands with our biggest boats</h2>
        <p>
          Looking for a luxurious and comfortable boat tour to explore Five Islands? Look no further than our Deluxe
          Five Islands Tour. Our biggest and best speedboats provide the most comfortable and stylish way to explore the
          stunning scenery of the Adriatic Sea.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Biggest speedboat with full equipment</h2>
        <p>
          Our speedboats, Colnago 33, Colnago 35 and Aliskaf 37, are equipped with a toilet, shower, free WIFI, and a
          cabin, ensuring that you have all the amenities you need during your tour.
        </p>
        <p>
          In addition, our boats have a sundeck in the back and plenty of shade in the front, allowing you to relax and
          enjoy the stunning views in comfort.
        </p>
        <p>
          They also have a platform with stairs at the back, making it easy to get in and out of the sea. With their
          large size and modern design, they offer the perfect blend of comfort and functionality.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_DELUXE_FIVE_ISLANDS.speedboat.map((img, index) => (
            <Img key={`speedboat-${index}`} src={img} alt={`speedboat-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.devider} style={{ margin: '40px 0px 0px' }} />
    </div>
  );
};

export default Description;
