import React from 'react';
import css from './blog.module.scss';
import { BANNERS } from '../../../utils/images';
import BlogCarousel from '../../../components/desktop/BlogCarousel';
import { TOP_BLOGS } from '../../../data/blogs';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const Blog = () => {
  return (
    <MotionDiv className={css.blogPageLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.blogs.blog_page.src} alt={BANNERS.blogs.blog_page.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>EXCURSION DALMATIA</h1>
        <h3>the best way to explore Split</h3>
      </div>

      <div className={css.contentContainer}>
        <h2>BLOG - LATEST POSTS</h2>
        <BlogCarousel data={TOP_BLOGS} />
      </div>
    </MotionDiv>
  );
};

export default Blog;
