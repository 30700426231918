import React from 'react';
import MotionDiv from '../../components/universal/MotionDiv';
import css from './weather.module.scss';
import Img from '../../components/universal/Img';
import { BLOGS_BANNERS } from '../../utils/images/blogs';
import WeatherInCroatia from '../Blogs/WeatherInCroatia';

const Weather = () => {
  return (
    <MotionDiv className={css.pageLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BLOGS_BANNERS.weather} alt="blog" className={css.bannerImg} />
        <div className={css.filter} />
        <h1>WEATHER IN CROATIA</h1>
      </div>
      <div className={css.contentContainer}>
        <WeatherInCroatia />
      </div>
    </MotionDiv>
  );
};

export default Weather;
