import React from 'react';
import css from './accommodation.module.scss';
import Img from '../../../components/universal/Img';
import { ACCOMMODATION } from '../../../utils/images/blogs';
import classNames from 'classnames';

const Accommodation = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>BEST PLACES FOR ACCOMMODATION IN & AROUND SPLIT</h2>
        <p>
          Discover a world of exceptional accommodations in Split, Croatia's second-largest city and the vibrant heart
          of Dalmatia. Offering an unforgettable spring and summer vacation experience, Split boasts a diverse range of
          lodgings to suit every taste and budget. From luxurious hotels and elegant villas to stylish apartments and
          budget-friendly hostels, all our accommodations have been handpicked and reviewed by guests to ensure the
          highest level of satisfaction.
        </p>
        <p>
          Immerse yourself in the local culture by selecting an apartment or vacation home with direct contact to the
          property owner. We do not handle bookings or charge commission fees for these rentals, allowing you to make
          reservations directly with the owner and avoid additional costs.ea, then spring and summer are the ultimate
          seasons to immerse yourself in the wonders of Croatia.
        </p>
        <p>
          Before confirming your accommodation, we encourage you to explore popular booking platforms such as
          Booking.com and Airbnb. These sites provide detailed information, including reviews, images, availability, and
          pricing, ensuring that you make an informed decision. Once you have identified your ideal lodging, we will
          gladly facilitate communication with the owner by providing their contact information.
        </p>
        <p>
          You may be wondering why we offer this service without charging a commission. The answer is simple: we are
          passionate locals, born and raised in Split. Our primary focus is not on renting accommodations, with the
          exception of our own Apartment Dalmatian. Instead, we dedicate our time and expertise to organizing
          exceptional excursions and private tours, allowing you to experience the very best of our beautiful city.
        </p>
      </div>
      <div className={css.box}>
        <h3>SPLIT ACCOMMODATION - HOTELS</h3>
        <p>
          Year upon year, the number of hotels in the city and its surrounding suburbs continues to grow. Among the
          finest and most luxurious establishments in and around Split, you will find:
        </p>
        <ul>
          <li>Le Méridien Lav in Podstrana</li>
          <li>Hotel Atrium near Joker shopping mall</li>
          <li>Hotel President (located in Split and Solin)</li>
          <li>The elegant Radisson Blu Resort offering stunning views</li>
          <li>Hotel Park situated above sandy beach Bačvice</li>
          <li>Medena complex near Trogir</li>
          <li>Hotel Globo in close proximity to Split center</li>
        </ul>
        <p>
          These exquisite hotels boast opulent rooms, complimentary access to gym, spa, and wellness facilities, as well
          as onsite casinos, clubs, and swimming pools. Some establishments feature private beaches and are situated
          adjacent to the sea, while others, such as Le Meridien Lav in Podstrana, possess their own marina.
        </p>
        <p>
          The average cost of a hotel room for one night ranges between €100 and €500, subject to your preferred choice
          of accommodation.
        </p>
        <div className={css.gallery}>
          {ACCOMMODATION.hotels.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3>SPLIT ACCOMMODATION - VILLAS</h3>
        <p>
          Split, a vibrant tourist hub in Dalmatia and Croatia's second-largest city, is nestled amongst charming
          smaller towns, offering a plethora of villas for rent, particularly in picturesque rural locations.
        </p>
        <p>
          As an alluring tourist destination, accommodation in Split, especially villas, can come at a premium. However,
          the city's suburbs provide more budget-friendly options without compromising on luxury.
        </p>
        <p>
          Rental rates for these villas typically range from €1,000 to €5,000 per week, depending on factors such as the
          season, level of luxury, and location.
        </p>
        <p>Luxury Villas in Split area:</p>
        <ul>
          <li>Villa Castello Split located in Meje (Split's version of Beverly Hills)</li>
          <li>Villa Penthouse - Split owned by Radisson Resort</li>
          <li>Villa Red Land or Villa Greta (beach villa)</li>
          <li>Luxury Villa Luminosa located in suburb of Split</li>
          <li>Villa Tomislav located in Meje</li>
          <li>Villa Bila near Split centre</li>
        </ul>
        <p>
          While booking.com and Airbnb offer some villa options, we recommend exploring luxury retreats and my private
          villas for an exquisite selection of accommodations.
        </p>
        <div className={classNames(css.gallery, css.grid4)}>
          {ACCOMMODATION.villas.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3>SPLIT ACCOMMODATION - HOSTELS</h3>
        <p>
          If you're looking for a cheap and clean place to stay in Split, Croatia, consider staying at a hostel. Some
          popular options include:
        </p>
        <ul>
          <li>Design Hostel Goli & Bosi</li>
          <li>Hostel Split</li>
          <li>Hostel Split Backpackers</li>
          <li>Hostel Booze & Snooze</li>
          <li>Al's Place</li>
          <li>Tchaikovsky Hostel Split</li>
        </ul>
        <p>
          Prices are calculated by bed and range from 13€ to 35€ per night. You can expect amenities such as Wi-Fi,
          air-conditioning, 24-hour reception, lockers, bars, and shared living spaces.
        </p>
        <p>
          Hostels are great for solo travelers who want to meet other people. You don’t have much privacy but they are
          places with a good vibe and energy.
        </p>
        <p>
          To find and book a hostel that best suits you we recommend Tripadvisor as best the website for this type of
          accommodation in Split.
        </p>
        <div className={classNames(css.gallery, css.grid4)}>
          {ACCOMMODATION.hostels.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3>Split Accommodation - APARTMENTS</h3>
        <p>
          Discover the perfect blend of space, privacy, and exceptional value for money with a selection of apartments
          in Split. Catering to a diverse range of budgets and preferences, a wide array of options guarantees a match
          for your ideal luxury experience.
        </p>
        <p>
          While centrally-located apartments tend to come at a premium, the charming suburbs of Solin, Kaštela, Stobreč,
          and Trogir offer outstanding value. In these picturesque neighborhoods, you can often find a spacious 2 or
          3-bedroom apartment at unbeatable prices. Rest assured that public transportation, taxis, and Uber services
          are well-organized and easily accessible throughout the area.
        </p>
        <p>
          Before finalizing your reservation, take the time to carefully consider the location of your chosen apartment.
          Additionally, research the available transportation and car rental options in the vicinity, particularly if
          you're arriving without your own vehicle. Allow yourself the peace of mind knowing that you've selected the
          perfect accommodation for a truly unforgettable stay in Split.
        </p>
      </div>
    </div>
  );
};

export default Accommodation;
