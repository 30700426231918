import React from 'react';
import css from './privacy-policy.module.scss';
import { BANNERS } from '../../../utils/images';
import { Link } from 'react-router-dom';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const PrivacyPolicy = () => {
  return (
    <MotionDiv className={css.privacyPolicyMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.privacy_policy.src} alt={BANNERS.privacy_policy.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>PRIVACY POLICY</h1>
      </div>

      <div className={css.contentContainer}>
        <h2>Privacy Policy</h2>
        <p>
          At <span>excursion-dalmatia.com</span> we are dedicated to protecting your data and privacy.
        </p>
        <p>
          We recognize that your privacy is important. This document outlines the types of personal information we
          receive and collect when you use <span>excursion-dalmatia.com</span>, as well as some of the steps we take to
          safeguard information. This will help you make an informed decision about sharing personal information with
          us.
        </p>
        <p>
          excursion-dalmatia.com strives to maintain the highest standards of decency, fairness and integrity in all our
          operations. Likewise, we are dedicated to protecting our customers' and online visitors' privacy on our
          website.
        </p>

        <h2>Personally Identifiable Information</h2>
        <p>
          <span>excursion-dalmatia.com</span> may collect and use your personal information for the following purposes:
        </p>
        <ul>
          <li>To run and operate our site.</li>
          <li>To display content on the site correctly.</li>
          <li>To improve customer service.</li>
          <li>To help us respond to your customer service requests and support needs more efficiently.</li>
          <li>To personalize your user experience.</li>
          <li>To understand how our users as a group use the services and resources provided on our site.</li>
          <li>To improve our site.</li>
          <li>To improve our products and services.</li>
          <li>To run a promotion, contest, survey or other site feature.</li>
          <li>
            To send information to you that you agreed to receive about topics we think will be of interest to you.
          </li>
          <li>
            To send periodic emails, which may include electronic newsletters and/or autoresponder series of emails.
          </li>
          <li>To respond to your enquiries, questions, and/or other requests.</li>
        </ul>

        <p>
          <span>excursion-dalmatia.com</span> may collect personally identifiable information from you in a variety of
          ways, including, but not limited to, when you visit our site, register on the site, or fill out a form, and in
          connection with other activities, services, features or resources we make available on our site.
        </p>
        <p>
          If you complete a form, we will require your name and your email address, and will ask for information
          relevant to the purpose of the form.
        </p>
        <p>
          If you submit content to us, we will ask for your name and email address. Photos you include with your
          submission, or details in your submission, may personally identify you.
        </p>
        <p>
          If you request to be notified about comments on any content you submit to us, or request to be notified when
          others submit content, we will require your first name and your email address.
        </p>
        <p>
          If you purchase a product or service from us, we will collect your name, email address, billing address and
          credit card number. This information may be stored on a third-party's servers.
        </p>
        <p>
          This site lets you share content with your friends and followers on various social media platforms. Below are
          links to the privacy policies of:
        </p>
        <p>
          <Link
            to={'https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0'}
            target="_blank"
          >
            Facebook Privacy Policy
          </Link>
        </p>
        <p>
          <Link to={'https://twitter.com/en/privacy'} target="_blank">
            Twitter Privacy Policy
          </Link>
        </p>
        <p>
          <Link to={'https://policies.google.com/privacy'} target="_blank">
            Google Privacy Policy
          </Link>
        </p>
        <p>
          <Link to={'https://policy.pinterest.com/en/privacy-policy'} target="_blank">
            Pinterest Privacy Policy
          </Link>
        </p>
        <p>
          <Link to={'https://www.tumblr.com/privacy'} target="_blank">
            Tumblr Privacy Policy
          </Link>
        </p>
        <p>
          <Link
            to={'https://www.figma.com/exit?url=https%3A%2F%2Fwww.reddit.com%2Fhelp%2Fprivacypolicy%2F'}
            target="_blank"
          >
            Redit Privacy Policy
          </Link>
        </p>
        <p>
          <span>excursion-dalmatia.com</span> also uses Facebook commenting. Facebook stores all of your comments and
          may show them on your timeline, depending on your Facebook settings.
        </p>
        <p>
          You can always refuse to supply personally identifiable information and visit our site anonymously. However,
          it may prevent you from engaging in certain site-related activities.
        </p>
        <p>
          If we make material changes in the collection of personally identifiable information, we will inform you by
          placing a notice on our site. We will use personal information received from you for internal purposes only
          and will not sell it or provide it to third parties.
        </p>
        <p>
          We also collect each visitor's IP address, which helps us combat spam and fraud. We do not use IP addresses
          for any other purpose.
        </p>
        <p>
          <span>excursion-dalmatia.com</span> contains links to other websites that may be of interest to you. However,
          once you have used them to leave our site, we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any information you provide while visiting such sites.
          These sites are not governed by this privacy statement. Always exercise caution by reviewing the privacy
          statement of the website in question before continuing to use it.
        </p>
        <h2>Non-personally Identifiable Information</h2>
        <p>
          We may collect non-personally identifiable information about you whenever you interact with our site.
          Non-personally identifiable information may include the browser name, the type of computer and technical
          information about your method of connecting to our site, such as the operating system and the Internet service
          provider you used, and other similar information.
        </p>
        <h2>Children's Privacy Protection</h2>
        <p>
          This website is directed to adults. It is not directed to children under the age of 16. We operate our site in
          compliance with current regulations. Anyone under the age of 16 must have a parent or guardian provide consent
          to use this site. We do not knowingly collect or use personally identifiable information from anyone under 16
          years of age.
        </p>
        <h2>Release of Information</h2>
        <p>
          If we sell excursion-dalmatia.com, the information we have obtained from you through your voluntary
          participation in our site may transfer to the new owner as a part of the sale so that the service provided to
          you may continue. In that event, you will receive notice through our website of that change in control and
          practices, and we will make reasonable efforts to ensure that the purchaser honors any opt-out requests you
          might make.
        </p>
        <h2>Data Storage</h2>
        <p>
          Personally identifiable information and non-personally identifiable information collected by this site is
          stored on our host's servers. That host is SiteSell Inc., located at 1000 Saint-Jean Boulevard, Suite 702,
          Pointe-Claire, QC H9R 5P1 Canada.
        </p>
        <h2>How You Can Correct or Remove Information</h2>
        <p>
          We provide this privacy policy as a statement to you of our commitment to protect your personally identifiable
          information. If you have submitted personally identifiable information through our website and would like to
          download and review that information, click on this link.
        </p>
        <p>You can also request to edit or delete any of your information from the servers by clicking on this link.</p>
        <h2>Updates and Effective Date</h2>
        <p>
          excursion-dalmatia.com reserves the right to make changes in this policy. If there is a material change in our
          cookie and privacy practices, we will indicate on our site that those practices have changed and provide a
          link to the updated privacy policy. We encourage you to periodically review this policy so that you will know
          what information we collect and how we use it.
        </p>
        <h2>Agreeing to Terms</h2>
        <p>
          If you do not agree to <span>excursion-dalmatia.com</span>'s privacy policy as posted here on this website,
          please do not consent to the setting of cookies and the collection and storage of your personally identifiable
          information.
        </p>
        <p style={{ textDecoration: 'underline', fontWeight: '700' }}>
          Your explicit consent indicates acceptance of this privacy policy in its entirety.
        </p>
      </div>
    </MotionDiv>
  );
};

export default PrivacyPolicy;
