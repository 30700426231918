import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { BOL_HVAR_PAKLENI } from '../../../../../utils/images/private-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={BOL_HVAR_PAKLENI.important_bring} alt="Important to bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and crew.</p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const FirstPart = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: <Img src={BOL_HVAR_PAKLENI.overview} alt="overview" className={classNames(css.overviewImg, css.fadeIn)} />,
    1: <ImportantTab />,
    2: <Gallery images={BOL_HVAR_PAKLENI.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Bol & Hvar Tour</h2>
        <p>
          Ready for an unforgettable beach day? Experience the divine bliss of a paradise-like beach and the salty air
          that comes with it.
        </p>
        <p>Explore the crystal blue waters of Zlatni Rat beach and Pakleni islands.</p>
        <p>
          Soak up the sun, smell the salty air of Brač island and immerse yourself in the history of town Hvar on this
          one day boat tour.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Private Zlatni Rat Tour: Zlatni rat (Golden Cape)</h2>
        <p>
          Experience the beauty of the Mediterranean at Zlatni Rat beach, located on the stunning Brač Island. With its
          unique shape-shifting nature, Zlatni Rat has become one of Europe's most beloved beaches, drawing visitors
          from all over the world.
        </p>
        <p>
          Take a dip in the crystal-clear waters or sunbathe on the golden sands. You can also explore the vibrant
          marine life with snorkeling and scuba diving, or enjoy a meal at a nearby restaurant and sample some of the
          local cuisine.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BOL_HVAR_PAKLENI.bol_1} alt="Zlatni rat 1" />
          <Img src={BOL_HVAR_PAKLENI.bol_2} alt="Zlatni rat 2" />
          <Img src={BOL_HVAR_PAKLENI.bol_3} alt="Zlatni rat 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Bol & Hvar Tour: Hvar</h2>
        <p>
          This beautiful getaway for jetsetters is sure to capture your heart from the moment you arrive. An exciting
          nightlife, stunning beaches and coves, and a rich cultural history await you here.
        </p>
        <p>
          Take a stroll through the quaint streets of Hvar and experience its past, then relax at one of the many beach
          bars and enjoy your day.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BOL_HVAR_PAKLENI.hvar_1} alt="Hvar 1" />
          <Img src={BOL_HVAR_PAKLENI.hvar_2} alt="Hvar 2" />
          <Img src={BOL_HVAR_PAKLENI.hvar_3} alt="Hvar 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Bol & Hvar Tour: Pakleni islands</h2>
        <p>
          Discover the untouched beauty of the Pakleni Islands, a stunning collection of fourteen islands which offer
          the perfect escape. Relax on secluded beaches and crystal-clear lagoons and bays, including the breathtaking
          Carpe Diem beach.
        </p>
        <p>
          Rejuvenate in the refreshing waters of the Adriatic Sea, surrounded by a lush pine tree forest and vibrant
          beach bars. Experience a day of unparalleled beauty and bliss.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BOL_HVAR_PAKLENI.pakleni_1} alt="Pakleni islands 1" />
          <Img src={BOL_HVAR_PAKLENI.pakleni_2} alt="Pakleni islands 2" />
          <Img src={BOL_HVAR_PAKLENI.pakleni_3} alt="Pakleni islands 3" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
    </div>
  );
};

export default FirstPart;
