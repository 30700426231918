import React from 'react';
import classNames from 'classnames';
import css from './blog-card.module.scss';
import Img from '../Img';

const BlogCard = ({ className, data, small, onClick }) => {
  if (!data) return null;
  return (
    <div className={classNames(className, css.blogCardWrapper)} onClick={onClick}>
      <Img src={data.photo} alt="blog" className={css.photo} />
      <div className={css.filter} />
      <div className={css.nameWrapper}>
        <h3 className={classNames(css.name, { [css.small]: small })}>{data.name}</h3>
      </div>
    </div>
  );
};

export default BlogCard;
