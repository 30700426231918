import React, { useState } from 'react';
import classNames from 'classnames';
import css from './select.module.scss';
import { IconDropdown } from '../../../assets/icons';

const Select = ({
  className,
  register,
  name,
  rules = { required: true },
  options,
  setValue,
  errors,
  placeholder = 'Country code',
  icon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const changSelection = (option) => {
    setSelected(option);
    setValue(name, option.value);
    setIsOpen(false);
  };

  return (
    <div
      className={classNames(className, css.selectWrapper)}
      {...register(name, rules)}
      onClick={() => setIsOpen(!isOpen)}
    >
      {icon || null}
      <IconDropdown className={css.icon} />
      <div className={classNames(css.selection, { [css.selectionWithIcon]: icon })}>
        {selected?.name || placeholder}
      </div>
      {errors[name] && (
        <p className={css.error}>
          {errors[name].type === 'required' ? 'This field is required.' : errors[name].message}
        </p>
      )}
      {isOpen && (
        <ul className={css.dropdown}>
          {options.map((option, index) => (
            <li key={`option-${index}`} onClick={() => changSelection(option)}>
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Select;
