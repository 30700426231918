import React from 'react';
import css from './not-found.module.scss';
import { Link } from 'react-router-dom';
import MotionDiv from '../../../components/universal/MotionDiv';

const NotFound = () => {
  return (
    <MotionDiv className={css.pageLayout}>
      <div className={css.bannerWrapper}>
        <div className={css.filter} />
        <h1>PAGE NOT FOUND</h1>
      </div>
      <div className={css.contentContainer}>
        <h2>404 error</h2>
        <p>The page that you are looking for does not exist :(</p>
        <Link to="/">Back to home</Link>
      </div>
    </MotionDiv>
  );
};

export default NotFound;
