import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { HVAR_PAKLENI } from '../../../../../utils/images/private-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={HVAR_PAKLENI.important_bring} alt="Important to bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and crew.</p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const Description = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: <Img src={HVAR_PAKLENI.overview} alt="overview" className={classNames(css.overviewImg, css.fadeIn)} />,
    1: <ImportantTab />,
    2: <Gallery images={HVAR_PAKLENI.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Hvar & Pakleni islands Tour</h2>
        <p>
          Discover the beauty of ancient city Hvar and the Pakleni Islands on our unforgettable private Hvar & Pakleni
          islands tour! From the moment you step onto the boat, you are sure to be mesmerized by the stunning views of
          the Croatian coastline.
        </p>
        <p>
          Enjoy the clear waters of the Pakleni Islands and sandy beaches Palmižana and Carpe Diem. Take the
          breathtaking views of Hvar. Whether you are looking to relax with your family, friends, or on a romantic
          getaway, you are sure to find a place that will leave you with lasting memories.
        </p>
        <p>
          What's more, you can explore the hidden bays, beach bars, and restaurants to make your vacation truly unique.
          Don't miss this chance to experience the beauty of Hvar and the Pakleni Islands!
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Private Hvar & Pakleni islands Tour: Hvar</h2>
        <p>
          Come explore the beautiful city of Hvar, favorite destination for celebrities! From the eight centuries old
          architecture to the first ever public theater in Europe, this is a holiday destination you don't want to miss.
        </p>
        <p>
          Climb the Fortica Fortress and admire the breathtaking panoramic view of the bay and the old town. Get the
          perfect picture of Hvar with the Pakleni islands in front.
        </p>
        <p>
          Enjoy your free time wandering through the charming streets, soaking in the local life, and indulging in the
          delicious local cuisine. Make sure you take the time to experience everything Hvar has to offer!
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={HVAR_PAKLENI.hvar_1} alt="Hvar 1" />
          <Img src={HVAR_PAKLENI.hvar_2} alt="Hvar 2" />
          <Img src={HVAR_PAKLENI.hvar_3} alt="Hvar 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Hvar & Pakleni islands Tour: Pakleni islands</h2>
        <p>Explore the hidden gems of the Adriatic Sea and come to the Pakleni Islands!</p>
        <p>
          This string of islands is situated in front of Hvar. With stunning beaches, crystal-clear waters, and
          picturesque views, these islands are the perfect place for a relaxing getaway.
        </p>
        <p>
          Enjoy swimming, snorkeling, and sunbathing in the Palmizana Bay or the Carpe Diem beach. Discover the amazing
          history of the islands, which are named after the black resin "paklina" from the local pines, which was used
          in shipbuilding.
        </p>
        <p>
          So don't miss out - come and explore the beautiful Pakleni Islands on our private Hvar & Pakleni islands tour.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={HVAR_PAKLENI.pakleni_1} alt="Pakleni islands 1" />
          <Img src={HVAR_PAKLENI.pakleni_2} alt="Pakleni islands 2" />
          <Img src={HVAR_PAKLENI.pakleni_3} alt="Pakleni islands 3" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
    </div>
  );
};

export default Description;
