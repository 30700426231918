import React, { useState } from 'react';
import css from './three-islands.module.scss';
import { BANNER_IMAGES } from '../../../../utils/images/group-tours';
import TabMenu from '../../../../components/universal/TabMenu';
import FirstPart from './description';
import { GROUP_TOURS, THREE_ISLANDS_SHIPWRECK } from '../../../../data/group-tours';
import BookingForm from '../../../../components/universal/BookingFormWithStripe';
import { IconWhiteTelephone } from '../../../../assets/icons';
import MotionDiv from '../../../../components/universal/MotionDiv';
import TourItinerary from '../../../../components/universal/TourItinerary';
import TourLocation from '../../../../components/universal/TourLocation';
import TripadvisorReviews from '../../../../components/universal/TripadvisorReviews';
import TEXT from '../../../../json/pages/3-islands.json';
import Img from '../../../../components/universal/Img';
import OtherTours from '../../../../components/desktop/OtherTours';

const GroupTureSinglePageThreeIslands = () => {
  const [activeTab, setActiveTab] = useState(0);

  const mainContent = {
    0: <FirstPart />,
    1: <TourItinerary textItinerary={TEXT.itinerary} />,
    2: <TourLocation />,
    3: <TripadvisorReviews />,
  };

  return (
    <MotionDiv className={css.groupToursPageLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNER_IMAGES.three_islands.src} alt={BANNER_IMAGES.three_islands.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>3 ISLANDS TOUR: SHIPWRECK, BLUE LAGOON, MASLINICA</h1>
      </div>

      <div className={css.contentContainer}>
        <TabMenu
          menuItems={['Description', 'Itinerary', 'Location', 'Reviews']}
          activeTab={activeTab}
          onClick={setActiveTab}
        />
      </div>

      <div className={css.mainContentWrapper}>
        {mainContent[activeTab]}

        <div className={css.contentRight}>
          <div className={css.phoneContainer}>
            <IconWhiteTelephone />
            <a href="tel:+385958267981">Call center: +385 95 826 7981</a>
          </div>
          <BookingForm
            type={2}
            tourName={THREE_ISLANDS_SHIPWRECK.name}
            startPassengersPrice={THREE_ISLANDS_SHIPWRECK.price}
          />
        </div>
      </div>

      <div className={css.otherTours}>
        <h2>OTHER TOURS</h2>
        <OtherTours data={GROUP_TOURS} />
      </div>
    </MotionDiv>
  );
};

export default GroupTureSinglePageThreeIslands;
