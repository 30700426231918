import React from 'react';
import { useParams } from 'react-router-dom';
import MotionDiv from '../../../components/universal/MotionDiv';
import css from './blog.module.scss';
import Img from '../../../components/universal/Img';
import { BLOGS_BANNERS } from '../../../utils/images/blogs';
import WeatherInCroatia from '../../Blogs/WeatherInCroatia';
import PlanningVacation from '../../Blogs/PlanningVacation';
import ApartmentsSplit from '../../Blogs/ApartmentsSplit';
import ThingsToDo from '../../Blogs/ThingsToDo';
import Accommodation from '../../Blogs/Accommodation';
import RomanticRestaurants from '../../Blogs/RomanticRestaurants';
import Nightlife from '../../Blogs/Nightlife';
import Beaches from '../../Blogs/Beaches';
import CheapEats from '../../Blogs/CheapEats';
import Vege from '../../Blogs/Vege';

const blogBannerContent = {
  'weather-in-croatia': {
    banner: BLOGS_BANNERS.weather,
    title: 'WEATHER IN CROATIA',
  },
  'planning-a-vacation-in-croatia-heres-what-you-need-to-know': {
    banner: BLOGS_BANNERS.planning_a_vacation,
    title: "PLANNING A VACATION IN CROATIA? HERE'S WHAT YOU NEED TO KNOW",
  },
  'apartments-in-split-with-direct-contact-to-the-owners': {
    banner: BLOGS_BANNERS.apartments_in_split,
    title: 'APARTMENTS IN SPLIT WITH DIRECT CONTACT TO THE OWNERS',
  },
  'top-10-things-to-do-in-split': {
    banner: BLOGS_BANNERS.top_10_things_to_do_in_split,
    title: 'TOP 10 THINGS TO DO IN SPLIT',
  },
  'best-places-for-accommodation-in-around-split': {
    banner: BLOGS_BANNERS.best_places_for_accommodation,
    title: 'BEST PLACES FOR ACCOMMODATION IN & AROUND SPLIT',
  },
  'best-romantic-restaurants-in-split': {
    banner: BLOGS_BANNERS.best_romantic_restaurants,
    title: 'BEST ROMANTIC RESTAURANTS IN SPLIT',
  },
  'nightlife-in-split-the-best-places-for-fun-night-out': {
    banner: BLOGS_BANNERS.nightlife_in_split,
    title: 'NIGHTLIFE IN SPLIT: THE BEST PLACES FOR FUN NIGHT OUT',
  },
  'top-10-croatia-beaches-find-your-slice-of-paradise': {
    banner: BLOGS_BANNERS.top_10_croatia_beaches,
    title: 'TOP 10 CROATIA BEACHES - FIND YOUR SLICE OF PARADISE',
  },
  'cheap-eats-in-split-best-fast-foods-sandwich-bars': {
    banner: BLOGS_BANNERS.cheap_eats_in_split,
    title: 'CHEAP EATS IN SPLIT: BEST FAST FOODS & SANDWICH BARS',
  },
  'the-best-vegetarian-restaurants': {
    banner: BLOGS_BANNERS.the_best_vegetarian_restaurants,
    title: 'THE BEST VEGETARIAN RESTAURANTS IN SPLIT',
  },
};

const blogContent = {
  'weather-in-croatia': <WeatherInCroatia />,
  'planning-a-vacation-in-croatia-heres-what-you-need-to-know': <PlanningVacation />,
  'apartments-in-split-with-direct-contact-to-the-owners': <ApartmentsSplit />,
  'top-10-things-to-do-in-split': <ThingsToDo />,
  'best-places-for-accommodation-in-around-split': <Accommodation />,
  'best-romantic-restaurants-in-split': <RomanticRestaurants />,
  'nightlife-in-split-the-best-places-for-fun-night-out': <Nightlife />,
  'top-10-croatia-beaches-find-your-slice-of-paradise': <Beaches />,
  'cheap-eats-in-split-best-fast-foods-sandwich-bars': <CheapEats />,
  'the-best-vegetarian-restaurants': <Vege />,
};

const IndividualBlog = () => {
  const { slug } = useParams();

  const getBannerPhoto = () => {
    const blog = blogBannerContent[slug];
    if (blog) return blog.banner;
    return 'https://via.placeholder.com/600/1ab5a92';
  };

  const getBannerTitle = () => {
    const blog = blogBannerContent[slug];
    if (blog) return blog.title;
    return 'BLOG TITLE';
  };

  const getBlogHtml = () => {
    const blog = blogContent[slug];
    if (blog) return blog;
    return <p>Individual Blog {slug} page</p>;
  };

  return (
    <MotionDiv>
      <MotionDiv className={css.mobileLayout}>
        <div className={css.bannerWrapper}>
          <Img src={getBannerPhoto()} alt="blog banner" className={css.bannerImg} />
          <div className={css.filter} />
          <h1>{getBannerTitle()}</h1>
        </div>
        <div className={css.contentContainer}>{getBlogHtml()}</div>
      </MotionDiv>
    </MotionDiv>
  );
};

export default IndividualBlog;
