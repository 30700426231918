import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { BOL_SOLTA } from '../../../../../utils/images/private-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={BOL_SOLTA.important_bring} alt="Important to bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and crew.</p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const Description = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: <Img src={BOL_SOLTA.overview} alt="overview" className={classNames(css.overviewImg, css.fadeIn)} />,
    1: <ImportantTab />,
    2: <Gallery images={BOL_SOLTA.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Zlatni Rat Tour</h2>
        <p>Discover Brač and Šolta islands and experience the true beauty of Croatia on our Private Zlatni Rat Tour.</p>
        <p>
          Get away from the hustle and bustle of everyday life and find yourself on one of the most beautiful beaches in
          the world - Zlatni Rat beach.
        </p>
        <p>
          Create special memories with us while exploring the hidden bays of Brač and Šolta islands. Let the
          peacefulness and serenity of Milna relax your mind and body.
        </p>
        <p>Uncover the secret places of Croatia with us and create a lifetime of memories.</p>
      </div>

      <div className={css.textBox}>
        <h2>Private Bol & Hvar Tour: Zlatni rat (Golden Cape)</h2>
        <p>
          Are you looking for a unique and stunning beach destination? Look no further than Zlatni Rat beach, located on
          the gorgeous island of Brač in Croatia.
        </p>
        <p>
          This 500 meters long beach is renowned for its unique shape, which changes ever so slightly each time the tide
          rolls in.
        </p>
        <p>
          It is no surprise that Zlatni Rat beach was named one of the best beach destinations in Europe for two years
          in a row. Not only does it boast breathtaking views, but you can also enjoy swimming, snorkeling and exploring
          the local restaurants.
        </p>
        <p>So don't miss out on the chance to experience one of the most beautiful beaches in the world.</p>
        <Img src={BOL_SOLTA.zlatni_rat} alt="Bol 1" />
      </div>

      <div className={css.textBox}>
        <h2>Private Zlatni Rat Tour: Military Tunnels on Brač Island</h2>
        <p>
          Discover the unique military tunnels on Brač Island, a remarkable remnant from World War II and a hidden
          treasure of Dalmatia.
        </p>
        <p>
          Built to provide safe harboring for submarines and smaller navy vessels, these tunnels have now become a
          popular destination for tourists and military enthusiasts.
        </p>
        <p>
          Just a short distance from the UNESCO-protected Blaca Hermitage site, these impressive structures offer a
          glimpse into the history of the Croatian coast.
        </p>
        <p>Don't miss your chance to explore these tunnels and uncover the secrets of Dalmatian history.</p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_2)}>
          <Img src={BOL_SOLTA.tunel_1} alt="Tunels 1" />
          <Img src={BOL_SOLTA.tunel_2} alt="Tunels 2" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Zlatni Rat Tour: Milna on island Brač</h2>
        <p>
          Set sail to Milna on the beautiful island of Brač and discover a coastal haven like no other. From the ”valle
          di mille navi” (the bay of a thousand ships) to the historical sights and impressive works of Venetian
          masters, it's a place of true local charm.
        </p>
        <p>
          Enjoy a cozy meal in one of the many inviting taverns and restaurants that dot the harbors of Milna and
          experience the unique atmosphere of this nautical paradise.
        </p>
        <p>Taste how it is to be a true local of this biggest natural harbor of Brač.</p>
        <p>
          Whether you are a sea lover, a history buff or just looking for a peaceful escape, Milna on Brač Island is the
          perfect destination for your next vacation.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_2)}>
          <Img src={BOL_SOLTA.milna_1} alt="Milna 1" />
          <Img src={BOL_SOLTA.milna_2} alt="Milna 2" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Zlatni Rat Tour: Stomorska on island Šolta</h2>
        <p>Discover the hidden gem of the Dalmatian Coast - Stomorska.</p>
        <p>
          This charming village boasts a long history of fisherman families and its picturesque location surrounded by
          natural beauty makes it the perfect getaway.
        </p>
        <p>
          Spend your days swimming in the chrystal clear waters, exploring the island's charming streets or simply
          relaxing and taking in the stunning views.
        </p>
        <p>
          With the daily catch of fish being sold right on the dock in the town, Stomorska is sure to provide you with a
          memorable experience on our Private Zlatni Rat Tour.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_2)}>
          <Img src={BOL_SOLTA.stomorska_1} alt="Stomorska 1" />
          <Img src={BOL_SOLTA.stomorska_2} alt="Stomorska 2" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
    </div>
  );
};

export default Description;
