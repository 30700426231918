import React from 'react';
import css from './things-to-do.module.scss';
import Img from '../../../components/universal/Img';
import { THINGS_TO_DO } from '../../../utils/images/blogs';

const ThingsToDo = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>Split Travel Guide: Top 10 things to do in Split</h2>
        <p>
          Welcome to our captivating Split travel guide! Get ready to embark on an unforgettable journey through the
          enchanting city of Split, nestled in the heart of Dalmatia. Whether you find yourself already planning a visit
          or are simply daydreaming about the idyllic Adriatic coast, we've got you covered with an array of thrilling
          activities and must-see destinations.
        </p>
        <p>
          Regardless of the season, Split promises an extraordinary vacation like no other. But if you're seeking
          vibrant festivals, sun-kissed days, and the allure of sandy beaches caressed by the crystal-clear waters of
          the Adriatic Sea, then spring and summer are the ultimate seasons to immerse yourself in the wonders of
          Croatia.
        </p>
        <Img src={THINGS_TO_DO.view.src} alt={THINGS_TO_DO.view.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3 style={{ marginBottom: '0px' }}>SPLIT TRAVEL GUIDE - EXPLORE THE TOWN & ITS BEACHES</h3>
      </div>
      <div className={css.box}>
        <h3>Explore Diocletian's Palace and the old town:</h3>
        <p>
          Step back in time as you explore the majestic Diocletian's Palace, the top attraction in the city of Split.
          Constructed during the reign of the Roman Emperor Diocletian, this awe-inspiring structure was designed to
          serve as his luxurious retirement abode.
        </p>
        <p>
          Far more than just a palace, this architectural masterpiece dominates more than half of Split's city center,
          its sheer size and grandeur making it an absolute must-see for history buffs and tourists alike.
        </p>
        <p>
          Immerse yourself in the rich history and culture of the city as you meander through the captivating old town
          that spills out around the palace and stretches towards the lush park forest Marjan.
        </p>
        <p>Visit one of Split museums:</p>
        <p>
          If you're a history buff, architecture enthusiast, and lover of ancient monuments, Diocletian's Palace by
          itself is a great sightseeing spot. But there's more! Dive deeper into the rich and turbulent past of this
          captivating town and the country it calls home with an array of fascinating museums, both within and just
          beyond the palace walls.
        </p>
      </div>
      <div className={css.box}>
        <h3>Take a bike tour or go for a hike through the Park Forest Marjan:</h3>
        <p>
          Discover the verdant oasis of Split, nestled on a hill adjacent to the historic old town and enveloped by the
          breathtaking Adriatic Sea. This hidden gem is a must-see destination for any traveler. As you ascend the hill,
          prepare to be captivated by panoramic views of the surrounding landscape and the shimmering bay below.
        </p>
        <p>
          Marjan boasts not only lush vegetation but also an array of outdoor activities for all ages. Explore the
          numerous hiking and jogging trails, admire the ancient churches scattered throughoutthe area, or challenge
          yourself at one of the two tennis courts. Sports enthusiasts can also take advantage of the small football
          field, while children can delight in the playground and charming pony rides.
        </p>
        <p>
          When it's time to refuel, indulge in some local cuisine at the onsite restaurant, or simply relax on one of
          the many picturesque beaches that line the peninsula. For those interested in marine life, be sure to visit
          the maritime research institute located within Marjan.
        </p>
      </div>
      <div className={css.box}>
        <h3>Cool down on one of Split beaches:</h3>
        <p>
          Venture beyond the picturesque park forest Marjan, and you'll find a treasure trove of breathtaking beaches
          lining the Split coastline. In addition to popular spots like Obojena, Kašjuni, Prve Vode, and Bene, there are
          plenty of other coastal gems waiting to be explored.
        </p>
        <p>
          Take a leisurely 15-minute stroll from the city center, and you'll find yourself at the idyllic shallow sandy
          beaches of Bačvice and Firule. These tranquil havens are perfect for sunbathing or taking a refreshing dip in
          the crystal-clear waters.
        </p>
        <p>
          For those seeking a more vibrant beach experience, the Žnjan plateau is a must-visit destination. This
          bustling hotspot boasts a long stretch of pebble beaches, offering stunning views and plenty of opportunities
          for fun-filled activities. That being said, it is in process of reconstruction.
        </p>
        <p>
          But the wonders of Split's coastline don't stop there - the enchanting suburbs surrounding the city also have
          their fair share of beautiful beaches. From the charming town of Stobreč to the picturesque Kaštela and the
          historic city of Trogir, there's no shortage of stunning seaside spots to uncover.
        </p>
        <div className={css.gallery}>
          {THINGS_TO_DO.splitBeaches.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3 style={{ marginBottom: '0px' }}>SPLIT TRAVEL GUIDE - VISIT NEARBY TOWNS & GO SHOPPING</h3>
      </div>
      <div className={css.box}>
        <h3>Take a day trip and visit surrounding towns:</h3>
        <p>
          While Split undoubtedly reigns as the crown jewel of Dalmatia, several enchanting towns in its vicinity hold
          their own unique allure. Venture beyond the bustling metropolis and uncover the captivating secrets of these
          lesser-known destinations.
        </p>
        <p>
          Solin, a quaint town with undeniable charm, is home to the ancient Roman city of Salona - once the capital of
          Dalmatia. Its rich history and picturesque landscapes make it a must-visit spot for any traveler seeking a
          deeper connection to the region's past.
        </p>
        <p>
          Just a stone's throw away from Solin lies Kaštela, a town boasting not one, but seven magnificent medieval
          castles. Each structure tells its own story, allowing visitors to journey through time as they explore these
          architectural masterpieces.
        </p>
        <p>
          Head westward along the Split Riviera and you'l find the breathtaking city of Trogir, a haven of exquisite
          Baroque architecture. The intricate details and undeniable beauty of the buildings transport you to another
          era, creating an unforgettable experience.
        </p>
        <p>
          Venture eastward and you'll encounter two coastal gems: Stobreč and Omiš. Stobreč tempts visitors with its
          expansive beach and an array of inviting cafe bars along the seaside, while Omiš boasts the finest beaches in
          the region and a fascinating history as a pirate stronghold.
        </p>
      </div>
      <div className={css.box}>
        <h3>Enjoy in vibrant nightlife and summer parties:</h3>
        <p>
          As the summer sun blazes over Split and its neighboring towns, the nightlife scene transforms into a wild and
          vibrant playground. With tourists flocking from every corner of the globe, the atmosphere becomes an
          electrifying mix of energetic revelers ready to let loose.
        </p>
        <p>
          To cater to the insatiable partygoers, Split comes alive with a plethora of summer festivals, beach parties,
          and live concerts. Boasting some of the most popular clubs in town, including Vanilla, Central, Fabrique, Club
          305, and Boiler, there is never a dull moment in Split's buzzing nightlife scene.
        </p>
        <p>
          Of course, no summer party is complete without a beach party, and Split has you covered. The wide, shallow
          expanse of Bačvice beach is the ultimate stage for unforgettable beach parties that will have you dancing the
          night away.
        </p>
        <p>
          For electronic dance music enthusiasts, the world-renowned Ultra Music Festival sets up camp at Split's city
          stadium, Poljud. With the pulsating beats of the main stage echoing through the night, there's no better time
          to experience the unparalleled nightlife of Split.
        </p>
      </div>
      <div className={css.box}>
        <h3>Go to a shopping in one of Split malls:</h3>
        <p>
          Our Split travel guide would be incomplete without a special mention of the city's premier shopping
          destinations, which include Joker, City Center One, Salona Mall, and the famed Mall of Split. Although
          Shopping Center Joker is conveniently located close to the city center, the most impressive and expansive
          malls are undoubtedly Mall of Split and City Center One.
        </p>
        <p>
          These two retail havens not only boast an impressive array of stores, eateries, and movie theaters, but their
          strategic location just a few kilometers apart makes for an incredibly convenient and time-efficient shopping
          experience. So, whether you're a shopaholic seeking the latest trends, a foodie scouting for new dining
          experiences, or a movie buff looking to catch the latest flick, Split's top malls have you covered!
        </p>
        <div className={css.gallery}>
          {THINGS_TO_DO.shopping.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3 style={{ marginBottom: '0px' }}>SPLIT TRAVEL GUIDE - DAY TRIP TO ISLANDS & NATIONAL PARKS</h3>
      </div>
      <div className={css.box}>
        <h3>Visit mid-Adriatic islands or National Parks:</h3>
        <p>
          Venture beyond the stunning city of Split and its picturesque riviera towns to uncover the breathtaking beauty
          of Croatia's national parks and mid-Adriatic islands. While these enchanting destinations are slightly farther
          afield, making public transportation less convenient, you have a variety of travel options to choose from -
          rent a car, book a guided excursion, hop on a ferry, or charter a boat.
        </p>
        <p>
          Whichever mode of transportation you opt for, prepare to be captivated by the serene splendor of National Park
          Krka, the awe-inspiring landscapes of N.P. Plitvice, and the idyllic charm of mid-Adriatic islands, including
          Brač, Hvar, Vis, and Šolta.
        </p>
      </div>
      <div className={css.box}>
        <h3>Book a Private Tour which suits you:</h3>
        <p>
          Imagine designing the perfect vacation for you and your loved ones, tailored to your every desire. With a
          private tour, you can do just that! Transform your family trip into an unforgettable experience by handpicking
          the destinations, setting your own pace, and deciding the duration of your stay at each location. The power to
          create your dream vacation lies in your hands.
        </p>
      </div>
      <div className={css.box}>
        <h3>Go snorkeling and scuba diving in crystal clear Adriatic sea:</h3>
        <p>
          Bypass the typical tourist destinations of Croatia's national parks, medieval towns like Dubrovnik, and
          idyllic islands by immersing yourself in the captivating underwater world of Dalmatia. Boasting over a
          thousand islands and a crystal-clear sea, Croatia's long maritime tradition and fascinating history along the
          Adriatic coastline make it a treasure trove for diving enthusiasts.
        </p>
        <div className={css.gallery}>
          {THINGS_TO_DO.adriatic.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3>SPLIT TRAVEL GUIDE - WHEN TO VISIT</h3>
        <p>
          While some experiences are ideally enjoyed in spring, summer, or early fall, the winter months offer their own
          unique charm. Although you may not be able to bask on the sun-kissed beaches or dance the night away at lively
          summer parties, there's still plenty to explore during the cooler seasons.
        </p>
        <p>
          Visiting attractions like Krka, the mid-Adriatic islands, and N.P. Plitvice may not be as warm during the
          winter months, but you'll certainly have the luxury of fewer tourists and more serene, intimate experiences at
          these stunning locations.
        </p>
        <p>
          We hope some of these tips and activities mentioned in Split travel guide, will help you with planning an
          awesome Croatian vacation in Split.
        </p>
      </div>
    </div>
  );
};

export default ThingsToDo;
