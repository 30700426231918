import React from 'react';
import css from './blog.module.scss';
import BlogCarousel from '../../../components/mobile/BlogCarousel';
import { TOP_BLOGS } from '../../../data/blogs';
import MotionDiv from '../../../components/universal/MotionDiv';

const Blog = () => {
  return (
    <MotionDiv className={css.blogMobileLayout}>
      <div className={css.contentContainer}>
        <h2>BLOG</h2>
        <BlogCarousel data={TOP_BLOGS} />
      </div>
    </MotionDiv>
  );
};

export default Blog;
