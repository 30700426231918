import React from 'react';
import css from './description.module.scss';
import { GROUP_TOUR_BUDGET_FIVE_ISLANDS } from '../../../../../utils/images/group-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Img from '../../../../../components/universal/Img';

const Description = () => {
  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10.5 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Budget Five islands Tour: Travel with Rib Boat</h2>
        <p>
          The Budget Five Islands Tour option features RIB boats, which are lightweight but high-performance boats which
          provide the most affordable way to discover the stunning mid-Adriatic islands, including Hvar and Vis. These
          boats are open from the sides and are built for speed, making them among the fastest speedboats available.
          Their design allows them to cut through waves easily and use less fuel.
        </p>
        <p>
          The Budget Blue Cave Tour is especially popular during the high season when weather conditions permit
          comfortable travel in an open space boat. If you enjoy speed or want to save time on your tour, consider
          choosing this budget friendly option. You'll get to your destinations quickly and enjoy the thrill of riding
          in a high-performance speedboat.
        </p>
        <p>Plus, with the time saved on the faster ride, you'll have more time to take in the stunning views.</p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_BUDGET_FIVE_ISLANDS.rib_boat.map((img, index) => (
            <Img key={`rib_boar-${index}`} src={img} alt={`rib_boar-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Surprise destination</h2>
        <p>
          With our Budget Five Islands Tour you can save time, giving us the opportunity to visit additional
          destinations like the charming town of Komiža on the island Vis or the picturesque Porat beach. Thanks to the
          speed of our RIB boats, we're able to make surprise stops at hidden gems throughout the Adriatic.
        </p>
        <p>
          The actual location of the stop will be determined on the move, depending on our location at a given moment
          and how much time we have left.
        </p>
        <p>
          These surprise destinations are exclusive to our Budget Five Islands Tour and offer a unique way to explore
          the beauty and diversity of the Adriatic. Don't miss your chance to visit Komiža or Porat beach on this
          unforgettable tour.
        </p>
        <Img src={GROUP_TOUR_BUDGET_FIVE_ISLANDS.surprise} alt="surprise" />
      </div>
      <div className={css.devider} style={{ margin: '40px 0px 0px' }} />
    </div>
  );
};

export default Description;
