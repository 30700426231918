import React from 'react';
import css from './apartments-split.module.scss';
import Img from '../../../components/universal/Img';
import { APARTMENTS_SPLIT } from '../../../utils/images/blogs';

const ApartmentsSplit = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>APARTMENTS IN SPLIT WITH DIRECT CONTACT TO THE OWNERS</h2>
        <p style={{ fontWeight: '700' }}>
          Discover the Best Apartments in Split: Connect Directly with Owners & Save on Commissions!
        </p>
        <p>
          Unearth the perfect apartment for your stay in Split while saving money and bypassing online booking platform
          fees. We've got your back!
        </p>
        <p>
          - Explore our curated selection of stunning apartments in Split, available for rent straight from their
          owners.
        </p>
        <p>- Say goodbye to commission charges from us or any third parties involved in renting Split apartments.</p>
        <p>- Enjoy seamless communication exclusively between you and your chosen accommodation host.</p>
      </div>
      <div className={css.box}>
        <h3>Why Choose Our FREE Service for Split Apartment Rentals?</h3>
        <p>Are you curious about why we offer commission-free apartment rentals in Split, Croatia?</p>
        <p>
          It's because we are passionate about promoting the beauty of Croatia, its most popular region Dalmatia, and
          our stunning city of Split. Plus, we have personal connections with all apartment owners, ensuring a secure
          and risk-free experience for you.
        </p>
        <p>
          Our primary focus lies in providing exceptional land and sea transfer services, private tours, excursions, and
          boat rentals.
        </p>
        <p>
          While accommodation rentals aren't our main business, we do offer our own apartment, named Dalmatian, as an
          added bonus.
        </p>
        <Img src={APARTMENTS_SPLIT.view.src} alt={APARTMENTS_SPLIT.view.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>How to book an apartment?</h3>
        <p>
          Discover your dream apartment in Split by exploring the stunning images on our website. Simply click on your
          favorite apartment image, and you'll be directed to its listing on Booking.com or Airbnb.
        </p>
        <p>
          On the listing, you'll find essential information such as pricing, availability, location, and guest reviews.
          This makes it easy for you to find the perfect apartment in Split that meets your needs.
        </p>
        <p style={{ fontWeight: '700' }}>
          Once you've found your ideal apartment, return to our website and reach out to the owner or host (often a
          family member) directly via the contact number provided here.
        </p>
        <p>
          You can call, or use Viber or WhatsApp to get in touch. From there, it's all about working together to secure
          your reservation (and don't forget to ask for a quote without Airbnb or Booking fees).
        </p>
        <p>When you're ready to book, the owner may request a partial payment upfront to confirm your reservation.</p>
        <p>
          If you prefer, you can book the initial days on Booking or Airbnb and pay the remaining balance upon arrival.
          The choice is yours, and the arrangements are flexible to suit your needs.
        </p>
      </div>
      <div className={css.box}>
        <h3>Apartments in and around city of Split</h3>
        <p>
          Check these apartments on Airbnb or Booking, find the one you like and contact the owner directly via the
          number below the picture.
        </p>
      </div>

      <div className={css.cards}>
        <div className={css.cardsContainer}>
          <h3>Apartments in Split</h3>
          <div className={css.grid}>
            <div className={css.card}>
              <Img src={APARTMENTS_SPLIT.apartment1.src} alt={APARTMENTS_SPLIT.apartment1.alt} />
              <div className={css.text}>
                <h3>Apartment Split Explore</h3>
                <p>
                  Nestled just 2 km from the bustling city center, Apartment Split Explore offers a tranquil retreat in
                  a peaceful neighborhood, surrounded by an abundance of shops for your convenience. Experience the best
                  of both worlds as you escape the city's chaos without compromising on proximity to the old town,
                  pristine beaches, and restaurants - all just a short drive away.
                </p>
                <p>
                  Indulge in the comfort of our newly renovated and well-appointed apartment. Lightning-fast WIFI
                  connection, refreshing air-conditioning, and not one, but two cable flat-screen TVs. Plus, enjoy the
                  convenience of complimentary parking right outside the building. All available for free
                </p>
              </div>
            </div>
            <div className={css.card}>
              <Img src={APARTMENTS_SPLIT.apartment2.src} alt={APARTMENTS_SPLIT.apartment2.alt} />
              <div className={css.text}>
                <h3>Bepo Luxury Apartments</h3>
                <p>
                  Set in the heart of Split, just 700 meters from the iconic Diocletian's Palace, Bepo Luxury Apartments
                  offer an unparalleled living experience with private parking facilities on the premises.
                </p>
                <p>
                  Each of our stylish units boasts a comfortable dining and seating area, complete with a flat-screen TV
                  and air conditioning for your ultimate relaxation.
                </p>
                <p>
                  The modern kitchens are fully equipped with a dishwasher, oven, and kettle, allowing you the
                  convenience of preparing your favorite meals in the comfort of your own space.
                </p>
                <p>
                  Indulge in the luxury of a private bathroom featuring either a bathtub or shower, accompanied by
                  bathrobes in every unit. Bask in the sun or enjoy a refreshing drink on our exclusive terrace,
                  providing the perfect setting for unwinding after a day of exploring the city.
                </p>
                <p>
                  Conveniently located just 25 kilometers from Split Airport, Bepo Luxury Apartments are your ideal home
                  away from home in this vibrant Croatian city.
                </p>
              </div>
            </div>
            <div className={css.card}>
              <Img src={APARTMENTS_SPLIT.apartment3.src} alt={APARTMENTS_SPLIT.apartment3.alt} />
              <div className={css.text}>
                <h3>Apartment City and Sea</h3>
                <p>
                  Welcome to Apartment City & Sea, a newly revamped and fully-equipped sanctuary in the heart of Split.
                  With every essential amenity at your fingertips, our prime location ensures a seamless blend of urban
                  convenience and coastal relaxation.
                </p>
                <p>
                  Situated just a 5-minute stroll from the iconic Diocletian's Palace, history and culture abound, while
                  a short jaunt in the opposite direction leads to the serene Marjan Park, home to a myriad of stunning
                  beaches.
                </p>
                <p>
                  Experience comfort and convenience during your stay at Apartment City & Sea, the ultimate base for
                  exploring the breathtaking beauty of Split.
                </p>
              </div>
            </div>
            <div className={css.card}>
              <Img src={APARTMENTS_SPLIT.apartment4.src} alt={APARTMENTS_SPLIT.apartment4.alt} />
              <div className={css.text}>
                <h3>Studio Vanilla</h3>
                <p>
                  Located in the heart of Split, Studio Vanilla is a contemporary haven just a stone's throw away from
                  the city's top attractions. Experience the historic charm of Diocletian's Palace, savor the vibrant
                  energy of Riva Promenade, and indulge in the nightlife at Bacvice Beach - all within a comfortable
                  5-minute walk or 1 km from our doorstep.
                </p>
                <p>
                  Elegantly designed and air-conditioned units with free Wi Fi, seamlessly blend modern comfort with a
                  touch of luxury. Rest in cozy living room, cook a delicious meal in the kitchenette, and sleep in
                  spacious bedrooms, featuring a double bed and an inviting gallery bedroom.
                </p>
                <p>
                  Pamper yourself in the pristine private bathroom with a shower, complimentary cosmetic accessories,
                  and towels.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={css.cardsContainer}>
          <h3>Apartments Around Split</h3>
          <div className={css.grid}>
            <div className={css.card}>
              <Img src={APARTMENTS_SPLIT.apartment5.src} alt={APARTMENTS_SPLIT.apartment5.alt} />
              <div className={css.text}>
                <h3>Apartment Dalmatian</h3>
                <p>
                  Welcome to Apartment Dalmatian, a stunningly modern and cozy retreat nestled in the picturesque town
                  of Kaštela. Designed to comfortably accommodate up to 6 guests, this spacious property offers the
                  perfect home away from home for your next sun-soaked getaway.
                </p>
                <p>
                  Bask in the breathtaking sea views of the bay area from your big terrace and private balcony - the
                  ideal spot to enjoy a morning coffee or a relaxing evening drink. This prime location places you right
                  between the vibrant cities of Split and Trogir, making Apartment Dalmatian the perfect base for
                  exploring the best of Croatia's Dalmatian coast.
                </p>
                <p>
                  Indulge in the many amenities provided, including complimentary WI Fi internet access, air
                  conditioning, a satellite flat-screen TV, and secure private parking. For a truly memorable
                  experience, fire up the barbecue.
                </p>
                <p>
                  Apartment Dalmatian stands out as the largest one we offer in the Split and surrounding area,
                  providing ample space and comfort for all guests. With easy access to nearby attractions and the
                  convenience of private parking, don't miss out on the opportunity to experience this incredible
                  coastal oasis.
                </p>
              </div>
            </div>
            <div className={css.card}>
              <Img src={APARTMENTS_SPLIT.apartment6.src} alt={APARTMENTS_SPLIT.apartment6.alt} />
              <div className={css.text}>
                <h3>Apartment Marin</h3>
                <p>
                  Apartment Marin is situated in Kaštel Kambelovac. This cozy one-bedroom apartment boasts a private
                  balcony, where you can unwind to the sights of glistening sea waves and majestic mountains.
                </p>
                <p>
                  Designed with your comfort in mind, Apartment Marin is fully equipped with modern amenities, including
                  a well-appointed kitchen, air conditioning, and complimentary WiFi access. Outdoors, you can gather
                  around the barbecue facilities, or simply relax in the lush garden surroundings.
                </p>
                <p>
                  For the adventurous souls, the breathtaking landscape beckons with hiking opportunities just waiting
                  to be explored. With Split located a mere 16 km away and Split Airport just 9 km from your doorstep,
                  you'll find convenience and accessibility to all your travel needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentsSplit;
