import React, { useRef, useState } from 'react';
import css from './banner.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useInViewport } from 'react-in-viewport';
import { Link } from 'react-router-dom';
import { HOME_BANNER } from '../../../data/landing-page';
import Img from '../../universal/Img';
import classNames from 'classnames';
import 'animate.css';

const Banner = () => {
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    swipeToSlide: true,
    arrows: false,
    afterChange: (current) => setActiveSlide(current),
  };

  return (
    <div className={css.bannerMobile} ref={myRef}>
      <Slider {...settings} slickPause={!inViewport}>
        <div className={css.bannerMobileDragItem}>
          <Img src={HOME_BANNER[0].imgUrl} alt={HOME_BANNER[0].title} className={css.bannerImg} />
          <div className={css.bannerMobileFilter} />
          <div
            className={classNames(css.bannerMobileTitle, {
              'animate__animated animate__fadeIn': activeSlide === 0,
            })}
          >
            <h1>{HOME_BANNER[0].title}</h1>
            <h2>{HOME_BANNER[0].subtitle}</h2>
          </div>
        </div>
        <div className={css.bannerMobileDragItem}>
          <Img src={HOME_BANNER[1].imgUrl} alt={HOME_BANNER[1].title} className={css.bannerImg} />
          <div className={css.bannerMobileFilter} />
          <div className={css.callToAction}>
            <div className={classNames({ 'animate__animated animate__fadeInUp': activeSlide === 1 })}>
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 1,
                })}
              >
                {HOME_BANNER[1].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 1,
                })}
              >
                {HOME_BANNER[1].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 1,
                })}
              >
                BOOK HERE
              </Link>
            </div>
          </div>
        </div>
        <div className={css.bannerMobileDragItem}>
          <Img src={HOME_BANNER[2].imgUrl} alt={HOME_BANNER[2].title} className={css.bannerImg} />
          <div className={css.bannerMobileFilter} />
          <div className={css.callToAction}>
            <div className={classNames({ 'animate__animated animate__fadeInUp': activeSlide === 2 })}>
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 2,
                })}
              >
                {HOME_BANNER[2].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 2,
                })}
              >
                {HOME_BANNER[2].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 2,
                })}
              >
                BOOK HERE
              </Link>
            </div>
          </div>
        </div>
        <div className={css.bannerMobileDragItem}>
          <Img src={HOME_BANNER[3].imgUrl} alt={HOME_BANNER[3].title} className={css.bannerImg} />
          <div className={css.bannerMobileFilter} />
          <div className={css.callToAction}>
            <div className={classNames({ 'animate__animated animate__fadeInUp': activeSlide === 3 })}>
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 3,
                })}
              >
                {HOME_BANNER[3].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 3,
                })}
              >
                {HOME_BANNER[3].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 3,
                })}
              >
                BOOK HERE
              </Link>
            </div>
          </div>
        </div>
        <div className={css.bannerMobileDragItem}>
          <Img src={HOME_BANNER[4].imgUrl} alt={HOME_BANNER[4].title} className={css.bannerImg} />
          <div className={css.bannerMobileFilter} />
          <div className={css.callToAction}>
            <div className={classNames({ 'animate__animated animate__fadeInUp': activeSlide === 4 })}>
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 4,
                })}
              >
                {HOME_BANNER[4].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 4,
                })}
              >
                {HOME_BANNER[4].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': activeSlide === 4,
                })}
              >
                BOOK HERE
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
