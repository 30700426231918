import React from 'react';
import classNames from 'classnames';
import css from './our-boats.module.scss';
import { Link } from 'react-router-dom';
import { LANDING_PAGE } from '../../../utils/images';
import Img from '../../universal/Img';

const OurBoats = ({ className }) => {
  return (
    <div className={classNames(className, css.ourBoatsWrapper)}>
      <div className={css.ourBoatsImgWrapper}>
        <Img src={LANDING_PAGE.our_boats.src} alt="our boats" className={css.ourBoatsImg} />
      </div>
      <div className={css.ourBoatsInfo}>
        <h2>OUR BOATS</h2>
        <h3>Create your own excursion</h3>
        <p>
          Split Private Tours are for those who want to explore the Adriatic and Croatia with just their friends and
          family on a private boat.
        </p>
        <Link to="/our-boats" className={css.ourBoatsInfoButton}>
          Read more
        </Link>
      </div>
    </div>
  );
};

export default OurBoats;
