import React, { useState } from 'react';
import classNames from 'classnames';
import css from './flippable-card.module.scss';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import Img from '../Img';

const FlippableCard = ({ className, data, link, priceFrom }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();

  const changeState = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={classNames(className, css.flippableCardWrapper, {
        [css.flipped]: isFlipped,
      })}
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
      onClick={changeState}
    >
      <div className={css.frontFace}>
        <Img src={data.cardPhoto} alt="group tour" className={css.frontFaceImg} />
        <div className={css.frontFaceFilter} />
        <div className={css.frontFaceDesc}>
          <h3>{data.name}</h3>
          <div className={css.price}>
            <span>{data.duration} HOURS</span>
            <span>
              {priceFrom ? 'FROM ' : ''}
              {data.price} €
            </span>
          </div>
        </div>
      </div>
      <div className={css.backFace}>
        <h3>{data.name}</h3>
        <p>{data.description}</p>
        <Button text="Learn more" className={css.actionButton} onClick={() => navigate(link)} />
      </div>
    </div>
  );
};

export default FlippableCard;
