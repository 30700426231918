import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import css from './carousel.module.scss';
import BlogCard from '../../universal/BlogCard';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useInViewport } from 'react-in-viewport';
import SwipeIndicator from '../../universal/SwipeIndicator';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  swipeToSlide: true,
  arrows: false,
};

const BlogCarousel = ({ data }) => {
  const items = useMemo(() => _.chunk(data, 5), [data]);
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);
  const [hideIndicator, setHideIndicator] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!inViewport) setHideIndicator(false);
  }, [inViewport]);

  const onTouch = () => {
    setHideIndicator(true);
  };

  return (
    <div className={classNames(css.sliderContainer)} ref={myRef} onTouchStart={onTouch}>
      <SwipeIndicator hide={hideIndicator} />
      <Slider {...settings} slickPause={!inViewport}>
        {items.map((item, index) => (
          <div key={index} className={css.column}>
            {item.map((blog, index) => (
              <BlogCard
                key={`blog-card-${index}`}
                data={blog}
                className={css.blogCard}
                onClick={() => navigate(`/blog/${blog.slug}`)}
              />
            ))}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BlogCarousel;
