import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import css from './faq-card.module.scss';
import { IconDropdown } from '../../../assets/icons';

const FaqCard = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const innerRef = useRef(null);
  return (
    <div className={css.faqCardWrapper}>
      <div className={css.header} onClick={() => setIsOpen(!isOpen)}>
        <h6>{title}</h6>
        <IconDropdown className={classNames(css.icon, { [css.iconOpen]: isOpen })} />
      </div>
      <div ref={innerRef} className={classNames(css.content, { [css.open]: isOpen })}>
        {data}
      </div>
    </div>
  );
};

export default FaqCard;
