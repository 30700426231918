import React from 'react';
import classNames from 'classnames';
import css from './prev-button.module.scss';
import { IconPrev } from '../../../assets/icons';

const PrevButton = ({ className, onClick, iconColor }) => {
  return (
    <div className={classNames(className, css.prevButton)} onClick={onClick}>
      <IconPrev color={iconColor} />
    </div>
  );
};

export default PrevButton;
