import React from 'react';
import classNames from 'classnames';
import css from './next-button.module.scss';
import { IconNext } from '../../../assets/icons';

const NextButton = ({ className, onClick, iconColor }) => {
  return (
    <div className={classNames(className, css.nextButton)} onClick={onClick}>
      <IconNext color={iconColor} />
    </div>
  );
};

export default NextButton;
