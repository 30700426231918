import { LANDING_PAGE } from '../utils/images';

export const HOME_BANNER = [
  {
    imgUrl: LANDING_PAGE.banner_01.src,
    title: 'Welcome to Excursion Dalmatia',
    subtitle: 'the signature for excellence',
  },
  {
    imgUrl: LANDING_PAGE.banner_02.src,
    title: 'SELECTION OF BEST TOURS FROM SPLIT',
    subtitle: 'GET TO KNOW THE FINEST OF CROATIA',
  },
  {
    imgUrl: LANDING_PAGE.banner_03.src,
    title: 'DISCOVER THE NEARBY ISLANDS',
    subtitle: 'SELECTION OF DAY TOURS AND PRIVATE EXCURSIONS',
  },
  {
    imgUrl: LANDING_PAGE.banner_04.src,
    title: 'BLUE CAVE & ISLAND HOPPING TOUR',
    subtitle: 'THE MOST POPULAR TOUR FROM SPLIT',
  },
  {
    imgUrl: LANDING_PAGE.banner_05.src,
    title: 'SIGHTSEEING, SWIMMING & SNORKELING',
    subtitle: 'HALF-DAY SPEEDBOAT ADVENTURE',
  },
];

export const HOME_TEXT_CAROUSEL = [
  {
    title: 'WELCOME TO EXCURSION DALMATIA',
    description: [
      'The signature for excellence.',
      'Hello there, travelers! We are more than simply a travel agency; we are your new travel companions. Our team of travel experts and tourist guides live to plan the trip of a lifetime for you.',
      "We're all listening to your wants and needs which are our top priority. Our goal is to provide you with the best trips to the most beautiful sites in Croatia. We work with the best in the tourism industry to offer you an amazing experience at lower prices.",
      'We accomplish this by collaborating with tourism experts, trusted travelers, and other credible sources. Our goal is to provide you with a genuine and unique journey.',
      "Excursion Dalmatia is changing people's lives one tour at a time. But don't take our word for it. Check out our top-rated day trips from Split and see for yourself.",
    ],
  },
  {
    title: 'WHY US?',
    description: [
      "Exploring the unknown, sampling wonderful cuisine, and taking in breathtaking surroundings are just a few of the things that get us going. We are your ideal travel companion, guiding you on a journey of discovery through Croatia's captivating region.",
      'Our marine trips are guaranteed to be an amazing experience thanks to our highly trained team and seasoned skippers. We exclusively work with the best shore excursion operators because we are experts in speedboat tours. This way, you can be assured of receiving the finest quality and most dependable service available.',
      "Our experienced instructors will walk you through every step of the process, ensuring you're ready for whatever adventure tour you pick. Furthermore, our qualified tour guides are a wealth of information, offering insightful comments on the past and culture of our beloved town.",
    ],
  },
  {
    title: 'BEST PRICES',
    title_2: '* Tours tailored to your wishes * 10 + boats to choose * 24/7 customer support',
    description: [
      'Do you have any questions concerning your upcoming or present tour? Our professionals are here to assist and advise you. We have a wide range of trips and guaranteed departures available to suit all tastes, ages, and budgets.',
      'Whether you want a lavish holiday or a low-cost excursion, we can make it happen! Our low rates and high quality make it easier to organize your perfect vacation without breaking the budget.',
      'Excursion Dalmatia has made booking easier than ever before, so don’t put off planning your trip of a lifetime any longer!',
    ],
  },
];
