import { PRIVATE_TOURS_CARDS } from '../../utils/images/private-tours';

export const PRIVATE_TOUR_BOL_HVAR_PAKLENI_ISLANDS = {
  photo: PRIVATE_TOURS_CARDS.hvar_and_bol,
  cardPhoto: PRIVATE_TOURS_CARDS.hvar_and_bol,
  name: 'BOL, HVAR  & PAKLENI ISLANDS',
  description:
    'Enjoy the beauty of the most famous Zlatni Rat beach, refresh in crystal clear waters of the mid Adriatic sea, enjoy a walk through iconic town of Hvar and cool off with a cocktail on Pakleni islands. Spend a rich day filled with swimming, snorkeling and exploring.',
  views: 1234,
  duration: 10,
  price: 550,
  slug: '/bol-hvar-and-pakleni-islands-private-tour',
};

export const PRIVATE_TOUR_SUNSET = {
  photo: PRIVATE_TOURS_CARDS.sunset,
  cardPhoto: PRIVATE_TOURS_CARDS.sunset,
  name: 'SUNSET TOUR',
  description:
    'Discover Split old town from the sea side and capture a picture perfect moment from the sunset. Enjoy your boat cruise, capture new memories, enjoy the atmosphere and our experience skipper who will be you private tour guide, listen to his stories and go for an optional quick swim.',
  views: 1100,
  duration: 1.5,
  price: 200,
  slug: '/sunset-private-tour',
};

export const PRIVATE_TOUR_HVAR_PAKLENI_ISLANDS = {
  photo: PRIVATE_TOURS_CARDS.hvar_pakleni,
  cardPhoto: PRIVATE_TOURS_CARDS.hvar_pakleni,
  name: 'HVAR & PAKLENI ISLANDS',
  description:
    'Soak up the sun on our speedboat, witness the originality and magnificence of Hvar and sign of your day with exploring ad thriving underwater world on Pakleni islands. Take memorable photos and discover natural beauty of our area.',
  views: 2001,
  duration: 10,
  price: 500,
  slug: '/hvar-and-pakleni-islands-private-tour',
};

export const PRIVATE_TOUR_BLUE_CAVE_FIVE_ISLANDS = {
  photo: PRIVATE_TOURS_CARDS.blue_cave_five_islands,
  cardPhoto: PRIVATE_TOURS_CARDS.blue_cave_five_islands,
  name: 'BLUE CAVE & 5 ISLANDS TOUR',
  description:
    '“50” shades of Blue Memories” we call it. Blue Cave, Stiniva Cove, Blue lagoon on island Budikovac, Pakleni islands and Hvar. We have the right crew who will plan the best route for you in order to make your experience unforgettable. ',
  views: 456,
  duration: 10,
  price: 600,
  slug: '/blue-cave-and-five-islands-private-tour',
};

export const PRIVATE_TOUR_BLUE_LAGOON_THREE_ISLANDS = {
  photo: PRIVATE_TOURS_CARDS.blue_lagoon_three_islands,
  cardPhoto: PRIVATE_TOURS_CARDS.blue_lagoon_three_islands,
  name: 'BLUE LAGOON & 3 ISLANDS TOUR',
  description:
    'Get and unforgettable experience and choose your top snorkeling spots Blue lagoon, Nečujam bay  (shipwreck) or island Čiovo with beautiful beach bars and crystal clear sea. Use our free snorkel equipment and jump from the boat.',
  views: 456,
  duration: 5,
  price: 400,
  slug: '/blue-lagoon-and-three-islands-private-tour',
};

export const PRIVATE_TOUR_BOL_SOLTA = {
  photo: PRIVATE_TOURS_CARDS.bol_solta,
  cardPhoto: PRIVATE_TOURS_CARDS.bol_solta,
  name: 'BOL & ŠOLTA ISLAND',
  description:
    'Cool down at the world’s famous Zlatni rat beach, soak up in the sun, visit town of Bol and explore two charming towns Milna and Stomorska. Our experience skippers will go above and beyond to make sure that you will leave us with a big smile and great memories.',
  views: 456,
  duration: 10,
  price: 500,
  slug: '/bol-and-solta-island-private-tour',
};

export const PRIVATE_TOUR_BLUE_LAGOON_FIVE_ISLANDS = {
  photo: PRIVATE_TOURS_CARDS.blue_lagoon_five_islands,
  cardPhoto: PRIVATE_TOURS_CARDS.blue_lagoon_five_islands,
  name: 'BLUE LAGOON & 5 ISLANDS TOUR',
  description:
    'Enjoy your day on a full day private tour to nearby islands, get more privacy and make sure you get the most of your trip. Swim and snorkel at the best locations on the mid Adriatic sea and discover two beautiful historical UNESCO towns Trogir and town of Hvar.',
  views: 2344,
  duration: 10,
  price: 550,
  slug: '/blue-lagoon-and-five-islands-private-tour',
};

export const PRIVATE_TOUR_BLUE_LAGOON_FOUR_ISLANDS = {
  photo: PRIVATE_TOURS_CARDS.blue_lagoon_four_islands,
  cardPhoto: PRIVATE_TOURS_CARDS.blue_lagoon_four_islands,
  name: 'BLUE LAGOON & 4 ISLANDS TOUR',
  description:
    'Take a swim or snorkel in the famous Blue Lagoon, discover Sunken Ship Kontesa by snorkeling, relax in the most beautiful Laganini beach bar or visit ancient old town of Trogir and experience the Dalmatian cusine at charming village Maslinica on island Šolta.',
  views: 2344,
  duration: 10,
  price: 500,
  slug: '/blue-lagoon-and-four-islands-private-tour',
};

export const PRIVATE_TOURS = [
  PRIVATE_TOUR_BOL_HVAR_PAKLENI_ISLANDS,
  PRIVATE_TOUR_SUNSET,
  PRIVATE_TOUR_HVAR_PAKLENI_ISLANDS,
  PRIVATE_TOUR_BLUE_CAVE_FIVE_ISLANDS,
  PRIVATE_TOUR_BLUE_LAGOON_THREE_ISLANDS,
  PRIVATE_TOUR_BOL_SOLTA,
  PRIVATE_TOUR_BLUE_LAGOON_FIVE_ISLANDS,
  PRIVATE_TOUR_BLUE_LAGOON_FOUR_ISLANDS,
];
