import React from 'react';
import classNames from 'classnames';
import css from './textarea.module.scss';

const Textarea = ({ className, register, name, rules = { required: true }, placeholder, errors }) => {
  return (
    <div className={css.textareaContainer}>
      <textarea className={classNames(className, css.textarea)} {...register(name, rules)} placeholder={placeholder} />
      {errors[name] && (
        <p className={css.error}>
          {errors[name].type === 'required' ? 'This field is required.' : errors[name].message}
        </p>
      )}
    </div>
  );
};

export default Textarea;
