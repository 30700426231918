import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { SUNSET } from '../../../../../utils/images/private-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={SUNSET.important_bring} alt="Important to bring" />
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        English speaking captain and crew, insurance, VAT and safety gear are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and skipper.</p>

      <h4>USEFUL TIPS:</h4>
      <p>Here are couple of items you may want to bring with you:</p>
      <p style={{ marginBottom: '12px' }}>
        Additional clothes for change if doing the tour in spring or late summer. You can bring your own drinks or
        snacks onboard.
      </p>

      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const Description = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: <Img src={SUNSET.overview} alt="overview" className={classNames(css.overviewImg, css.fadeIn)} />,
    1: <ImportantTab />,
    2: <Gallery images={SUNSET.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 1.5 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Sunset Tour Around Split</h2>
        <p>
          Join our unforgettable private Sunset Tour around Split during a nice evening and see the city from a whole
          new perspective. From the comfort of our boat, you'll witness the stunning beauty of the Dalmatian coast as
          the sun sets over the horizon.
        </p>
        <p>
          Our cruise begins at Split Port and following the sun you will experience the views of the charming Marjan
          Park Forest, with Adriatic Sea washing over pebble beaches.
        </p>
        <p>
          You'll also catch a glimpse of the impressive football stadium of the local club Hajduk, an immensely
          important architectural gem loved by the citizens of this Dalmatian metropolis on Sunset Tour cruise.
        </p>
        <p>
          Stadium is shaped like seashell and perfectly blends in Mediterranean environment. It influenced stadiums in
          Italy, Japan and Malaysia and is very important piece of sport architecture.
        </p>
        <p>
          As we continue along the coast, you'll be enchanted by the new part of town and several city beaches, all
          illuminated by the warm glow of the setting sun.
        </p>
        <p>
          Finally, as we return to the port, you'll be treated to a breathtaking view of Split's historic Palace and
          Promenade (Riva) from its best side, showcasing more than 1700 years of history in one small area. Don't miss
          the opportunity to experience Split from the sea with our Sunset Tour.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={SUNSET.sunset_1} alt="Split 1" />
          <Img src={SUNSET.sunset_2} alt="Split 2" />
          <Img src={SUNSET.sunset_3} alt="Split 3" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
    </div>
  );
};

export default Description;
