import React from 'react';
import classNames from 'classnames';
import css from './input-number-field.module.scss';

const InputNumberField = ({
  min = 0,
  max = 20,
  step = 1,
  className,
  register,
  icon,
  name,
  rules = { required: true },
  placeholder,
  errors,
}) => {
  return (
    <div className={classNames(className, css.inputNumberFieldWrapper)}>
      {icon || null}
      <input
        type="number"
        {...register(name, rules)}
        placeholder={placeholder}
        autoComplete="false"
        className={classNames({ [css.additionalPadding]: !icon })}
        min={min}
        max={max}
        step={step}
      />
      {errors[name] && (
        <p className={css.error}>
          {errors[name].type === 'required' ? 'This field is required.' : errors[name].message}
        </p>
      )}
    </div>
  );
};

export default InputNumberField;
