import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import css from './carousel.module.scss';
import FlippableCard from '../../universal/FlippableCard';
import { useInViewport } from 'react-in-viewport';
import { PRIVATE_TOURS } from '../../../data/private-tours';

const PrivateToursCarousel = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState(PRIVATE_TOURS.slice(0, 4));
  const loadingRefRef = useRef();
  const { inViewport } = useInViewport(loadingRefRef);

  useEffect(() => {
    if (inViewport) {
      setTimeout(() => {
        setIsLoaded(true);
        setItems(PRIVATE_TOURS);
      }, 500);
    }
  }, [inViewport]);

  return (
    <div className={classNames(css.sliderContainer)}>
      {items.map((item, index) => (
        <FlippableCard
          priceFrom
          key={`card-${index}`}
          data={item}
          className={css.card}
          link={`/private-tours${item.slug}`}
        />
      ))}
      <div className={classNames(css.loading, { [css.hide]: isLoaded })} ref={loadingRefRef}>
        Loading more...
      </div>
    </div>
  );
};

export default PrivateToursCarousel;
