import React from 'react';
import css from './transfers.module.scss';
import { TRANSFERS, BANNERS } from '../../../utils/images';
import { Link } from 'react-router-dom';
import PrivateToursForm from '../../../components/universal/PrivateToursForm';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const Transfers = () => {
  return (
    <MotionDiv className={css.transfersMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.transfers.src} alt={BANNERS.transfers.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>PRIVATE TOURS</h1>
      </div>

      <div className={css.boxWrapper}>
        <div className={css.contentWrapper}>
          <h2>Split Transfers: We provide sea transfers to mid-Adriatic islands</h2>
          <p>
            Split Transfers provides private sea transfer services in and around the city of Split. Our services cover
            transfers to and from the city of Split, surrounding areas, Split airport, and mid-Adriatic islands. Our
            clients can choose from our diverse fleet of boats.
          </p>
          <p>
            We also provide transfer services to Dubrovnik and Zadar from Split, ensuring that our clients can travel
            with ease across the Dalmatia. Please note that these transfers are only available from Split.
          </p>
          <p>
            If you have any questions, concerns, or special arrangements, please don't hesitate to contact us via email,
            WhatsApp, or Viber. Our team is always happy to help you.
          </p>
        </div>

        <div className={css.contentWrapper}>
          <h2>Pick up point</h2>
          <p>
            We provide private transport services from our headquarters in Split, as well as in Trogir and Hvar. Our
            services cover a wide range of pick-up points, including the town of Split and its surrounding areas such as
            Solin, Kaštela, Trogir and Stobreč.
          </p>
          <p>
            We also offer boat transfers to and from the islands of Drvenik, Šolta, Brač, Hvar, Vis, and other islands
            and towns in Dalmatia. Our clients can choose from a variety of boats depending on their needs.
          </p>
          <p>
            If you have any special requests or inquiries, please don't hesitate to contact us. Our team is always ready
            to assist you and ensure that you have a comfortable and hassle-free transfer experience.
          </p>
        </div>

        <div className={css.contentWrapper}>
          <h2>Split Transfers by boat</h2>
          <p>
            If you've just arrived at Split Airport and want to quickly get to some of the mid Adriatic islands or
            you're in a rush to catch a flight but are stuck on Brač, Hvar or Šolta,{' '}
            <Link to="/transfers/boat-transfers">
              we have a wide range of speedboats, luxury yachts, and big boats for you to choose from.
            </Link>
          </p>
          <p>
            Our selection includes fast RIB boats, deluxe speedboats, luxury yachts, and big boats that can accommodate
            large groups. We also provide experienced skippers who speak fluent English. So wherever you need to be
            picked up, just contact us, and we'll take care of the rest.
          </p>
          <p>
            Choose our hassle-free service for quick and easy island hopping from Split Airport to the mid Adriatic
            islands. Contact us now to book your boat and skipper.
          </p>
        </div>

        {/* IMAGES */}
        <div className={css.images}>
          <Img src={TRANSFERS.img_1.src} alt={TRANSFERS.img_1.alt} />
          <Img src={TRANSFERS.img_2.src} alt={TRANSFERS.img_2.alt} />
        </div>
      </div>

      <div className={css.formWrapper}>
        <h2>BOOK A TRANSFER</h2>
        <PrivateToursForm />
      </div>

      {/* GRAY BOX */}
      <div className={css.grayBox}>
        <h2>Split Transfers - How to book?</h2>
        <p>
          Reserve a transfer with our private boat up to one month in advance. To confirm your booking, we require a 30%
          deposit with the remaining 70% due to be paid directly to your skipper upon pick up.
        </p>
        <h4>No extra fees</h4>
        <p>
          We pride ourselves on our transparent pricing policy, which means there are no hidden fees or extra charges to
          worry about. To get started, simply provide us with your travel details and we'll check availability for your
          desired date and time.
        </p>
        <p>
          Once we've confirmed availability, we'll send you an email or text message with all the details you need to
          know about our private Split transfers. If you need a quicker response, feel free to reach out to us on
          WhatsApp or Viber at +385 95 826 79 81.
        </p>
        <Link to="/transfers/boat-transfers" className={css.link}>
          Read more
        </Link>
      </div>
    </MotionDiv>
  );
};

export default Transfers;
