import React from 'react';
import AboutUs from '../pages/AboutUs/mobile';
import Blog from '../pages/Blog/mobile';
import Contact from '../pages/Contact/mobile';
import CookiePolicy from '../pages/CookiePolicy/mobile';
import FAQ from '../pages/FAQ/mobile';
import GroupTours from '../pages/GroupTours/mobile';
import Home from '../pages/Home/mobile';
import IndividualBlog from '../pages/IndividualBlog/mobile';
import NotFound from '../pages/NotFound/mobile';
import PrivacyPolicy from '../pages/PrivacyPolicy/mobile';
import PrivateTours from '../pages/PrivateTours/mobile';
import OurBoats from '../pages/OurBoats/mobile';
import Transfers from '../pages/Transfers/mobile';
import BoatTransfers from '../pages/BoatTransfers/mobile';
import RentABoat from '../pages/RentABoat/mobile';
import Weather from '../pages/Weather';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from '../components/universal/ScrollToTop';
import css from './app.module.scss';
import Cookie from '../components/universal/Cookie';
import Footer from '../components/mobile/Footer';
import Header from '../components/mobile/Header';
// single pages - group tours
import GroupTourSinglePageThreeIslandsHalfDay from '../pages/GroupToursSinglePages/ThreeIslandsHalfDay/mobile';
import GroupTourSinglePageThreeIslands from '../pages/GroupToursSinglePages/ThreeIslands/mobile';
import GroupTourSinglePageHvarBolPakleni from '../pages/GroupToursSinglePages/HvarBolPakleni/mobile';
import GroupTourSinglePageBudgetFiveIslands from '../pages/GroupToursSinglePages/BudgetFiveIslands/mobile';
import GroupTourSinglePageStandardFiveIslands from '../pages/GroupToursSinglePages/StandardFiveIslands/mobile';
import GroupTourSinglePageDeluxeFiveIslands from '../pages/GroupToursSinglePages/DeluxeFiveIslands/mobile';
import GroupTourSinglePageBlueCaveFiveIslands from '../pages/GroupToursSinglePages/BlueCaveFiveIslands/mobile';
// single pages - private tours
import PrivateTourSinglePageThreeIslands from '../pages/PrivateToursSinglePages/BlueLagoonThreeIslands/mobile';
import PrivateTourSunset from '../pages/PrivateToursSinglePages/SunsetTour/mobile';
import PrivateTourHvarPakleniIslands from '../pages/PrivateToursSinglePages/HvarPakleniIslands/mobile';
import PrivateTourBlueCaveFiveIslands from '../pages/PrivateToursSinglePages/BlueCaveFiveIslands/mobile';
import PrivateTourBolSoltaIsland from '../pages/PrivateToursSinglePages/BolSoltaIsland/mobile';
import PrivateTourBlueLagoonFourIslands from '../pages/PrivateToursSinglePages/BlueLagoonFourIslands/mobile';
import PrivateTourBolHvarPakleniIslands from '../pages/PrivateToursSinglePages/BolHvarPakleniIslands/mobile';
import PrivateTourBlueLagoonFiveIslands from '../pages/PrivateToursSinglePages/BlueLagoonFiveIslands/mobile';
// notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// animation
import { AnimatePresence } from 'framer-motion';

const LayoutMobile = () => {
  const location = useLocation();
  return (
    <div className={css.desktopLayout}>
      <ScrollToTop />
      <Cookie />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route exact element={<Home />} path="/" />
          <Route exact element={<OurBoats />} path="/our-boats" />
          {/* START GROUP TURES WITH SINGLE PAGES */}
          <Route exact element={<GroupTours />} path="/group-tours" />
          <Route
            exact
            element={<GroupTourSinglePageThreeIslandsHalfDay />}
            path="/group-tours/three-islands-half-day-tour-trogir-blue-lagoon-ciovo"
          />
          <Route
            exact
            element={<GroupTourSinglePageThreeIslands />}
            path="/group-tours/three-islands-tour-shipwreck-blue-lagoon-maslinica"
          />
          <Route
            exact
            element={<GroupTourSinglePageHvarBolPakleni />}
            path="/group-tours/hvar-and-bol-tours-with-pakleni-islands"
          />
          <Route
            exact
            element={<GroupTourSinglePageBlueCaveFiveIslands />}
            path="/group-tours/five-islands-tour-and-blue-cave"
          />
          <Route
            exact
            element={<GroupTourSinglePageBudgetFiveIslands />}
            path="/group-tours/budget-five-islands-tour"
          />
          <Route
            exact
            element={<GroupTourSinglePageStandardFiveIslands />}
            path="/group-tours/standard-five-islands-tour"
          />
          <Route
            exact
            element={<GroupTourSinglePageDeluxeFiveIslands />}
            path="/group-tours/deluxe-five-islands-tour"
          />
          {/* END GROUP TURES WITH SINGLE PAGES */}
          {/* START PRIVATE TOURS WITH SINGLE PAGES */}
          <Route exact element={<PrivateTours />} path="/private-tours" />
          <Route
            exact
            element={<PrivateTourSinglePageThreeIslands />}
            path="/private-tours/blue-lagoon-and-three-islands-private-tour"
          />
          <Route exact element={<PrivateTourSunset />} path="/private-tours/sunset-private-tour" />
          <Route
            exact
            element={<PrivateTourHvarPakleniIslands />}
            path="/private-tours/hvar-and-pakleni-islands-private-tour"
          />
          <Route
            exact
            element={<PrivateTourBlueCaveFiveIslands />}
            path="/private-tours/blue-cave-and-five-islands-private-tour"
          />
          <Route
            exact
            element={<PrivateTourBolSoltaIsland />}
            path="/private-tours/bol-and-solta-island-private-tour"
          />
          <Route
            exact
            element={<PrivateTourBlueLagoonFourIslands />}
            path="/private-tours/blue-lagoon-and-four-islands-private-tour"
          />
          <Route
            exact
            element={<PrivateTourBolHvarPakleniIslands />}
            path="/private-tours/bol-hvar-and-pakleni-islands-private-tour"
          />
          <Route
            exact
            element={<PrivateTourBlueLagoonFiveIslands />}
            path="/private-tours/blue-lagoon-and-five-islands-private-tour"
          />
          {/* END PRIVATE TOURS WITH SINGLE PAGES */}
          <Route exact element={<Transfers />} path="/transfers" />
          <Route exact element={<BoatTransfers />} path="/transfers/boat-transfers" />
          <Route exact element={<RentABoat />} path="/transfers/rent-a-boat" />
          {/* START BLOGS */}
          <Route exact element={<Blog />} path="/blog" />
          <Route exact element={<IndividualBlog />} path="/blog/:slug" />
          {/* END BLOGS */}
          <Route exact element={<Contact />} path="/contact" />
          <Route exact element={<AboutUs />} path="/about-us" />
          <Route exact element={<FAQ />} path="/faq" />
          <Route exact element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route exact element={<CookiePolicy />} path="/cookie-policy" />
          <Route exact element={<Weather />} path="/weather-in-croatia" />
          <Route exact element={<NotFound />} path="/not-found" />
          <Route exact path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
};

export default LayoutMobile;
