import React from 'react';
import css from './yellow-price-tag.module.scss';

const YellowPriceTag = ({ price }) => {
  return (
    <div className={css.priceWrapper}>
      price starting from: <span>{price} €</span>
    </div>
  );
};

export default YellowPriceTag;
