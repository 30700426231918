import React from 'react';
import css from './splitter.module.scss';

const Splitter = () => {
  return (
    <div className={css.splitter}>
      <div className={css.white} />
      <div className={css.inner} />
    </div>
  );
};

export default Splitter;
