import React, { useMemo } from 'react';
import classNames from 'classnames';
import css from './most-popular-group-tours.module.scss';
import { MOST_POPULAR_GROUP_TOURS } from '../../../data/group-tours';
import FlippableCard from '../../universal/FlippableCard';
import useWindowSize from '../../../helpers/hooks/useWindowSize';

const MostPopularGroupTours = ({ className }) => {
  const windowSize = useWindowSize();
  const items = useMemo(() => {
    if (windowSize.width > 1390) return MOST_POPULAR_GROUP_TOURS.slice(0, 4);
    else return MOST_POPULAR_GROUP_TOURS.slice(0, 3);
  }, [windowSize.width]);

  return (
    <div className={classNames(className, css.mostPopularGroupToursWrapper)}>
      <h2>MOST POPULAR GROUP TOURS</h2>
      <div className={css.cardsWrapper}>
        {items.map((item, index) => (
          <FlippableCard
            priceFrom={item.slug === '/five-islands-tour-and-blue-cave'}
            key={`${item.name}-${index}`}
            data={item}
            className={css.flippableCard}
            link={`/group-tours${item.slug}`}
          />
        ))}
      </div>
    </div>
  );
};

export default MostPopularGroupTours;
