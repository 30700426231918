import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { BLUE_LAGOON_THREE_ISLANDS } from '../../../../../utils/images/private-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={BLUE_LAGOON_THREE_ISLANDS.important_bring} alt="Important to bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and crew.</p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const FirstPart = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: (
      <Img
        src={BLUE_LAGOON_THREE_ISLANDS.overview}
        alt="overview"
        className={classNames(css.overviewImg, css.fadeIn)}
      />
    ),
    1: <ImportantTab />,
    2: <Gallery images={BLUE_LAGOON_THREE_ISLANDS.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 5 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon & 3 Islands Tour</h2>
        <p>
          Private half day tour Blue Lagoon from Split is a perfect excursion to spice up your vacation in Split that
          doesn't take a full day. The Lagoon is situated between three small islands located only about 30 minutes
          speedboat ride from Split, making it the perfect choice to experience the ultimate swimming and snorkeling
          location.
        </p>
        <p>
          Visit UNESCO heritage town of Trogir and get an insight into its interesting history, take a walk along the
          promenade, or grab a lunch or coffee in one of many bars and restaurants.
        </p>
        <p>
          Choose Nečujam bay, on island Šolta, where you will have free time for swimming and snorkeling above the
          shipwreck.
        </p>
        <p>
          If you decide for the small Dalmatian village Maslinica you can have free time to discover stunning beaches
          and enjoy the chrystal clear waters of the Adriatic Sea or have lunch in one of the local restaurants on the
          promenade. Keep your eyes wide open to spot dolphins during the boat ride.
        </p>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.textBox}>
        <p className={css.specialInfo}>
          Choose three of 5 destinations where do you want to go on our private half day tour
        </p>
        <h2>Private Half Day Tour: Trogir</h2>
        <p>
          Nestled in the midst of the Adriatic Sea, Trogir is a picturesque coastal town that promises an unforgettable
          experience.
        </p>
        <p>
          On the UNESCO World Heritage List, the town is a stunning display of urban continuity. As you explore its
          cobblestone streets, you'll be awe-struck by its architectural wonders, including churches, palaces, and the
          renowned Kamerlengo fortress.
        </p>
        <p>
          When the summer season kicks in, the promenade is lined with yachts, cafes, and bars, making it the perfect
          place to relax and take in the beauty of the Adriatic.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_2)}>
          <Img src={BLUE_LAGOON_THREE_ISLANDS.trogir_1} alt="Trogir 1" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.trogir_2} alt="Trogir 2" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Half Day Tour: Blue Lagoon</h2>
        <p>
          Located in a nearby area, the Blue Lagoon with its crystal-clear water is the perfect destination for everyone
          in search of a small piece of paradise close to Trogir.
        </p>
        <p>The turquoise water and breathtaking beauty makes this little paradise a must-see location.</p>
        <p>
          Once you sail into Blue Lagoon the color and the transparency of the sea will enchant you. Whether you want to
          do some swimming and snorkeling or just relax on the beach and get away from the city noise, you'll find that
          escaping to the Blue Lagoon is the best option.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_THREE_ISLANDS.blue_lagoon_1} alt="Blue Lagoon 1" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.blue_lagoon_2} alt="Blue Lagoon 2" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.blue_lagoon_3} alt="Blue Lagoon 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Half Day Tour: Laganini beach bar on island Čiovo</h2>
        <p>
          On our half day Blue Lagoon Tour from Split you can also visit Duga bay and Laganini beach bar on island
          Čiovo.
        </p>
        <p>
          Relax in the shade with a refreshing cocktail in hand as you take in the stunning views. With its laid back
          atmosphere and great service, it stands out from the rest.
        </p>
        <p>
          You can also go swimming and snorkeling at the beach, or take a jump from the pier. On our Half Day Blue
          Lagoon Tour from Split, you can experience the unique atmosphere of Laganini Beach Bar and the overall feel of
          the tour.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_THREE_ISLANDS.ciovo_1} alt="Laganini beach 1" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.ciovo_2} alt="Laganini beach 2" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.ciovo_3} alt="Laganini beach 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Half Day Tour: Maslinica</h2>
        <p>
          Escape to Maslinica, a tiny picturesque village situated on the island of Šolta. With only 1700 inhabitants,
          it's the perfect spot for those seeking a tranquil and peaceful holiday.
        </p>
        <p>
          Enjoy the stunning scenery, crystal-clear waters and secluded beaches. Take a dip in the sea and explore the
          old castle and stone houses. Stroll through the streets and sample the delicious local restaurants.
        </p>
        <p>
          Whether you're looking to relax, swim, snorkel, or have an ice cream, Maslinica is the ideal holiday
          destination.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_THREE_ISLANDS.maslinica_1} alt="Maslinica 1" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.maslinica_2} alt="Maslinica 2" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.maslinica_3} alt="Maslinica 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Half Day Tour: Shipwreck</h2>
        <p>
          Come explore the secrets of Šolta island and the mysterious shipwreck beneath the sea! Get snorkeling
          equipment from our boat for free and enjoy the ultimate underwater experience.
        </p>
        <p>
          Enjoy the breathtaking views of the crystal clear waters and the lush green pine forests, while the crickets
          and waves create a tranquil ambiance.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_THREE_ISLANDS.shipwreck_1} alt="Shipwreck 1" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.shipwreck_2} alt="Shipwreck 2" />
          <Img src={BLUE_LAGOON_THREE_ISLANDS.shipwreck_3} alt="Shipwreck 3" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
    </div>
  );
};

export default FirstPart;
