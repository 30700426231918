import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import css from './text-carousel.module.scss';
import { useInViewport } from 'react-in-viewport';
import { HOME_TEXT_CAROUSEL } from '../../../data/landing-page';

const TextCarousel = ({ className }) => {
  const [active, setActvie] = useState(0);
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);

  useEffect(() => {
    let timerId;

    // change only if in viewport
    if (inViewport) {
      timerId = setTimeout(() => {
        setActvie((activeIndex) => (activeIndex + 1) % HOME_TEXT_CAROUSEL.length);
      }, 15000);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [active, inViewport]);

  return (
    <div className={classNames(className, css.textCarouselWrapper)} ref={myRef}>
      <div className={css.textContainer}>
        {active === 0 ? (
          <h2 className={classNames(css.textCarouselWrapper_h2, { [css.fadeIn]: active === 0 })}>
            {HOME_TEXT_CAROUSEL[active].title}
          </h2>
        ) : null}
        {active === 1 ? (
          <h2 className={classNames(css.textCarouselWrapper_h2, { [css.fadeIn]: active === 1 })}>
            {HOME_TEXT_CAROUSEL[active].title}
          </h2>
        ) : null}
        {active === 2 ? (
          <h2 className={classNames(css.textCarouselWrapper_h2, { [css.fadeIn]: active === 2 })}>
            {HOME_TEXT_CAROUSEL[active].title}
          </h2>
        ) : null}
        {HOME_TEXT_CAROUSEL[active].title_2 ? (
          <h3 className={classNames(css.textCarouselWrapper_h3, css.fadeIn)}>{HOME_TEXT_CAROUSEL[active].title_2}</h3>
        ) : null}
        {active === 0
          ? HOME_TEXT_CAROUSEL[active].description.map((text, index) => (
              <p key={`text-${index}`} className={classNames(css.description, { [css.fadeIn]: active === 0 })}>
                {text}
              </p>
            ))
          : null}
        {active === 1
          ? HOME_TEXT_CAROUSEL[active].description.map((text, index) => (
              <p key={`text-${index}`} className={classNames(css.description, { [css.fadeIn]: active === 1 })}>
                {text}
              </p>
            ))
          : null}
        {active === 2
          ? HOME_TEXT_CAROUSEL[active].description.map((text, index) => (
              <p key={`text-${index}`} className={classNames(css.description, { [css.fadeIn]: active === 2 })}>
                {text}
              </p>
            ))
          : null}
      </div>

      <div className={css.textCarouselDots}>
        {HOME_TEXT_CAROUSEL.map((_, index) => (
          <div
            key={`dot-${index}`}
            className={classNames(css.dot, {
              [css.activeDot]: index === active,
            })}
            onClick={() => setActvie(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default TextCarousel;
