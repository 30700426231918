import React from 'react';
import LayoutDesktop from './layoutDesktop';
import LayoutMobile from './layoutMobile';
import useWindowSize from '../helpers/hooks/useWindowSize';
import { BrowserRouter as Router } from 'react-router-dom';

const App = () => {
  const windowSize = useWindowSize();

  if (windowSize.width >= 1025)
    return (
      <Router>
        <LayoutDesktop />
      </Router>
    );
  return (
    <Router>
      <LayoutMobile />
    </Router>
  );
};

export default App;
