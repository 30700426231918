import React from 'react';
import classNames from 'classnames';
import css from './tripadvisor-reviews.module.scss';
import { SOCIAL_MEDIA_LINKS } from '../../../utils/constants';
import { TRIPADVISOR } from '../../../utils/images';
import Img from '../Img';

const TripadvisorReviews = ({ className }) => {
  return (
    <div className={classNames(css.tripadvisorReviewsWrapper, className)}>
      <a href={SOCIAL_MEDIA_LINKS.tripadvisor} target="_blank" rel="noreferrer">
        Click here to check out for our TripAdvisor reviews!
      </a>
      <p>
        Take a journey of discovery and have a memorable holiday with our private travel agency. Our day trips from
        Split will take you to the most pristine beaches, spectacular natural treasures, and charming islands of the
        Adriatic. We are dedicated to sparking your adventurous spirit. Join us in exploring Croatia's stunning beauty.
        <br />
        <br />
        Our famous trips are prepared by travel experts, who are passionate about providing you with an unforgettable
        holiday experience. Your aspirations are our main focus. However, we take pride in recommending the most
        exceptional spots, quiet coves, pristine beaches, and natural wonders that will make your holiday an
        unforgettable adventure.
      </p>
    </div>
  );
};

export default TripadvisorReviews;
