import React, { useState } from 'react';
import css from './rent-a-boat.module.scss';
import PrivateToursForm from '../../../components/universal/PrivateToursForm';
import classNames from 'classnames';
import TabsContent from './TabsContent';
import { BANNERS } from '../../../utils/images';
import MotionDiv from '../../../components/universal/MotionDiv';
import TripadvisorReviews from '../../../components/universal/TripadvisorReviews';
import Img from '../../../components/universal/Img';

const TABS_INFO_TEXT = [
  [
    'If you are a smaller group (4 to 6 people) or a couple, these speedboats will be the best option, regarding the size, speed and price.',
    'When it comes to Split boat rental, they are best suited for exploring small towns and hidden beaches of mid Adriatic islands such as Brač, Hvar, Šolta and Drvenik.',
  ],
  [
    'When it comes to split boat rental, these are the best speedboats available for rent without the skipper.',
    'Not only that these boats are bigger and faster but they are also better equipped and more suited for visiting farthest islands from Split and Trogir such as Vis, Biševo, Budikovac and famous Blue Cave.',
  ],
];

const RentABoat = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <MotionDiv className={css.boatTransfersPageLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.rent_a_boat.src} alt={BANNERS.rent_a_boat.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>RENT A BOAT</h1>
      </div>

      {/* CONTENT CONTAINER */}
      <div className={css.contentContainer}>
        <div className={css.contentLeft}>
          <div className={css.contentLeftBox}>
            <h3>Rent a Boat in Split & be your own skipper</h3>
            <p>
              If you're looking for a fun and adventurous way to explore the Adriatic islands, you can rent a boat in
              Split or Trogir. With our boat rental services, you can be your own skipper and enjoy in complete privacy
              while cruising around the stunning islands with your family and friends.
            </p>
            <p>
              To rent a boat without a skipper, you must have a valid certified skipper license. You DO NOT need a
              license issued in Croatia. Any valid skipper license issued in your country is sufficient for you to rent
              a speedboat.
            </p>
            <p>
              These speedboats are the perfect option for smaller groups (4 - 6 people), couples, or anyone looking for
              a budget-friendly and fast-paced adventure.
            </p>
            <p>
              When it comes to boat rental in Split, our speedboats are best suited for exploring the smaller towns and
              hidden beaches of mid-Adriatic islands, such as Brač, Hvar, Šolta, and Blue Lagoon.
            </p>
          </div>
          <TripadvisorReviews className={css.tripadvisor} />
        </div>

        <div className={css.contentRight}>
          <div className={css.form}>
            <h3>RENT A BOAT</h3>
            <PrivateToursForm firstRowColumn />
          </div>
        </div>
      </div>
      {/* TABS */}
      <div className={css.tabSection}>
        <div className={css.tabsContainer}>
          <div
            className={classNames(css.tab, {
              [css.activeTab]: activeTab === 0,
            })}
            onClick={() => setActiveTab(0)}
          >
            <h3>SMALLER SPEEDBOATS</h3>
            <h4>FOR UP TO 8 PEOPLE</h4>
          </div>
          <div
            className={classNames(css.tab, {
              [css.activeTab]: activeTab === 1,
            })}
            onClick={() => setActiveTab(1)}
          >
            <h3>MIDSIZE SPEEDBOATS</h3>
            <h4>FOR UP TO 12 PEOPLE</h4>
          </div>
        </div>

        <div className={css.info}>
          {TABS_INFO_TEXT[activeTab].map((text) => (
            <p key={text}>{text}</p>
          ))}
        </div>

        <TabsContent tabIndex={activeTab} />
      </div>

      <div
        className={css.textWrapper}
        style={{
          padding: '0px 130px 16px',
        }}
      >
        <h3>Rent a boat in Split: Terms and Conditions</h3>
        <p>
          The list of boats that we rent without the skipper you can find above. Besides the skipper license, you need
          to bring your passport or personal ID card. Also, you need to be at least 21 years old. You can rent a boat
          for one day or several days.
        </p>
        <p>
          You can pick it up from 8:00AM and return it until 7:00 PM, on your last day of rent. Whether the last day is
          the first day or seventh day of weekly rent.
        </p>
      </div>

      <div
        className={css.textWrapper}
        style={{
          padding: '16px 130px 32px',
        }}
      >
        <h3>Pick up point</h3>
        <p>
          At our boat rental service in Split, our pick-up point is usually at the Split Waterfront, near the promenade.
          However, we understand that some customers may prefer a different pick-up location. That's why we offer
          customizable pick-up locations to suit your needs.
        </p>
        <p>
          If you want the boat to be delivered to a specific location, we can arrange this for you. Please note that we
          may charge an extra fee for this special service, depending on the circumstances. Feel free to contact us with
          any questions if you want to rent a boat in Split or Trogir.
        </p>
        <p>
          Don't let transportation logistics hold you back from enjoying the beautiful Adriatic islands. Contact us to
          arrange a boat rental pick-up location that works for you. We're here to make your experience as seamless and
          enjoyable as possible.
        </p>
      </div>

      <div
        className={css.textWrapper}
        style={{
          background: '#FAFAFA',
        }}
      >
        <h3>How to Rent a Boat in Split</h3>
        <p>
          To rent a boat, you will need to pay a deposit at least one day in advance. The deposit is 30% of the total
          price, and the remaining 70% will be paid when you pick up the boat. You can book the boat up to one month in
          advance, and we recommend booking early to secure the boat you want.
        </p>
        <p>
          When our skipper delivers the boat at the agreed time and location, they will go over the basic rules with the
          person who will be sailing the boat. It is essential that the person who will rent a boat and steer the boat
          has a VALID SKIPPER LICENSE.
        </p>
        <p>
          Fuel is not included in the rental price. You will receive the boat with a full tank, and you must return it
          in the same condition. The gas station is located at Split harbor, and if you plan to visit mid-Adriatic
          islands, fuel will cost between 50 and 100 €. For trips to Vis and the Blue Cave, or if you sail at full
          throttle, fuel costs can be between 150 and 300 €.
        </p>
        <p>
          If you have additional questions or want to rent a boat in Split, please contact us via email, WhatsApp, or
          Viber at +385 95 826 7981. We're here to make your boat rental experience as enjoyable and straightforward as
          possible.
        </p>
      </div>
    </MotionDiv>
  );
};

export default RentABoat;
