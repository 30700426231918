import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { GROUP_TOUR_HVAR_BOL_PAKLENI } from '../../../../../utils/images/group-tours';
import {
  IconBlueCalendar,
  IconBlueClock,
  IconBlueFlag,
  IconBlueLocation,
  IconDropdown,
} from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={GROUP_TOUR_HVAR_BOL_PAKLENI.important_bring} alt="important bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, fuel and skipper, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const TABS = ['OVERVIEW', 'IMPORTANT', 'GALLERY'];

const FirstPart = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
    setIsOpened(false);
  };

  const tabContent = {
    0: (
      <Img
        src={GROUP_TOUR_HVAR_BOL_PAKLENI.overview}
        alt="overview"
        className={classNames(css.overviewImg, css.fadeIn)}
      />
    ),
    1: <ImportantTab />,
    2: <Gallery images={GROUP_TOUR_HVAR_BOL_PAKLENI.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div>
          <IconBlueFlag />
          <p>Tour type: daily</p>
        </div>
        <div>
          <IconBlueCalendar />
          <p>Availability: From 1th April</p>
        </div>
        <div>
          <IconBlueClock />
          <p>Duration: 10 hours</p>
        </div>
        <div>
          <IconBlueLocation />
          <p>
            Location: Trg Franje Tudmana <br /> 21 000, Split, Hrvatska
          </p>
        </div>
      </div>

      <div className={css.textBox}>
        <h2 style={{ fontSize: '26px' }}>Hvar and Bol tour with Pakleni Islands</h2>
        <p>
          Have you ever wondered what it would feel like to relax on a beach that looks like a paradise? Zlatni Rat on
          Brač Island is only a short boat ride away and it will give you that divine feeling of spending the vacation
          on the prettiest beach around.
        </p>
        <p>
          Soak up the sun, smell the salty air, explore the underwater world of Brač and Hvar on this one day boat tour.
        </p>
        <p>
          Join us and visit the world's famous Zlatni Rat beach (Golden Cape), Hvar island as well as Pakleni Islands
          and small charming town Milna on Brač.
        </p>
        <p>
          Bol's stunning beach, the Golden Cape, is the pinnacle of this tour. However, other places are just as
          remarkable, such as the beautiful Hvar and its Pakleni Islands. This tour is one of those which shouldn't be
          missed, boasting some of the most breathtaking spots in Croatia.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Why choose Zlatni Rat and Hvar Tour</h2>
        <p>
          If famous Zlatni Rat beach is destination of your desire and not the Blue Cave, look no further. Not only that
          this tour starts a bit later, especially considering peak of the season, but it also includes somewhat shorter
          boat trip and no unnecessary waiting at certain destinations.
        </p>
        <p>
          This tour takes place daily at 8:30 AM, returning to Split at 18:30. As a speedboat tour, it enables you to
          make the most of your holiday in Dalmatia by exploring all of the undiscovered gems that are included in the
          itinerary.
        </p>
        <p>
          This includes a visit to the gorgeous Golden Horn beach, a dip in the refreshing waters of the Pakleni Islands
          and a wine tasting experience at a local family resort on Brač island. With this tour, you will experience
          Dalmatia like never before!
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Bol and Zlatni Rat on island of Brač</h2>
        <p>Discover the beauty of Bol and Zlatni Rat beach on the island of Brač!</p>
        <p>
          Located on the south side of the island, Bol is the oldest town on the southern coast of Brač. It boasts a
          history that begins as early as the 12th century and is a stunning tourist oasis with over 10 km of gravel and
          pebble beaches and many natural springs of drinking water.
        </p>
        <p>
          This small, charming town is a paradise for tourists, offering plenty of bars, restaurants, and attractions.
        </p>
        <p>
          Explore the Dominican monastery and its museum with its fascinating archaeological collection. Admire the
          Baroque mansion, the parish church of Our Lady of Carmel, and the Renaissance-Baroque palace with its art
          gallery.
        </p>
        <p>
          Make sure to also spend some time at the iconic Zlatni Rat beach, one of the most beautiful beaches in the
          world! Come to Bol and Zlatni Rat beach on Brač and experience a truly unforgettable holiday.
        </p>
        <Img src={GROUP_TOUR_HVAR_BOL_PAKLENI.bol} alt="bol and zlatni rat" />
      </div>

      <div className={css.textBox}>
        <h2>The Promenade</h2>
        <p>
          Hop on a train or take a stroll along the beautiful promenade to Zlatni Rat beach and be captivated by the
          stunning views.
        </p>
        <p>
          Enjoy the fresh sea breeze and take in the picturesque scenery while you indulge in some of the delicious
          snacks and drinks available at the many bars and kiosks. Don't forget to pick up some souvenirs from the
          vendors to take home and remember your amazing experience!
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Zlatni Rat Beach</h2>
        <p>
          The stunning city of Bol is one of the most popular cities among mid-Adriatic islands and is renowned for its
          West side, particularly the famous Zlatni Rat beach.
        </p>
        <p>
          This beach features 500 meters of golden pebbles stretched into the Adriatic Sea and is constantly changing
          shape due to the winds and currents. Surrounded by deep blue and turquoise waters, the beach's center is
          adorned with lush forests, pine grooves, restaurants, and beach bars.
        </p>
        <p>Grooves on the cape house are remnants of roman villa rustica.</p>
        <p>
          Looking for a more thrilling experience? Enjoy a jet-ski rental or parasailing adventure! Zlatni Rat is the
          perfect spot for those looking for an unforgettable beach experience.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_HVAR_BOL_PAKLENI.zlatni_rat.map((img, index) => (
            <Img key={`zlatni-rat-${index}`} src={img} alt={`zlatni-rat-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Hvar</h2>
        <p>
          Discover the allure of Hvar Island, one of the most popular party destinations in Croatia, and soak in the
          picturesque views of secluded bays, lavender fields, and olive groves. It is more reminiscent of southern
          Italy or France.
        </p>
        <p>
          Scenic, charming, and tranquil, Hvar is an awe-inspiring island that enchants visitors with sublime
          accommodations and a peaceful atmosphere. Those traits are very difficult to find on the mainland along the
          Dalmatian coast.
        </p>
        <p>
          This famous getaway for jetsetters will undoubtedly seduce you and steal your heart at first sight. Apart from
          the strong nightlife game, this town has a lot more to offer - from amazing natural beaches and coves to the
          rich cultural heritage. Here you can wander the old street filled with history or unwind in one of many beach
          bars and enjoy your day.
        </p>
        <p>
          The stunning old fortress located on the hill overlooking Hvar and Pakleni islands is the city's top
          attraction. Taking just 15 minutes to ascend on foot, visitors can enjoy breathtaking panoramic views from the
          top.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_HVAR_BOL_PAKLENI.hvar.map((img, index) => (
            <Img key={`hvar-${index}`} src={img} alt={`hvar-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Pakleni Islands</h2>
        <p>
          If you are looking for beaches, Pakleni Islands is the right place to visit. The most popular beaches of Hvar
          archipelago are situated on these islands, and they are considered the most beautiful part of Hvar riviera.
        </p>
        <p>
          Pakleni islands are made of 16 islands and islets which stretch for 10 kilometers while offering peaceful
          coves for different activities like diving, fishing or sports.
        </p>
        <p>On these islands, two beaches stand out.</p>
        <p>
          First is Palmižana sandy beach on St. Clement island. There is an array of fantastic restaurants, lush trees,
          and a popular beach bar that is frequently visited with several boat tours, especially the Five Islands Tour.
          There is also a botanical garden with numerous exotic plants from all over the world.
        </p>
        <p>
          Secondly, there's Carpe Diem beach, renowned as the party hotspot of the Hvar Riviera, where the nightlife
          continues until dawn.
        </p>
        <p>
          Crystal clear water of the Adriatic Sea allows visitors to refresh during those sunny days while pine tree
          forest hides great beach bars to enjoy music and partying.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_HVAR_BOL_PAKLENI.pakleni_islands.map((img, index) => (
            <Img key={`pakleni_islands-${index}`} src={img} alt={`pakleni_islands-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Milna on Brač island</h2>
        <p>While visiting Brač, you shouldn't forget to visit Milna.</p>
        <p>
          Milna is the island's Brač biggest natural harbor, ideally located for all sailors who are eager to visit
          Dalmatian islands. Its position is sheltering boats from winds and waves since roman times.
        </p>
        <p>
          Crystal clear sea, healthy local cuisine, ideal conditions for diving, sailing, fishing, and a fast connection
          to the land are the main reasons why we stop to visit this hidden charming town on our way back from our Hvar
          and Zlatni Rat tour.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_2)}>
          {GROUP_TOUR_HVAR_BOL_PAKLENI.milna.map((img, index) => (
            <Img key={`milna-${index}`} src={img} alt={`milna-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.devider} style={{ margin: '0px 0px 32px' }} />

      <div className={css.tabsContainer}>
        <div className={css.tabsWrapper}>
          <div
            className={classNames(css.activeTabName, { [css.isOpened]: isOpened })}
            onClick={() => setIsOpened(!isOpened)}
          >
            {TABS[activeTab]}
            <IconDropdown />
          </div>
          {isOpened ? (
            <div className={css.dropdownWrapper}>
              {TABS.map((tab, index) => (
                <div key={`tab-${index}`} className={css.dropdownItem} onClick={() => changeTab(index)}>
                  {tab}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {tabContent[activeTab]}

      <div className={css.devider} style={{ margin: '24px 0px' }} />
    </div>
  );
};

export default FirstPart;
