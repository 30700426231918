import React from 'react';
import classNames from 'classnames';
import css from './speedboat-transfers.module.scss';
import { Link } from 'react-router-dom';
import { LANDING_PAGE } from '../../../utils/images';
import Img from '../../universal/Img';

const SpeedboatTransfers = ({ className }) => {
  return (
    <div className={classNames(className, css.speedboatTransfersWrapper)}>
      <div className={css.speedboatTransfersInfo}>
        <h2>SPEEDBOAT TRANSFERS</h2>
        <p>
          We provide sea transfer services in the region of Dalmatia. Our services cover transfers to and from the city
          of Split, surrounding areas, Split airport, and mid-Adriatic islands. Our clients can choose from our diverse
          fleet of boats.
        </p>
      </div>
      <div className={css.speedboatTransfersImgs}>
        <div className={css.imgsWrapper}>
          <Img
            src={LANDING_PAGE.speedboat_transfers.speedboart_01.src}
            alt="speedboat 1"
            className={css.speedboatTransfersImgs_img1}
          />
          <Img
            src={LANDING_PAGE.speedboat_transfers.speedboart_02.src}
            alt="speedboat 2"
            className={css.speedboatTransfersImgs_img2}
          />
          <Img
            src={LANDING_PAGE.speedboat_transfers.speedboart_03.src}
            alt="speedboat 3"
            className={css.speedboatTransfersImgs_img3}
          />
        </div>
      </div>
      <Link to="/transfers" className={css.speedboatTransfersInfoButton}>
        Learn more
      </Link>
    </div>
  );
};

export default SpeedboatTransfers;
