import React from 'react';
import classNames from 'classnames';
import css from './input-field.module.scss';

const onlyNumber = (e) => {
  const re = /^[0-9\b]+$/;
  if (e.target.value === '' || re.test(e.target.value)) {
    return e.target.value;
  }
};

const defaultInput = (e) => e.target.value;

const InputField = ({
  className,
  type = 'text',
  register,
  icon,
  name,
  rules = { required: true },
  placeholder,
  errors,
}) => {
  return (
    <div className={classNames(className, css.inputFieldWrapper)}>
      {icon || null}
      <input
        type={type}
        {...register(name, rules)}
        placeholder={placeholder}
        autoComplete="false"
        onInput={type === 'number' ? onlyNumber : defaultInput}
        className={classNames({ [css.additionalPadding]: !icon })}
      />
      {errors[name] && (
        <p className={css.error}>
          {errors[name].type === 'required' ? 'This field is required.' : errors[name].message}
        </p>
      )}
    </div>
  );
};

export default InputField;
