import React from 'react';
import css from './weather-in-croatia.module.scss';
import Img from '../../../components/universal/Img';
import { WEATHER_IN_CROATIA } from '../../../utils/images/blogs';

const WeatherInCroatia = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>Weather in Croatia: What to expect during your holiday</h2>
        <p>
          If you're thinking of spending your holidays in our country, it's important to know what to expect from
          weather in Croatia. The Croatian coast enjoys a Mediterranean climate with hot and dry summers and moderate
          winters, making it a pleasant place to visit year-round.
        </p>
        <p>
          During the summer season, temperatures can reach very high values, so be prepared for the heat. To make the
          most of your holiday, pack appropriate clothing and sunscreen to stay comfortable in the hot weather.
        </p>
      </div>
      <div className={css.box}>
        <h3>Winter months in Croatia</h3>
        <p>
          Along the Adriatic coast, temperatures are rather mild, but rain is common. While snow is rare, it can happen
          for a day or two.
        </p>
        <p>
          Low temperatures are not a big problem, and while it can occasionally get as low as -12°C (10°F) in January or
          February, temperatures typically range between - 2°C (28°F) and 12°C (53°F).
        </p>
        <p>
          However, the weather in Croatia during the winter can be rather cold during gusts of the bone-chilling wind
          (Bura in Croatian language), a northern wind that can blow for days and make temperatures feel much cooler
          than they actually are. Fortunately, these gusts are usually infrequent and not extreme.
        </p>
        <p>
          With its mild temperatures and occasional snow, Croatia can be a charming destination during the winter
          months. However, plan for the occasional rainy day or wind gust.
        </p>
        <Img src={WEATHER_IN_CROATIA.winter.src} alt={WEATHER_IN_CROATIA.winter.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>Summer months in Croatia</h3>
        <p>
          The Adriatic coast is a popular summer destination, with hot and dry weather typically lasting from May to
          September. Temperatures range from 24°C to 30°C (75°F - 104°F), with June being the month when the temperature
          suddenly rises.
        </p>
        <p>
          July and August are the warmest months, with mid-day temperatures occasionally reaching 40°C (104°F).
          September's weather in Croatia is similar to June's.
        </p>
        <p>
          Even though normally dry, every once in a while, there is a year when it rains every couple of days. However,
          the rain usually passes quickly, and it does not detract from the summer experience.
        </p>
        <Img src={WEATHER_IN_CROATIA.summer.src} alt={WEATHER_IN_CROATIA.summer.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>Weather in Croatia - Pre & Post Season</h3>
        <p>
          If you are looking to enjoy a relaxing vacation on the Adriatic coast, then the pre-season (April and May) and
          post-season in early autumn (September and October) are the perfect times to visit.
        </p>
        <p>
          Not only will you avoid the crowds that flock to the region during the high season, but you will also be able
          to enjoy ideal weather conditions with temperatures ranging between 12°C and 24°C (53°F to 75°F) and sunny
          days.
        </p>
        <p>
          While the sea may not be warm enough for swimming unless you are arriving from Russia, Scandinavia, or Canada,
          there are plenty of other activities to keep you entertained. With fewer tourists around, you will have the
          opportunity to explore the region's stunning National Parks and islands at your leisure.
        </p>
      </div>
      <div className={css.box}>
        <h3>Plan ahead with AccuWeather, aladin.hr or yr.no</h3>
        <p>
          When planning a trip to Croatia, it's important to consider the weather. While we can give you a general idea
          of what to expect, it's always best to check the weather forecast before you arrive. This is especially true
          if you're planning a cruise vacation.
        </p>
        <p>
          Unfortunately, the weather in Croatia is becoming more unpredictable, which is why it's essential to use
          reliable weather forecast mobile apps and websites to plan ahead. AccuWeather, aladin.hr and yr.no are three
          of the best tools and websites to use.
        </p>
        <p>
          These websites provide information about the current weather in Croatia, as well as 3-7 day forecasts and
          current sea temperatures. Pay attention to these data points to make the most out of your Croatian vacation.
        </p>
      </div>
    </div>
  );
};

export default WeatherInCroatia;
