import React from 'react';
import css from './braches.module.scss';
import Img from '../../../components/universal/Img';
import { BEACHES } from '../../../utils/images/blogs';

const Beaches = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>Croatia Beaches: Check the pictures & find their location</h2>
        <p>
          Croatia's exquisite beaches consistently top the list of most-searched seaside escapes in the country.
          Boasting over a thousand idyllic islands and an impressive 1700 km of pristine mainland coastline, there's an
          abundance of striking shores just waiting to be explored.
        </p>
      </div>
      <div className={css.box}>
        <h3>1. Zlatni Rat or Golden Horn</h3>
        <p>
          Experience the breathtaking beauty of Zlatni Rat, also known as the Golden Horn, the most renowned beach on
          the Adriatic's shores. Nestled on the enchanting island of Brač, one glimpse of its unique and ever-changing
          shape will leave you captivated. With its dynamic shoreline sculpted by the whims of the winds, Zlatni Rat
          offers a plethora of exhilarating windsurfing activities, charming café bars, and delightful restaurants, all
          enveloped by the calming embrace of lush pine trees. Add its close proximity to the picturesque town of Bol,
          and it's no wonder Zlatni Rat is hailed as one of Croatia's finest beaches.
        </p>
        <p>
          Embark on an unforgettable journey to this stunning destination with our specially curated full-day tour.
          Delight in exploring the charming town of Bol and unwind on the famed shores of Zlatni Rat. Immerse yourself
          in this idyllic paradise, and bask in the atmosphere as you indulge in the comforts provided by our top
          speedboats. Savor coffee, snacks, lunch, and drinks, all included in the price, as you create memories to
          cherish for a lifetime.
        </p>
        <Img src={BEACHES.zlatni_rat.src} alt={BEACHES.zlatni_rat.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>2. Stiniva Cove</h3>
        <p>
          Awarded the prestigious title of “Best Beach in Europe” by the renowned tourism organization, „European Best
          Destinations”, Stiniva Cove offers a truly unique and mesmerizing experience to its visitors. This captivating
          beach stands out from the rest, thanks to its extraordinary formation and distinctive shape.
        </p>
        <p>
          Born from the remnants of an ancient cave whose roof collapsed eons ago, Stiniva Cove now boasts a striking
          funnel-like structure. This natural wonder invites you to explore its expansive pebble beach, which opens up
          to reveal a sandy seabed and a narrow entrance to an otherwise hidden paradise.
        </p>
        <p>
          Reaching this secluded beach may pose a challenge, as the only access is via a steep and treacherous goat path
          from Vis Island. However, this has only served to boost the popularity of Five Islands Tours, which offers
          thrilling speedboat excursions to Stiniva Cove, among other breathtaking locations.
        </p>
        <Img src={BEACHES.stiniva.src} alt={BEACHES.stiniva.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>3. Lovrečina Bay</h3>
        <p>
          Located on the northern side of Brač island, Lovrečina Bay is a true Croatian gem that undoubtedly ranks among
          its most stunning beaches. Unlike the famous Zlatni Rat on the island's southern side, Lovrečina's unique
          location provides a protective embrace, offering a completely different environment and surroundings.
        </p>
        <p>
          Although slightly further away from the nearest town, the rewards of making the journey to Lovrečina Bay are
          well worth it. With fewer visitors and limited parking, this slice of paradise remains a secret haven
          primarily frequented by locals and tourists with private or rented boats.
        </p>
        <p>
          Lovrečina Bay's true appeal lies in its picturesque sandy beach and shallow waters that stretch almost halfway
          across the bay, making it an ideal spot for children and non-swimmers alike.
        </p>
        <Img src={BEACHES.lovrecina.src} alt={BEACHES.lovrecina.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>4. Pakleni Islands</h3>
        <p>
          Pakleni Islands are a captivating collection of islets nestled just off the coast of Hvar Island. While the
          bustling Palmižana and Carpe Diem beaches undoubtedly steal the limelight, there's a multitude of lesser-known
          shores and hidden bays just waiting to be explored.
        </p>
        <p>
          Our exclusive Five Islands Tour transports you to the vibrant Palmižana beach, but for those craving a more
          intimate escape, chartering a boat unveils limitless possibilities. Unravel the secrets of the Pakleni Islands
          from top to bottom, and indulge in the luxury of your own private beach, where you and your loved ones can
          bask in the sun, undisturbed.
        </p>
        <Img src={BEACHES.pakleni.src} alt={BEACHES.pakleni.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>5. Makarska riviera</h3>
        <p>
          Makarska Riviera is a stunning 60-kilometer stretch nestled at the base of the majestic Biokovo mountain. This
          coastal paradise boasts a series of expansive, pebble-strewn beaches, tucked away beneath the cooling shade of
          lush pine trees. At the heart of this picturesque region lies the bustling town of Makarska, a thriving
          tourist and economic hub.
        </p>
        <p>
          Venture beyond Makarska and uncover an array of secluded bays, offering a tranquil haven of privacy amidst
          breathtaking natural beauty. Explore the idyllic beaches of Brela, Baška Voda, Tučepi, and Podgora, each
          revered as some of the finest shorelines Croatia has to offer.
        </p>
        <Img src={BEACHES.makarska.src} alt={BEACHES.makarska.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>6. Zrće</h3>
        <p>
          Discover Zrće, a stretch of pebble beach on the picturesque island of Pag. Renowned for its crystal-clear
          waters and ample space, Zrće is recognized as one of Croatia's top party beaches, attracting a vibrant and
          energetic crowd. Brimming with lively clubs and trendy café bars, the beach has earned itself the title of
          “Croatian Ibiza”, with the festivities lasting all day and all night long. For those seeking accommodation
          while exploring Zrće beach, the charming town of Novalja is just 4 km away, offering a tranquil retreat from
          the exhilarating party scene.
        </p>
        <Img src={BEACHES.zrce.src} alt={BEACHES.zrce.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>7. Bačvice</h3>
        <p>
          Bačvice Beach is a picturesque U-shaped bay nestled along the stunning Croatian coastline. Framed by a scenic
          promenade, golden sandy shores, and lush pine trees, this beach is a sought-after destination for locals and
          tourists alike. Conveniently located in the heart of Split, Bačvice Beach is a stone's throw away from the
          bustling city center.ng town of Novalja is just 4 km away, offering a tranquil retreat from the exhilarating
          party scene.
        </p>
        <p>
          Renowned as a family-friendly haven, the beach offers shallow waters that are perfect for children and a
          lively game of “Picigin”, a traditional Croatian sport that originated on these very shores. As day turns to
          night, Bačvice Beach transforms into a vibrant hub of activity, with its trendy café bars and shimmering night
          lights attracting visitors from the town and surrounding streets well into the evening.
        </p>
        <Img src={BEACHES.bacvice.src} alt={BEACHES.bacvice.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>8. Sakarun beach</h3>
        <p>
          Located on the northwest coast of Dugi Otok, the breathtaking Sakarun Beach is a true gem among Croatia's top
          beaches. Its pristine white sand, crystal-clear waters, and the surrounding pine forest that provides a
          refreshing shade make this 800-meter long beach a must-visit for any sun-seeker.
        </p>
        <p>
          With a depth of just 3.5 meters at 250 meters off the shore, Sakarun provides an expansive and shallow
          swimming area, making it particularly suitable for families with children. Along the beach, you'll find
          several inviting restaurants to satisfy your taste buds, while a variety of charming apartments can be found
          in nearby locales such as Veli Rat, Verunić, Soline, and Božava.
        </p>
        <Img src={BEACHES.sakarun.src} alt={BEACHES.sakarun.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>9. Sandy beaches of Omiš</h3>
        <p>
          Sandy beaches of Omiš are a group of beaches nestled around an ancient pirate stronghold in the heart of
          Dalmatia. Situated just 25 km from Split, these idyllic shores offer a diverse range of experiences for
          visitors seeking the perfect coastal getaway.
        </p>
        <p>
          At the center of it all, you'll find the city's main beach, a charming rendition of the iconic Copacabana.
          Venture further out to explore the picturesque beaches tucked within the campgrounds or situated at the town's
          western entrance, all boasting fine sand, inviting shallow waters, and an array of beachside amenities.
        </p>
        <p>
          Dotted with cafes, restaurants, and swaying palm trees offering just the right amount of shade, these
          delightful spots cater to a diverse range of visitors.
        </p>
        <Img src={BEACHES.omis.src} alt={BEACHES.omis.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>10. Banje beach</h3>
        <p>
          Banje Beach is the crown jewel of Dubrovnik's coastline and one of Croatia's most sought-after sandy escapes.
          Nestled near the historic city center and ancient walls, this pristine shoreline offers an unparalleled view
          of the majestic old town and the enchanting, untouched island of Lokrum.
        </p>
        <p>
          Be warned, though: the long flight of stairs to reach the beach may pose a challenge for those with mobility
          issues or limited physical fitness. But fear not, as the rewards are bountiful. As the sun sets, Banje Beach
          transforms into a vibrant hub for the elite, with its pulsating nightlife scene attracting the chic and
          stylish from near and far.
        </p>
        <Img src={BEACHES.banje.src} alt={BEACHES.banje.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>BEST BEACHES IN CROATIA - HONORABLE MENTIONS</h3>
        <p>
          While we've already shared our top 10 favorite Croatian beaches, there are several more captivating coastlines
          that were just shy of making the list. Allow us to introduce you to these hidden gems that are sure to delight
          and inspire.
        </p>
        <p>
          Witness the breathtaking view of Velebit mountains across the sea from the regal Queen's Beach in Nin.
          Experience the ethereal beauty of Spiaza beach's moon-gray crescent on Susak island, or explore the enchanting
          Dubovica bay and Grebišće beach on Hvar island.
        </p>
        <p>
          No trip to Croatia is complete without a visit to the secluded islet of Proizd, the challenging but rewarding
          Šunj bay, or the romantic Valalta Resort near Rovinj. For the ultimate off-the-beaten-path experience, be sure
          to venture to the remote Divna beach on the Pelješac peninsula, the aptly named Paradise beach on Rab island,
          and the picturesque Stončica bay on the island of Vis.
        </p>
      </div>
      <div className={css.box}>
        <h3>CROATIA BEACHES: BOATS \ TRANSFERS \ PRIVATE TOURS</h3>
        <p>
          Discover the hidden gems of Croatia's stunning coastline with our premium transfer services, boat rentals, and
          exclusive private tours. Experience the breathtaking beauty of islands like Brač, Vis, Hvar, Pelješac, and
          Dugi Otok, where some of the country's top beaches are nestled, and explore them at your own pace with our
          variety of options. Choose from a convenient transfer to your dream beach, rent a boat for your personal
          adventure, join an exciting excursion, or indulge in a tailor-made private tour.
        </p>
        <p>
          For those looking to unwind on the pristine mainland beaches, our luxury cars and minivans provide the
          ultimate comfort and convenience. Elevate your Croatian beach getaway and connect with us today to start
          creating unforgettable memories.
        </p>
      </div>
    </div>
  );
};

export default Beaches;
