import React, { useState } from 'react';
import FormTypeOne from './pieces/FormTypeOne';
import StripeForm from '../StripeForm';
import { toast } from 'react-toastify';
import FormTypeTwo from './pieces/FormTypeTwo';
import FormTypeThree from './pieces/FormTypeThree';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const STRIPE_ERROR_MESSAGES = {
  incorrect_number: "The card number is incorrect. Check the card's number or use a different card.",
  incorrect_cvc: "The card's security code is incorrect. Check the card's security code or use a different card.",
  expired_card: 'The card has expired. Check the expiration date or use a different card.',
  insufficient_funds: "The customer's account has insufficient funds to cover this payment.",
};

const DEFAULT_FORM_VALUES = {
  email: '',
  name: '',
  countryCode: '',
  mobile: '',
  departureTime: '',
  departureDate: '',
  passengers: '',
  adults: '',
  children0to3: '',
  children4to12: '',
  boatOption: '',
};

const toastErrorMessages = (code) => {
  const message = STRIPE_ERROR_MESSAGES[code];
  if (message) return message;
  return 'Some error occured.';
};

const getFormType = ({ type, handleForm, startPassengersPrice }) => {
  const piece = {
    1: <FormTypeOne handleForm={handleForm} startPassengersPrice={startPassengersPrice} />,
    2: <FormTypeTwo handleForm={handleForm} startPassengersPrice={startPassengersPrice} />,
    3: <FormTypeThree handleForm={handleForm} startPassengersPrice={startPassengersPrice} />,
  };

  return piece[type];
};

const BookingForm = ({ type = 1, tourName = '', startPassengersPrice }) => {
  const [formValues, setFormValues] = useState({});
  const [bookingStep, setBookingStep] = useState(1);

  const handleForm = async (values) => {
    setFormValues({ ...DEFAULT_FORM_VALUES, ...values, tourName });
    setBookingStep(2);
  };

  const setStripe = async (data) => {
    if (data?.error) {
      const { code } = data.error;
      toast.error(toastErrorMessages[code]);
    } else {
      // TODO: api admin
      await delay(500);
      // saved values
      console.log('form: ', formValues);
      console.log('stripe: ', data);
      toast.success('Successfully purchased!');
      setBookingStep(1);
    }
  };

  return (
    <>
      {bookingStep === 1 ? (
        getFormType({ type, handleForm, startPassengersPrice })
      ) : (
        <StripeForm handleForm={setStripe} amount={formValues.totalPrice * 100} />
      )}
    </>
  );
};

export default BookingForm;
