import React from 'react';
import css from './top-private-tours.module.scss';
import PrivateToursCarousel from '../PrivateToursCarousel';

const TopPrivateTours = () => {
  return (
    <div className={css.topPrivateToursWrapper}>
      <h2>TOP PRIVATE TOURS</h2>
      <PrivateToursCarousel />
    </div>
  );
};

export default TopPrivateTours;
