import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import css from './group-tours-card.module.scss';
import { IconTime, IconViews } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import Img from '../Img';

const GroupToursCard = ({
  className,
  link,
  photo,
  name,
  description,
  views,
  fromPrice,
  price,
  priceText = 'Price:',
  duration,
  onButtonClick,
}) => {
  const nameRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(nameRef.current?.clientHeight || 0);
  }, [nameRef]);

  return (
    <div className={classNames(className, css.groupToursCardWrapper)}>
      <Img src={photo} alt={name} className={css.cardPhoto} />
      <div className={css.cardInfoWrapper}>
        <h2 className={css.cardName} ref={nameRef}>
          <Link to={link}>{name}</Link>
        </h2>
        <div className={css.cardStats}>
          <div className={css.cardStatsItem}>
            <IconViews />
            <p>{views}</p>
          </div>
          <div className={css.cardStatsItem}>
            <IconTime />
            <p>{duration} hours</p>
          </div>
        </div>
        <p className={css.cardDescription} style={{ WebkitLineClamp: height > 30 ? '2' : '3' }}>
          {description}
        </p>
        <div className={css.devider} />
        <div className={css.cardFooter}>
          <p style={{ fontSize: '12px' }}>{priceText}</p>
          <div className={css.cardFooterAction}>
            <p className={css.price}>
              {fromPrice ? <span>FROM </span> : null}
              {price} €
            </p>
            <button className={css.cardButton} onClick={onButtonClick}>
              BOOK NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupToursCard;
