import React, { useState } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import GroupToursCard from '../../../components/universal/GroupToursCard';
import MotionDiv from '../../../components/universal/MotionDiv';
import css from './other-tours.module.scss';
import PrevButton from '../../universal/PrevButton';
import NextButton from '../../universal/NextButton';

const OtherTours = ({ fromPrice = false, baseUrl = 'group-tours', data }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [cards, setCards] = useState(_.filter(data, (tour) => !pathname.includes(tour.slug)));

  const goToPrev = () => {
    const temp = cards;
    const firstItem = temp.pop();
    setCards([firstItem, ...temp]);
  };

  const goToNext = () => {
    const temp = cards;
    const firstItem = temp.shift();
    setCards([...temp, firstItem]);
  };

  return (
    <MotionDiv>
      <div className={css.cardsContainer}>
        <PrevButton onClick={goToPrev} className={css.cardsContainerPrevButton} />
        {cards.slice(0, 3).map((groupTour, index) => (
          <MotionDiv key={`tour-card-${index}`}>
            <GroupToursCard
              className={css.card}
              link={`/${baseUrl}${groupTour.slug}`}
              name={groupTour.name}
              photo={groupTour.photo}
              description={groupTour.description}
              views={groupTour.views}
              duration={groupTour.duration}
              price={groupTour.price}
              fromPrice={fromPrice}
              onButtonClick={() => navigate(`/${baseUrl}${groupTour.slug}`)}
            />
          </MotionDiv>
        ))}
        <NextButton onClick={goToNext} className={css.cardsContainerNextButton} />
      </div>
    </MotionDiv>
  );
};

export default OtherTours;
