import React, { useState } from 'react';
import classNames from 'classnames';
import css from './private-tours-form.module.scss';
import Button from '../Button';
import { useForm } from 'react-hook-form';
import InputField from '../InputField';
import { IconEmail, IconUser } from '../../../assets/icons';
import Textarea from '../Textarea';
import Select from '../Select';
import DIAL_NUMBERS from '../../../json/dial-numbers.json';
import sendEmailJs from '../../../helpers/email';
import { FORM_TYPE } from '../../../utils/constants';
// notifications
import { toast } from 'react-toastify';

const PrivateToursForm = ({ className, firstRowColumn, secondRowColumn, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (values) => {
    setIsLoading(true);
    // send email
    const { success, message } = await sendEmailJs({ ...values, type: type || FORM_TYPE.baciscBookingForm });
    if (success) toast.success(message);
    else toast.error(message);
    setIsLoading(false);
  };

  return (
    <form className={classNames(css.formWrapper, className)} onSubmit={handleSubmit(onSubmit)}>
      <div className={classNames(css.row, { [css.column]: firstRowColumn })}>
        <InputField
          errors={errors}
          icon={<IconUser />}
          name="name"
          register={register}
          placeholder="Name and surname"
        />
        <InputField
          errors={errors}
          icon={<IconEmail />}
          name="email"
          type="email"
          register={register}
          placeholder="Email address"
        />
      </div>
      <div className={classNames(css.row, { [css.column]: secondRowColumn })}>
        <Select errors={errors} name="countryCode" register={register} options={DIAL_NUMBERS} setValue={setValue} />
        <InputField
          errors={errors}
          type="number"
          name="mobile"
          register={register}
          placeholder="Phone number"
          rules={{
            required: true,
            validate: (value) => /^.{4,12}$/.test(value) || 'Mobile phone is too long or too short',
          }}
        />
      </div>
      <Textarea errors={errors} name="message" register={register} placeholder="Message" />
      <Button type="submit" isLoading={isLoading} />
    </form>
  );
};

export default PrivateToursForm;
