import React from 'react';
import css from './group-tour-location.module.scss';
import GoogleMap from '../GoogleMap';

const GroupTourLocation = ({ title = 'Meeting point:', text = 'Split waterfront next to the fountain' }) => {
  return (
    <div className={css.locationContainer}>
      <h3>{title}</h3>
      <p>{text}</p>
      <GoogleMap lat={43.508361294963066} lng={16.436131366049363} zoom={19} className={css.googleMap} />
    </div>
  );
};

export default GroupTourLocation;
