import React, { useRef, useState } from 'react';
import css from './our-boats.module.scss';
import PrivateToursForm from '../../../components/universal/PrivateToursForm';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider-style.scss';
import { Link } from 'react-router-dom';
import { SOCIAL_MEDIA_LINKS } from '../../../utils/constants';
import { BANNERS, LOGO, OUR_BOATS } from '../../../utils/images';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const PrivateTours = () => {
  const [activeTab, setActiveTab] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: true,
    afterChange: (index) => setActiveTab(index),
  };

  const changeTab = (index) => {
    setActiveTab(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <MotionDiv className={css.privateToursPageLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.our_boats.src} alt={BANNERS.our_boats.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>PRIVATE TOURS</h1>
      </div>

      {/* CONTENT CONTAINER */}
      <div className={css.contentContainer}>
        <div className={css.contentContainerSection1}>
          <div className={css.privateTourContent}>
            <div className={css.privateTourContent1}>
              <h3>Private Tours: Create your own excursion</h3>
              <p style={{ marginBottom: '16px' }}>
                If you're looking for a unique and personalized way to explore the Adriatic and Croatia, <br /> private
                tours from Split are the way to go. With a private boat, you can create your own <br /> itinerary and
                explore at your own pace.
              </p>
              <p style={{ marginBottom: '16px' }}>
                One of the key advantages of private tours is the flexibility they offer. You can set your own schedule
                and make the most of your time.
              </p>
              <p>
                Another benefit of private tours is the privacy they provide. You can travel with your friends and
                family without having to worry about sharing your space with strangers. This means you can relax and
                enjoy your tour without any distractions or interruptions.
              </p>
            </div>
            <div>
              <h3>Benefits of private tours:</h3>
              <ul className={css.benefitList}>
                <li>Choose your departure time (no earlier than 7:30 AM)</li>
                <li>Choose at which time you return back to Split (no later than 7:00 PM)</li>
                <li>You have a boat just for yourselves</li>
                <li>Stay as long as you want in each place</li>
                <li>Plan your own tour - choose destinations you want to visit</li>
                <li>During the tour you can visit other places on the route you find interesting</li>
              </ul>
            </div>
          </div>
          <div className={css.bookPrivateTour}>
            <h3>BOOK A PRIVATE TOUR</h3>
            <PrivateToursForm firstRowColumn />
          </div>
        </div>

        <div className={css.contentContainerSection2}>
          <div>
            <h2>Explore the Mid-Adriatic Islands</h2>
            <p>
              We offer a variety of boats for your private tour, including speedboats, luxury yachts and big boats. You
              can explore the mid-Adriatic islands at your own pace and visit other places on the route that you find
              interesting.
            </p>
          </div>
          <div className={css.socialMedia}>
            <Link to={SOCIAL_MEDIA_LINKS.tripadvisor} target="_blank">
              <Img src={LOGO.tripadvisor_desktop.src} alt={LOGO.tripadvisor_desktop.alt} />
            </Link>
            <Link to={SOCIAL_MEDIA_LINKS.instagram} target="_blank">
              <Img src={LOGO.instagram_desktop.src} alt={LOGO.instagram_desktop.alt} />
            </Link>
            <Link to={SOCIAL_MEDIA_LINKS.facebook} target="_blank">
              <Img src={LOGO.facebook_desktop.src} alt={LOGO.facebook_desktop.alt} />
            </Link>
          </div>
        </div>

        {/* TABS */}
        <div className={css.contentContainerSection3}>
          <div className={css.tabsContainer}>
            <div
              className={classNames(css.tab, {
                [css.activeTab]: activeTab === 0,
              })}
              onClick={() => changeTab(0)}
            >
              PRIVATE SPEEDBOAT TOURS
            </div>
            <div
              className={classNames(css.tab, {
                [css.activeTab]: activeTab === 1,
              })}
              onClick={() => changeTab(1)}
            >
              LUXURY YACHT TOURS
            </div>
            <div
              className={classNames(css.tab, {
                [css.activeTab]: activeTab === 2,
              })}
              onClick={() => changeTab(2)}
            >
              BIG BOAT TOURS
            </div>
          </div>
          <Slider {...settings} ref={sliderRef} className={classNames('customReactSlick', css.sliderWrapper)}>
            <div className={css.sliderCard}>
              <div className={css.sliderCardContent}>
                <ul className={css.list}>
                  <li>
                    Our speedboats and yachts come in different categories, including RIB boats, plastic speedboats, and
                    luxury yachts. Our speedboats can accommodate up to twelve passengers, depending on the size.
                  </li>
                  <li>
                    Whichever type of boat you need, small speedboat for 3 - 5 persons, midsize speedboat for 6 - 9
                    people or big speedboat for up to 12 people onboard, we got you covered.
                  </li>
                  <li>
                    That being said, if you need luxury yacht with extra space and comfort, we have this type of vessels
                    in our fleet, too.
                  </li>
                </ul>
              </div>
              <Img className={css.sliderCardImg} src={OUR_BOATS.tab_1.src} alt={OUR_BOATS.tab_1.alt} />
            </div>
            <div className={css.sliderCard}>
              <div className={css.sliderCardContent}>
                <ul className={css.list}>
                  <li>
                    We also offer several luxury yachts for daily and weekly tours, when it comes to private tours from
                    Split. Our crew members are your private tour guides, and they'll take care of any of your requests
                    if able.
                  </li>
                  <li>
                    So, if you want to travel in style and luxury, choose our yachts. They are especially suitable for
                    guests which prefer to have maximum comfort and plenty of space onboard to relax.
                  </li>
                  <li>
                    Private cabins, spacious sundeck, kitchen, shower and even towels are all on your disposal when you
                    book a luxury yacht.
                  </li>
                </ul>
              </div>
              <Img className={css.sliderCardImg} src={OUR_BOATS.tab_2.src} alt={OUR_BOATS.tab_2.alt} />
            </div>
            <div className={css.sliderCard}>
              <div className={css.sliderCardContent}>
                <ul className={css.list}>
                  <li>
                    If you're travelling with a large group, we have various big boats that can accommodate anywhere
                    from 20 up to 150 passengers onboard.
                  </li>
                  <li>
                    So, for larger groups, our big boats are perfect solution. Soak up the rays on second deck with
                    sunbeds on both sides or take a drink in the shade of the first deck.
                  </li>
                  <li>
                    Big Boats are equipped with powerful diesel engines, water tank, GPS, VHF, fridge, full safety
                    equipment, and a toilet. Experience comfort and safety on your next island adventure with us.
                  </li>
                </ul>
              </div>
              <Img className={css.sliderCardImg} src={OUR_BOATS.tab_3.src} alt={OUR_BOATS.tab_3.alt} />
            </div>
          </Slider>
        </div>
      </div>

      {/* HOW TO BOOK */}
      <div className={css.howToBookContainer}>
        <h2>How To Book a Private Tour?</h2>
        <div className={css.howToBookContainerDescription}>
          <p>
            On our website you will find pages where you can check our speedboats, big boats, yachts and sailboats
            available for private tours from Split.
          </p>
          <p>
            After you decide which vessel you would like to reserve for your private tour, simply send us enquiry via
            contact form, WhatsApp or Viber.
          </p>
          <p>
            We will help to organize your private tour and provide you all necessary information and prices regarding
            your inquiry.
          </p>
          <p>
            We will let you know if the boat you requested is available for the dates you prefer and let you know the
            price of the private tour you planned for your friends or family.
          </p>
        </div>
      </div>
    </MotionDiv>
  );
};

export default PrivateTours;
