import React from 'react';
import css from './plannin-vacation.module.scss';
import Img from '../../../components/universal/Img';
import { PLANNING_VACATION } from '../../../utils/images/blogs';

const PlanningVacation = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>VACATION IN CROATIA</h2>
        <p>
          Embarking on a vacation to Croatia and eager to learn more about this fascinating country? Look no further!
          Dive into Croatia's rich history, vibrant culture, must-see destinations, captivating towns, stunning islands,
          pristine beaches, and breathtaking national parks.
        </p>
        <p>
          Croatia offers the perfect blend of exotic allure and romantic charm, ensuring an unforgettable experience for
          all.
        </p>
        <Img src={PLANNING_VACATION.location.src} alt={PLANNING_VACATION.location.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>Perfect location</h3>
        <p>
          Conveniently located in Central Europe along the sparkling Adriatic Sea, opposite Italy, Croatia is an easily
          accessible destination for European travelers.
        </p>
        <p>
          Its prime geographic position also offers the opportunity to explore neighboring countries such as Italy,
          Austria, Hungary, Bosnia and Herzegovina, or Montenegro. So, why not turn your Croatian vacation into an epic
          adventure by discovering the surrounding regions too?
        </p>
      </div>
      <div className={css.box}>
        <h3>VACATION IN CROATIA - 5 REGIONS</h3>
        <p>
          Despite being a small country with a population of just 4 million, Croatia captivates visitors with its
          strikingly diverse landscapes, rich culture, and endless array of activities. Delve into five distinct
          regions, each offering a unique blend of exotic, romantic, thrilling, and adventurous experiences that cater
          to all ages and backgrounds. Unravel the mesmerizing tapestry of this vibrant country by traversing its five
          geographical and cultural zones:
        </p>
      </div>
      <div className={css.box}>
        <h3>Zagreb and the northern Croatia with old baroque cities such as Varaždin and Čakovec</h3>
        <p>
          Immerse yourself in the urban lifestyle and rich cultural heritage of northern Croatia, home to the bustling
          capital city of Zagreb and enchanting baroque towns like Varaždin and Čakovec. With a population of over one
          million, Zagreb offers a vibrant atmosphere and an array of historical monuments to explore all year round.
        </p>
      </div>
      <div className={css.box}>
        <h3>Capital experience</h3>
        <p>
          Experience the magic of Zagreb during the winter months, particularly the Christmas Advent season, which is
          renowned as one of the best in Europe. The city transforms into a captivating Christmas Village, adorned with
          festive decorations, exhilarating attractions, and lively parks and squares. Indulge in boutique wines, savor
          gourmet dishes, and enjoy live music that brings the city to life.
        </p>
      </div>
      <div className={css.box}>
        <h3>Visit small towns with rich heritage</h3>
        <p>
          Venture beyond Zagreb to uncover the charm of smaller baroque towns in the region, boasting stunning castles
          and historical landmarks. Visit the romantic Trakošćan Castle in Varaždin, marvel at the well-preserved
          medieval fortification of Veliki Tabor in Desnić, and explore the picturesque town of Ozalj, where rich
          history and breathtaking nature await.
        </p>
      </div>
      <div className={css.box}>
        <h3>Slavonia on the east with its valleys, Nature Park Papuk and town of Osijek</h3>
        <p>
          Visit Croatia's hidden gem - the enchanting region of Slavonia. Nestled in the eastern part of the country,
          Slavonia captivates with its serene valleys, lush Nature Park Papuk, and the charming town of Osijek. While it
          may not be as popular as the coast or islands for a vacation in Croatia, it is the perfect destination for
          those seeking tranquility, breathtaking landscapes, and a retreat from the bustling tourist spots.
        </p>
      </div>
      <div className={css.box}>
        <h3>Osijek and Danube</h3>
        <p>
          At the heart of Slavonia lies Osijek, a city adorned with stunning architecture that sets the stage for
          romantic strolls along the banks of the majestic Danube River. Immerse yourself in the serene ambiance of the
          region and uncover the hidden treasures that await in the picturesque valleys and nature parks of Slavonia -
          an undiscovered paradise waiting to be explored.
        </p>
      </div>
      <div className={css.box}>
        <h3>Lika and Gorski Kotar with National Park Plitvice Lakes</h3>
        <p>
          In the heart of Croatia are situated the mountainous regions of Lika and Gorski Kotar. They serve as a
          stunning connector between the country's coastal and inland areas. Idyllic countryside landscapes of these
          regions are enveloped by verdant forests and pristine lakes, offering a picturesque escape for travelers all
          year round.
        </p>
      </div>
      <div className={css.box}>
        <h3>Snowy in the winter and refreshing in the summer</h3>
        <p>
          In the winter months, Lika and Gorski Kotar transform into a winter wonderland, providing the perfect setting
          for skiing, snowboarding, and other winter getaways. As temperatures rise, these areas become a popular summer
          retreat for those seeking respite from the heat. The refreshing climate, combined with the magnificent
          Plitvice Lakes National Park, makes this destination an ideal choice for nature enthusiasts.
        </p>
      </div>
      <div className={css.box}>
        <h3>Budget friendly accommodations near the coast</h3>
        <p>
          Not only do Lika and Gorski Kotar offer captivating scenery, but they also provide more budget-friendly
          accommodation options. Conveniently located just a 1 to 4-hour drive from the coast, these regions are easily
          accessible, making them the perfect addition to any Croatian adventure. So, whether you're a winter sports
          aficionado or a summer explorer, Lika and Gorski Kotar await your discovery.
        </p>
      </div>
      <div className={css.box}>
        <h3>Kvarner and Istria</h3>
        <p>
          Welcome to the enchanting coastal region of Kvarner and Istria, nestled in the northwest part of Croatia's
          shoreline.
        </p>
      </div>
      <div className={css.box}>
        <h3>Rijeka</h3>
        <p>
          The bustling city of Rijeka serves as the area's largest urban center and a gateway to a world of charming
          towns, pristine beaches, tranquil islands, and vibrant cultural experiences. Its close proximity to Slovenia,
          Italy, and Austria makes this region an increasingly popular destination for travelers seeking a unique
          European adventure.
        </p>
      </div>
      <div className={css.box}>
        <h3>Istria</h3>
        <p>
          Venture beyond Rijeka to explore a myriad of smaller towns, each brimming with festivals and tourists during
          the sun-soaked summer months. From the quaint charm of Opatija to the ancient Roman Colosseum in Pula, there's
          a captivating experience waiting for every traveler.
        </p>
        <p>
          Film enthusiasts will adore the renowned Motovun Film Festival, while beach lovers can bask in the glory of
          Crikvenica Riviera's beautiful shores and promenades. For those seeking a resort-style getaway, Rabac and
          Rovinj offer luxurious summer destinations, and Poreč beckons with its stunning architectural marvels.
        </p>
      </div>
      <div className={css.box}>
        <h3>Dalmatia with Split & Dubrovnik</h3>
        <p>
          You don't want to miss the captivating beauty of Dalmatia, a mesmerizing region in southern Croatia, boasting
          a plethora of idyllic cities waiting to be explored. From the historic town of Zadar with its one-of-a-kind
          sea organ to the royal city of Šibenik, adorned with majestic fortresses, and the awe-inspiring town of
          Trogir.
        </p>
        <p>
          Experience the allure of Split, renowned for its sprawling Diocletian's Palace, the pirate haven of Omiš, the
          sun-kissed Makarska Riviera, and the celebrated walled city of Dubrovnik, to name just a few.
        </p>
        <p>
          Nestled along the pristine shores of the Adriatic Sea, Dalmatia also encompasses a multitude of enchanting
          islands. Whether you seek charming towns, serene islands, secluded beaches, picturesque national parks, or
          thrilling adventures, Dalmatia is the ultimate destination for an unforgettable Croatian vacation.
        </p>
        <p>
          The must-visit cities in the region include Zadar in the north, Dubrovnik in the south, and Split, the vibrant
          heart of Dalmatia. Although there are countless islands to explore, Šolta, Brač, Hvar, Vis, Korčula, and Dugi
          Otok are among the most captivating. For nature enthusiasts, several National Parks can be found in the
          region, with N.P. Krka is the most popular attraction.
        </p>
      </div>
      <div className={css.box}>
        <h3>CLIMATE IN CROATIA</h3>
        <p>
          Although compact in size, Croatia boasts a diverse climate that varies dramatically across its regions. In
          general, the country enjoys mild winters and scorching, arid summers. The coastal areas are perfect for
          sun-soaked spring and summer getaways, while the capital city, Zagreb, offers a delightful winter retreat.
        </p>
        <p>
          If you're planning a Croatian summer vacation, be sure to pack plenty of sunscreen, stay hydrated, and don't
          forget your shades. With its enchanting landscapes and unique weather patterns, Croatia truly has something
          for every type of traveler.
        </p>
        <Img src={PLANNING_VACATION.dubrovnik.src} alt={PLANNING_VACATION.dubrovnik.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>CROATIAN FOOD</h3>
        <p>
          Croatian food is a delight for those who enjoy Mediterranean cuisine, as it features a variety of fresh
          fruits, vegetables, olive oil, fish, and wine. Although many dishes are meat-based, there are also plenty of
          options for vegetarians and vegans.
        </p>
        <Img src={PLANNING_VACATION.food.src} alt={PLANNING_VACATION.food.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>For meat lovers</h3>
        <p>
          One must-try dish for meat lovers is ćevapi, a type of grilled minced meat, often served with flatbread and
          onions. Another popular meat dish is the meat pie, which can be found in various forms throughout the country.
        </p>
      </div>
      <div className={css.box}>
        <h3>For vegetarians</h3>
        <p>
          For those who prefer plant-based meals, Soparnik is an authentic Croatian dish made with spinach, garlic, and
          olive oil as the main ingredients. This delicious and nutritious meal is perfect for vegetarians and vegans
          alike.
        </p>
        <p>
          As tourism continues to grow in Croatia, new restaurants are opening every summer, offering diverse menus and
          romantic atmospheres for an unforgettable vacation experience. Whether you're a meat eater, vegetarian, or
          vegan, there's no doubt that you'll find a place that suits your tastes in Croatia. So, next time you visit,
          be sure to indulge in the delicious flavors of Croatian cuisine.
        </p>
      </div>
      <div className={css.box}>
        <h3>YACHTS & BOATS</h3>
        <p>
          Embrace the allure of sun-soaked Croatian coastlines, pristine beaches, and idyllic islands with the ultimate
          summer escape. Discover the captivating charms of Dalmatia, Istria, and Kvarner as you sail through azure
          waters on your choice of small boats, speedboats, or luxurious yachts.
        </p>
        <p>
          Croatia offers plenty of island-hopping adventures, hidden coves, and secluded beaches, perfect for creating
          unforgettable memories with friends and family. Enjoy the freedom to explore at your own pace and uncover the
          unspoiled gems this captivating country has to offer.
        </p>
        <p>
          Choose from a range of convenient options to suit your needs: hop on a ferry to visit the islands, book a
          private tour, or rent a boat with a skipper. On the other hand, you can also rent a boat without the skipper,
          if you have a skipper license. Regardless of your choice, don't skip the islands.
        </p>
        <Img src={PLANNING_VACATION.yachts.src} alt={PLANNING_VACATION.yachts.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>BEACHES IN CROATIA</h3>
        <p>
          Experience an abundance of sunny days and spectacular shorelines on your unforgettable vacation in Croatia.
          Boasting over a thousand picturesque islands and an impressive 1700+ km of captivating coastline, you'll be
          spoilt for choice with a diverse range of sandy, secluded, pebble, and party beaches to discover. Find your
          perfect slice of paradise and create lifelong memories in this enchanting European destination.
        </p>
        <Img src={PLANNING_VACATION.zlatni_rat.src} alt={PLANNING_VACATION.zlatni_rat.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>HISTORY & ARCHITECTURE</h3>
        <p>
          Croatia boasts over two millennia worth of history, thanks to which it has a rich collection of monuments,
          museums, castles and fortresses. Much of it is incredibly well-preserved. What is even better, you have entire
          towns which are centuries old.
        </p>
        <p>
          Besides Zagreb, Varaždin and Ozalj, there are even better towns for sightseeing on the coast. While you have
          Rovinj, Poreč and Pula with its roman colosseum in Istria, in Dalmatia you have old town of Šibenik,
          Primošten, Omiš, Korčula, Ston and especially Hvar, Trogir and Zadar.
        </p>
        <p>
          However, two of them are in a league of their own and top locations for your summer vacation in Croatia. I am
          talking about Split and Dubrovnik.
        </p>
        <p>
          Split is the capital of Dalmatia with the massive 1700-year old Diocletian's Palace located in the town
          center. The Palace was an emperor's vacation resort, built during the Roman empire which was also used to film
          some scenes for Game of Thrones. Along with its outskirts such as the fortress of Klis, 7 castles of Kaštela,
          ancient ruins of Salona and charming old town of Trogir, Split is an invaluable historical and cultural
          center.
        </p>
        <p>
          Old town of Dubrovnik, surrounded by fortified walls, is maybe the most beautiful city in Europe. Thanks to
          its stunning architecture, Dubrovnik has turned into one of the top modern tourist destinations in the world
          as well as a desirable set for filmmakers. It is known as King's Landing in Game of Thrones and as the
          glamorous galactic city of Canto Bight in the Star Wars franchise.
        </p>
        <Img
          src={PLANNING_VACATION.dubrovnik_architecture.src}
          alt={PLANNING_VACATION.dubrovnik_architecture.alt}
          className={css.imgSingle}
        />
      </div>
      <div className={css.box}>
        <h3>OTHER THINGS TO DO IN CROATIA</h3>
        <p>
          An unforgettable vacation in Croatia is not complete without indulging in wine tasting, adrenaline- pumping
          tours, diving in breathtaking locations, exploring scenic national parks, and hiking to mountain peaks for
          awe-inspiring views of the Croatian coast and its islands.
        </p>
        <p>
          Thrill-seekers will love the exhilarating Zipline experience, nestled near Omiš in the picturesque canyon of
          the Cetina river. Additionally, rafting and kayaking opportunities abound in this same river. For those
          seeking a bungee jumping adventure, take the plunge from the Šibenik bridge, suspended above the gulf.
        </p>
        <p>
          Snorkeling and scuba diving enthusiasts will be delighted by Croatia's shallow seas, thousands of islands, and
          incredible dive spots. The region boasts numerous shipwrecks, caves, underwater cliffs, abundant marine life,
          giant mussels, and the added safety of being a closed sea rather than an ocean.
        </p>
        <p>
          For those who prefer to stay on land, Croatia offers stunning national parks such as Plitvice and Krka, as
          well as hiking opportunities to the peaks of mountains Biokovo near Makarska, Mosor near Split, Vidova Gora on
          the island of Brač, or Paklenica National Park. Whichever destination you choose, prepare yourself for
          breathtaking panoramic views that will leave you in awe.
        </p>
      </div>
    </div>
  );
};

export default PlanningVacation;
