import React, { useState } from 'react';
import css from './description.module.scss';
import { GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS } from '../../../../../utils/images/group-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import classNames from 'classnames';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';
import { Link } from 'react-router-dom';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.important_bring} alt="important bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p>
        Since the trip can be a bit bumpy on s speedboat, especially if it's windy, we don't take pregnant women and
        children 3 years old or younger on the Five Islands Tour.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, fuel and skipper, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p>
        Ticket or entrance fee to the Blue Cave is NOT included in the price of the excursion. Nautical center Komiža
        governs the Blue Cave and determines the price of the entrance fee.
      </p>
      <p>
        They determine the price for pre/post season and for high season as well as for adults and children 6 - 12 years
        old. Entrance for children up to 6 years old is free.
      </p>
      <p>
        For full price list of season 2023, visit their info link here.
        <a
          href="http://www.komiza.hr/wp-content/uploads/2023/01/Cjenik-usluga-Modra-%C5%A1pilja.pdf"
          target="_blank"
          rel="noreferrer"
        >
          (http://www.komiza.hr/wp-content/uploads/2023/01/Cjenik-usluga-Modra-%C5%A1pilja.pdf)
        </a>
      </p>
      <p style={{ marginBottom: '12px' }}>
        Ticket or entrance fee to the Blue Cave is NOT included in the price of the excursion. Nautical center Komiža
        governs the Blue Cave and determines the price of the entrance fee.
      </p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions, we hold the right to change the route or cancel
        the tour, for benefit of all passengers. This decision is made by captain. In this case, you have the right to
        shift the tour for some other day, or get a full refund.
      </p>
    </div>
  );
};

const TourInfo = () => {
  return (
    <div className={css.fadeIn}>
      <div className={classNames(css.textBox, css.fadeIn)}>
        <h2>Which islands / locations we visit?</h2>
        <p>
          Explore the breathtaking islands of Biševo, Vis, Budikovac, Pakleni, and Hvar on our most popular tour. Hvar
          and Vis, two of the biggest islands in mid-Adriatic, are waiting for you to discover their hidden treasures.
        </p>
        <p>
          During our tour, you'll have the opportunity to visit some of the best beaches in mid-Adriatic, including the
          stunning Palmižana beach and Carpe Diem beach at Pakleni Islands, located in front of Hvar. Indulge in the
          mouth-watering local cuisine with a delicious lunch on one of the islands.
        </p>
        <p>
          Immerse yourself in the crystal-clear waters, swim, and snorkel while taking in the beautiful scenery. Each
          island offers a unique and unforgettable experience, ensuring you won't get bored for a second.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Blue Cave</h2>
        <p>
          Located on the island of Biševo, the Blue Cave is a natural wonder that has captivated visitors for centuries.
          It is located on the east side of the small island of Biševo, adjacent to Vis Island. This fascinating natural
          phenomenon is one of the most incredible spots on the Adriatic coast.
        </p>
        <p>
          As you approach the grotto by boat, the crystal-clear waters sparkle in the sunlight and the stunning blue hue
          of the cave walls will leave you in awe. Once inside, the calm waters reflect the light, creating a magical
          blue glow that fills the cavern.
        </p>
        <p>
          Along with Hvar and Stiniva, the Blue Cave is a must-see destination. Don't miss the opportunity to capture
          stunning photographs that will serve as a lasting reminder of your unforgettable experience in Croatia.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.blue_cave.map((img, index) => (
            <Img key={`blue_cave-${index}`} src={img} alt={`blue_cave-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Vis island and Stiniva Cove</h2>
        <p>
          Vis is the farthest inhabited island on the Croatian coast. Its rich history dates from the Neolithic and
          because of its important strategic position, this island was location of many conflicts.
        </p>
        <p>
          Stiniva, voted by the tourists as the best beach in Europe, is a unique destination. This secluded little
          beach is located on the southern side of the island of Vis in Croatia. Stiniva beach has a peculiar outlook as
          it was created by the collapse of the dome of a cave.
        </p>
        <p>
          The main appeal of Stiniva Cove is its isolation and secludedness. There is no road leading to the beach, and
          the only way to access it is through a dangerous goat path or a narrow entrance by sea. However, visitors will
          be rewarded with a magnificent pebble beach with crystal clear water and white sanded bottom, all set against
          a stunning cliff top backdrop.
        </p>
        <p>
          Tourists can visit the beach on boat excursions but most boats anchor in front of the beach due to rope which
          blocks the entrance.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.stiniva.map((img, index) => (
            <Img key={`stiniva-${index}`} src={img} alt={`stiniva-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon at Budikovac island</h2>
        <p>
          Join us on our next stop to the small and serene island of Budikovac, located near Vis, which boasts a
          breathtaking turquoise lagoon.
        </p>
        <p>
          As you approach the island by boat, the sparkling clear waters of the lagoon will catch your eye, and the
          tranquil setting will transport you to a world of relaxation and peace. Once you dive into the water, you'll
          feel like you're swimming in a dream, surrounded by the vibrant colors of the sea and the natural beauty of
          the island.
        </p>
        <p>
          Explore the diverse sea life with our free snorkeling equipment or just take a dip in the crystal-clear waters
          of the lagoon. The vivid colors offer a great opportunity to take stunning photographs of the scenery.
        </p>
        <p>
          After your underwater adventure, head to the beach restaurant for a refreshing cup of coffee, delicious brunch
          or a quick trip to the restroom. Budikovac island and its turquoise lagoon offer an idyllic and unforgettable
          experience that you won't want to miss.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.budikovac.map((img, index) => (
            <Img key={`budikovac-${index}`} src={img} alt={`budikovac-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Pakleni islands</h2>
        <p>16 islands stretch for 10 kilometers offering numerous hidden bays and beaches.</p>
        <p>
          Our next destination is the beautiful Pakleni Islands, a group of islets located in front of the town of Hvar.
          The islands are known for their picturesque seabed, untouched nature and beautiful gravel beaches, including
          the famous Palmižana and Carpe Diem beach.
        </p>
        <p>
          Palmižana offers a wide range of amenities, including several restaurants, a lounge bar, and a botanical
          garden. The famous Palmižana beach is a real treat, perfect for those looking for a serene and relaxing day on
          the sand, while enjoying the amenities of several restaurants and a lounge bar.
        </p>
        <p>
          If you'ree looking for a more lively atmosphere, there is Carpe Diem. This beach is known for its party vibes,
          especially during the night. However, during the day, you can still soak up the sun and take a dip in the
          crystal-clear waters while enjoying a chilled-out atmosphere.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.pakleni.map((img, index) => (
            <Img key={`pakleni-${index}`} src={img} alt={`pakleni-${index}`} />
          ))}
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Hvar island</h2>
        <p>
          Our final destination is Hvar with its crystal-clear sea, gorgeous beaches, lavender fields, olive groves and
          sunny weather that make it the sunniest island on the Adriatic.
        </p>
        <p>
          While Hvar's beaches are its main attraction, the island's rich and turbulent history is reflected in its
          archaeological sites, old buildings, monasteries, and churches. A visit to the cathedral, the theater,
          established in 1612, the arsenal, and the Franciscan monastery is an excellent way to learn more about the
          island's history and culture.
        </p>
        <p>
          No trip to Hvar is complete without visiting the Fortica fortress, perched on a hill above the town. The
          15-minute walk to the top is well worth it for the panoramic view of the town, the Adriatic sea, and the
          Pakleni islands.
        </p>
        <p>
          Apart from its stunning attractions, Hvar offers excellent dining and nightlife options, with a wide range of
          restaurants, pubs, and local taverns. During the summer months, the island is famous for its summer parties,
          attracting young people from all over the world. With so much to see and do, Hvar is a destination that offers
          endless possibilities for fun and adventure.
        </p>
        <div className={css.imgsContainer}>
          {GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.hvar.map((img, index) => (
            <Img key={`hvar-${index}`} src={img} alt={`hvar-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Description = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: <TourInfo />,
    1: (
      <div>
        {/* <Img
          src={GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.overview}
          alt="overview"
          className={classNames(css.overviewImg, css.fadeIn)}
        /> */}
        <div className={css.textBox} style={{ gap: '8px', margin: '0px 0px 16px' }}>
          <h2 style={{ fontSize: '24px' }}>WHAT TO EXPECT & WHAT TO BRING?</h2>
          <p>
            Join us on the Five Islands Tour, starting with a trip to Biševo Island - home of the world-renowned Blue
            Cave. Although the farthest island from Split, it's a journey worth making. The trip will take between 1
            hour 20 minutes and 1 hour 50 minutes.
          </p>
          <p>
            After exploring the Blue Cave, we'll visit other gorgeous islands, each a short 10-20 minute ride away. On
            the way back from Hvar to Split, we'll spend around 45-60 minutes enjoying the stunning views.
          </p>
          <p>
            To ensure your comfort on the speedboat, especially during spring or early fall, we recommend bringing a
            long-sleeve shirt for cover. Don't forget to pack your bathing suit, towel, sunglasses, hat, and sunscreen.
            It's also a good idea to bring some food, snacks, and lunch money and a camera to capture the picturesque
            scenery.
          </p>
          <p>
            With our tour, everything you need for a safe and enjoyable experience is included in the price, such as the
            boat, fuel, crew, drinks, safety gear, and snorkeling equipment. Sit back, relax and let us take care of
            everything else!
          </p>
        </div>
        <div className={css.textBox} style={{ gap: '8px', margin: '0px 0px 16px' }}>
          <h2 style={{ fontSize: '24px' }}>WHAT ABOUT LUNCH?</h2>
          <p>
            During the trip, we offer complimentary water, but please note that we do not provide lunch. You are welcome
            to bring your own food, which is a more budget-friendly option, or purchase snacks from a local market on
            Hvar Island.
          </p>
          <p>
            If you prefer to dine out, you will have plenty of time to enjoy a meal at one of the restaurants located on
            the Pakleni Islands or in the town of Hvar. However, we would like to caution you that these restaurants can
            be quite costly, with the least expensive lunch option starting at approximately 20 € per person.
          </p>
        </div>
        <div className={css.textBox} style={{ gap: '8px', margin: '0px 0px 16px' }}>
          <h2 style={{ fontSize: '24px' }}>GET 2 FOR THE PRICE OF 1</h2>
          <p>
            On our Five Islands Tour, Hvar is the final destination, giving you the option to remain on the island
            rather than returning to Split.
          </p>
          <p>
            This tour can serve as both an excursion and a transfer to Hvar. If you plan to stay on the island, please
            inform us ahead of time of your luggage requirements so we can accommodate your needs.
          </p>
        </div>
      </div>
    ),
    2: <ImportantTab />,
    3: <Gallery images={GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.gallery} />,
  };
  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10.5 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Why is Five Islands Tour #1?</h2>
        <p>
          Are you looking for an unforgettable adventure on the Croatian coast? Look no further than the Five Islands
          Tour, the #1 choice for exploring Croatia's most beautiful mid-Adriatic islands in just one day.
        </p>
        <p>
          As the most sought-after excursion, this tour promises to take you to the most impressive and picturesque
          locations. With the Five Islands Tour, also known as the Blue Cave Tour, you're in for an experience you'll
          never forget.
        </p>
        <p>
          Our speedboat tour is the perfect way to make the most of your island-hopping adventure in Croatia. We'll take
          you to each destination in a fraction of the time, allowing you more time to soak up the sights and sounds of
          each place.
        </p>
        <p>
          Our itinerary includes stops of 30 to 45 minutes on some spots and 1 to 2 hours on others, depending on what
          they offer.
        </p>
        <p>
          You'll have ample time to explore Hvar city's impressive fortress and still have time to swim and snorkel in
          the crystal-clear waters of the Blue Lagoon on Budikovac Island. Our priority is to ensure you have enough
          time to fully enjoy each location without feeling rushed.
        </p>
        <p>
          However, the ultimate highlight of the tour is the Blue Cave on Biševo Island, a must-see attraction that will
          leave you in awe.
        </p>
        <iframe
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          src={GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.youtube_video}
          title="YouTube video"
        ></iframe>
      </div>

      <div className={css.textBox}>
        <h2>We also offer 3 types of speedboats for Five Islands Tour:</h2>
        <ul>
          <li>
            <Link to="/group-tours/budget-five-islands-tour">fast RIB-boats</Link>
          </li>
          <li>
            <Link to="/group-tours/standard-five-islands-tour">semi closed plastic boats</Link>
          </li>
          <li>
            <Link to="/group-tours/deluxe-five-islands-tour">luxury boats with powerful inboard engines</Link>
          </li>
        </ul>
        <div className={css.videos}>
          {GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.two_videos.map((video, index) => (
            <video key={`video-${index}`} controls>
              <source src={video} type="video/mp4" />
            </video>
          ))}
        </div>
        <p>
          Why do we have three speedboat options to choose from? Well, each one is designed to cater to your unique
          needs. Our fastest and most affordable boat is perfect for those who want to make the most of their time and
          budget while still enjoying the journey.
        </p>
        <p>
          If comfort and protection are your top priorities, then our middle option is just right for you. And for the
          ultimate luxurious experience, our largest and most expensive boat will exceed all your expectations.
        </p>
        <p>
          With our range of options, you can select the boat that fits your budget and guarantees an unforgettable tour.
          We're committed to making sure you have the best experience possible, and selecting the perfect boat is a
          crucial part of that.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Five Islands Tour: Choose your boat</h2>
        <p>Five Islands Tour is a 10-hour long excursion to Biševo, Vis, Budikovac, Pakleni Islands, and Hvar. </p>
        <p>
          Departure is between 7:30 - 8:00 AM from the Split harbor, and return between 18:00 - 18:30 PM to the same
          place.
        </p>
        <p>Depending on a speedboat type, size and fuel consumption, you can choose from 3 different price options:</p>
        <div className={css.cards}>
          {/* BUDGET */}
          <div className={css.card}>
            <Img src={GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.option_1} alt="budget option" />
            <div className={css.cardContainer}>
              <div className={css.cardContainerText}>
                <h4>BUDGET OPTION</h4>
                <h5>RIB BOAT</h5>
                <p>
                  Our Budget Five Islands Tour is the ultimate adventure to explore the hidden gems of mid-Adriatic
                  islands. Our lightweight RIB boats are built for speed and are the most affordable way to experience
                  the breathtaking scenery of Hvar and Vis archipelago.
                </p>
                <p>
                  This budget-friendly option is perfect for those who want to make the most of their time and are
                  looking for an adrenaline rush with our high performance speedboats, while enjoying in captivating
                  sights.
                </p>
              </div>
              <div>
                <div className={css.devider} style={{ margin: '16px 0px' }} />
                <p className={css.price}>Price per passenger: 110€</p>
                <Link to="/group-tours/budget-five-islands-tour" className={css.action}>
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          {/* STANDARD */}
          <div className={css.card}>
            <Img src={GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.option_2} alt="standard option" />
            <div className={css.cardContainer}>
              <div className={css.cardContainerText}>
                <h4>STANDARD OPTION</h4>
                <h5>SEMI-CLOSED BOAT</h5>
                <p>
                  Join us on our most popular excursion, Standard Five Islands Tour. Embark on a thrilling journey and
                  set sail on our spacious Enzo 35 and Mercan 34 boats, expertly crafted for both transfers and
                  excursions.
                </p>
                <p>
                  Take in the breathtaking views of the sun and sea from our spacious sun deck, perfect for lounging and
                  sunbathing. Sturdy roof, equipped with transparent roll-down side covers, provides reliable protection
                  against harsh elements like sun, wind, and rain.
                </p>
              </div>
              <div>
                <div className={css.devider} style={{ margin: '16px 0px' }} />
                <p className={css.price}>Price per passenger: 120€</p>
                <Link to="/group-tours/standard-five-islands-tour" className={css.action}>
                  Learn more
                </Link>
              </div>
            </div>
          </div>
          {/* DELUXE */}
          <div className={css.card}>
            <Img src={GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS.option_3} alt="deluxe option" />
            <div className={css.cardContainer}>
              <div className={css.cardContainerText}>
                <h4>DELUXE OPTION</h4>
                <h5>LUXURY SPEEDBOAT</h5>
                <p>
                  Experience the pinnacle of comfort and style with our spacious, cozy and luxurious speedboats on our
                  top - rated Deluxe Five Islands Tour, designed to provide an unforgettable trip through the
                  mesmerizing beauty of the Adriatic Sea.
                </p>
                <p>
                  Our luxury motorboats Colnago 33, Colnago 35, and Aliskaf 37 are designed to provide you ultimate
                  luxury with all the amenities you need, including shower, plenty of shade infront, sundeck at the
                  stern and a cabin onboard.
                </p>
              </div>
              <div>
                <div className={css.devider} style={{ margin: '16px 0px' }} />
                <p className={css.price}>Price per passenger: 130€</p>
                <Link to="/group-tours/deluxe-five-islands-tour" className={css.action}>
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          TOUR INFO
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 3,
          })}
          onClick={() => setActiveTab(3)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
      <div className={css.devider} style={{ margin: '8px 0px 0px' }} />
    </div>
  );
};

export default Description;
