import { S3_BASE_URL } from '..';

// DECLARE HERE IMAGES
const IMG_BLUE_CAVE_1 = S3_BASE_URL + '/gallery/blue-cave-1.jpg';
const IMG_BLUE_CAVE_2 = S3_BASE_URL + '/gallery/blue-cave-2.jpg';
const IMG_BLUE_CAVE_3 = S3_BASE_URL + '/gallery/blue-cave-3.jpg';
const IMG_BLUE_LAGOON_1 = S3_BASE_URL + '/gallery/blue-lagoon-1.webp';
const IMG_BLUE_LAGOON_10 = S3_BASE_URL + '/gallery/blue-lagoon-10.webp';
const IMG_BLUE_LAGOON_11 = S3_BASE_URL + '/gallery/blue-lagoon-11.jpg';
const IMG_BLUE_LAGOON_12 = S3_BASE_URL + '/gallery/blue-lagoon-12.webp';
const IMG_BLUE_LAGOON_13 = S3_BASE_URL + '/gallery/blue-lagoon-13.webp';
const IMG_BLUE_LAGOON_14 = S3_BASE_URL + '/gallery/blue-lagoon-14.webp';
const IMG_BLUE_LAGOON_2 = S3_BASE_URL + '/gallery/blue-lagoon-2.jpg';
const IMG_BLUE_LAGOON_3 = S3_BASE_URL + '/gallery/blue-lagoon-3.jpg';
const IMG_BLUE_LAGOON_4 = S3_BASE_URL + '/gallery/blue-lagoon-4.webp';
const IMG_BLUE_LAGOON_5 = S3_BASE_URL + '/gallery/blue-lagoon-5.jpg';
const IMG_BLUE_LAGOON_6 = S3_BASE_URL + '/gallery/blue-lagoon-6.jpg';
const IMG_BLUE_LAGOON_7 = S3_BASE_URL + '/gallery/blue-lagoon-7.jpg';
const IMG_BLUE_LAGOON_8 = S3_BASE_URL + '/gallery/blue-lagoon-8.webp';
const IMG_BLUE_LAGOON_9 = S3_BASE_URL + '/gallery/blue-lagoon-9.webp';
const IMG_BOL_1 = S3_BASE_URL + '/gallery/bol-1.jpg';
const IMG_BOL_2 = S3_BASE_URL + '/gallery/bol-2.jpg';
const IMG_BOL_3 = S3_BASE_URL + '/gallery/bol-3.jpg';
const IMG_BOL_4 = S3_BASE_URL + '/gallery/bol-4.jpg';
const IMG_BOL_5 = S3_BASE_URL + '/gallery/bol-5.webp';
const IMG_BOL_6 = S3_BASE_URL + '/gallery/bol-6.jpg';
const IMG_BOL_7 = S3_BASE_URL + '/gallery/bol-7.jpg';
const IMG_CIOVO_1 = S3_BASE_URL + '/gallery/ciovo-1.jpg';
const IMG_CIOVO_2 = S3_BASE_URL + '/gallery/ciovo-2.jpg';
const IMG_CIOVO_3 = S3_BASE_URL + '/gallery/ciovo-3.jpg';
const IMG_HVAR_1 = S3_BASE_URL + '/gallery/hvar-1.jpg';
const IMG_HVAR_2 = S3_BASE_URL + '/gallery/hvar-2.jpg';
const IMG_HVAR_3 = S3_BASE_URL + '/gallery/hvar-3.jpg';
const IMG_HVAR_4 = S3_BASE_URL + '/gallery/hvar-4.jpg';
const IMG_HVAR_5 = S3_BASE_URL + '/gallery/hvar-5.jpg';
const IMG_HVAR_6 = S3_BASE_URL + '/gallery/hvar-6.jpg';
const IMG_HVAR_7 = S3_BASE_URL + '/gallery/hvar-7.jpg';
const IMG_HVAR_8 = S3_BASE_URL + '/gallery/hvar-8.JPG';
const IMG_HVAR_9 = S3_BASE_URL + '/gallery/hvar-9.jpg';
const IMG_HVAR_10 = S3_BASE_URL + '/gallery/hvar-10.jpg';
const IMG_HVAR_11 = S3_BASE_URL + '/gallery/hvar-11.jpg';
const IMG_HVAR_12 = S3_BASE_URL + '/gallery/hvar-12.jpg';
const IMG_HVAR_13 = S3_BASE_URL + '/gallery/hvar-13.webp';
const IMG_MASLINICA_1 = S3_BASE_URL + '/gallery/maslinica-1.jpg';
const IMG_MASLINICA_2 = S3_BASE_URL + '/gallery/maslinica-2.jpg';
const IMG_MASLINICA_3 = S3_BASE_URL + '/gallery/maslinica-3.webp';
const IMG_MASLINICA_4 = S3_BASE_URL + '/gallery/maslinica-4.webp';
const IMG_MILNA_1 = S3_BASE_URL + '/gallery/milna-1.jpg';
const IMG_MILNA_2 = S3_BASE_URL + '/gallery/milna-2.jpg';
const IMG_PAKLENI_1 = S3_BASE_URL + '/gallery/pakleni-1.webp';
const IMG_PAKLENI_2 = S3_BASE_URL + '/gallery/pakleni-2.jpg';
const IMG_PAKLENI_3 = S3_BASE_URL + '/gallery/pakleni-3.webp';
const IMG_PAKLENI_4 = S3_BASE_URL + '/gallery/pakleni-4.jpg';
const IMG_PAKLENI_5 = S3_BASE_URL + '/gallery/pakleni-5.jpg';
const IMG_PAKLENI_6 = S3_BASE_URL + '/gallery/pakleni-6.webp';
const IMG_PAKLENI_9 = S3_BASE_URL + '/gallery/pakleni-9.jpg';
const IMG_PAKLENI_10 = S3_BASE_URL + '/gallery/pakleni-10.jpg';
const IMG_PAKLENI_11 = S3_BASE_URL + '/gallery/pakleni-11.webp';
const IMG_PAKLENI_12 = S3_BASE_URL + '/gallery/pakleni-12.jpg';
const IMG_PAKLENI_13 = S3_BASE_URL + '/gallery/pakleni-13.JPG';
const IMG_PAKLENI_14 = S3_BASE_URL + '/gallery/pakleni-14.jpg';
const IMG_PAKLENI_15 = S3_BASE_URL + '/gallery/pakleni-15.jpg';
const IMG_PAKLENI_16 = S3_BASE_URL + '/gallery/pakleni-16.jpg';
const IMG_PAKLENI_17 = S3_BASE_URL + '/gallery/pakleni-17.webp';
const IMG_PAKLENI_18 = S3_BASE_URL + '/gallery/pakleni-18.jpg';
const IMG_SHIPWRECK_1 = S3_BASE_URL + '/gallery/shipwreck-1.webp';
const IMG_SHIPWRECK_2 = S3_BASE_URL + '/gallery/shipwreck-2.jpg';
const IMG_SHIPWRECK_3 = S3_BASE_URL + '/gallery/shipwreck-3.jpg';
const IMG_SHIPWRECK_4 = S3_BASE_URL + '/gallery/shipwreck-4.webp';
const IMG_SHIPWRECK_5 = S3_BASE_URL + '/gallery/shipwreck-5.webp';
const IMG_SHIPWRECK_6 = S3_BASE_URL + '/gallery/shipwreck-6.webp';
const IMG_STINIVA_1 = S3_BASE_URL + '/gallery/stiniva-1.jpg';
const IMG_STINIVA_2 = S3_BASE_URL + '/gallery/stiniva-2.jpg';
const IMG_STINIVA_3 = S3_BASE_URL + '/gallery/stiniva-3.jpg';
const IMG_STOMORSKA_1 = S3_BASE_URL + '/gallery/stomorska-1.jpg';
const IMG_STOMORSKA_2 = S3_BASE_URL + '/gallery/stomorska-2.jpg';
const IMG_SUNSET_1 = S3_BASE_URL + '/gallery/sunset-1.jpg';
const IMG_SUNSET_2 = S3_BASE_URL + '/gallery/sunset-2.webp';
const IMG_SUNSET_3 = S3_BASE_URL + '/gallery/sunset-3.jpg';
const IMG_SUNSET_4 = S3_BASE_URL + '/gallery/sunset-4.jpg';
const IMG_SUNSET_5 = S3_BASE_URL + '/gallery/sunset-5.jpg';
const IMG_SUNSET_6 = S3_BASE_URL + '/gallery/sunset-6.jpg';
const IMG_SUNSET_7 = S3_BASE_URL + '/gallery/sunset-7.jpg';
const IMG_SUNSET_8 = S3_BASE_URL + '/gallery/sunset-8.jpg';
// const IMG_SUNSET_9 = S3_BASE_URL + '/gallery/sunset-9.jpg';
const IMG_TROGIR_1 = S3_BASE_URL + '/gallery/trogir-1.jpg';
const IMG_TROGIR_2 = S3_BASE_URL + '/gallery/trogir-2.jpg';
const IMG_TROGIR_3 = S3_BASE_URL + '/gallery/trogir-3.jpg';
const IMG_TROGIR_4 = S3_BASE_URL + '/gallery/trogir-4.jpg';
const IMG_TROGIR_5 = S3_BASE_URL + '/gallery/trogir-5.jpg';
const IMG_TROGIR_6 = S3_BASE_URL + '/gallery/trogir-6.jpg';
const IMG_TROGIR_7 = S3_BASE_URL + '/gallery/trogir-7.jpg';
const IMG_TROGIR_8 = S3_BASE_URL + '/gallery/trogir-8.webp';
const IMG_TROGIR_9 = S3_BASE_URL + '/gallery/trogir-9.webp';
const IMG_TROGIR_10 = S3_BASE_URL + '/gallery/trogir-10.webp';
const IMG_TROGIR_11 = S3_BASE_URL + '/gallery/trogir-11.jpg';
const IMG_TROGIR_12 = S3_BASE_URL + '/gallery/trogir-12.jpg';
const IMG_TROGIR_13 = S3_BASE_URL + '/gallery/trogir-13.jpg';
const IMG_TROGIR_14 = S3_BASE_URL + '/gallery/trogir-14.jpg';
const IMG_TUNEL_1 = S3_BASE_URL + '/gallery/tunnel-1.jpg';
const IMG_TUNEL_2 = S3_BASE_URL + '/gallery/tunnel-2.jpg';

// PRIVATE TOURS
export const PRIVATE_TOURS_BLUE_LAGOON_3_ISLANDS = [
  IMG_TROGIR_1,
  IMG_TROGIR_2,
  IMG_TROGIR_3,
  IMG_TROGIR_4,
  IMG_TROGIR_12,
  IMG_TROGIR_11,
  IMG_TROGIR_8,
  IMG_TROGIR_9,
  IMG_TROGIR_10,
  IMG_BLUE_LAGOON_1,
  IMG_BLUE_LAGOON_6,
  IMG_BLUE_LAGOON_13,
  IMG_BLUE_LAGOON_12,
  IMG_BLUE_LAGOON_14,
  IMG_MASLINICA_2,
  IMG_MASLINICA_3,
  IMG_MASLINICA_4,
  IMG_SHIPWRECK_3,
  IMG_SHIPWRECK_2,
  IMG_SHIPWRECK_4,
  IMG_SHIPWRECK_6,
];

export const PRIVATE_TOURS_BLUE_LAGOON_4_ISLANDS = [
  IMG_SHIPWRECK_3,
  IMG_SHIPWRECK_2,
  IMG_SHIPWRECK_4,
  IMG_SHIPWRECK_6,
  IMG_MASLINICA_1,
  IMG_MASLINICA_2,
  IMG_MASLINICA_4,
  IMG_BLUE_LAGOON_1,
  IMG_BLUE_LAGOON_5,
  IMG_BLUE_LAGOON_13,
  IMG_BLUE_LAGOON_12,
  IMG_BLUE_LAGOON_14,
  IMG_TROGIR_1,
  IMG_TROGIR_2,
  IMG_TROGIR_3,
  IMG_TROGIR_4,
  IMG_TROGIR_12,
  IMG_TROGIR_11,
  IMG_TROGIR_14,
  IMG_TROGIR_13,
  IMG_TROGIR_9,
];

export const PRIVATE_TOURS_BLUE_LAGOON_5_ISLANDS = [
  IMG_TROGIR_1,
  IMG_TROGIR_2,
  IMG_TROGIR_3,
  IMG_TROGIR_4,
  IMG_TROGIR_12,
  IMG_TROGIR_11,
  IMG_BLUE_LAGOON_1,
  IMG_BLUE_LAGOON_6,
  IMG_BLUE_LAGOON_13,
  IMG_BLUE_LAGOON_12,
  IMG_BLUE_LAGOON_14,
  IMG_PAKLENI_4,
  IMG_PAKLENI_5,
  IMG_PAKLENI_10,
  IMG_PAKLENI_12,
  IMG_PAKLENI_14,
  IMG_HVAR_12,
  IMG_HVAR_5,
  IMG_HVAR_4,
  IMG_SHIPWRECK_4,
  IMG_SHIPWRECK_6,
];

export const PRIVATE_TOURS_SUNSET = [
  IMG_SUNSET_1,
  IMG_SUNSET_2,
  IMG_SUNSET_3,
  IMG_SUNSET_4,
  IMG_SUNSET_5,
  IMG_SUNSET_6,
  IMG_SUNSET_7,
  IMG_SUNSET_8,
  // IMG_SUNSET_9,
];

export const PRIVATE_TOURS_HVAR_PAKLENI = [
  IMG_PAKLENI_1,
  IMG_PAKLENI_18,
  IMG_PAKLENI_2,
  IMG_PAKLENI_3,
  IMG_PAKLENI_4,
  IMG_PAKLENI_5,
  IMG_PAKLENI_6,
  IMG_PAKLENI_10,
  IMG_PAKLENI_11,
  IMG_PAKLENI_9,
  IMG_PAKLENI_12,
  IMG_PAKLENI_13,
  IMG_PAKLENI_15,
  IMG_PAKLENI_14,
  IMG_HVAR_1,
  IMG_HVAR_10,
  IMG_HVAR_6,
  IMG_HVAR_12,
  IMG_HVAR_5,
  IMG_HVAR_4,
  IMG_HVAR_13,
  IMG_HVAR_11,
  IMG_HVAR_9,
  IMG_HVAR_8,
];

export const PRIVATE_TOURS_BOL_HVAR_PAKLENI = [
  IMG_BOL_7,
  IMG_BOL_2,
  IMG_BOL_3,
  IMG_BOL_4,
  IMG_PAKLENI_1,
  IMG_PAKLENI_2,
  IMG_PAKLENI_3,
  IMG_PAKLENI_4,
  IMG_PAKLENI_5,
  IMG_PAKLENI_6,
  IMG_PAKLENI_10,
  IMG_PAKLENI_11,
  IMG_PAKLENI_9,
  IMG_PAKLENI_12,
  IMG_PAKLENI_13,
  IMG_PAKLENI_15,
  IMG_HVAR_1,
  IMG_HVAR_7,
  IMG_HVAR_2,
  IMG_HVAR_6,
  IMG_HVAR_12,
  IMG_HVAR_5,
  IMG_HVAR_4,
  IMG_HVAR_3,
];

export const PRIVATE_TOURS_BLUE_CAVE = [
  IMG_BLUE_CAVE_2,
  IMG_BLUE_CAVE_1,
  IMG_BLUE_CAVE_3,
  IMG_STINIVA_1,
  IMG_STINIVA_3,
  IMG_STINIVA_2,
  IMG_PAKLENI_16,
  IMG_PAKLENI_17,
  IMG_PAKLENI_18,
  IMG_PAKLENI_2,
  IMG_PAKLENI_3,
  IMG_PAKLENI_4,
  IMG_PAKLENI_5,
  IMG_PAKLENI_6,
  IMG_PAKLENI_10,
  IMG_PAKLENI_11,
  IMG_PAKLENI_13,
  IMG_PAKLENI_12,
  IMG_HVAR_7,
  IMG_HVAR_1,
  IMG_HVAR_6,
  IMG_HVAR_12,
  IMG_HVAR_4,
  IMG_HVAR_8,
];

export const PRIVATE_TOURS_BOL_SOLTA = [
  IMG_BOL_5,
  IMG_BOL_2,
  IMG_BOL_3,
  IMG_BOL_4,
  IMG_BOL_6,
  IMG_BOL_1,
  IMG_TUNEL_1,
  IMG_TUNEL_2,
  IMG_MILNA_1,
  IMG_MILNA_2,
  IMG_STOMORSKA_1,
  IMG_STOMORSKA_2,
];

// GROUP TOURS
export const GROUP_TOURS_BLUE_CAVE = [
  IMG_BLUE_CAVE_2,
  IMG_BLUE_CAVE_1,
  IMG_BLUE_CAVE_3,
  IMG_STINIVA_1,
  IMG_STINIVA_3,
  IMG_STINIVA_2,
  IMG_PAKLENI_16,
  IMG_PAKLENI_17,
  IMG_PAKLENI_18,
  IMG_PAKLENI_2,
  IMG_PAKLENI_3,
  IMG_PAKLENI_4,
  IMG_PAKLENI_5,
  IMG_PAKLENI_6,
  IMG_PAKLENI_10,
  IMG_PAKLENI_11,
  IMG_PAKLENI_13,
  IMG_PAKLENI_12,
  IMG_HVAR_7,
  IMG_HVAR_1,
  IMG_HVAR_6,
  IMG_HVAR_12,
  IMG_HVAR_4,
  IMG_HVAR_8,
];

export const GROUP_TOURS_HVAR_BOL_PAKLENI = [
  IMG_BOL_1,
  IMG_BOL_2,
  IMG_BOL_3,
  IMG_BOL_4,
  IMG_PAKLENI_1,
  IMG_PAKLENI_2,
  IMG_PAKLENI_3,
  IMG_PAKLENI_4,
  IMG_PAKLENI_5,
  IMG_PAKLENI_6,
  IMG_PAKLENI_10,
  IMG_PAKLENI_11,
  IMG_PAKLENI_9,
  IMG_PAKLENI_12,
  IMG_PAKLENI_13,
  IMG_PAKLENI_15,
  IMG_HVAR_1,
  IMG_HVAR_7,
  IMG_HVAR_5,
  IMG_HVAR_4,
  IMG_HVAR_3,
];

export const GROUP_TOURS_THREE_ISLANDS_SHIPWRECK = [
  IMG_SHIPWRECK_1,
  IMG_SHIPWRECK_5,
  IMG_SHIPWRECK_3,
  IMG_SHIPWRECK_2,
  IMG_SHIPWRECK_4,
  IMG_MASLINICA_1,
  IMG_MASLINICA_2,
  IMG_MASLINICA_4,
  IMG_MASLINICA_3,
  IMG_BLUE_LAGOON_4,
  IMG_BLUE_LAGOON_1,
  IMG_BLUE_LAGOON_5,
  IMG_BLUE_LAGOON_6,
  IMG_BLUE_LAGOON_7,
  IMG_BLUE_LAGOON_9,
  IMG_BLUE_LAGOON_10,
  IMG_BLUE_LAGOON_8,
  IMG_BLUE_LAGOON_11,
];

export const GROUP_TOURS_THREE_HALF_DAY = [
  IMG_TROGIR_1,
  IMG_TROGIR_2,
  IMG_TROGIR_3,
  IMG_TROGIR_4,
  IMG_TROGIR_5,
  IMG_TROGIR_7,
  IMG_TROGIR_8,
  IMG_TROGIR_9,
  IMG_TROGIR_10,
  IMG_TROGIR_6,
  IMG_BLUE_LAGOON_1,
  IMG_BLUE_LAGOON_2,
  IMG_BLUE_LAGOON_3,
  IMG_CIOVO_2,
  IMG_CIOVO_1,
  IMG_CIOVO_3,
];
