import React, { useRef, useState } from 'react';
import css from './banner.module.scss';
import PrevButton from '../../universal/PrevButton';
import NextButton from '../../universal/NextButton';
import { useInViewport } from 'react-in-viewport';
import { Link } from 'react-router-dom';
import { HOME_BANNER } from '../../../data/landing-page';
import Img from '../../universal/Img';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Banner = () => {
  const [active, setActvie] = useState(0);
  const sliderRef = useRef();
  const { inViewport } = useInViewport(sliderRef);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    swipeToSlide: true,
    arrows: false,
    afterChange: (current) => setActvie(current),
  };

  const setPrevious = () => {
    sliderRef.current.slickGoTo(active - 1);
  };

  const setNext = () => {
    sliderRef.current.slickGoTo(active + 1);
  };

  return (
    <div className={css.bannerDesktop}>
      <Slider {...settings} slickPause={!inViewport} ref={sliderRef}>
        <div className={css.bannerDesktopDragItem}>
          <Img src={HOME_BANNER[0].imgUrl} alt={HOME_BANNER[0].title} className={css.bannerDesktopImg} />
          <div className={css.bannerDesktopFilter} />
          <div
            className={classNames(css.bannerDesktopTitle, {
              'animate__animated animate__fadeIn': active === 0,
            })}
          >
            <h1>{HOME_BANNER[0].title}</h1>
            <h2>{HOME_BANNER[0].subtitle}</h2>
          </div>
        </div>
        <div className={css.bannerDesktopDragItem}>
          <Img src={HOME_BANNER[1].imgUrl} alt={HOME_BANNER[1].title} className={css.bannerDesktopImg} />
          <div className={css.bannerDesktopFilter} />
          <div className={css.callToAction}>
            <div
              className={classNames({
                'animate__animated animate__fadeInUp': active === 1,
              })}
            >
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 1,
                })}
              >
                {HOME_BANNER[active].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 1,
                })}
              >
                {HOME_BANNER[active].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 1,
                })}
              >
                BOOK HER
              </Link>
            </div>
          </div>
        </div>
        <div className={css.bannerDesktopDragItem}>
          <Img src={HOME_BANNER[2].imgUrl} alt={HOME_BANNER[2].title} className={css.bannerDesktopImg} />
          <div className={css.bannerDesktopFilter} />
          <div className={css.callToAction}>
            <div
              className={classNames({
                'animate__animated animate__fadeInUp': active === 2,
              })}
            >
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 2,
                })}
              >
                {HOME_BANNER[active].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 2,
                })}
              >
                {HOME_BANNER[active].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 2,
                })}
              >
                BOOK HERE
              </Link>
            </div>
          </div>
        </div>
        <div className={css.bannerDesktopDragItem}>
          <Img src={HOME_BANNER[3].imgUrl} alt={HOME_BANNER[3].title} className={css.bannerDesktopImg} />
          <div className={css.bannerDesktopFilter} />
          <div className={css.callToAction}>
            <div
              className={classNames({
                'animate__animated animate__fadeInUp': active === 3,
              })}
            >
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 3,
                })}
              >
                {HOME_BANNER[active].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 3,
                })}
              >
                {HOME_BANNER[active].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 3,
                })}
              >
                BOOK HERE
              </Link>
            </div>
          </div>
        </div>
        <div className={css.bannerDesktopDragItem}>
          <Img src={HOME_BANNER[4].imgUrl} alt={HOME_BANNER[4].title} className={css.bannerDesktopImg} />
          <div className={css.bannerDesktopFilter} />
          <div className={css.callToAction}>
            <div
              className={classNames({
                'animate__animated animate__fadeInUp': active === 4,
              })}
            >
              <h1
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 4,
                })}
              >
                {HOME_BANNER[active].title}
              </h1>
              <h2
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 4,
                })}
              >
                {HOME_BANNER[active].subtitle}
              </h2>
              <Link
                to="/group-tours"
                className={classNames({
                  'animate__animated animate__fadeInUp': active === 4,
                })}
              >
                BOOK HERE
              </Link>
            </div>
          </div>
        </div>
      </Slider>

      {HOME_BANNER.length > 1 && <PrevButton onClick={setPrevious} className={css.bannerDesktopPrevButton} />}
      {HOME_BANNER.length > 1 && <NextButton onClick={setNext} className={css.bannerDesktopNextButton} />}
    </div>
  );
};

export default Banner;
