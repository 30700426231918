import { RENT_A_BOAT } from '../utils/images';

export const CONTENT = {
  0: [
    {
      photo: RENT_A_BOAT.tab_1.boat_1.src,
      section1: {
        name: 'MANO MARINE 18.50 ',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 100 HP',
          'Maximum Persons: 6',
          'Boat Length: 5,06 m',
          'Production Year: 2017',
          'Boat Width: 2,08 m',
        ],
      },
      section2: {
        name: 'RATES FOR MANO MARINE 18.50 ',
        text: [
          'Low season: (before 20.06) 230 € / per day - 1400 € / week',
          'High season: (20.06 - 31.08) 250 € / per day - 1600 € / week',
          'Low season: (after 01.09) 230 € / per day - 1400 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
    {
      photo: RENT_A_BOAT.tab_1.boat_2.src,
      section1: {
        name: 'JEANNEAU CAP CAMARAT 755 OPEN',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 100 HP',
          'Maximum Persons: 6',
          'Boat Length: 5,50 m',
          'Production Year: 2019',
          'Boat Width: 2,45 m',
        ],
      },
      section2: {
        name: 'RATES FOR J. C. CAMARAT 755 OPEN',
        text: [
          'Low season: (before 20.06) 250 € / per day - 1400 € / week',
          'High season: (20.06 - 31.08) 270 € / per day - 1600 € / week',
          'Low season: (after 01.09) 250 € / per day - 1400 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
    {
      photo: RENT_A_BOAT.tab_1.boat_3.src,
      section1: {
        name: 'PRINCE 570 OPEN',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 115 HP',
          'Maximum Persons: 7',
          'Boat Length: 5,70 m',
          'Production Year: 2019',
          'Boat Width: 2,25 m',
        ],
      },
      section2: {
        name: 'RATES FOR PRINCE 570 OPEN',
        text: [
          'Low season: (before 20.06) 260 € / per day - 1400 € / week',
          'High season: (20.06 - 31.08) 300 € / per day - 1600 € / week',
          'Low season: (after 01.09) 260 € / per day - 1400 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
    {
      photo: RENT_A_BOAT.tab_1.boat_4.src,
      section1: {
        name: 'PRINCE 626 OPEN',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 140 HP',
          'Maximum Persons: 8',
          'Boat Length: 6,25 m',
          'Production Year: 2018',
          'Boat Width: 2,50 m',
        ],
      },
      section2: {
        name: 'RATES FOR PRINCE 625 OPEN',
        text: [
          'Low season: (before 20.06) 290 € / per day - 1400 € / week',
          'High season: (20.06 - 31.08) 340 € / per day - 1600 € / week',
          'Low season: (after 01.09) 290 € / per day - 1400 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
  ],
  1: [
    {
      photo: RENT_A_BOAT.tab_2.boat_1.src,
      section1: {
        name: 'ATLANTIC 670 OPEN',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 140 HP',
          'Maximum Persons: 10',
          'Boat Length: 6,70 m',
          'Production Year: 2016',
          'Boat Width: 2,50 m',
        ],
      },
      section2: {
        name: 'RATES FOR ATLANTIC 670 OPEN',
        text: [
          'Low season: (before 20.06) 330 € / per day - 2200 € / week',
          'High season: (20.06 - 31.08) 390 € / per day - 2400 € / week',
          'Low season: (after 01.09) 330 € / per day - 2200 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
    {
      photo: RENT_A_BOAT.tab_2.boat_2.src,
      section1: {
        name: 'NUOVA JOLLY PRINCE 23',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 200 HP',
          'Maximum Persons: 11',
          'Boat Length: 7,50 m',
          'Production Year: 2019',
          'Boat Width: 3,00 m',
        ],
      },
      section2: {
        name: 'RATES FOR NUOVA JOLLY PRINCE 23',
        text: [
          'Low season: (before 20.06) 360 € / per day - 2300 € / week',
          'High season: (20.06 - 31.08) 440 € / per day - 2800 € / week',
          'Low season: (after 01.09) 360 € / per day - 2300 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
    {
      photo: RENT_A_BOAT.tab_2.boat_3.src,
      section1: {
        name: 'JEANNEAU CAP CAMARAT 755 OPEN',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 225 HP',
          'Maximum Persons: 12',
          'Boat Length: 7,50 m',
          'Production Year: 2015',
          'Boat Width: 2,55 m',
        ],
      },
      section2: {
        name: 'RATES FOR J. C. CAMARAT 755 OPEN',
        text: [
          'Low season: (before 20.06) 380 € / per day - 2300 € / week',
          'High season: (20.06 - 31.08) 480 € / per day - 2800 € / week',
          'Low season: (after 01.09) 380 € / per day - 2300 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
    {
      photo: RENT_A_BOAT.tab_2.boat_4.src,
      section1: {
        name: 'MARSEA SP 170',
        bullets: [
          'Boat type: Speedboat',
          'Engine: 115 HP',
          'Maximum Persons: 7',
          'Boat Length: 5,70 m',
          'Production Year: 2019',
          'Boat Width: 2,25 m',
        ],
      },
      section2: {
        name: 'RATES FOR MARSEA SP 170',
        text: [
          'Low season: (before 20.06) 430 € / per day - 2400 € / week',
          'High season: (20.06 - 31.08) 500 € / per day - 2900 € / week',
          'Low season: (after 01.09) 430 € / per day - 2400 € / week',
        ],
      },
      footer: 'Available: Music, Sunshade, Fridge, Shower, GPS',
    },
  ],
};
