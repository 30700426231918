import React from 'react';
import css from './top-blogs.module.scss';
import BlogCarousel from '../BlogCarousel';
import { TOP_BLOGS } from '../../../data/blogs';

const TopBlogs = ({ blogs = TOP_BLOGS }) => {
  return (
    <div className={css.topBlogsWrapper}>
      <h2>FIND OUT WHAT TO DO AND WHERE TO GO</h2>
      <BlogCarousel data={blogs} />
    </div>
  );
};

export default TopBlogs;
