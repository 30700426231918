import React from 'react';
import css from './home.module.scss';
import './whatsapp.scss';
import Banner from '../../../components/mobile/Banner';
import TextCarousel from '../../../components/universal/TextCarousel';
import PrivateTours from '../../../components/mobile/PrivateTours';
import OurBoats from '../../../components/mobile/OurBoats';
import SpeedboatTransfers from '../../../components/mobile/SpeedboatTransfers';
import RentBoat from '../../../components/universal/RentBoat';
import Splitter from '../../../components/universal/Splitter';
import Tripadvisor from '../../../components/mobile/Tripadvisor';
import TopBlogs from '../../../components/mobile/TopBlogs';
import MostPopularGroupTours from '../../../components/mobile/MostPopularGroupTours';
import TopPrivateTours from '../../../components/mobile/TopPrivateTours';
import MotionDiv from '../../../components/universal/MotionDiv';
// wapp widget
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { WHATSAPP_MOBILE } from '../../../utils/constants';

const Home = () => {
  return (
    <MotionDiv className={css.homePageMobileLayout}>
      <Banner />
      <TextCarousel />
      <PrivateTours />
      <TopPrivateTours />
      <OurBoats />
      <SpeedboatTransfers />
      <MostPopularGroupTours />
      <RentBoat />
      <Splitter />
      <Tripadvisor />
      <TopBlogs />
      <div className="whatsAppWidgetMobile">
        <WhatsAppWidget
          phoneNumber={WHATSAPP_MOBILE}
          companyName="Excursion Dalmatia"
          replyTimeText="Typically replies in minutes"
          message={`Hi there! \n\nIf you need some info about tours or anything else, I'm here to help!`}
        />
      </div>
    </MotionDiv>
  );
};

export default Home;
