import React, { useState } from 'react';
import css from './boat-transfers.module.scss';
import PrivateToursForm from '../../../components/universal/PrivateToursForm';
import { IconDropdown } from '../../../assets/icons';
import classNames from 'classnames';
import { BANNERS, BOAT_TRANSFERS } from '../../../utils/images';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const TABS_CONTENT_1 = [
  {
    name: 'SMALLER SPEEDBOATS',
    text: [
      "If you're looking for a small and comfortable speedboat for a group of up to 6 or 7 passengers, Barracuda 545, Prince 625, and Saver 650 are great options.",
      'Despite being only 5-6 meters long, they offer adequate cover and comfort, making them ideal for Split boat transfers to nearby islands or to the airport.',
    ],
    images: [
      {
        src: BOAT_TRANSFERS.slider_1.tab_1.img_1,
        alt: 'boat Saver',
      },
      {
        src: BOAT_TRANSFERS.slider_1.tab_1.img_2,
        alt: 'boat baracuda',
      },
      {
        src: BOAT_TRANSFERS.slider_1.tab_1.img_3,
        alt: 'boat Mano marine',
      },
    ],
  },
  {
    name: 'MIDSIZE SPEEDBOATS',
    text: [
      'Mid-sized boats are ideal for private tours and boat transfers in Split. With enough space and protection, they can accommodate 10 to 12 passengers comfortably.',
      "While they may be more expensive than smaller boats, mid-sized boats offer added value, especially if you&#39;re traveling to a destination that's farther away from your pickup point.",
    ],
    images: [
      {
        src: BOAT_TRANSFERS.slider_1.tab_2.img_1,
        alt: 'boat Marlin',
      },
      {
        src: BOAT_TRANSFERS.slider_1.tab_2.img_2,
        alt: 'boat White boat',
      },
      {
        src: BOAT_TRANSFERS.slider_1.tab_2.img_3,
        alt: 'boat Marsea',
      },
    ],
  },
  {
    name: 'DELUXE SIZE SPEEDBOATS',
    text: [
      'Deluxe speedboats offer plenty of space, protection, and comfort for all passengers. With their larger size, they can accommodate up to 12 passengers with plenty of room to spare, making them ideal for bigger groups and those who prefer larger boats.',
      "However, it's important to note that bigger boats come with a larger engine, which means they consume more fuel and are more expensive than smaller boats.",
    ],
    images: [
      {
        src: BOAT_TRANSFERS.slider_1.tab_3.img_1,
        alt: 'speedboat',
      },
      {
        src: BOAT_TRANSFERS.slider_1.tab_3.img_2,
        alt: 'speedboat 2',
      },
      {
        src: BOAT_TRANSFERS.slider_1.tab_3.img_3,
        alt: 'boat Colnago',
      },
    ],
  },
];

const TABS_CONTENT_2 = [
  {
    name: 'LUXURY YACHTS',
    text: [
      "If you're looking for the ultimate luxury experience, we have a few spacious yacht options for you to choose from. Designed to accommodate a maximum of 11 passengers, these yachts offer plenty of comfort for you to relax and even take a nap during your trip.",
    ],
    images: [
      {
        src: BOAT_TRANSFERS.slider_2.tab_1.img_1,
        alt: 'boat Jeanneau Prestige',
      },
      {
        src: BOAT_TRANSFERS.slider_2.tab_1.img_2,
        alt: 'luxury yacht',
      },
      {
        src: BOAT_TRANSFERS.slider_2.tab_1.img_3,
        alt: 'boat Cranci Endurance',
      },
    ],
  },
  {
    name: 'BIG BOATS FOR BIG GROUPS',
    text: [
      'If you need to arrange a transfer for a larger group of more than 12 people and want everyone to be on one vessel, we have several big boats that can accommodate groups ranging from 60 to 150 passengers. While they may not be as fast as speedboats, their size more than makes up for it.',
    ],
    images: [
      {
        src: BOAT_TRANSFERS.slider_2.tab_2.img_1,
        alt: 'boat Blaga',
      },
      {
        src: BOAT_TRANSFERS.slider_2.tab_2.img_2,
        alt: 'boat Humac',
      },
      {
        src: BOAT_TRANSFERS.slider_2.tab_2.img_3,
        alt: 'big boat',
      },
    ],
  },
];

const PrivateTours = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [isOpened2, setIsOpened2] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTab2, setActiveTab2] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
    setIsOpened(false);
  };

  const changeTab2 = (index) => {
    setActiveTab2(index);
    setIsOpened2(false);
  };

  return (
    <MotionDiv className={css.boatTransfersMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.boat_transfers.src} alt="excursion dalmatia private transfer" className={css.bannerImg} />
        <div className={css.filter} />
        <h1>BOAT TRANSFERS</h1>
      </div>

      {/* CONTENT CONTAINER */}
      <div className={css.contentContainer}>
        <div className={css.contentContainerSection1}>
          <div className={css.privateTourContent}>
            <div className={css.privateTourContentBox}>
              <h2>Split Boat Transfers: Choose your boat</h2>
              <p style={{ marginBottom: '16px' }}>
                At Split Boat Transfers, we pride ourselves on providing fast and high-quality services. Whether you opt
                for our affordable speedboat or our luxurious yacht, we guarantee a comfortable and relaxing experience.
              </p>
              <p style={{ marginBottom: '16px' }}>
                Our speedy boats not only offer style but also save you valuable time. We offer pickup services for our
                guests from Hvar and Split Airport, conveniently located in Kaštela.
              </p>
              <p>
                Moreover, we provide pickup services from your hotel marina or accommodation at a pre-scheduled point.
                Simply inform us, and we will take care of the rest.
              </p>
            </div>

            <div className={css.privateTourContentBox}>
              <h2>Transfers from Split</h2>
              <p style={{ marginBottom: '16px' }}>
                Our company is based in Split, the largest town in Dalmatia and very popular tourist destination. We
                offer boat transfers from Split to various destinations, including the airport and popular mid-Adriatic
                islands such as Hvar, Brač, and Šolta.
              </p>
              <p>
                Our services extend to all destinations on the Croatian side of the Adriatic, and we also provide Split
                boat transfers to Dubrovnik or Pula. Our team is committed to providing reliable and efficient transfer
                services to ensure that your journey is comfortable and stress-free.
              </p>
            </div>

            <div className={css.privateTourContentBox}>
              <h2>Transfers from Hvar</h2>
              <p>
                If you're looking for a speedboat transfer from Hvar to Split, Dubrovnik, Zadar, or even Split Airport,
                our company can help. We have offices in Hvar, which is the most popular destination in the mid-Adriatic
                region. Hvar refers to both the island and the town. Contact us today for all your speedboat transfer
                needs.
              </p>
            </div>

            <div className={css.privateTourContentBox}>
              <h2>Transfers from Split Airport</h2>
              <p style={{ marginBottom: '16px' }}>
                If you're traveling to Split for a summer vacation, you'll likely arrive at Split Airport, located in
                the town of Kaštela, 25 kilometers away from Split. During the peak season, the airport is bustling with
                tourists from all over the world who come to enjoy the mid-Adriatic islands.
              </p>
              <p>
                If you need a direct transfer from Split Airport to your hotel or one of the islands, we can help.
                Contact us today via email, WhatsApp, or Viber to arrange your transfer.
              </p>
            </div>
          </div>
          <div className={css.bookPrivateTour}>
            <h2>BOOK A PRIVATE TOUR</h2>
            <PrivateToursForm />
          </div>
        </div>

        {/* TABS */}
        <div className={css.contentContainerSection3}>
          <h3>TRANSFERS WITH:</h3>
          <div className={css.tabsWrapper}>
            <div
              className={classNames(css.activeTabName, { [css.isOpened]: isOpened })}
              onClick={() => setIsOpened(!isOpened)}
            >
              {TABS_CONTENT_1[activeTab].name}
              <IconDropdown />
            </div>
            {isOpened ? (
              <div className={css.dropdownWrapper}>
                {TABS_CONTENT_1.map((tab, index) => (
                  <div key={`tab-${index}`} className={css.dropdownItem} onClick={() => changeTab(index)}>
                    {tab.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {TABS_CONTENT_1[activeTab].text.map((text, index) => (
            <p key={`text${index}`}>{text}</p>
          ))}

          <div className={css.images}>
            {TABS_CONTENT_1[activeTab].images.map((image, index) => (
              <Img key={`img${index}`} src={image.src} alt={image.alt} className={css.img} />
            ))}
          </div>
        </div>

        <div className={css.contentContainerSection4}>
          <div className={css.tabsWrapper}>
            <div
              className={classNames(css.activeTabName, { [css.isOpened]: isOpened2 })}
              onClick={() => setIsOpened2(!isOpened2)}
            >
              {TABS_CONTENT_2[activeTab2].name}
              <IconDropdown />
            </div>
            {isOpened2 ? (
              <div className={css.dropdownWrapper}>
                {TABS_CONTENT_2.map((tab, index) => (
                  <div key={`tab-${index}`} className={css.dropdownItem} onClick={() => changeTab2(index)}>
                    {tab.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {TABS_CONTENT_2[activeTab2].text.map((text, index) => (
            <p key={`text${index}`}>{text}</p>
          ))}

          <div className={css.images}>
            {TABS_CONTENT_2[activeTab2].images.map((image, index) => (
              <Img key={`img-${index}`} src={image.src} alt={image.alt} className={css.img} />
            ))}
          </div>
        </div>
      </div>

      {/* HOW TO BOOK */}
      <div className={css.howToBookContainer}>
        <h2>Split Transfers - How to book?</h2>
        <div className={css.howToBookContainerDescription}>
          <p>
            Reserve a transfer up to one month in advance. To confirm your booking, we require 30% deposit with the
            remaining 70% to be paid directly to your skipper upon pick up.
          </p>
          <h4>No extra fees</h4>
          <p>
            We pride ourselves on our transparent pricing policy, which means there are no hidden fees or extra charges
            to worry about. To get started, simply provide us with your travel details and we'll check availability for
            your desired date and time.
          </p>
          <p>
            Once we've confirmed availability, we'll send you an email or text message with all the details you need to
            know about our private Split transfers. If you need a quicker response, feel free to reach out to us on
            WhatsApp or Viber at +385 95 826 79 81.
          </p>
        </div>
      </div>
    </MotionDiv>
  );
};

export default PrivateTours;
