import React, { useState } from 'react';
import css from './our-boats.module.scss';
import PrivateToursForm from '../../../components/universal/PrivateToursForm';
import { IconDropdown } from '../../../assets/icons';
import classNames from 'classnames';
import { BANNERS, OUR_BOATS } from '../../../utils/images';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const TABS_CONTENT = [
  {
    name: 'PRIVATE SPEEDBOAT TOURS',
    bullets: [
      'Our speedboats and yachts come in different categories, including RIB boats, plastic speedboats, and luxury yachts. Our speedboats can accommodate up to twelve passengers, depending on the size.',
      'Whichever type of boat you need, small speedboat for 3 - 5 persons, midsize speedboat for 6 - 9 people or big speedboat for up to 12 people onboard, we got you covered.',
      'That being said, if you need luxury yacht with extra space and comfort, we have this type of vessels in our fleet, too.',
    ],
    img: { ...OUR_BOATS.tab_1 },
  },
  {
    name: 'LUXURY YACHT TOURS',
    bullets: [
      "We also offer several luxury yachts for daily and weekly tours, when it comes to private tours from Split. Our crew members are your private tour guides, and they'll take care of any of your requests if able.",
      'So, if you want to travel in style and luxury, choose our yachts. They are especially suitable for guests which prefer to have maximum comfort and plenty of space onboard to relax.',
      'Private cabins, spacious sundeck, kitchen, shower and even towels are all on your disposal when you book a luxury yacht.',
    ],
    img: { ...OUR_BOATS.tab_2 },
  },
  {
    name: 'BIG BOAT TOURS',
    bullets: [
      "If you're travelling with a large group, we have various big boats that can accommodate anywhere from 20 up to 150 passengers onboard.",
      'So, for larger groups, our big boats are perfect solution. Soak up the rays on second deck with sunbeds on both sides or take a drink in the shade of the first deck.',
      'Big Boats are equipped with powerful diesel engines, water tank, GPS, VHF, fridge, full safety equipment, and a toilet. Experience comfort and safety on your next island adventure with us.',
    ],
    img: { ...OUR_BOATS.tab_3 },
  },
];

const PrivateTours = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
    setIsOpened(false);
  };

  return (
    <MotionDiv className={css.privateToursMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.our_boats.src} alt={BANNERS.our_boats.alt} className={css.bannerImg} />
        <div className={css.filter} />
        <h1>PRIVATE TOURS</h1>
      </div>

      {/* CONTENT CONTAINER */}
      <div className={css.contentContainer}>
        <div className={css.contentContainerSection1}>
          <div className={css.privateTourContent}>
            <div className={css.privateTourContent1}>
              <h3>Private Tours: Create your own excursion</h3>
              <p style={{ marginBottom: '16px' }}>
                If you're looking for a unique and personalized way to explore the Adriatic and Croatia, <br /> private
                tours from Split are the way to go. With a private boat, you can create your own <br /> itinerary and
                explore at your own pace.
              </p>
              <p style={{ marginBottom: '16px' }}>
                One of the key advantages of private tours is the flexibility they offer. You can set your own schedule
                and make the most of your time.
              </p>
              <p>
                Another benefit of private tours is the privacy they provide. You can travel with your friends and
                family without having to worry about sharing your space with strangers. This means you can relax and
                enjoy your tour without any distractions or interruptions.
              </p>
            </div>
            <div>
              <h3>Benefits of private tours:</h3>
              <ul className={css.benefitList}>
                <li>Choose your departure time (no earlier than 7:30 AM)</li>
                <li>Choose at which time you return back to Split (no later than 7:00 PM)</li>
                <li>You have a boat just for yourselves</li>
                <li>Stay as long as you want in each place</li>
                <li>Plan your own tour - choose destinations you want to visit</li>
                <li>During the tour you can visit other places on the route you find interesting</li>
              </ul>
            </div>
          </div>
          <div className={css.bookPrivateTour}>
            <h3>BOOK A PRIVATE TOUR</h3>
            <PrivateToursForm />
          </div>
        </div>

        <div className={css.contentContainerSection2}>
          <h2>Explore the Mid-Adriatic Islands</h2>
          <p>
            We offer a variety of boats for your private tour, including speedboats, luxury yachts and big boats. You
            can explore the mid-Adriatic islands at your own pace and visit other places on the route that you find
            interesting.
          </p>
        </div>

        {/* TABS */}
        <div className={css.contentContainerSection3}>
          <div className={css.tabsWrapper}>
            <div
              className={classNames(css.activeTabName, { [css.isOpened]: isOpened })}
              onClick={() => setIsOpened(!isOpened)}
            >
              {TABS_CONTENT[activeTab].name}
              <IconDropdown />
            </div>
            {isOpened ? (
              <div className={css.dropdownWrapper}>
                {TABS_CONTENT.map((tab, index) => (
                  <div key={`tab-${index}`} className={css.dropdownItem} onClick={() => changeTab(index)}>
                    {tab.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <ul className={css.list}>
            {TABS_CONTENT[activeTab].bullets.map((bullet, index) => (
              <li key={`bullet-${index}`}>{bullet}</li>
            ))}
          </ul>
          <Img src={TABS_CONTENT[activeTab].img.src} alt={TABS_CONTENT[activeTab].name.alt} className={css.img} />
        </div>
      </div>

      {/* HOW TO BOOK */}
      <div className={css.howToBookContainer}>
        <h2>How To Book a Private Tour?</h2>
        <div className={css.howToBookContainerDescription}>
          <p>
            On our website you will find pages where you can check our speedboats, big boats, yachts and sailboats
            available for private tours from Split.
          </p>
          <p>
            After you decide which vessel you would like to reserve for your private tour, simply send us enquiry via
            contact form, WhatsApp or Viber.
          </p>
          <p>
            We will help to organize your private tour and provide you all necessary information and prices regarding
            your inquiry.
          </p>
          <p>
            We will let you know if the boat you requested is available for the dates you prefer and let you know the
            price of the private tour you planned for your friends or family.
          </p>
        </div>
      </div>
    </MotionDiv>
  );
};

export default PrivateTours;
