import React from 'react';
import css from './private-tours.module.scss';
import GroupToursCard from '../../../components/universal/GroupToursCard';
import { PRIVATE_TOURS } from '../../../data/private-tours';
import { useNavigate } from 'react-router-dom';
import { BANNERS } from '../../../utils/images';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const PrivateTours = () => {
  const navigate = useNavigate();

  return (
    <MotionDiv className={css.privateToursPageLayout}>
      <div className={css.bannerWrapper}>
        <Img
          src={BANNERS.private_tours.general.src}
          alt={BANNERS.private_tours.general.alt}
          className={css.bannerImg}
        />
        <div className={css.filter} />
        <h1>PRIVATE TOURS</h1>
      </div>

      <div className={css.cardsContainer}>
        {PRIVATE_TOURS.map((groupTour, index) => (
          <GroupToursCard
            fromPrice
            link={`/private-tours${groupTour.slug}`}
            key={`group-tour-card-${index}`}
            name={groupTour.name}
            photo={groupTour.photo}
            description={groupTour.description}
            views={groupTour.views}
            duration={groupTour.duration}
            price={groupTour.price}
            onButtonClick={() => navigate(`/private-tours${groupTour.slug}`)}
            priceText="Price starting from:"
          />
        ))}
      </div>
    </MotionDiv>
  );
};

export default PrivateTours;
