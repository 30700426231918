import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import css from './bol-solta-islands.module.scss';
import TabMenu from '../../../../components/universal/TabMenu';
import PrivateToursForm from '../../../../components/universal/PrivateToursForm';
import { PRIVATE_TOURS } from '../../../../data/private-tours';
import GroupToursCard from '../../../../components/universal/GroupToursCard';
import { BANNER_IMAGES } from '../../../../utils/images/private-tours';
import MotionDiv from '../../../../components/universal/MotionDiv';
import Description from './description';
import TourItinerary from '../../../../components/universal/TourItinerary';
import TourLocation from '../../../../components/universal/TourLocation';
import TripadvisorReviews from '../../../../components/universal/TripadvisorReviews';
import TEXT from '../../../../json/pages/pt-bol-solta.json';
import Img from '../../../../components/universal/Img';

const BolSoltaIsland = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const otherTours = useMemo(() => _.filter(PRIVATE_TOURS, (tour) => !pathname.includes(tour.slug)), [pathname]);

  const mainContent = {
    0: <Description />,
    1: <TourItinerary textItinerary={TEXT.itinerary} />,
    2: <TourLocation />,
    3: <TripadvisorReviews />,
  };

  return (
    <MotionDiv className={css.privateToureMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img
          src={BANNER_IMAGES.bol_solta_island.src}
          alt={BANNER_IMAGES.bol_solta_island.alt}
          className={css.bannerImg}
        />
        <div className={css.filter} />
        <h1>BOL & ŠOLTA ISLAND</h1>
      </div>

      <TabMenu
        menuItems={['Description', 'Tour Info', 'Location', 'Reviews']}
        activeTab={activeTab}
        onClick={setActiveTab}
      />

      <div className={css.contentContainer}>
        {mainContent[activeTab]}

        <div className={css.formWrapper}>
          <h2>BOOK A PRIVATE TOUR</h2>
          <PrivateToursForm type="BOL & ŠOLTA ISLAND" />
        </div>

        <div className={css.otherTours}>
          <h2>OTHER TOURS</h2>
          <div className={css.cardsContainer}>
            <div className={css.slider}>
              {otherTours.map((tourData, index) => (
                <GroupToursCard
                  link={`/private-tours${tourData.slug}`}
                  className={css.card}
                  key={`group-tour-card-${index}`}
                  name={tourData.name}
                  photo={tourData.photo}
                  description={tourData.description}
                  views={tourData.views}
                  duration={tourData.duration}
                  price={`${tourData.price}`}
                  fromPrice
                  onButtonClick={() => navigate(`/private-tours${tourData.slug}`)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default BolSoltaIsland;
