import { BANNERS, IMPORTANT_BRING, S3_BASE_URL } from '..';
import {
  GROUP_TOURS_BLUE_CAVE,
  GROUP_TOURS_HVAR_BOL_PAKLENI,
  GROUP_TOURS_THREE_HALF_DAY,
  GROUP_TOURS_THREE_ISLANDS_SHIPWRECK,
} from '../galleries';

export const BANNER_IMAGES = BANNERS.group_tours;

// GROUP TOURS
export const GROUP_TOURS_CARDS = {
  hvar_and_bol: S3_BASE_URL + '/group-tours-cards/bol.jpg',
  three_islands_half_day: S3_BASE_URL + '/group-tours-cards/trogir.jpg',
  three_islands: S3_BASE_URL + '/group-tours-cards/shipwreck.jpg',
  blue_cave: S3_BASE_URL + '/group-tours-cards/blue-cave.jpg',
  budget_five_islands: S3_BASE_URL + '/group-tours-cards/budget.webp',
  standard_five_islands: S3_BASE_URL + '/group-tours-cards/standard.webp',
  deluxe_five_islands: S3_BASE_URL + '/group-tours-cards/deluxe.jpg',
};

export const GROUP_TOUR_3_ISLANDS_HALF_DAY = {
  overview: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/karta.jpg',
  trogir_1: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/trogir-1.jpg',
    alt: 'Half Day Blue Lagoon Tour from Split with speedboat',
  },
  trogir_2: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/trogir-2.jpg',
    alt: 'Blue Lagoon Tour from Split',
  },
  trogir_3: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/trogir-3.jpg',
    alt: 'UNESCO Trogir',
  },
  drvenik_veli_1: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/blue-lagoon-1.webp',
    alt: 'Drvenik Veli 1',
  },
  drvenik_veli_2: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/blue-lagoon-2.jpg',
    alt: 'Krknjasi 1',
  },
  drvenik_veli_3: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/blue-lagoon-3.webp',
    alt: 'Krknjasi 2',
  },
  laganini_bar_1: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/duga-1.webp',
    alt: 'Laganini Beach Bar 1',
  },
  laganini_bar_2: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/duga-2.webp',
    alt: 'Laganini Beach Bar 2',
  },
  laganini_bar_3: {
    src: S3_BASE_URL + '/group-tours/3-islands-half-day-tour-trogir-blue-lagoon-ciovo/duga-3.webp',
    alt: 'Laganini Beach Bar 3',
  },
  important_bring: {
    src: IMPORTANT_BRING,
    alt: 'Bring with you',
  },
  gallery: GROUP_TOURS_THREE_HALF_DAY,
};

export const GROUP_TOUR_3_ISLANDS_SHIPWRECK = {
  overview: S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/karta.jpg',
  shipwreck: [
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/shipwreck-1.jpg',
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/shipwreck-2.jpg',
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/shipwreck-3.jpg',
  ],
  maslinica: [
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/maslinica-1.jpg',
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/maslinica-2.jpg',
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/maslinica-3.jpg',
  ],
  blue_lagoon: [
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/blue-lagoon-1.webp',
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/blue-lagoon-2.jpg',
    S3_BASE_URL + '/group-tours/3-islands-tour-shipwreck-blue-lagoon-maslinica/blue-lagoon-3.jpg',
  ],
  important_bring: IMPORTANT_BRING,
  gallery: GROUP_TOURS_THREE_ISLANDS_SHIPWRECK,
};

export const GROUP_TOUR_HVAR_BOL_PAKLENI = {
  bol: S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/bol.jpg',
  zlatni_rat: [
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/zlatni-rat-1.jpg',
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/zlatni-rat-2.jpg',
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/zlatni-rat-3.jpg',
  ],
  hvar: [
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/hvar-1.jpg',
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/hvar-2.jpg',
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/hvar-3.jpg',
  ],
  pakleni_islands: [
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/pakleni-1.jpg',
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/pakleni-2.jpg',
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/pakleni-3.JPG',
  ],
  milna: [
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/milna-1.jpg',
    S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/milna-2.jpg',
  ],
  overview: S3_BASE_URL + '/group-tours/hvar-and-bol-tour-with-pakleni-islands/karta.jpg',
  important_bring: IMPORTANT_BRING,
  gallery: GROUP_TOURS_HVAR_BOL_PAKLENI,
};

export const GROUP_TOUR_BUDGET_FIVE_ISLANDS = {
  rib_boat: [
    S3_BASE_URL + '/group-tours/budget-five-islands-tour/budget-1.webp',
    S3_BASE_URL + '/group-tours/budget-five-islands-tour/budget-2.jpg',
    S3_BASE_URL + '/group-tours/budget-five-islands-tour/budget-3.JPG',
  ],
  surprise: S3_BASE_URL + '/group-tours/budget-five-islands-tour/budget-4.jpg',
};

export const GROUP_TOUR_STANDARD_FIVE_ISLANDS = {
  protection: [
    S3_BASE_URL + '/group-tours/standard-five-islands-tour/standard-1.webp',
    S3_BASE_URL + '/group-tours/standard-five-islands-tour/standard-2.jpg',
    S3_BASE_URL + '/group-tours/standard-five-islands-tour/standard-3.webp',
  ],
};

export const GROUP_TOUR_DELUXE_FIVE_ISLANDS = {
  banner: 'https://via.placeholder.com/600/f16b97',
  speedboat: [
    S3_BASE_URL + '/group-tours/deluxe-five-islands-tour/deluxe-1.webp',
    S3_BASE_URL + '/group-tours/deluxe-five-islands-tour/deluxe-2.webp',
    S3_BASE_URL + '/group-tours/deluxe-five-islands-tour/deluxe-3.JPG',
  ],
};

export const GROUP_TOUR_BLUE_CAVE_FIVE_ISLANDS = {
  youtube_video: 'https://www.youtube.com/embed/2o6vutUme90',
  two_videos: [
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/videos/video-1.mp4',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/videos/video-2.mp4',
  ],
  option_1: S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/budget.webp',
  option_2: S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/standard.webp',
  option_3: S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/deluxe.JPG',
  blue_cave: [
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/blue-cave-1.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/blue-cave-2.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/blue-cave-3.jpg',
  ],
  stiniva: [
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/stiniva-1.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/stiniva-2.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/stiniva-3.jpg',
  ],
  budikovac: [
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/blue-lagoon-1.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/blue-lagoon-2.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/blue-lagoon-3.jpg',
  ],
  pakleni: [
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/pakleni-1.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/pakleni-2.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/pakleni-3.JPG',
  ],
  hvar: [
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/hvar-1.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/hvar-2.jpg',
    S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/hvar-3.jpg',
  ],
  overview: S3_BASE_URL + '/group-tours/blue-cave-and-5-islands-tour/karta.jpg',
  important_bring: IMPORTANT_BRING,
  gallery: GROUP_TOURS_BLUE_CAVE,
};
