import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import css from './header.module.scss';
import { NAVIGATION } from '../../../utils/constants';
import { LOGO } from '../../../utils/images';
import Img from '../../universal/Img';

const Header = () => {
  return (
    <div className={css.desktopHeaderContainer}>
      <Link to="/">
        <Img src={LOGO.logo.src} alt="logo" />
      </Link>

      <ul className={css.navlinkList}>
        {NAVIGATION.header.map((navlink) => (
          <li
            key={navlink.route}
            className={classNames({ [css.activeLink]: window.location.pathname === navlink.route })}
          >
            <Link to={navlink.route} className={css.navlink}>
              {navlink.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Header;
