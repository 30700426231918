import React, { useRef } from 'react';
import classNames from 'classnames';
import css from './rent-boat.module.scss';
import { Link } from 'react-router-dom';
import Img from '../Img';
import { LANDING_PAGE } from '../../../utils/images';

const RentBoat = ({ className }) => {
  const myRef = useRef();

  return (
    <div className={classNames(className, css.rentBoatContainer)} ref={myRef}>
      <div className={css.rentBoatWrapper}>
        <div className={css.rentBoatInfo}>
          <h2>RENT A BOAT WITHOUT SKIPPER </h2>
          <p>
            Rent a boat & be your own skipper. Enjoy with your friends and family while cruising around the Adriatic
            islands with Excursion Dalmatia.
          </p>
          <Link className={classNames(css.button, css.buttonDesktop)} to="/transfers/rent-a-boat">
            Learn more
          </Link>
        </div>
        <div className={css.rentBoatImages}>
          <Img src={LANDING_PAGE.rent_a_boat[0]} alt="boat" className={classNames(css.img, css.mobile)} />

          <div className={css.grid}>
            {LANDING_PAGE.rent_a_boat.map((photo, index) => (
              <Img key={`photo-${index}`} src={photo} alt="boat" className={css.img} />
            ))}
          </div>
        </div>
        <Link className={classNames(css.button, css.buttonMobile)} to="/transfers/rent-a-boat">
          Learn more
        </Link>
      </div>
    </div>
  );
};

export default RentBoat;
