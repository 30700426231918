import React from 'react';
import css from './footer.module.scss';
import { NAVIGATION } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import {
  IconFacebook,
  IconGooglePlus,
  IconInstagram,
  IconMailEmpty,
  IconTelephone,
  IconTripadvisorV2,
  IconWhatsApp,
  IconYoutube,
} from '../../../assets/icons';
import { LOGO, FOOTER } from '../../../utils/images';
import { SOCIAL_MEDIA_LINKS } from '../../../utils/constants';
import Img from '../../universal/Img';

const Footer = () => {
  return (
    <div className={css.footerContainer}>
      <div className={css.footerContent}>
        <div className={css.footerContentColumns}>
          <div className={css.column}>
            <div className={css.columnTitle}>
              <h3>COMPANY INFORMATION</h3>
            </div>
            <div className={css.address}>
              <p>Tourist agency address:</p>
              <p>Trg Franje Tuđmana</p>
              <p>21 000 Split</p>
            </div>
            <div className={css.workingHours}>
              <p>Working hours:</p>
              <p>Mon - Sun 7:00 - 22:00</p>
            </div>
          </div>
          <div className={css.column}>
            <div className={css.columnTitle}>
              <h3>DAY TRIPS FROM SPLIT</h3>
            </div>
            <div className={css.trips}>
              {NAVIGATION.footer.trips.map((trip, index) => (
                <Link key={`${trip.name}-${index}`} to={trip.route}>
                  {trip.name}
                </Link>
              ))}
            </div>
          </div>
          <div className={css.column}>
            <div className={css.columnTitle}>
              <h3>SITEMAP</h3>
            </div>
            <div className={css.sitemap}>
              {NAVIGATION.footer.sitemap.map((trip, index) => (
                <Link key={`${trip.name}-${index}`} to={trip.route}>
                  {trip.name}
                </Link>
              ))}
            </div>
          </div>
          <div className={css.column}>
            <div className={css.columnTitle}>
              <h3>CONTACT</h3>
            </div>
            <div className={css.contactInfo}>
              <IconTelephone />
              <p>+385 95 826 7981</p>
            </div>
            <div className={css.contactInfo}>
              <IconMailEmpty />
              <p>excursion.dalmatia@gmail.com</p>
            </div>
            <div className={css.logo}>
              <Img src={LOGO.logo.src} alt={LOGO.logo.src} />
            </div>
          </div>
        </div>
        <div className={css.sponsors}>
          <Img src={FOOTER.sponsors_desktop.src} alt="footer sponsors" />
        </div>
      </div>
      <div className={css.footerSocialMedia}>
        <p>TOUR AGENCY EXCURSION DALMATIA</p>
        <div className={css.socialMedia}>
          <a href={SOCIAL_MEDIA_LINKS.facebook} target="_blank" rel="noreferrer">
            <IconFacebook />
          </a>
          <a href={SOCIAL_MEDIA_LINKS.instagram} target="_blank" rel="noreferrer">
            <IconInstagram />
          </a>
          <a href={SOCIAL_MEDIA_LINKS.youtube} target="_blank" rel="noreferrer">
            <IconYoutube />
          </a>
          <a href={SOCIAL_MEDIA_LINKS.tripadvisor} target="_blank" rel="noreferrer">
            <IconTripadvisorV2 />
          </a>
          <a href={SOCIAL_MEDIA_LINKS.googleplus} target="_blank" rel="noreferrer">
            <IconGooglePlus />
          </a>
          <a href={SOCIAL_MEDIA_LINKS.whatsapp} target="_blank" rel="noreferrer">
            <IconWhatsApp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
