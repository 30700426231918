import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import css from './carousel.module.scss';
import PrevButton from '../../universal/PrevButton';
import NextButton from '../../universal/NextButton';
import BlogCard from '../../universal/BlogCard';
import { useNavigate } from 'react-router-dom';
import { useInViewport } from 'react-in-viewport';

const BlogCarousel = ({ data, children }) => {
  const [active, setActvie] = useState(0);
  const items = useMemo(() => _.chunk(data, 5), [data]);
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);

  const navigate = useNavigate();

  useEffect(() => {
    let timerId;

    // change only if in viewport
    if (inViewport) {
      timerId = setTimeout(() => {
        setActvie((activeIndex) => (activeIndex + 1) % items.length);
      }, 5000);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [active, items, inViewport]);

  const setPrevious = () => {
    const length = items.length;
    if (active === 0) setActvie(length - 1);
    else setActvie((curActive) => curActive - 1);
  };

  const setNext = () => {
    const length = items.length;
    if (active < length - 1) setActvie((curActive) => curActive + 1);
    else setActvie(0);
  };

  return (
    <div className={classNames(css.carouselDesktop)} ref={myRef}>
      {items.length > 1 ? <PrevButton onClick={setPrevious} className={css.carouselDesktopPrevButton} /> : null}
      {children}
      <div className={css.carouselWrapper}>
        <div className={css.column}>
          <BlogCard
            data={items[active][0]}
            className={css.bigCard}
            onClick={() => navigate(`/blog/${items[active][0].slug}`)}
          />
        </div>
        <div
          className={classNames(css.column, css.gridOfFour, {
            [css.gridTwoRows]: items[active].length <= 3,
          })}
        >
          <BlogCard
            data={items[active][1]}
            className={css.smallCard}
            small
            onClick={() => navigate(`/blog/${items[active][1].slug}`)}
          />
          <BlogCard
            data={items[active][2]}
            className={css.smallCard}
            small
            onClick={() => navigate(`/blog/${items[active][2].slug}`)}
          />
          <BlogCard
            data={items[active][3]}
            className={css.smallCard}
            small
            onClick={() => navigate(`/blog/${items[active][3].slug}`)}
          />
          <BlogCard
            data={items[active][4]}
            className={css.smallCard}
            small
            onClick={() => navigate(`/blog/${items[active][4].slug}`)}
          />
        </div>
      </div>
      {items.length > 1 ? <NextButton onClick={setNext} className={css.carouselDesktopNextButton} /> : null}
    </div>
  );
};

export default BlogCarousel;
