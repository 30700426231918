import React from 'react';
import classNames from 'classnames';
import css from './swipe-indicator.module.scss';
import Flick from '../../../assets/images/flick-to-left.png';

const SwipeIndicator = ({ hide, className }) => {
  return <img src={Flick} alt="slide" className={classNames(className, css.swipeIcon, { [css.hide]: hide })} />;
};

export default SwipeIndicator;
