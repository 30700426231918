import React from 'react';
import css from './vege.module.scss';
import Img from '../../../components/universal/Img';
import { VEGE } from '../../../utils/images/blogs';

const Vege = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>The Best Vegetarian Restaurants in Split</h2>
        <p>Are you on the hunt for exceptional vegetarian dining options in Split? Look no further!</p>
        <p>
          While many fast food establishments and restaurants in Split predominantly feature meat and seafood dishes,
          there are always vegetarian-friendly alternatives available, such as delectable vegetarian pizzas, diverse
          salads, scrumptious pasta, and mouth-watering gluten-free cakes.
        </p>
        <p>
          Venture into the heart of Split, and you'll discover a selection of restaurants that exclusively cater to
          vegetarian and vegan palates. These establishments not only offer gluten-free meals, but also serve up
          refreshing smoothies, satisfying veggie burgers, vibrant fruit salads, and irresistible sugar-free cakes.
          Complement your meal with a refreshing craft beer or a robust barley coffee, and you're set to enjoy a truly
          wholesome and eco-conscious dining experience.
        </p>
      </div>
      <div style={{ marginBottom: '0px' }} className={css.box}>
        <h3>VEGETARIAN RESTAURANTS IN SPLIT</h3>
      </div>
      <div className={css.box}>
        <h2>Check some of these places or their reviews on tripadvisor:</h2>
        <p>
          <span>Marta's Veggie Fusion</span> Located in the heart of Diocletian's palace and adjacent to the iconic
          Peristil, Marta's Veggie Fusion invites you to indulge in a delectable array of vegetarian, vegan, and
          gluten-free cuisine. Our extensive menu boasts an enticing selection of dishes, from savory burrito bowls and
          wraps to fragrant curries, mouthwatering falafels, hearty burgers, refreshing salads, and comforting soups.
        </p>
        <p>
          To complement your meal, explore our impressive assortment of local craft beers, a comprehensive coffee menu,
          revitalizing fresh juices and smoothies, as well as an assortment of vegan and gluten-free desserts to satisfy
          your sweet tooth.
        </p>
        <p>
          Though intimate in size, our charming eatery promises a memorable dining experience, with each dish freshly
          prepared and bursting with flavor. Discover the enchanting fusion of culinary delights at Marta's Veggie
          Fusion, where healthy meets delicious.
        </p>
        <p>
          <span>Pandora Greenbox</span> is a bit more expensive vegetarian restaurant located in the center of Split,
          with a contemporary bar and an enchanting garden area.
        </p>
        <p>
          They make an array of culinary delights, including beet tartare, delectable vegan burgers, sumptuous truffle
          pasta, velvety hummus served with bread, hearty pea soup, and vibrant beet risotto.
        </p>
        <p>
          To further elevate your dining experience, Pandora Greenbox also features a curated selection of refreshing
          juices and tantalizing cocktails, including a variety of vegan options. Indulge in a remarkable gastronomic
          journey, complemented by a captivating ambiance and attentive, professional service at Pandora Greenbox.
        </p>
        <p>
          <span>UpCafe</span> is an intimate vegetarian establishment that prides itself on crafting delectable dishes
          from whole, seasonal, and predominantly organic ingredients. Their diverse menu boasts an array of
          health-conscious vegetarian and vegan options, as well as gluten-free choices to cater to all dietary
          preferences.
        </p>
        <p>
          Indulge in freshly squeezed juices, invigorating barley coffee, and aromatic organic teas as you savor our
          scrumptious sugar-free cakes, wholesome oat and millet porridges, and mouth-watering wraps and snacks. At
          UpCafe, they are committed to providing exceptional culinary experiences that nourish both the body and soul.
        </p>
        <p>
          <span>Vege Fast Food</span> is a quaint vegan café nestled in the heart of Split, conveniently positioned
          across from Inbox Bar and adjacent to the bustling main marketplace. Their street food-inspired eatery
          presents an array of traditional, home-cooked vegan dishes, accompanied by a diverse assortment of delectable
          sauces.
        </p>
        <p>
          At Veggie Fast Food, they pride themselves on swift service and affordable pricing. While this establishment
          may not boast a lavish ambiance, it makes up for it with scrumptious cuisine and exceptional value for money.
        </p>

        <div className={css.gallery}>
          {VEGE.food.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3>VEGETARIAN FOOD STORES IN SPLIT</h3>
        <p>Along with vegetarian restaurants in Split, the city also has wonderful health food stores and markets.</p>
        <p>
          <span>Pazar Market or the Green Market</span> is located within the heart of the historic Old Town, and offers
          a vibrant, open-air shopping experience for locals and tourists alike. With a stunning backdrop of
          Diocletian's Palace's east walls extending all the way to the majestic Silver Gate, this bustling farmer's
          market boasts an array of fresh, locally-sourced fruits, vegetables, olive oil, honey, and nuts, as well as
          numerous other enticing goods.
        </p>
        <p>
          For the most delightful experience, we recommend visiting the market early in the morning when the atmosphere
          is truly enchanting. Pazar Market operates from around 7 AM to 2 PM, providing ample time to browse and savor
          the bountiful offerings. Although prices are generally reasonable, do be aware that they may slightly increase
          during high season.
        </p>
        <p>
          Above all, Pazar Market takes pride in showcasing the rich, seasonal produce cultivated by local farms in the
          surrounding Split region, ensuring the highest quality and freshness for its patrons.
        </p>
        <p>
          <span>Bio&Bio</span> is one of the largest health food stores and can be found in 3 locations in Split and as
          an online shop as well.
        </p>
        <p>
          Their expertise lies in providing an extensive range of organic products, plant-based delicacies,
          environmentally conscious home essentials, all-natural beauty solutions, and potent nutritional supplements.
        </p>
        <p>
          <span>Refužo</span> has 5 locations in Split and is more than just a health food store. It presents a plethora
          of organic food items, along with traditional farm-fresh fare, encompassing an extensive variety of bulk food
          selections such as beans, seeds, lentils, oats, and much more.
        </p>
        <p>
          Delve deeper into diverse inventory and uncover vegan supplements, an impressive collection of over 100
          medicinal plants, and the most extensive assortment of honey in the region.
        </p>
        <p>
          <span>Zdravi Bili</span> and <span>Zdravi Đir</span> are two charming family-owned health food boutiques
          nestled just a stone's throw away from the bustling Green Market. Indulge in an array of wholesome offerings,
          from organic produce and plant-based proteins like seitan and tofu, to natural body care products and
          nourishing supplements. Delight your palate with Zdravi Đir's freshly baked vegan or sugar-free pastries,
          crafted with love and care.
        </p>
        <p>
          Embark on a culinary journey through our health food stores and vegetarian eateries in Split, and we guarantee
          you'll be satiated and satisfied throughout your stay.
        </p>
      </div>
    </div>
  );
};

export default Vege;
