import React, { useState } from 'react';
import css from './bol-solta-island.module.scss';
import TabMenu from '../../../../components/universal/TabMenu';
import FirstPart from './description';
import { PRIVATE_TOURS, PRIVATE_TOUR_BOL_SOLTA } from '../../../../data/private-tours';
import PrivateToursForm from '../../../../components/universal/PrivateToursForm';
import { BANNER_IMAGES } from '../../../../utils/images/private-tours';
import MotionDiv from '../../../../components/universal/MotionDiv';
import TourItinerary from '../../../../components/universal/TourItinerary';
import TourLocation from '../../../../components/universal/TourLocation';
import TripadvisorReviews from '../../../../components/universal/TripadvisorReviews';
import TEXT from '../../../../json/pages/pt-bol-solta.json';
import Img from '../../../../components/universal/Img';
import YellowPriceTag from '../../../../components/desktop/YellowPriceTag';
import OtherTours from '../../../../components/desktop/OtherTours';

const BlueLagoonThreeIslands = () => {
  const [activeTab, setActiveTab] = useState(0);

  const mainContent = {
    0: <FirstPart />,
    1: <TourItinerary textItinerary={TEXT.itinerary} />,
    2: <TourLocation />,
    3: <TripadvisorReviews />,
  };

  return (
    <MotionDiv className={css.privateToursPageLayout}>
      <div className={css.bannerWrapper}>
        <Img
          src={BANNER_IMAGES.bol_solta_island.src}
          alt={BANNER_IMAGES.bol_solta_island.alt}
          className={css.bannerImg}
        />
        <div className={css.filter} />
        <h1>BOL & ŠOLTA ISLAND</h1>
        <YellowPriceTag price={PRIVATE_TOUR_BOL_SOLTA.price} />
      </div>

      <div className={css.contentContainer}>
        <TabMenu
          menuItems={['Description', 'Tour Info', 'Location', 'Reviews']}
          activeTab={activeTab}
          onClick={setActiveTab}
        />
      </div>

      <div className={css.mainContentWrapper}>
        <div>{mainContent[activeTab]}</div>

        <div className={css.contentRight}>
          <h2>BOOK A PRIVATE TOUR</h2>
          <PrivateToursForm firstRowColumn secondRowColumn type="BOL & ŠOLTA ISLAND" />
        </div>
      </div>

      <div className={css.deviderContainer}>
        <div className={css.devider} />
      </div>

      <div className={css.otherTours}>
        <h2>OTHER TOURS</h2>
        <OtherTours data={PRIVATE_TOURS} fromPrice baseUrl="private-tours" />
      </div>
    </MotionDiv>
  );
};

export default BlueLagoonThreeIslands;
