import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import css from './three-islands.module.scss';
import { BANNER_IMAGES } from '../../../../utils/images/group-tours';
import TabMenu from '../../../../components/universal/TabMenu';
import BookingForm from '../../../../components/universal/BookingFormWithStripe';
import { GROUP_TOURS, HVAR_AND_BOL_WITH_PAKLENI_ISLANDS } from '../../../../data/group-tours';
import FirstPart from './description';
import GroupToursCard from '../../../../components/universal/GroupToursCard';
import { IconWhiteTelephone } from '../../../../assets/icons';
import MotionDiv from '../../../../components/universal/MotionDiv';
import TourItinerary from '../../../../components/universal/TourItinerary';
import TourLocation from '../../../../components/universal/TourLocation';
import TripadvisorReviews from '../../../../components/universal/TripadvisorReviews';
import TEXT from '../../../../json/pages/hvar-and-bol-and-pakleni-islands.json';
import Img from '../../../../components/universal/Img';

const GroupTureSinglePageThreeIslands = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const otherTours = useMemo(() => _.filter(GROUP_TOURS, (tour) => !pathname.includes(tour.slug)), [pathname]);

  const mainContent = {
    0: <FirstPart />,
    1: <TourItinerary textItinerary={TEXT.itinerary} />,
    2: <TourLocation />,
    3: <TripadvisorReviews />,
  };

  return (
    <MotionDiv className={css.threeIslandsMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img
          src={BANNER_IMAGES.hvar_bol_pakleni.src}
          alt={BANNER_IMAGES.hvar_bol_pakleni.alt}
          className={css.bannerImg}
        />
        <div className={css.filter} />
        <h1>HVAR AND BOL TOUR WITH PAKLENI ISLANDS</h1>
      </div>

      <TabMenu
        menuItems={['Description', 'Itinerary', 'Location', 'Reviews']}
        activeTab={activeTab}
        onClick={setActiveTab}
      />

      <div className={css.contentContainer}>
        {mainContent[activeTab]}

        <div className={css.formWrapper}>
          <div className={css.phoneContainer}>
            <IconWhiteTelephone />
            <a href="tel:+385958267981">Call center: +385 95 826 7981</a>
          </div>
          <BookingForm
            type={1}
            tourName={HVAR_AND_BOL_WITH_PAKLENI_ISLANDS.name}
            startPassengersPrice={HVAR_AND_BOL_WITH_PAKLENI_ISLANDS.price}
          />
        </div>

        <div className={css.otherTours}>
          <h2>OTHER TOURS</h2>
          <div className={css.cardsContainer}>
            <div className={css.slider}>
              {otherTours.map((groupTour, index) => (
                <GroupToursCard
                  link={`/group-tours${groupTour.slug}`}
                  className={css.card}
                  key={`group-tour-card-${index}`}
                  name={groupTour.name}
                  photo={groupTour.photo}
                  description={groupTour.description}
                  views={groupTour.views}
                  duration={groupTour.duration}
                  price={groupTour.price}
                  onButtonClick={() => () => navigate(`/group-tours${groupTour.slug}`)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default GroupTureSinglePageThreeIslands;
