export const SOCIAL_MEDIA_LINKS = {
  instagram: 'https://www.instagram.com/excursion_dalmatia_split/',
  facebook: 'https://www.facebook.com/ExcursionDalmatia/',
  youtube: 'https://www.youtube.com/@ExcursionDalmatia',
  tripadvisor:
    'https://www.tripadvisor.com/Attraction_Review-g295370-d17691782-Reviews-Excursion_Dalmatia-Split_Split_Dalmatia_County_Dalmatia.html',
  whatsapp: 'https://api.whatsapp.com/send?phone=385958267981',
  googleplus:
    'https://www.google.com/localservices/profile?spp=Cg0vZy8xMWtjOGx5Y3B4&scp=ChVnY2lkOmJvYXRfdG91cl9hZ2VuY3kaEmV4Y3Vyc2lvbiBkYWxtYXRpYSISZXhjdXJzaW9uIGRhbG1hdGlhKhJleGN1cnNpb24gZGFsbWF0aWE%3D',
};

export const WHATSAPP_MOBILE = '385958267981';

export const NAVIGATION = {
  header: [
    {
      name: 'Private Tours',
      route: '/private-tours',
    },
    {
      name: 'Group Tours',
      route: '/group-tours',
    },
    {
      name: 'Transfers',
      route: '/transfers',
    },
    {
      name: 'Blog',
      route: '/blog',
    },
    {
      name: 'Contact',
      route: '/contact',
    },
    {
      name: 'About Us',
      route: '/about-us',
    },
  ],

  footer: {
    trips: [
      {
        name: 'Blue Cave Tour',
        route: '/group-tours/five-islands-tour-and-blue-cave',
      },
      {
        name: 'Bol & Hvar tour',
        route: '/group-tours/hvar-and-bol-tours-with-pakleni-islands',
      },
      {
        name: 'Half Day Tour',
        route: '/group-tours/three-islands-half-day-tour-trogir-blue-lagoon-ciovo',
      },
      {
        name: 'Three Islands Tour',
        route: '/group-tours/three-islands-tour-shipwreck-blue-lagoon-maslinica',
      },
      {
        name: 'Private Tours',
        route: '/private-tours',
      },
    ],
    sitemap: [
      {
        name: 'About Us',
        route: '/about-us',
      },
      {
        name: 'Weather',
        route: '/weather-in-croatia',
      },
      {
        name: 'Privacy Policy',
        route: '/privacy-policy',
      },
      {
        name: 'Cookie Policy',
        route: '/cookie-policy',
      },
      {
        name: 'FAQ',
        route: '/faq',
      },
    ],
  },
};

export const BOAT_PRICES = {
  'Budget option': 0,
  'Standard option': 10,
  'Deluxe option': 20,
};

export const BOAT_OPTIONS = [
  {
    value: 'Budget option',
    name: 'Budget option (+0€ per passenger)',
  },
  {
    value: 'Standard option',
    name: 'Standard option (+10€ per passenger) ',
  },
  {
    value: 'Deluxe option',
    name: 'Deluxe option (+20€ per passenger)',
  },
];

export const FORM_TYPE = {
  baciscBookingForm: 'Booking Form',
  contactPageForm: 'Contact Form',
};
