import React from 'react';
import classNames from 'classnames';
import css from './tab-menu.module.scss';

const TabMenu = ({ menuItems, onClick, activeTab }) => {
  return (
    <div className={css.tabsContainer}>
      <ul className={css.list}>
        {menuItems.map((menuItem, index) => (
          <li
            key={`menu-item-${index}`}
            className={classNames(css.listItem, { [css.listItemActive]: activeTab === index })}
            onClick={() => onClick(index)}
          >
            {menuItem}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabMenu;
