import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PK } from '../../../config/env';
import { payWithStripe } from '../../../helpers/stripe';
import css from './stripe-form.module.scss';

const CheckoutForm = ({ handleForm, amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    setIsLoading(true);
    if (result.error) {
      handleForm(result);
    } else {
      const { id: tokenId } = result.token;
      const response = await payWithStripe({ tokenId, amount, currency: 'EUR' });
      handleForm(response);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className={css.stripeContainer}>
      <CardElement className={css.cardElement} />
      <button className={css.button} type="submit" disabled={!stripe || isLoading}>
        {isLoading ? 'Loading' : 'Pay'}
      </button>
    </form>
  );
};

const stripePromise = loadStripe(STRIPE_PK);

const StripeForm = ({ handleForm, amount }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm handleForm={handleForm} amount={amount} />
    </Elements>
  );
};

export default StripeForm;
