import React from 'react';
import css from './nightlife.module.scss';
import Img from '../../../components/universal/Img';
import { NIGHTLIFE } from '../../../utils/images/blogs';

const Nightlife = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>Nightlife in Split: The Best Places for fun night out</h2>
        <p>
          Split's vibrant nightlife caters to those seeking nocturnal excitement, ensuring they never tire of the city's
          after-dark offerings. With an array of options, including enticing bars, bustling cafes, energetic clubs,
          lively discos, cozy pubs, and captivating live music, there is something to suit every preference. We will
          guide you through the finest establishments, beach parties, and festivals to guarantee an evening of enjoyment
          in this dynamic city.
        </p>
      </div>
      <div className={css.box}>
        <h3>NIGHTLIFE IN SPLIT - WARM UP</h3>
        <p>
          For those who enjoy a bit of pre-gaming before diving into the club scene, the city of Split offers a variety
          of enticing options. Within the historic Diocletian's Palace and along the picturesque promenade, you will
          find an array of popular bars such as Inbox, Rooftop 68, Paradiso, Luxor, and Antique.
        </p>
        <p>
          As night falls, locals tend to gravitate towards the more affordable Matejuška and Split's “west coast” areas,
          where the cost of enjoying a beer with friends on a bench is significantly lower than at the pricier café bars
          in the city's center. Whatever your preference, these lively destinations provide the perfect warm-up to a
          night of dancing in Split.
        </p>
      </div>
      <div className={css.box}>
        <h3>VANILLA CLUB</h3>
        <p>
          Located in the heart of Split, the Vanilla Club stands as the city's premier nightlife destination, promising
          an unforgettable evening of excitement and entertainment for all who enter.
        </p>
        <p>
          As a celebrated hotspot among Split's party enthusiasts, the Vanilla Club has become synonymous with thrilling
          summer nights and unrivaled fun. For those seeking to immerse themselves in the city's most exhilarating
          party, look no further than this iconic venue.
        </p>
        <p>
          Over the years, the Vanilla Club has continuously evolved and expanded, offering a diverse array of services
          and experiences within its spacious confines. To maintain an upscale atmosphere, a dress code is enforced for
          all patrons. While entry is typically complimentary, exceptions may be made for special events featuring
          renowned local and international artists.
        </p>
        <Img src={NIGHTLIFE.vanilla.src} alt={NIGHTLIFE.vanilla.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>CENTRAL</h3>
        <p>
          Experience an unforgettable night at Central Club, featuring one of the finest sound systems available on the
          market. This exceptional venue offers a spacious dance floor, prompt service, and the best music in town,
          ensuring a memorable and enjoyable experience for all patrons.
        </p>
        <p>
          Central Club, a former cinema, has undergone a complete 360-degree transformation, now boasting a two-story
          nightclub with four bars, VIP lodges, and state-of-the-art sound equipment. Throughout the summer, the club is
          open nightly and is renowned for hosting various themed events and special nights.
        </p>
        <p>
          The diverse music selection is enhanced by the talents of professional performers, renowned DJs, and skilled
          acrobats, creating a unique clubbing experience unlike any other. Located in the heart of Split, Central
          Club's captivating interior design further adds to the extraordinary atmosphere of this nightlife hotspot.
          Best of all, entrance to the club is free for women. Guys have to pay entrance fee.
        </p>
        <Img src={NIGHTLIFE.central.src} alt={NIGHTLIFE.central.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>FABRIQUE PUB</h3>
        <p>
          Experience the perfect blend of coffee and cocktails, barbecue and pub fare at Fabrique, a one-of-a-kind
          establishment in the heart of the city. Our innovative menu seamlessly marries classic pub favorites with
          contemporary flair and a touch of Croatian influence for a truly unique dining experience.
        </p>
        <p>
          Elegant yet inviting, Fabrique effortlessly transitions from a bustling restaurant during the day to a lively
          club at night. Guests are immediately drawn to the chic industrial-style interior, while the prime waterfront
          location offers stunning views and an unbeatable atmosphere.
        </p>
        <p>
          Catering to a discerning crowd primarily in the 21+ age range, Fabrique has quickly become a favorite
          destination for those seeking an upscale, unforgettable experience.
        </p>
        <Img src={NIGHTLIFE.fabrique.src} alt={NIGHTLIFE.fabrique.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>BAČVICE BEACH</h3>
        <p>
          Bačvice Beach is a premier destination for those seeking sun, sand, and unforgettable nightlife experiences.
          As one of the city's most popular sandy beaches, it offers the perfect setting for both daytime relaxation and
          evening festivities, especially during the summer months.
        </p>
        <p>
          Often playing host to a variety of music festivals, Bačvice Beach boasts an array of clubs and bars that line
          its shoreline. Among the most notable venues are Tortuga Grill & Pub, Caffe-Club Bačvice, and La Playa Beach
          Bar & Club, each offering a unique atmosphere guaranteed to please visitors from all walks of life.
        </p>
        <p>
          As a beloved gathering spot for both locals and tourists alike, Bačvice Beach is the ideal location for
          meeting new friends from around the world. Whether you're a visitor seeking the best Split has to offer or a
          resident enjoying a night out on the town, look no further than the vibrant clubs and bars of Bačvice Beach.
        </p>
        <Img src={NIGHTLIFE.bacvice.src} alt={NIGHTLIFE.bacvice.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>ULTRA FESTIVAL</h3>
        <p>
          Discussing nightlife in Split would be incomplete without mentioning the Ultra Music Festival, the largest
          music festival in this region of Europe. Held annually, this spectacular event attracts visitors from over 140
          countries, with only a third of the festival-goers being locals. The impressive attendance at the Ultra Music
          Festival in Split reaches up to 150,000 visitors.
        </p>
        <p>
          Featuring a top-tier lineup of the world's finest EDM, house, and techno music DJs, the festival offers an
          incredible combination of remarkable production and unparalleled stage designs.
        </p>
        <p>
          Split, a renowned tourist destination, is home to Diocletian's Palace, a rich cultural history, numerous
          beaches, and mid-Adriatic islands, making it the perfect setting for one of the world's most unforgettable
          summer parties.
        </p>
        <Img src={NIGHTLIFE.ultra.src} alt={NIGHTLIFE.ultra.alt} className={css.imgSingle} />
      </div>
    </div>
  );
};

export default Nightlife;
