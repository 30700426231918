import React, { useState } from 'react';
import css from './contact.module.scss';
import { FORM_TYPE, SOCIAL_MEDIA_LINKS } from '../../../utils/constants';
import { BANNERS } from '../../../utils/images';
import { useForm } from 'react-hook-form';
import {
  IconEmail,
  IconFacebookDark,
  IconGooglePlusDark,
  IconInstagramDark,
  IconTripadvisorDark,
  IconUser,
  IconWhatsAppDark,
  IconYoutubeDark,
} from '../../../assets/icons';
import DIAL_NUMBERS from '../../../json/dial-numbers.json';
import Select from '../../../components/universal/Select';
import InputField from '../../../components/universal/InputField';
import Textarea from '../../../components/universal/Textarea';
import Button from '../../../components/universal/Button';
// notifications
import { toast } from 'react-toastify';
import sendEmailJs from '../../../helpers/email';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      passengers: 1,
    },
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    // Send mail
    const { success, message } = await sendEmailJs({ ...values, type: FORM_TYPE.contactPageForm });
    if (success) toast.success(message);
    else toast.error(message);
    setIsLoading(false);
  };
  return (
    <MotionDiv className={css.contactMobileLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.contact_mobile.src} alt="excursion dalmatia contact page" className={css.bannerImg} />
        <div className={css.filter} />
      </div>

      <div className={css.contentContainer}>
        {/* INFO */}
        <div className={css.info}>
          <h2>BOOK ONLINE</h2>
          <a href="mailto:excursion.dalmatia@gmail.com">excursion.dalmatia@gmail.com</a>
          <p style={{ marginBottom: '28px' }}>We offer 24/7 web booking service</p>
          <h2>CALL US</h2>
          <a href="tel:+385958267981">
            <span>Phone:</span> +385 95 826 7981
          </a>
          <a href="mailto:excursion.dalmatia@gmail.com">
            <span>Email:</span> excursion.dalmatia@gmail.com
          </a>
          <p style={{ marginBottom: '16px' }}>
            If you have any questions or need any help, feel free to contact us on email, viber, whatsapp or call us on
            +385 95 826 7981. Our staff will gladly answer on all your questions.
          </p>
          <div className={css.socialMedia}>
            <a href={SOCIAL_MEDIA_LINKS.facebook} target="_blank" rel="noreferrer">
              <IconFacebookDark />
            </a>
            <a href={SOCIAL_MEDIA_LINKS.instagram} target="_blank" rel="noreferrer">
              <IconInstagramDark />
            </a>
            <a href={SOCIAL_MEDIA_LINKS.youtube} target="_blank" rel="noreferrer">
              <IconYoutubeDark />
            </a>
            <a href={SOCIAL_MEDIA_LINKS.tripadvisor} target="_blank" rel="noreferrer">
              <IconTripadvisorDark />
            </a>
            <a href={SOCIAL_MEDIA_LINKS.googleplus} target="_blank" rel="noreferrer">
              <IconGooglePlusDark />
            </a>
            <a href={SOCIAL_MEDIA_LINKS.whatsapp} target="_blank" rel="noreferrer">
              <IconWhatsAppDark />
            </a>
          </div>
        </div>
        {/* FORM */}
        <form className={css.formWrapper} onSubmit={handleSubmit(onSubmit)}>
          <InputField
            errors={errors}
            icon={<IconUser />}
            name="name"
            register={register}
            placeholder="Name and surname"
          />
          <InputField
            errors={errors}
            icon={<IconEmail />}
            name="email"
            type="email"
            register={register}
            placeholder="Email address"
          />
          <Select errors={errors} name="countryCode" register={register} options={DIAL_NUMBERS} setValue={setValue} />
          <InputField errors={errors} type="number" name="mobile" register={register} placeholder="Phone number" />
          <Textarea className={css.textarea} errors={errors} name="message" register={register} placeholder="Message" />
          <Button className={css.buttonSubmit} type="submit" isLoading={isLoading} />
        </form>
      </div>
    </MotionDiv>
  );
};

export default Contact;
