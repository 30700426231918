import React, { useEffect } from 'react';
import css from './cookie.module.scss';
import ImgCookie from './cookie.png';
import { useState } from 'react';
import Button from '../Button';
import { IconExit } from '../../../assets/icons';
import Cookies from 'js-cookie';
import Img from '../Img';

const COOKIE_NAME = 'excurions-dalmatia-cookie';

const Cookie = () => {
  const [showCookieModal, setShowCookieModal] = useState(Cookies.get(COOKIE_NAME) !== 'true');

  const onButtonClick = () => {
    Cookies.set(COOKIE_NAME, true, { expires: 365 });
    setShowCookieModal(false);
  };

  const onExitClick = () => {
    Cookies.set(COOKIE_NAME, false, { expires: 365 });
    setShowCookieModal(false);
  };

  useEffect(() => {
    if (showCookieModal) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'auto';
  }, [showCookieModal]);

  if (showCookieModal) {
    return (
      <div className={css.modalContainer}>
        <div className={css.cookieWrapper}>
          <IconExit className={css.iconExit} onClick={onExitClick} />
          <Img src={ImgCookie} alt="cookie" className={css.image} />
          <p>We use cookies to make your experience better!</p>
          <Button text="OK" onClick={onButtonClick} className={css.button} />
        </div>
      </div>
    );
  } else return null;
};

export default Cookie;
