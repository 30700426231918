import React, { useEffect, useRef, useState } from 'react';
import css from './group-tours.module.scss';
import GroupToursCard from '../../../components/universal/GroupToursCard';
import { GROUP_TOURS } from '../../../data/group-tours';
import { useNavigate } from 'react-router-dom';
import MotionDiv from '../../../components/universal/MotionDiv';
import { useInViewport } from 'react-in-viewport';
import classNames from 'classnames';

const GroupTours = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState(GROUP_TOURS.slice(0, 4));
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);

  useEffect(() => {
    if (inViewport) {
      setTimeout(() => {
        setIsLoaded(true);
        setItems(GROUP_TOURS);
      }, 500);
    }
  }, [inViewport]);

  return (
    <MotionDiv className={css.groupToursMobileLayout}>
      <div className={css.sliderContainer}>
        <h1>MOST POPULAR TOURS IN SPLIT</h1>
        {items.map((groupTour, index) => (
          <GroupToursCard
            key={`group-tour-card-${index}`}
            className={css.mobileCard}
            link={`/group-tours${groupTour.slug}`}
            name={groupTour.name}
            photo={groupTour.photo}
            description={groupTour.description}
            views={groupTour.views}
            duration={groupTour.duration}
            price={groupTour.price}
            onButtonClick={() => navigate(`/group-tours${groupTour.slug}`)}
            priceText={groupTour.slug === '/five-islands-tour-and-blue-cave' ? 'Price starting from:' : 'Price:'}
          />
        ))}
        <div className={classNames(css.loading, { [css.hide]: isLoaded })} ref={myRef}>
          Loading more...
        </div>
      </div>
    </MotionDiv>
  );
};

export default GroupTours;
