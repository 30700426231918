import { STRIPE_SK } from '../config/env';

export async function payWithStripe({ tokenId, amount, currency }) {
  const response = await fetch('https://api.stripe.com/v1/charges', {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${STRIPE_SK}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    body: new URLSearchParams({
      amount,
      currency,
      source: tokenId,
    }),
  });
  const data = await response.json();
  return data;
}
