import React from 'react';
import classNames from 'classnames';
import css from './button.module.scss';

const Button = ({ arialLabel = 'submit button', className, text = 'Submit', onClick, type = 'button', isLoading }) => {
  return (
    <button aria-label={arialLabel} type={type} onClick={onClick} className={classNames(css.button, className)}>
      {isLoading ? 'Loading...' : text}
    </button>
  );
};

export default Button;
