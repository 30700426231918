import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import css from '../../booking-form.module.scss';
import Button from '../../../Button';
import { useForm } from 'react-hook-form';
import InputField from '../../../InputField';
import { IconEmail, IconUser } from '../../../../../assets/icons';
import Select from '../../../Select';
import DIAL_NUMBERS from '../../../../../json/dial-numbers.json';
import currencyFormatterHelper from '../../../../../helpers/currency-formater';
import InputNumberField from '../../../InputNumberField';
import DatePicker from '../../../DatePicker';
import { toast } from 'react-toastify';

const FormTypeTwo = ({ className, handleForm, startPassengersPrice = 110.0 }) => {
  const ADULTS_PRICE = startPassengersPrice;
  const CHILDREN_0_3_PRICE = 0;
  const CHILDREN_4_12_PRICE = startPassengersPrice / 2;

  // total price
  const [totalPrice, setTotalPrice] = useState(startPassengersPrice);
  const [passengers, setPassengers] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {
      adults: 1,
      children0to3: 0,
      children4to12: 0,
    },
  });

  const onFormSubmit = async (values) => {
    if (!parseInt(values.adults, 10)) {
      toast.error('At least one adult!');
    } else {
      handleForm({
        ...values,
        departureDate: values.departureDate.toISOString(),
        totalPrice,
        passengers,
      });
    }
  };

  // UPDATE TOTAL PRICE
  useEffect(() => {
    setTotalPrice(
      parseInt(watch('adults'), 10) * ADULTS_PRICE +
        parseInt(watch('children0to3'), 10) * CHILDREN_0_3_PRICE +
        parseInt(watch('children4to12'), 10) * CHILDREN_4_12_PRICE
    );
    setPassengers(
      parseInt(watch('adults'), 10) + parseInt(watch('children0to3'), 10) + parseInt(watch('children4to12'), 10)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('adults'), watch('children0to3'), watch('children4to12')]);

  return (
    <div>
      <div className={css.totalPriceContainer}>TOTAL: {currencyFormatterHelper(totalPrice)}</div>
      <form className={classNames(css.formWrapper, className)} onSubmit={handleSubmit(onFormSubmit)}>
        <InputField
          errors={errors}
          icon={<IconUser />}
          name="name"
          register={register}
          placeholder="Your name and surname"
        />
        <InputField
          errors={errors}
          icon={<IconEmail />}
          name="email"
          type="email"
          register={register}
          placeholder="Email address"
        />
        <Select errors={errors} name="countryCode" register={register} options={DIAL_NUMBERS} setValue={setValue} />
        <InputField
          errors={errors}
          type="number"
          name="mobile"
          register={register}
          placeholder="Phone number"
          rules={{
            required: true,
            validate: (value) => /^.{4,12}$/.test(value) || 'Mobile phone is too long or too short',
          }}
        />
        <DatePicker
          control={control}
          errors={errors}
          name="departureDate"
          type="text"
          register={register}
          placeholder="Departure time"
        />

        <div className={css.passengers}>
          <p className={css.passengersTitle}>ADULTS (13-85 years)</p>
          <div className={css.singleRow}>
            <InputNumberField
              errors={errors}
              name="adults"
              type="number"
              register={register}
              className={css.numberField}
              min={1}
            />
            <p className={css.passengersPrice}>
              x {currencyFormatterHelper(ADULTS_PRICE)} ={' '}
              {currencyFormatterHelper(parseInt(watch('adults'), 10) * ADULTS_PRICE)}
            </p>
          </div>
        </div>

        <div className={css.passengers}>
          <p className={css.passengersTitle}>CHILDREN (0-3 years)</p>
          <div className={css.singleRow}>
            <InputNumberField
              errors={errors}
              name="children0to3"
              type="number"
              register={register}
              className={css.numberField}
              min={0}
            />
            <p className={css.passengersPrice}>
              x {currencyFormatterHelper(CHILDREN_0_3_PRICE)} ={' '}
              {currencyFormatterHelper(parseInt(watch('children0to3'), 10) * CHILDREN_0_3_PRICE)}
            </p>
          </div>
        </div>

        <div className={css.passengers}>
          <p className={css.passengersTitle}>CHILDREN (4-12 years)</p>
          <div className={css.singleRow}>
            <InputNumberField
              errors={errors}
              name="children4to12"
              type="number"
              register={register}
              className={css.numberField}
              min={0}
            />
            <p className={css.passengersPrice}>
              x {currencyFormatterHelper(CHILDREN_4_12_PRICE)} ={' '}
              {currencyFormatterHelper(parseInt(watch('children4to12'), 10) * CHILDREN_4_12_PRICE)}
            </p>
          </div>
        </div>

        <Button type="submit" text="Book now" />
      </form>
    </div>
  );
};

export default FormTypeTwo;
