const {
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_ACCUWEATHER_API_KEY,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_TEMPLATE_ID,
  REACT_APP_EMAILJS_PUBLIC_KEY,
  REACT_APP_IS_LOCALHOST,
  REACT_APP_STRIPE_PK,
  REACT_APP_STRIPE_SK,
} = process.env;

module.exports = {
  GOOGLE_API_KEY: REACT_APP_GOOGLE_API_KEY,
  ACCUWEATHER_API_KEY: REACT_APP_ACCUWEATHER_API_KEY,
  EMAILJS_SERVICE_ID: REACT_APP_EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID: REACT_APP_EMAILJS_TEMPLATE_ID,
  EMAILJS_PUBLIC_KEY: REACT_APP_EMAILJS_PUBLIC_KEY,
  IS_LOCALHOST: Boolean(parseInt(REACT_APP_IS_LOCALHOST, 10)),
  STRIPE_PK: REACT_APP_STRIPE_PK,
  STRIPE_SK: REACT_APP_STRIPE_SK,
};
