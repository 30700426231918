import { GROUP_TOURS_CARDS } from '../../utils/images/group-tours';

// SINGLE INFO
export const BLUE_CAVE_FIVE_ISLANDS = {
  photo: GROUP_TOURS_CARDS.blue_cave,
  cardPhoto: GROUP_TOURS_CARDS.blue_cave,
  name: 'BLUE CAVE & 5 ISLANDS TOUR',
  description: 'Visit renowned Blue Cave and explore hidden gems of Vis and Hvar archipelago.',
  views: 456,
  duration: 10.5,
  price: 110,
  slug: '/five-islands-tour-and-blue-cave',
};

export const HVAR_AND_BOL_WITH_PAKLENI_ISLANDS = {
  photo: GROUP_TOURS_CARDS.hvar_and_bol,
  cardPhoto: GROUP_TOURS_CARDS.hvar_and_bol,
  name: 'HVAR AND BOL TOUR WITH PAKLENI ISLANDS',
  description: "Visit the world's famous Zlatni rat beach with the sunniest Croatian island Hvar.",
  views: 1234,
  duration: 4.5,
  price: 120,
  slug: '/hvar-and-bol-tours-with-pakleni-islands',
};

export const THREE_ISLANDS_HALF_DAY = {
  photo: GROUP_TOURS_CARDS.three_islands_half_day,
  cardPhoto: GROUP_TOURS_CARDS.three_islands_half_day,
  name: '3 ISLANDS HALF DAY TOUR: TROGIR, BLUE LAGOON, ČIOVO',
  description: 'Swim and snorkel in paradise Blue Lagoon and visit UNESCO protected town of Trogir.',
  views: 1100,
  duration: 4.5,
  price: 65,
  slug: '/three-islands-half-day-tour-trogir-blue-lagoon-ciovo',
};

export const THREE_ISLANDS_SHIPWRECK = {
  photo: GROUP_TOURS_CARDS.three_islands,
  cardPhoto: GROUP_TOURS_CARDS.three_islands,
  name: '3 ISLANDS TOUR: SHIPWRECK, BLUE LAGOON, MASLINICA',
  description:
    'Cool down in chrystal clear turquoise sea of Blue Lagoon, go snorkeling and discover the shipwreck in Nečujam bay.',
  views: 2001,
  duration: 8.5,
  price: 60,
  slug: '/three-islands-tour-shipwreck-blue-lagoon-maslinica',
};

export const BUDGET_FIVE_ISLANDS = {
  photo: GROUP_TOURS_CARDS.budget_five_islands,
  cardPhoto: GROUP_TOURS_CARDS.budget_five_islands,
  name: 'BUDGET FIVE ISLANDS TOUR',
  description:
    'Budget 5 Islands Tour provides the most affordable way to discover the stunning mid-Adriatic islands, including Hvar and Vis.',
  views: 456,
  duration: 10.5,
  price: 110,
  slug: '/budget-five-islands-tour',
};

export const STANDARD_FIVE_ISLANDS = {
  photo: GROUP_TOURS_CARDS.standard_five_islands,
  cardPhoto: GROUP_TOURS_CARDS.standard_five_islands,
  name: 'STANDARD FIVE ISLANDS TOUR',
  description:
    'Enjoy more space and protection from inclement weather as you explore the stunning mid-Adriatic islands, including Blue Cave.',
  views: 456,
  duration: 10.5,
  price: 120,
  slug: '/standard-five-islands-tour',
};

export const DELUXE_FIVE_ISLANDS = {
  photo: GROUP_TOURS_CARDS.deluxe_five_islands,
  cardPhoto: GROUP_TOURS_CARDS.deluxe_five_islands,
  name: 'DELUXE FIVE ISLANDS TOUR',
  description:
    'Deluxe 5 Islands Tour provides the most comfortable and stylish way to explore the stunning scenery of Vis and Hvar archipelago.',
  views: 2344,
  duration: 10.5,
  price: 130,
  slug: '/deluxe-five-islands-tour',
};

// GROUPED INFO
export const MOST_POPULAR_GROUP_TOURS = [
  BLUE_CAVE_FIVE_ISLANDS,
  THREE_ISLANDS_HALF_DAY,
  HVAR_AND_BOL_WITH_PAKLENI_ISLANDS,
  THREE_ISLANDS_SHIPWRECK,
  BUDGET_FIVE_ISLANDS,
  STANDARD_FIVE_ISLANDS,
  DELUXE_FIVE_ISLANDS,
];

export const GROUP_TOURS = [
  HVAR_AND_BOL_WITH_PAKLENI_ISLANDS,
  THREE_ISLANDS_HALF_DAY,
  THREE_ISLANDS_SHIPWRECK,
  BLUE_CAVE_FIVE_ISLANDS,
  BUDGET_FIVE_ISLANDS,
  STANDARD_FIVE_ISLANDS,
  DELUXE_FIVE_ISLANDS,
];

export const OTHER_TOURS_HVAR_AND_BOL_WITH_PAKLENI_ISLANDS = [
  THREE_ISLANDS_HALF_DAY,
  THREE_ISLANDS_SHIPWRECK,
  BLUE_CAVE_FIVE_ISLANDS,
];

export const OTHER_TOURS_THREE_ISLANDS_HALF_DAY = [
  HVAR_AND_BOL_WITH_PAKLENI_ISLANDS,
  THREE_ISLANDS_SHIPWRECK,
  BLUE_CAVE_FIVE_ISLANDS,
];

export const OTHER_TOURS_THREE_ISLANDS_SHIPWRECK = [
  HVAR_AND_BOL_WITH_PAKLENI_ISLANDS,
  THREE_ISLANDS_HALF_DAY,
  BLUE_CAVE_FIVE_ISLANDS,
];

export const OTHER_TOURS_BLUE_CAVE_FIVE_ISLANDS = [
  HVAR_AND_BOL_WITH_PAKLENI_ISLANDS,
  THREE_ISLANDS_HALF_DAY,
  THREE_ISLANDS_SHIPWRECK,
];
