import React, { useMemo } from 'react';
import css from './google-map.module.scss';
import { GOOGLE_API_KEY } from '../../../config/env';
import classNames from 'classnames';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';

const SimpleGoogleMap = ({ className, lng, lat, zoom = 20 }) => {
  const center = useMemo(() => ({ lat, lng }), [lng, lat]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  if (!isLoaded) return <div className={classNames(className, css.googleMapContainer)}>Loading...</div>;

  return (
    <GoogleMap zoom={zoom} center={center} mapContainerClassName={classNames(className, css.googleMapContainer)}>
      <MarkerF draggable={false} position={center} />
    </GoogleMap>
  );
};

export default SimpleGoogleMap;
