import currencyFormatter from 'currency-formatter';

const settings = {
  code: 'EUR',
  symbol: '€',
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbolOnLeft: false,
  spaceBetweenAmountAndSymbol: false,
  decimalDigits: 2,
};

const currencyFormatterHelper = (value) => currencyFormatter.format(value, settings);

export default currencyFormatterHelper;
