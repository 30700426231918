import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { BLUE_LAGOON_FOUR_ISLANDS } from '../../../../../utils/images/private-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={BLUE_LAGOON_FOUR_ISLANDS.important_bring} alt="Important to bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and crew.</p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const FirstPart = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: (
      <Img src={BLUE_LAGOON_FOUR_ISLANDS.overview} alt="overview" className={classNames(css.overviewImg, css.fadeIn)} />
    ),
    1: <ImportantTab />,
    2: <Gallery images={BLUE_LAGOON_FOUR_ISLANDS.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Full day tour: Blue Lagoon & 4 islands tour</h2>
        <p>
          On our private full day tour to Blue Lagoon & 4 islands you can discover the UNESCO listed town of Trogir with
          its fortresses, churches, palaces, and narrow streets.
        </p>
        <p>Relax while swimming, snorkeling, and sunbathing in the crystal-clear waters of the Blue Lagoon.</p>
        <p>Explore Maslinica on the island of Šolta, with lots of restaurants and beautiful beaches.</p>
        <p>
          Visit Nečujam, a famous snorkeling spot with a sunken ship. Keep your eyes wide open for the chance to spot
          dolphins during your boat ride.
        </p>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.textBox}>
        <p className={css.specialInfo}>
          Choose four of 5 destinations where do you want to go on our private half day tour
        </p>
        <h2>Private Full day Tour: Shipwreck</h2>
        <p>
          Nečujam, the youngest settlement on Šolta Island is known for a stunning pebble beach surrounded by a luscious
          pine tree forest that provides natural shade on hot summer days.
        </p>
        <p>
          Be sure to take advantage of the crystal-clear waters by swimming and snorkeling around the famous Kontesa
          shipwreck! The shallow depth of only five meters allows you to jump right in and explore this amazing
          underwater attraction.
        </p>
        <p>
          We provide all the necessary snorkeling equipment for free to make sure you have the best experience here.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.shipwreck_1} alt="Shipwreck 1" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.shipwreck_2} alt="Shipwreck 2" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.shipwreck_3} alt="Shipwreck 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Full day Tour: Maslinica</h2>
        <p>
          Come to Maslinica, the only settlement on the west coast of Šolta island, and experience the beauty of island
          life. This secluded settlement is famed for its production of olive oil, and its olive gardens are a sight to
          behold.
        </p>
        <p>Take a walk through the old fisherman's village and marvel at the simplicity of life before.</p>
        <p>
          Enjoy the beach, swim or snorkel at the most popular pebble beach, or visit Martinis Marchi- a 300-year-old
          castle that has been restored and converted into a hotel.
        </p>
        <p>
          After a day exploring, refuel at one of the local restaurants close to the shore, and finish your day with a
          coffee at the wooden bar.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.maslinica_1} alt="Maslinica 1" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.maslinica_2} alt="Maslinica 2" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.maslinica_3} alt="Maslinica 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Full day Tour: Blue Lagoon</h2>
        <p>
          The stunning Blue Lagoon is a must-visit destination if you're looking for an unforgettable underwater
          adventure.
        </p>
        <p>
          With its crystal-clear turquoise waters, endless sunshine, and sandy seabed, this tropical paradise is the
          ideal spot to explore the colorful underwater world.
        </p>
        <p>
          Whether you want to swim, snorkel, lounge in the sun, or just float peacefully among the sea creatures, at
          Blue Lagoon you can do it all.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.blue_lagoon_1} alt="Blue Lagoon 1" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.blue_lagoon_2} alt="Blue Lagoon 2" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.blue_lagoon_3} alt="Blue Lagoon 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Full day Tour: Laganini beach bar on island Čiovo</h2>
        <p>
          Island of Čiovo and its famous Duga Bay is a hidden natural gem, ideal for all those in love with a quiet and
          peaceful day in nature surrounded by lush greenery.
        </p>
        <p>
          Relax in the shade and take a cocktail at the laganini beach bar, or swim and snorkel in the chrystal clear
          water and take a deep breath in enjoying where you are.
        </p>
        <p>The unique atmosphere of this place fits perfectly in the overall feel of Blue Lagoon Tour from Split.</p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.ciovo_1} alt="Laganini beach 1" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.ciovo_2} alt="Laganini beach 2" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.ciovo_3} alt="Laganini beach 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Private Full day Tour: Trogir</h2>
        <p>
          Trogir is a charming little town, a few miles from Split. It was founded in the third century B.C. by Greek
          colonists.
        </p>
        <p>
          It is a UNESCO World Heritage site, known for its unique architectural blend of Renaissance, Baroque and
          Romanesque styles. Hence its name “the Stone Beauty”.
        </p>
        <p>
          Take a stroll along the waterfront promenade. Indulge in one of Croatia’s favorite pastimes - a coffee break -
          and experience the grandeur of St. Lawrence Cathedral, the Bell Tower and the Kamerlengo Fortress.
        </p>
        <p>Explore Trogir and discover why it is a gem of the Adriatic Sea.</p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_2)}>
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.trogir_1} alt="Trogir 1" />
          <Img src={BLUE_LAGOON_FOUR_ISLANDS.trogir_2} alt="Trogir 2" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
    </div>
  );
};

export default FirstPart;
