import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import css from './header.module.scss';
import { NAVIGATION } from '../../../utils/constants';
import { LOGO } from '../../../utils/images';
import { IconExit, IconMobileMenu } from '../../../assets/icons';
import Img from '../../universal/Img';
import classNames from 'classnames';

const BLUE_ICON_PATHNAMES = ['/blog', '/private-tours', '/group-tours'];

const Header = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const openCloseMenu = (value) => {
    if (value) {
      document.body.style.overflow = 'hidden';
      setIsOpen(true);
    } else {
      document.body.style.overflow = 'auto';
      setIsOpen(false);
    }
  };

  return (
    <div className={css.headerMobile}>
      <div className={css.headerMobileContent}>
        <Link to="/" className={css.headerMobileLogo}>
          <Img src={LOGO.logo.src} alt="logo" />
        </Link>

        {!isOpen ? (
          <div onClick={() => openCloseMenu(true)} className={css.headerMobileMenu}>
            <IconMobileMenu
              className={classNames({ [css.blueIcon]: BLUE_ICON_PATHNAMES.includes(location.pathname) })}
            />
          </div>
        ) : null}
      </div>

      {isOpen ? (
        <div className={css.headerMobileMenuWrapper}>
          <div className={css.blurFilter} />
          <div className={css.headerMobileMenuWrapperContent}>
            <div onClick={() => openCloseMenu(false)} className={css.headerMobileExit}>
              <IconExit />
            </div>
            <ul className={css.navItemsWrapper}>
              {NAVIGATION.header.map((navlink) => (
                <li key={navlink.route}>
                  <Link to={navlink.route} className={css.navItem} onClick={() => openCloseMenu(false)}>
                    {navlink.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
