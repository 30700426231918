import React from 'react';
import css from './faq.module.scss';
import { BANNERS } from '../../../utils/images';
import FaqCard from '../../../components/universal/FaqCard';
import { FAQ_DATA } from '../../../data/faq';
import MotionDiv from '../../../components/universal/MotionDiv';
import Img from '../../../components/universal/Img';

const FAQ = () => {
  return (
    <MotionDiv className={css.faqPageLayout}>
      <div className={css.bannerWrapper}>
        <Img src={BANNERS.faq.src} alt="excursion dalmatia faq" className={css.bannerImg} />
        <div className={css.filter} />
      </div>

      <div className={css.contentContainer}>
        <h2>FAQ - Bookings & Payments</h2>
        <h3>Below are some of the most frequently asked questions concerning Adria Day tours and our services.</h3>

        <div className={css.faqContainer}>
          {FAQ_DATA.map((data, index) => (
            <FaqCard key={`faq-card-${index}`} title={data.title} data={data.data} />
          ))}
        </div>
      </div>
    </MotionDiv>
  );
};

export default FAQ;
