import React, { useState } from 'react';
import classNames from 'classnames';
import css from './description.module.scss';
import { BLUE_LAGOON_FIVE_ISLANDS } from '../../../../../utils/images/private-tours';
import { IconBlueCalendar, IconBlueClock, IconBlueFlag, IconBlueLocation } from '../../../../../assets/icons';
import Gallery from '../../../../../components/universal/Gallery';
import Img from '../../../../../components/universal/Img';

const ImportantTab = () => {
  return (
    <div className={classNames(css.importantContainer, css.fadeIn)}>
      <Img src={BLUE_LAGOON_FIVE_ISLANDS.important_bring} alt="Important to bring" />
      <h4>PREGNANT WOMEN & TODDLERS!!!</h4>
      <p>
        In order to have enough time in each place and visit the best islands in one day, the only way is to go with a
        speedboat.
      </p>
      <p style={{ marginBottom: '12px' }}>
        Since the trip can be a bit bumpy, especially if it's windy, we don't take pregnant women and children 3 years
        old or younger on the speedboat.
      </p>
      <h4>INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>
        Insurance, safety gear, snorkeling equipment, water are included in the price of the tour.
      </p>
      <h4>NOT INCLUDED:</h4>
      <p style={{ marginBottom: '12px' }}>Fuel and crew.</p>
      <h4>IMPORTANT:</h4>
      <p>
        Safety first! In case of bad weather or rough sea conditions,{' '}
        <span> we hold the right to change the route or cancel Blue Lagoon Tour from Split</span>, for benefit of all
        passengers. This decision is made by captain. In this case, you have the right to shift the tour for some other
        day, or get a full refund.
      </p>
    </div>
  );
};

const FirstPart = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = {
    0: (
      <Img src={BLUE_LAGOON_FIVE_ISLANDS.overview} alt="overview" className={classNames(css.overviewImg, css.fadeIn)} />
    ),
    1: <ImportantTab />,
    2: <Gallery images={BLUE_LAGOON_FIVE_ISLANDS.gallery} />,
  };

  return (
    <div className={css.container}>
      <div className={css.shortInfo}>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueFlag />
            <p>Tour type: daily</p>
          </div>
          <div>
            <IconBlueCalendar />
            <p>Availability: From 1th April</p>
          </div>
        </div>
        <div className={css.shortInfoColumn}>
          <div>
            <IconBlueClock />
            <p>Duration: 10 hours</p>
          </div>
          <div>
            <IconBlueLocation />
            <p>Location: Trg Franje Tudmana, 21 000, Split, Hrvatska</p>
          </div>
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue lagoon, Hvar, Pakleni islands, Trogir & Shipwreck</h2>
        <p>
          Escape the everyday hustle and immerse yourself in the beauty of the Dalmatian Coast with a day spent
          adventuring to some of the most picturesque locations.
        </p>
        <p>
          Board a boat and take a short ride away from Split to explore the stunning town of Trogir, bask in the sun and
          cool off in the clear blue waters at the Blue Lagoon, witness the beauty of the renowned Hvar, and marvel at
          the fourteen pristine Pakleni islands.
        </p>
        <p>
          Finally, end the day with a swim and snorkel above the awe-inspiring Kontesa shipwreck at Nečujam bay on the
          island of Šolta.
        </p>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon & 5 islands tour: Trogir</h2>
        <p>
          Come to Trogir and explore its unique charm! This magical coastal town is a one-of-a-kind destination and an
          official UNESCO World Heritage Site.
        </p>
        <p>
          Explore its narrow streets and winding pathways, which offer a maze-like journey to discover historical
          buildings, churches, and palaces. Climb the cathedral's bell tower for breathtaking views of the island! See
          why Trogir is a must-visit destination.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.trogir_1} alt="Trogir 1" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.trogir_2} alt="Trogir 2" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.trogir_3} alt="Trogir 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon & 5 islands tour: Blue Lagoon</h2>
        <p>
          Escape to the Blue Lagoon of Croatia, where stunning turquoise waters set the scene for an unforgettable
          holiday. Located near Drvenik Island, the Blue Lagoon is the perfect spot for swimming, snorkeling, and
          diving.
        </p>
        <p>
          Surrounded by breathtaking scenery, visitors can explore abundant underwater life or simply relax on the beach
          and soak in the stunning views. Whether you're looking for a tranquil getaway or an adventure, the Blue Lagoon
          will leave an everlasting impression.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.blue_lagoon_1} alt="Blue Lagoon 1" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.blue_lagoon_2} alt="Blue Lagoon 2" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.blue_lagoon_3} alt="Blue Lagoon 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon & 5 islands tour: Hvar</h2>
        <p>
          Experience the charm and beauty of Hvar - an idyllic holiday destination for celebrities and yachtsmen. With
          its eight centuries of history, it is home to the world's oldest public theater, located in the heart of the
          city.
        </p>
        <p>
          Enjoy the Mediterranean climate, known for its sunny days, and take advantage of the opportunity for swimming
          and sunbathing in the statistically the sunniest spot in Europe.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.hvar_1} alt="Hvar 1" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.hvar_2} alt="Hvar 2" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.hvar_3} alt="Hvar 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon & 5 islands tour: Pakleni islands</h2>
        <p>
          Unlock the dreamy beauty of the Pakleni Islands. A paradise of fourteen islands, hidden beaches and secluded
          lagoons, each one more enchanting than the last. Embark on a journey to the Croatian Riviera and be entranced
          by the deep blues of the Adriatic Sea and the lush pine tree forests.
        </p>
        <p>
          Discover the Palmižana Bay for yourself and take in the idyllic landscape. Spend your day swimming,
          snorkeling, or simply soaking up the sun's rays.
        </p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.pakleni_1} alt="Pakleni islands 1" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.pakleni_2} alt="Pakleni islands 2" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.pakleni_3} alt="Pakleni islands 3" />
        </div>
      </div>

      <div className={css.textBox}>
        <h2>Blue Lagoon & 5 islands tour: Shipwreck</h2>
        <p>Ready for a real adventure?</p>
        <p>
          You will have the opportunity to explore a real shipwreck. The Kontesa boat sank in 2010. and is only 3 meters
          below the surface.
        </p>
        <p>Gather your free snorkeling equipment and dive to see beautiful Kontesa.</p>
        <div className={classNames(css.imgsContainer, css.imgsContainer_3)}>
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.shipwreck_1} alt="Shipwreck 1" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.shipwreck_2} alt="Shipwreck 2" />
          <Img src={BLUE_LAGOON_FIVE_ISLANDS.shipwreck_3} alt="Shipwreck 3" />
        </div>
      </div>

      <div className={css.devider} style={{ margin: '8px 0px 40px' }} />

      <div className={css.tabsContainer}>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          OVERVIEW
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}
        >
          IMPORTANT
        </div>
        <div
          className={classNames(css.tab, {
            [css.activeTab]: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}
        >
          GALLERY
        </div>
      </div>

      {tabContent[activeTab]}
    </div>
  );
};

export default FirstPart;
