import React from 'react';
import css from './romantic-restaurants.module.scss';
import Img from '../../../components/universal/Img';
import { ROMANTIC_RESTAURANTS } from '../../../utils/images/blogs';

const RomanticRestaurants = () => {
  return (
    <div className={css.mainContainer}>
      <div className={css.box}>
        <h2>Romantic restaurants in Split</h2>
        <p>
          Embarking on a vacation with your significant other is the perfect opportunity to revel in the romance of an
          intimate dining experience at one of Split's most charming restaurants.
        </p>
        <p>
          These exceptional establishments offer not only a captivating atmosphere through their modern design and
          awe-inspiring views, but also tantalize the taste buds with delectable cuisine and an extensive array of fine
          wines. With a plethora of choices, travelers to Split may find themselves spoilt for choice when selecting the
          ideal venue for an enchanting meal with their beloved.
        </p>
        <p>
          From picturesque promenade eateries to delightful bistros, the splendid city of Split boasts an impressive
          variety of dining options to cater to your every whim.
        </p>
        <p>
          The enchanting ambiance of Split is undeniable, particularly during the vibrant spring and summer months when
          the city comes alive with vivid colors, dazzling lights, and captivating festivals. While relishing a
          sumptuous meal, diners can bask in the breathtaking views of the historic 1700-year-old Diocletian's Palace.
          Afterward, a leisurely stroll to the pristine beach or the majestic surrounding mountains provides the perfect
          backdrop for a romantic sunset over the glistening Adriatic Sea with your cherished companion.
        </p>
        <Img src={ROMANTIC_RESTAURANTS.split.src} alt={ROMANTIC_RESTAURANTS.split.alt} className={css.imgSingle} />
      </div>
      <div className={css.box}>
        <h3>Terrace Vidilica</h3>
        <p>
          Nestled atop a hill overlooking the city, Terrace Vidilica offers an unparalleled dining experience amidst the
          stunning backdrop of the south side of the Marjan park-forest. This enchanting restaurant promises a night to
          remember, combining breathtaking views with exquisite cuisine and a curated selection of fine wines.
        </p>
        <p>
          As one of the most romantic locations in the entire city, Terrace Vidilica boasts unobstructed views of the
          iconic old town of Split. The restaurant prides itself on offering mouth-watering dishes and an extensive wine
          list, ensuring an unforgettable evening for its guests.
        </p>
        <p>
          While the journey to Terrace Vidilica may require a brief ascent up a flight of stairs or a quick taxi ride,
          the destination is well worth the effort. Free parking is a rare commodity in the vicinity of Vidilica, so the
          restaurant recommends alternative modes of transportation for your convenience.
        </p>
      </div>
      <div className={css.box}>
        <h3>Restaurant Dvor</h3>
        <p>
          Situated just a short walk away from the bustling city center, Restaurant Dvor offers an enchanting escape for
          couples and food connoisseurs alike. Boasting a prime location overlooking a pristine sandy beach, this
          exquisite dining establishment has firmly cemented its reputation as the premier romantic restaurant in Split.
        </p>
        <p>
          Patrons can indulge in an expertly curated menu featuring the finest Mediterranean cuisine alongside authentic
          Dalmatian dishes, all at remarkably affordable prices. The pièce de résistance of Restaurant Dvor, however, is
          undoubtedly its expansive outdoor terrace.
        </p>
        <p>
          Diners are invited to soak in the breathtaking views of the nearby islands and the gentle ebb and flow of the
          waves as they break upon the shore. This stunning backdrop, coupled with the sumptuous culinary offerings,
          ensures that every visit to Restaurant Dvor is truly an unforgettable experience.
        </p>
      </div>
      <div className={css.box}>
        <h3>The Caper Grill</h3>
        <p>
          Placed within the luxurious Radisson Blu Hotel, the Caper Grill strives to uphold the highest standards
          synonymous with the esteemed hotel brand. Boasting a beautifully curated Mediterranean menu, exquisite décor,
          and an atmosphere that exudes elegance, the Caper Grill effortlessly elevates itself to the realm of fine
          dining.
        </p>
        <p>
          As one of Split's premier romantic dining destinations, the Caper Grill captivates guests with its stunning
          panoramic views of the bay. Surrender to a sumptuous feast for the senses at this remarkable gastronomic
          haven.
        </p>
        <div className={css.gallery}>
          {ROMANTIC_RESTAURANTS.grill.map((img, index) => (
            <Img key={`img-${index}`} src={img.src} alt={img.alt} />
          ))}
        </div>
      </div>
      <div className={css.box}>
        <h3>SURPRISE YOUR SIGNIFICANT OTHER</h3>
        <p>
          We highly recommend visiting the esteemed restaurants Adriatic Graso and Zrno Soli, conveniently situated near
          the ACI Marina. Additionally, consider the recently opened F De Mar and the exquisite Re di Mare, which can be
          found on the opposite side of town.
        </p>
        <p>
          If you'e seeking an extraordinary experience to dazzle your partner, beyond visiting one of Split's romantic
          restaurants, simply let us know.
        </p>
        <p>
          You can charter a boat or reserve a private tour for an intimate sailing excursion to nearby islands or
          secluded beaches, creating a truly unforgettable memory for you both.
        </p>
      </div>
    </div>
  );
};

export default RomanticRestaurants;
